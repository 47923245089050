import React, { useState } from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';

type UserData = {
  userLang?: string;
  countryISOCode: string;
  deviceType: string;
};

const LanguagePie = ({ data, loading }: { data: UserData[]; loading: boolean }) => {
  // Initialize language counts for a fixed set of languages
  const initialLanguageCounts = { ar: 0, en: 0, fr: 0, id: 0 };

  // Normalize language codes and count occurrences
  const languageCounts = data.reduce((acc, item) => {
    const lang = item.userLang?.toLowerCase(); // Normalize to lowercase
    if (lang && acc.hasOwnProperty(lang)) {
      // Only count if the language is one of the fixed set
      acc[lang] += 1;
    }
    return acc;
  }, initialLanguageCounts);

  // Calculate the total count
  const totalCount = Object.values(languageCounts).reduce((sum, count) => sum + count, 0);

  // Prepare chart data and include percentage calculation
  const chartData = Object.entries(languageCounts).map(([key, value]) => {
    const percent = ((value / totalCount) * 100).toFixed(2);
    return {
      name: key.toUpperCase(), // Display the language code in uppercase for consistency
      value,
      percent: `${percent}%`
    };
  });

  type ChartData = {
    name: string;
    value: number;
    percent: string;
  };

  // Colors for different languages
  const COLORS = ['#2980BA', '#00C49F', '#FFBB28', '#FF8042'];

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index: number) => {
    setActiveIndex(index);
  };

  // Custom Tooltip content
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload as ChartData; // Typecast for better type safety
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}
        >
          <p className="label">{`${data.name} : ${data.percent}`}</p>
        </div>
      );
    }

    return null;
  };

  const generateChartData = (
    data: UserData[],
    key: 'countryISOCode' | 'deviceType'
  ): ChartData[] => {
    const counts = data.reduce((acc, item) => {
      const value = item[key]?.toLowerCase(); // Normalize to lowercase
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      }
      return acc;
    }, {} as Record<string, number>);

    const totalCount = Object.values(counts).reduce((sum, count) => sum + count, 0);

    return Object.entries(counts).map(([key, value]) => ({
      name: key.toUpperCase(), // Display in uppercase
      value,
      percent: `${((value / totalCount) * 100).toFixed(2)}%`
    }));
  };

  // Generate chart data for both countries and device types
  const countryChartData = generateChartData(data, 'countryISOCode');
  const deviceChartData = generateChartData(data, 'deviceType');

  // Custom Legend Renderer
  const renderCustomLegend = ({ payload }: any) => {
    return (
      <div className="flex justify-center">
        {!loading && (
          <ul className="custom-legend grid grid-cols-2 gap-2 text-left">
            {payload.map((entry: any, index: number) => (
              <li
                key={`item-${index}`}
                style={{ color: entry.color }}
              >
                <span style={{ color: entry.color }}>■ </span>
                {`${entry.payload.name} (${entry.payload.percent})`}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2">
      <div className="text-center">
        <h3>Languages</h3>
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              onMouseEnter={onPieEnter}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderCustomLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="text-center">
        <h3>Device Types</h3>
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              data={deviceChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              onMouseEnter={onPieEnter}
            >
              {deviceChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderCustomLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LanguagePie;
