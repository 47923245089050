import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from 'src/helper/firebase';
import axiosInstance from 'src/helper/AxiosInstance';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import Table from 'src/components/shared/tables';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useIntl } from 'react-intl';

export default function Index() {
  const [appointment, setAppointment] = useState<any[]>([]);
  const [userCalls, setUserCalls] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const { width, height } = useWindowDimensions();
  const database = getDatabase(app);
  const navigate = useNavigate();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);

  const handleJoinClick = (roomID: string, time) => {
    const newDetails = {
      roomID: roomID,
      userID: '12a345b6c78d9',
      userName: 'SUPPORT',
      appId: 1588161922,
      secretKey: '70b700b662f12e892602c841ce7d648a',
      time: time
    };

    navigate('/zego-cloud', { state: newDetails });
  };

  const fetchAppointmentData = async () => {
    try {
      const { data } = await axiosInstance.get('appointments');
      setAppointment(data.result.data);
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentData();
  }, []);

  useEffect(() => {
    const userCallsRef = ref(database, '/userCallState');

    const unsubscribe = onValue(
      userCallsRef,
      (snapshot) => {
        const fetchedData: any[] = [];
        snapshot.forEach((childSnapshot: any) => {
          fetchedData.push({ key: childSnapshot.key, ...childSnapshot.val() });
        });
        setUserCalls(fetchedData);

        // تحقق إذا كانت هناك مكالمات ومواعيد مطابقة
        const newData: any = [];
        fetchedData.forEach((call) => {
          appointment.forEach((app) => {
            const isCallerMatching =
              call?.callerID === call?.key &&
              call?.callState === 'oncall' &&
              call?.roomId === app?.appointmentId;

            if (
              isCallerMatching &&
              call?.roomId === app?.appointmentId &&
              !newData.some((item) => item.call.roomId === call.roomId)
            ) {
              newData.push({ call, app });
            }
          });
        });

        // تحديث البيانات الجديدة
        if (newData.length > 0) {
          setData(newData);
        } else {
          setData([]);
        }
      },
      (error) => {
        console.error('Error fetching user calls:', error);
      }
    );

    return () => {
      unsubscribe(); // تنظيف الاشتراك عند إلغاء التثبيت
    };
  }, [database, appointment]);

  console.log('User Calls Data:', userCalls);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  return (
    <div
      className=" p-8 pt-12 space-y-4  "
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div className="">
        <Table
          loading={false}
          timeAble={false}
          customizeAble={false}
          count={data.length}
          permissionsAble={false}
          searchAble={false}
          buttonAble={false}
          PromoAble={false}
          isEmpty={!data.length}
          calls={false}
          TimeTable={(props) => <></>}
          CustomizeTable={() => <></>}
          RenderBody={({ getRowColor }) => (
            <>
              {data.map((row, index) => (
                <tr
                  style={{ backgroundColor: getRowColor(index) }}
                  className="cursor-pointer"
                >
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {index + 1}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {row?.app?.consult?.name}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {row?.app?.user?.name}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch ">
                    <div className="flex items-center justify-center gap-4">
                      <button
                        className="join-button flex items-center justify-center gap-4 bg-[#28c794] w-[118px] px-1 py-2 rounded-[4px]"
                        onClick={() =>
                          handleJoinClick(row?.app?.appointmentId, row?.call?.timeStamp)
                        }
                      >
                        <p className="w-[32px] text-base text-left text-white">Join</p>
                        <Icon
                          className="text-white"
                          icon="mdi:video"
                          width={29.5}
                          height={29.5}
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
          RenderHead={() => (
            <>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                #
              </th>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'consultantName',
                  defaultMessage: 'consultant name'
                })}
              </th>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'userName',
                  defaultMessage: 'user name'
                })}
              </th>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                join
              </th>
            </>
          )}
        />
      </div>
    </div>
  );
}
