import React from 'react';
import {
  ResponsiveContainer,
  Funnel,
  FunnelChart,
  Tooltip,
  Layer,
  LabelList,
  Label,
  Line,
  TooltipProps
} from 'recharts';

interface FunnelChartComponentProps {
  userCategories: {
    [key: string]: number;
  };
}
const FunnelChartComponent: React.FC<FunnelChartComponentProps> = ({ userCategories = {} }) => {
  const defaultColors = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d'];
  const habitUserColor = '#FFA500'; // Orange color for habitUsers
  const minValueForVisibility = 100; // Set a minimum value for visibility

  const totalUsers = Object.values(userCategories).reduce((acc, cur) => acc + cur, 0);

  const data = Object.entries(userCategories).map(([key, value], index) => {
    let adjustedValue = value;

    // If the value is too small and it's a significant category, adjust it to the minimum visibility threshold.
    if (value < minValueForVisibility && key === 'habitUsers') {
      adjustedValue = minValueForVisibility;
    }

    return {
      value: adjustedValue,
      name: key
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toLowerCase()
        .replace('users', '')
        .trim(),
      fill: key === 'habitUsers' ? habitUserColor : defaultColors[index % defaultColors.length]
    };
  });

  // Define the expected prop types for the CustomTooltip
  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
  }

  // Custom tooltip
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
      const percentage = ((value / totalUsers) * 100).toFixed(2) + '%';
      return (
        <div style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{name}</p>
          <p>{`Percentage: ${percentage}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div>
      <div className="flex justify-center mb-2 font-bold">
        <h3>Customer retention funnel</h3>
      </div>
      <ResponsiveContainer
        width="100%"
        height={400}
      >
        <FunnelChart>
          <Tooltip content={<CustomTooltip />} />
          <Funnel
            dataKey="value"
            data={data}
            isAnimationActive
            labelLine
          >
            {data.map((entry, index) => (
              <Layer key={`custom-${index}`} />
            ))}
          </Funnel>
        </FunnelChart>
      </ResponsiveContainer>
      <div className="absolute bottom-0">
        {data.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{ margin: '10px 0', color: entry.fill }}
          >
            <strong>{entry.name}</strong>: {entry.value} (
            {((entry.value / totalUsers) * 100).toFixed(2)}
            %)
          </div>
        ))}
      </div>
    </div>
  );
};

export default FunnelChartComponent;
