import React from 'react'

export const Responsive = {
    mobileWidth: 574,
    mobileHeight: 1242,
    webWidth: 1920,
    webHeight: 1074,
    maxMobileWidth: 500,
  
    w: function(value, width) {
      let appropriateWidth = width >= this.maxMobileWidth ? this.webWidth : this.mobileWidth;
      return (value / appropriateWidth) * width;
    },
  
    h: function(value, height) {
      let appropriateHeight = height >= this.maxMobileWidth ? this.webHeight : this.mobileHeight;
      return (value / appropriateHeight) * height;
    },
  
    sp: function(value, width) {
      let appropriateWidth = width >= this.maxMobileWidth ? this.webWidth : this.mobileWidth;
      return (value / appropriateWidth) * width;
    },
}

