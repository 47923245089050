import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { getDates } from 'src/helper/DateUtils';
import parseJwt from 'src/helper/parseJwt';
import { setDashboard } from 'src/reducers/dashboard';
import { UpdateResult } from 'src/reducers/users';
import { RootState } from 'src/store';

export default function Index() {
  const navigate = useNavigate();
  const { dashboard, appStoreLink, playStoreLink } = useSelector(
    (state: RootState) => state.Dashboard
  );
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axiosInstance.get('dashboard/consultants-count');
      setData(res.data.result.data);
      setLoading(false);
      console.log('data', res.data.result.data);
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  // Now safe to call useHooks and other logic

  return (
    <div className="p-8 pt-12 space-y-6 bg-slate-100 min-h-screen">
      <p className="font-semibold text-3xl">
        <FormattedMessage
          id="serviceProviders"
          defaultMessage="Service providers"
        />
      </p>
      <div className="grid grid-cols-2 gap-8 w-3/4 mx-auto text-xl">
        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={24}
            height={24}
            icon="mdi:abjad-arabic"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="activeArabicFreelancers"
              defaultMessage="Active Arabic Freelancers"
            />
          </p>
          <p className="font-semibold">{loading ? <Spinner /> : data?.arabicConsultants}</p>
        </Card>
        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={24}
            height={24}
            icon="icon-park-outline:english"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="activeEnglishFreelancers"
              defaultMessage="Active English Freelancers"
            />
          </p>
          <p className="font-semibold">{loading ? <Spinner /> : data?.englishConsultants}</p>
        </Card>
        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={24}
            height={24}
            icon="material-symbols:language-french"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="activeFrenchFreelancers"
              defaultMessage="Active French Freelancers"
            />
          </p>
          <p className="font-semibold">{loading ? <Spinner /> : data?.frenchConsultants}</p>
        </Card>
        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={24}
            height={24}
            icon="fluent-emoji-high-contrast:id-button"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="activeIndonesianFreelancers"
              defaultMessage="Active Indonesian Freelancers"
            />
          </p>
          <p className="font-semibold">{loading ? <Spinner /> : data?.indonesianConsultants}</p>
        </Card>
        <Card className="flex flex-col justify-center items-center py-12 col-span-2">
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={24}
            height={24}
            icon="ooui:block"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="inactiveFreelancers"
              defaultMessage="Inactive Freelancers"
            />
          </p>
          <p className="font-semibold">{loading ? <Spinner /> : data?.inactiveConsultants}</p>
        </Card>
      </div>
    </div>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color:dashColor
          }}
        />
       
      </div>
    </>
  );
}
