import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import SideModal from 'src/components/shared/SideModal';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ChangeCustomizeKeys, UpdateFilter, UpdateResult } from 'src/reducers/payments/tap';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import SharedTime from 'src/components/shared/SharedTime';
import SortButton from 'src/components/shared/SortButton';
import { useAlert } from 'src/hooks/alerts';
import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';
import { FormattedMessage, useIntl } from 'react-intl';
import RangePicker from 'src/components/shared/RangePicker';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import TodayTransactions from './TodayTransactions';
import { Responsive } from 'src/layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';

function useHooks() {
  let rerender: boolean = true;
  const { filters: userFilters } = useSelector((state: RootState) => state.Tap);
  console.log('userFilters', userFilters);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  interface IFilters {
    limit: number;
    from: string;
    to: string;
    starting_after: string;
    customers: string[];
    status: string;
  }
  const [filters, setFilters] = useState<Partial<IFilters>>({
    limit: 25
  });

  const [todayChargesCount, setTodayChargesCount] = useState({
    INITIATED: 0,
    IN_PROGRESS: 0,
    ABANDONED: 0,
    CANCELLED: 0,
    FAILED: 0,
    DECLINED: 0,
    RESTRICTED: 0,
    CAPTURED: 0,
    VOID: 0,
    TIMEDOUT: 0,
    UNKNOWN: 0
  });
  const [countLoading, setCountLoading] = useState(false);
  useEffect(() => {
    if (!rerender) return;
    GetCharges();
    rerender = false;
  }, [filters]);

  useEffect(() => {
    fetchTodayCharges();
  }, []);

  const fetchTodayCharges = async () => {
    try {
      setCountLoading(true);
      const response = await axiosInstance.get('payments/tap/today-charges');
      const todayChargesData = response.data.result.data.chargesData;
      console.log('todayChargesData', todayChargesData);

      const updatedCounts = { ...todayChargesCount }; // Create a copy of the current state

      for (const charge of todayChargesData) {
        const status = charge.status.toUpperCase(); // Assuming status is in uppercase

        if (!updatedCounts[status]) {
          updatedCounts[status] = 0; // Initialize if not present
        }

        updatedCounts[status]++;
      }

      setTodayChargesCount(updatedCounts); // Update the state
      setCountLoading(false);
    } catch (error) {
      console.error('Error fetching today charges:', error);
      // Handle errors appropriately
    }
  };

  async function GetCharges(params?: Partial<IFilters>) {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get('payments/tap/charges', {
        params: { ...filters, ...params }
      });
      console.log('ah', { ...filters, ...params });
      dispatch(UpdateResult(data.result));
    } catch (error: AxiosError | any) {
      const messages = error?.response?.data?.result?.errors || [];
      const message = messages?.map((e: any) => e.description).join(', ');

      generateAlert(message, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const ChangeFilter = React.useCallback(
    (value: any, key: any) => {
      let result: any = { ...filters };

      switch (key) {
        case 'queries':
          result.date = value.date;
          result.status = value.status;
          break;
        case '':
          result.date = undefined;
          result.status = undefined;
          result.starting_after = undefined;
          break;
        default:
          result[key] = value;
          break;
      }
      // GetCharges(result);
      setFilters(result);
      dispatch(UpdateFilter(result));
    },
    [filters, userFilters]
  );

  return { isLoading, GetCharges, ChangeFilter, filters, todayChargesCount, countLoading };
}

export default function index() {
  const { width, height } = useWindowDimensions();
  const tableRef = useRef(null);
  const [title, setTitle] = useState<any>('allTransactions');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  async function ExportXlsx() {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        'payments/tap/charges/export',
        {},
        {
          params: filters,
          responseType: 'blob'
        }
      );
      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  const formatDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };

  const { isLoading, GetCharges, ChangeFilter, filters, todayChargesCount, countLoading } =
    useHooks();
  const { keys, customize_keys, data, pagination } = useSelector((state: RootState) => state.Tap);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const [filter, setFilter] = useState<any>(filters);
  
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const ChangeFilters = useCallback(
    (key: any, type?: any) => (e: any) => {
      let result: any = { ...filters };
      const value = e.target.value;

      result[key] = value;

      setFilter(result);
    },
    [filters]
  );

  const allTransactionsMessage = 'allTransactions';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = (option, title) => {
    setTitle(title);
    // Check if the option is 'Tab Table'
    if (option === 'All Transactions') {
      ChangeFilter('', '');
      return; // Exit the function early
    }

    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter({ from: formatStartDay(startDate), to: formatEndDay(endDate) }, 'date');
    }
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  console.log('data👩', data?.at(-1));
  console.log('pagination', pagination);
  return (
    <div className="p-8  space-y-4 overflow-x-hidden">
      <TodayTransactions
        todayChargesCount={todayChargesCount}
        countLoading={countLoading}
        ChangeFilter={ChangeFilter}
        tableRef={tableRef}
        color={dashColor}
      />
      {/* <p className="font-semibold text-3xl">
        <FormattedMessage
          id="overallTransactions"
          defaultMessage="Overall Transactions"
        />
      </p>
      <TableCustomizeFilterModal
        SendPrams={(val: any) => ChangeFilter(val, 'queries')}
        params={filters}
      /> */}
      <div
        ref={tableRef}
        className="relative"
      >
        {isLoading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-400 z-30 bg-opacity-50">
            <span className="text-lg font-semibold">Loading...</span>
          </div>
        )}
        <Table
          loading={isLoading}
          notCountable={true}
          isEmpty={!data.length}
          searchAble={true}
          title={title}
          TimeTable={(props) => (
            <div
              // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
              className={`absolute top-[35px] ${
                title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
              } bg-[#f5f3f8] p-[12px]`}
              style={{
                width: `${Responsive.w(572, width)}px`,
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
              }}
            >
              <ul
                className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
                aria-labelledby="dropdownDefaultButton"
                // style={{
                //   width: `${Responsive.w(572, width)}px`,
                // }}
              >
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full"
                    onClick={() => {
                      handleDateOptionClick('All Transactions', allTransactionsMessage);
                    }}
                  >
                    {<FormattedMessage id={allTransactionsMessage} defaultMessage={allTransactionsMessage} />}
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full "
                    onClick={() => handleDateOptionClick('Today', todayMessage)}
                  >
                    {<FormattedMessage id={todayMessage} defaultMessage={todayMessage} />}
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                    onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                  >
                    {<FormattedMessage id={last7DaysMessage} defaultMessage={last7DaysMessage} />}
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                  >
                    {<FormattedMessage id={last30DaysMessage} defaultMessage={last30DaysMessage} />}
                  </a>
                </li>
                <li className="w-full text-left">
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={() => {
                      handleDateOptionClick('Date range', dateRangeMessage);
                    }}
                  >
                    {<FormattedMessage id={dateRangeMessage} defaultMessage={dateRangeMessage} />}
                  </a>
                </li>
              </ul>
            </div>
          )}
          CustomizeTable={() => (
            <>
              {
                <div
                  className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] "
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(760, height)}px`,
                    borderRadius: `${Responsive.sp(16, width)}px`,
                    boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                  }}
                >
                  <div
                    className="space-y-4 flex flex-col z-[50]"
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    <div className="grid">
                      <ul
                        className="flex items-start justify-center p-0 flex-row"
                        style={{
                          width: `${Responsive.w(335, width)}px`,
                          height: `${Responsive.h(40, height)}px`,
                          gap: `${Responsive.sp(24, width)}px`
                        }}
                      >
                        {tabs.map((tab: any, index: number) => (
                          <li key={index}>
                            <button
                              className={[
                                'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                                currentTab === tab.key
                                  ? ` ${
                                      currentDashboard !== 'jeras'
                                        ? '!text-[#9c3981] border-b-[#9c3981]'
                                        : '!text-[#7b6c96] border-b-[#7b6c96]'
                                    } border-b-2 `
                                  : '!text-gray-800'
                              ].join(' ')}
                              onClick={() => setCurrentTab(tab.key)}
                            >
                              <span>{tab.name}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="flex items-start justify-start flex-col overflow-y-auto "
                      style={{
                        width: `${Responsive.w(369, width)}px`,
                        height: `${Responsive.h(710, height)}px`
                      }}
                    >
                      {currentTab === 'customize' ? (
                        <ul
                          className="space-y-2 overflow-y-auto"
                          style={{
                            width: `${Responsive.w(369, width)}px`,
                            height: `${Responsive.h(710, height)}px`
                          }}
                        >
                          {keys.map((field: string, index: number) => (
                            <li
                              className="flex flex-row items-start justify-start py-0 px-4 "
                              key={index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(12, width)}px`
                              }}
                            >
                              <label
                                htmlFor={field + '-' + index}
                                style={{
                                  width: `${Responsive.w(345, width)}px`,
                                  height: `${Responsive.h(40, height)}px`,
                                  gap: `${Responsive.sp(8, width)}px`
                                }}
                                className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                              >
                                <input
                                  type="checkbox"
                                  name={field + '-' + index}
                                  id={field + '-' + index}
                                  className="form-checkbox shrink-0"
                                  style={{
                                    width: `${Responsive.w(20, width)}px`,
                                    height: `${Responsive.h(20, height)}px`
                                  }}
                                  value={field}
                                  checked={customize_keys.some((key) => key === field)}
                                  onChange={ChangeCustomizeKey}
                                />

                                <p
                                  className=" font-medium capitalize flex justify-center items-center"
                                  style={{
                                    fontSize: `${Responsive.sp(14, width)}px`
                                  }}
                                >
                                  {useIntl().formatMessage({
                                    id: field,
                                    defaultMessage: field
                                  })}
                                </p>
                              </label>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <form
                          className="space-y-3"
                          onSubmit={(e) => {
                            e.preventDefault();
                            params: filter;
                          }}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="date-from"
                            >
                              {useIntl().formatMessage({
                                id: 'from',
                                defaultMessage: 'from'
                              })}
                            </label>
                            <input
                              type="date"
                              className="form-input"
                              name="date-from"
                              id="date-from"
                              onChange={ChangeFilters('from')}
                              defaultValue={filters?.from}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="date-to"
                            >
                              {useIntl().formatMessage({
                                id: 'to',
                                defaultMessage: 'to'
                              })}
                            </label>
                            <input
                              type="date"
                              className="form-input"
                              name="date-to"
                              id="date-to"
                              onChange={ChangeFilters('to')}
                              defaultValue={filters?.to}
                              min={filters?.from}
                              required
                            />
                          </div>

                          <div className="flex gap-2 flex-wrap justify-end">
                            <button
                              className="btn-with-icon "
                              style={{
                                backgroundColor:dashColor
                              }}
                              type="submit"
                            >
                              <span>
                                {' '}
                                {useIntl().formatMessage({
                                  id: 'apply',
                                  defaultMessage: 'apply'
                                })}
                              </span>
                            </button>
                            <button
                              className="btn-with-icon bg-gray-200 !text-gray-600"
                              type="reset"
                              onClick={() => {
                                ChangeFilter('', '');
                              }}
                            >
                              <span>
                                {' '}
                                {useIntl().formatMessage({
                                  id: 'clear',
                                  defaultMessage: 'clear'
                                })}
                              </span>
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              }
            </>
          )}
          RenderBody={({ getRowColor }) => (
            <>
              {data?.map((item: any, index: number) => (
                <tr
                  key={index}
                  style={{ backgroundColor: getRowColor(index) }}
                >
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {index + 1}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.customer?.first_name || 'N/A'}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.customer?.phone?.number}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.amount || 0)}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.currency || 'N/A'}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <p
                      className="flex items-center justify-center"
                      style={{
                        height: '30px',
                        border:
                          item?.status === 'CAPTURED'
                            ? 'solid 1px #28c794'
                            : item?.status === 'FAILED'
                            ? 'solid 1px #f93c65'
                            : item?.status === 'ABANDONED'
                            ? 'solid 1px #e6bc59'
                            : item?.status === 'INITIATED'
                            ? 'solid 1px #2980ba'
                            : item?.status === 'CANCELLED'
                            ? `solid 1px ${currentDashboard !== 'jeras'?'#9c3981': '#7b6c96' }`
                            : item?.status === 'DECLINED'
                            ? 'solid 1px #ba2b4a'
                            : '',
                        backgroundColor:
                          item?.status === 'CAPTURED'
                            ? '#ebfeef'
                            : item?.status === 'FAILED'
                            ? '#ffe2e2'
                            : item?.status === 'ABANDONED'
                            ? '#fff9e9'
                            : item?.status === 'INITIATED'
                            ? '#d8eefd'
                            : item?.status === 'CANCELLED'
                            ? `${currentDashboard !== 'jeras'?'#fff7fd': '#e5e1ec' }`
                            : item?.status === 'DECLINED'
                            ? '#ffe2e2'
                            : '',
                        color:
                          item?.status === 'CAPTURED'
                            ? ' #228176'
                            : item?.status === 'FAILED'
                            ? '#f93c65'
                            : item?.status === 'ABANDONED'
                            ? '#d7a93d'
                            : item?.status === 'INITIATED'
                            ? '#2980ba'
                            : item?.status === 'CANCELLED'
                            ? `${currentDashboard !== 'jeras'?'#9c3981': '#7b6c96' }`
                            : item?.status === 'DECLINED'
                            ? '#ba2b4a'
                            : ''
                      }}
                    >
                      {item?.status || 'N/A'}
                    </p>
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {formatDay(Number(item?.transaction?.created))}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.card?.brand || 'N/A'}
                  </td>
                </tr>
              ))}
            </>
          )}
          RenderHead={() => (
            <>
              <th
                className="text-center nowrap text-[#fff]  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                #
              </th>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="customerName"
                  text={useIntl().formatMessage({
                    id: 'customerName',
                    defaultMessage: 'customer name'
                  })}
                  handler={ChangeFilter}
                />
              </th>

              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="customerPhone"
                  text={useIntl().formatMessage({
                    id: 'customerPhone',
                    defaultMessage: 'customer phone'
                  })}
                  handler={ChangeFilter}
                />
              </th>

              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="amount"
                  text={useIntl().formatMessage({
                    id: 'amount',
                    defaultMessage: 'amount'
                  })}
                  handler={ChangeFilter}
                />
              </th>

              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="currency"
                  text={useIntl().formatMessage({
                    id: 'currency',
                    defaultMessage: 'currency'
                  })}
                  handler={ChangeFilter}
                />
              </th>

              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="status"
                  text={useIntl().formatMessage({
                    id: 'status',
                    defaultMessage: 'status'
                  })}
                  handler={ChangeFilter}
                />
              </th>

              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="transaction"
                  text={useIntl().formatMessage({
                    id: 'transaction',
                    defaultMessage: 'transaction date'
                  })}
                  handler={ChangeFilter}
                />
              </th>
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor:dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="card"
                  text={useIntl().formatMessage({
                    id: 'card',
                    defaultMessage: 'card'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            </>
          )}
          onApply={(startDate, endDate) => {
            setStart(startDate);
            setEnd(endDate);
          }}
          buttonExport={() => ExportXlsx()}
          pagination={{ ...pagination }}
          onNextClick={() => ChangeFilter(data?.at(-1)['id'], 'starting_after')}
          onPreviousClick={() => ChangeFilter(data?.at(0)['id'], 'starting_after')}
          ChangePerPageLimit={(e: any) => {
            if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'limit');
          }}
          
        />
      </div>
    </div>
  );
}

function TableCustomizeFilterModal({ SendPrams, params }: any) {
  const {
    keys,
    customize_keys,
    filters: userFilters
  } = useSelector((state: RootState) => state.Tap);
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const [visible, setVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(userFilters);

  const dispatch = useDispatch();

  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      let result: any = { ...filters };
      const value = e.target.value;

      result[key] = value;

      setFilters(result);
    },
    [filters]
  );

  const { ChangeFilter } = useHooks();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  return (
    <>
      <div>
        <div className="flex items-center flex-wrap gap-3 justify-between ">
          <RangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            // applyHandler={() =>
            //   ChangeFilter({ from: formatStartDay(startDate), to: formatEndDay(endDate) }, 'date')
            // }
          />
          <div className="flex items-center flex-wrap gap-3 ">
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => SendPrams([])}
              disabled={filters.length == 0 && params.queries.length == 0}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            {/* <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={ExportXlsx}
              disabled={exportDisabled}
            >
              <Icon
                icon="bi:filetype-xlsx"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'exportExcel',
                  defaultMessage: 'Export Excel'
                })}
              </span>
            </button> */}
            {/* <button
              className="btn-with-icon bg-gray-800 text-white"
              onClick={() => setVisible(true)}
            >
              <Icon
                icon="majesticons:filter-line"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'filterAndCustomizeTable',
                  defaultMessage: 'Filter & Customize table'
                })}
              </span>
            </button> */}
          </div>
        </div>
      </div>
      <SideModal
        title={useIntl().formatMessage({
          id: 'filterAndCustomizeTable',
          defaultMessage: 'Filter & Customize table'
        })}
        visible={visible}
        handleClose={() => setVisible(false)}
        size="max-w-screen-sm"
      >
        <div className="space-y-4 flex flex-col overflow-y-auto">
          <div className="border-b border-b-gray-200 overflow-x-auto grid">
            <ul className="inline-flex">
              {tabs.map((tab: any, index: number) => (
                <li key={index}>
                  <button
                    className={[
                      'btn-with-icon !rounded-none font-medium bg-transparent transition-all',
                      currentTab === tab.key
                        ? '!text-gray-800 border-b-2 border-b-gray-800'
                        : '!text-gray-500'
                    ].join(' ')}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    <span>{tab.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 grid overflow-y-auto">
            {currentTab === 'customize' ? (
              <ul className="space-y-2">
                {keys.map((field: string, index: number) => (
                  <li key={index}>
                    <label
                      htmlFor={field + '-' + index}
                      className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-200  cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        name={field + '-' + index}
                        id={field + '-' + index}
                        className="form-checkbox shrink-0"
                        value={field}
                        checked={customize_keys.some((key) => key === field)}
                        onChange={ChangeCustomizeKey}
                      />

                      <p className="text-sm font-medium capitalize flex-1">
                        {useIntl().formatMessage({
                          id: field,
                          defaultMessage: field
                        })}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <form
                className="space-y-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  SendPrams(filters);
                  setVisible(false);
                }}
              >
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="date-from"
                  >
                    {useIntl().formatMessage({
                      id: 'from',
                      defaultMessage: 'from'
                    })}
                  </label>
                  <input
                    type="date"
                    className="form-input"
                    name="date-from"
                    id="date-from"
                    onChange={ChangeFilters('from')}
                    defaultValue={filters?.from}
                    required
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="date-to"
                  >
                    {useIntl().formatMessage({
                      id: 'to',
                      defaultMessage: 'to'
                    })}
                  </label>
                  <input
                    type="date"
                    className="form-input"
                    name="date-to"
                    id="date-to"
                    onChange={ChangeFilters('to')}
                    defaultValue={filters?.to}
                    min={filters?.from}
                    required
                  />
                </div>

                <div className="flex gap-2 flex-wrap justify-end">
                  <button
                    className="btn-with-icon bg-gray-200 !text-gray-600"
                    type="reset"
                    onClick={() => {
                      // ChangeFilter([...initialQueries], 'queries')
                    }}
                  >
                    <span>
                      {' '}
                      {useIntl().formatMessage({
                        id: 'clear',
                        defaultMessage: 'clear'
                      })}
                    </span>
                  </button>
                  <button
                    className="btn-with-icon bg-gray-800"
                    type="submit"
                  >
                    <span>
                      {' '}
                      {useIntl().formatMessage({
                        id: 'apply',
                        defaultMessage: 'apply'
                      })}
                    </span>
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </SideModal>
    </>
  );
}
