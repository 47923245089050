import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font
} from '@react-pdf/renderer';
import Amiri from './Amiri-Regular.ttf';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import LoadingComponent from '../shared/LoadingComponent';
Font.register({
  family: 'Amiri',
  fonts: [
    {
      src: Amiri,
      fontWeight: 'bold'
    }
  ]
});

const DownloadInvoicePDF = ({ document }) => {
  return (
    <div>
      <PDFDownloadLink
        document={document}
        fileName="invoice.pdf"
      >
        {({ blob, url, loading, error }) => {
          if (error) {
            // console.log('ee', error);
            // toast.error('There is an error. Please try again.');
          }
          while (loading)
            return (
              <Icon
                icon="svg-spinners:3-dots-fade h-[24px] w-[24px]"
                width={24}
                height={24}
                className="text-[#fff]"
              />
            );
          return <Icon icon="material-symbols:download" />;
        }}
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadInvoicePDF;

