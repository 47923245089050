import React, { useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

type Order = {
  utcTime: string; // Updated type to include utcTime
  price: string; // or number if it's always a number
};

type ProcessedData = {
  date: string;
  total: number;
};

function CumulativePayedBalanceChart({ data }) {
  const processData = (orders: Order[]): { data: ProcessedData[]; sameYear: boolean } => {
    const earningsByDay: Record<string, number> = {};
    let sameYear = true;
    let prevYear: number | null = null;

    orders.forEach((order) => {
      if (order.utcTime && order.price) {
        const date = new Date(order.utcTime);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // Months are zero indexed
        const day = date.getUTCDate();
        const dateKey = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        if (prevYear !== null && prevYear !== year) {
          sameYear = false;
        }
        prevYear = year;

        const price = parseFloat(order.price);
        if (!isNaN(price)) {
          if (!earningsByDay[dateKey]) {
            earningsByDay[dateKey] = price;
          } else {
            earningsByDay[dateKey] += price;
          }
        } else {
          console.warn(`Invalid price for order on ${dateKey}: ${order.price}`);
        }
      }
    });

    const dailyData: ProcessedData[] = [];

    Object.keys(earningsByDay).forEach((date) => {
      const total = Math.round(earningsByDay[date] * 100) / 100;
      dailyData.push({ date, total });
    });

    return {
      data: dailyData,
      sameYear
    };
  };

  const { data: chartData, sameYear } = useMemo(() => processData(data), [data]);

  const formatDateForAxis = (date: string): string => {
    const [year, month, day] = date.split('-');
    // Display only day and month if all dates are in the same year
    return sameYear ? `${day}-${month}` : date;
  };

  const fullDateFormat = (date: string): string => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`; // Change this to your preferred date format
  };

  return (
    <div className="text-center w-full">
      <h2>Revenue per Day</h2>
      <ResponsiveContainer height={300}>
        <LineChart
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={formatDateForAxis}
          />
          <YAxis />
          <Tooltip labelFormatter={fullDateFormat} />
          <Legend />
          <Line
            type="monotone"
            dataKey="total"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CumulativePayedBalanceChart;

