import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const storageKeys = localStorage.getItem('tap-customize-table') as string;

export interface tapState {
  keys: string[];
  customize_keys: string[];
  data: any[];
  pagination: any;
  filters: any;
}

const initialState: tapState = {
  keys: [],
  customize_keys: JSON.parse(storageKeys) || [],
  data: [],
  pagination: null,
  filters: {}
};

export const tapSlice = createSlice({
  name: 'tap',
  initialState,
  reducers: {
    ChangeCustomizeKeys(state, action) {
      const { checked, value } = action.payload;
      const { keys, customize_keys } = state;

      if (!keys.includes(value) && checked) return;

      const filterKeys = customize_keys.filter((key) => key !== value);
      const collectKeys = new Array().concat(customize_keys, value);
      const data = [...new Set(checked ? collectKeys : filterKeys)];
      const uniqueMap = data;
      localStorage.setItem('tap-customize-table', JSON.stringify(data));

      state.customize_keys = uniqueMap;
    },

    UpdateResult(state, action) {
      const { keys, data, pagination } = action.payload;
      const { customize_keys } = state;
      const someKeys = ['amount', 'status', 'currency', 'customer'];
      state.keys = keys;
      state.data = data;
      state.pagination = pagination;
      if (customize_keys.length) return;
      state.customize_keys = someKeys;
    },

    UpdateFilter(state, action) {
      state.filters = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { ChangeCustomizeKeys, UpdateResult, UpdateFilter } = tapSlice.actions;

export default tapSlice.reducer;
