import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, redirect, NavLink } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
// import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import parseJwt from 'src/helper/parseJwt';
import { setDashboard } from 'src/reducers/dashboard';
import { UpdateResult } from 'src/reducers/users';
import { RootState } from 'src/store';
import photo from '../screens/images/Frame 37.png';
import dream from '../screens/images/dream.png';
import nikah from '../screens/images/Mask Group 35 4.png';
import img_1 from './images/Frame 1000004737.png';
import img_1_2 from './images/Frame 1000004737@2x.png';
import img_1_3 from './images/Frame 1000004737@3x.png';
import img_2 from './images/money-withdraw.png';
import img_2_2 from './images/money-withdraw@2x.png';
import img_2_3 from './images/money-withdraw@3x.png';
import img_3 from './images/Frame 1000003868.png';
import img_3_2 from './images/Frame 1000003868@2x.png';
import img_3_3 from './images/Frame 1000003868@3x.png';
import img_4 from './images/Frame 10000038681.png';
import img_4_2 from './images/Frame 10000038681@2x.png';
import img_4_3 from './images/Frame 10000038681@3x.png';
import img_5 from './images/Frame 10000038682.png';
import img_5_2 from './images/Frame 10000038682@2x.png';
import img_5_3 from './images/Frame 10000038682@3x.png';
import img_6 from './images/Frame 10000038683.png';
import img_6_2 from './images/Frame 10000038683@2x.png';
import img_6_3 from './images/Frame 10000038683@3x.png';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { usePermissions } from 'src/hooks/usePeermissions';
function useHooks() {
  const [data, setData] = useState<any>({
    todayOrders: { data: { todayOrdersCount: 0, todayOrdersEarn: 0 }, loading: true },
    clientsCount: { data: 0, loading: true },
    freelancersCount: { active: 0, inactive: 0, loading: true },
    supportCount: { data: 0, loading: true },
    promoCodesCount: { active: 0, inactive: 0, loading: true },
    recentClients: { data: [], loading: true },
    recentConsultants: { data: [], loading: true },
    recentOrders: { data: [], loading: true },
    playStoreInfo: { data: [], loading: true },
    appStoreInfo: { data: [], loading: true },
    recentTransactions: { data: [], loading: true }
  });
  console.log('data==>', data);

  useEffect(() => {
    fetchTodayOrders();
    fetchClientsCount();
    fetchFreelancersCount();
    fetchSupportCount();
    fetchPromoCodesCount();
    fetchRecentClients();
    fetchRecentConsultants();
    fetchRecentOrders();
    fetchPlayStoreInfo();
    fetchAppStoreInfo();
    fetchRecentTransaction();
  }, []);

  async function fetchTodayOrders() {
    try {
      const todayOrdersResponse = await axiosInstance.get('dashboard/todayorders');
      setData((prevData) => ({
        ...prevData,
        todayOrders: { data: todayOrdersResponse.data.result, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }

  async function fetchClientsCount() {
    try {
      const clientsCountResponse = await axiosInstance.get('dashboard/clients');
      setData((prevData) => ({
        ...prevData,
        clientsCount: { data: clientsCountResponse.data.result.clientsCount, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching clients count:', error);
    }
  }
  async function fetchRecentTransaction() {
    try {
      const clientsTransactionsResponse = await axiosInstance.get(
        'dashboard/payments/tap/charges',
        {
          params: { limit: 6, status: 'CAPTURED' }
        }
      );

      setData((prevData) => ({
        ...prevData,
        recentTransactions: { data: clientsTransactionsResponse.data.result.data, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching recent transactions:', error);
    }
  }

  async function fetchFreelancersCount() {
    try {
      const freelancersCountResponse = await axiosInstance.get('dashboard/freelancers');
      const { activeFreelancers, inactiveFreelancers } = freelancersCountResponse.data.result;
      setData((prevData) => ({
        ...prevData,
        freelancersCount: {
          active: activeFreelancers,
          inactive: inactiveFreelancers,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching freelancers count:', error);
    }
  }

  async function fetchSupportCount() {
    try {
      const supportCountResponse = await axiosInstance.get('dashboard/supports');
      setData((prevData) => ({
        ...prevData,
        supportCount: { data: supportCountResponse.data.result.supportsCount, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching support count:', error);
    }
  }

  async function fetchPromoCodesCount() {
    try {
      const promoCodesCountResponse = await axiosInstance.get('dashboard/promocodes');
      const { inactivePromoCodesCount, activePromoCodesCount } =
        promoCodesCountResponse.data.result;
      setData((prevData) => ({
        ...prevData,
        promoCodesCount: {
          active: activePromoCodesCount,
          inactive: inactivePromoCodesCount,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching promo codes count:', error);
    }
  }

  async function fetchRecentClients() {
    try {
      const res = await axiosInstance.get('dashboard/recentClients');

      setData((prevData) => ({
        ...prevData,
        recentClients: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent clients:', error);
    }
  }

  async function fetchRecentConsultants() {
    try {
      const res = await axiosInstance.get('dashboard/recentConsultants');

      setData((prevData) => ({
        ...prevData,
        recentConsultants: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent consultants:', error);
    }
  }
  
console.log('data',data.recentConsultants.data);


  async function fetchRecentOrders() {
    try {
      const res = await axiosInstance.get('dashboard/recentOrders');

      setData((prevData) => ({
        ...prevData,
        recentOrders: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching recent orders:', error);
    }
  }
  async function fetchPlayStoreInfo() {
    try {
      const res = await axiosInstance.get('dashboard/playStoreStats');

      setData((prevData) => ({
        ...prevData,
        playStoreInfo: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching play store:', error);
    }
  }
  async function fetchAppStoreInfo() {
    try {
      const res = await axiosInstance.get('dashboard/appStoreStats');

      setData((prevData) => ({
        ...prevData,
        appStoreInfo: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching app store info:', error);
    }
  }
  return { data };
}

export default function Dashboard() {
  const locale = useSelector((state: RootState) => state.Locale.value);
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const { dashboard, appStoreLink, playStoreLink } = useSelector(
    (state: RootState) => state.Dashboard
  );
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const permission = usePermissions();
  console.log('dashboard', localStorage.getItem('currentDashboard'));
  const directionClass = locale === 'ar' ? 'flex-row-reverse' : '';
  // Now safe to call useHooks and other logic
  const { data } = useHooks();
  const formatStartDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthsNamesEnglish = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const monthName = monthsNamesEnglish[monthIndex];

    return `${day},${monthName},${year}`;
  };
  const formatDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  console.log('permission', permission);
  return (
    <div
      className="p-[8px]  space-y-6 bg-[#fafafa] min-h-screen  overflow-hidden"
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div
        className="flex flex-row justify-start items-start   ml-auto  mt-[24px] mr-0 mb-[32px]  p-0 pr-[20px]"
        style={{
          width: `${Responsive.w(1584, width)}px`,
          height: `${Responsive.h(110, height)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        <Link to={permission?.some(
              (item) => item?.Pages?.includes('Orders') || item?.Pages?.includes('All')
            ) &&"/orders?date=today"}>
          <Card
            className="flex  justify-between items-center  py-[24px] px-[16px] bg-white border-solid border-b-4 border-b-[#4ad991] rounded-[16px]"
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(110, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(62, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(99, width)}px`,
                  height: `${Responsive.h(20, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="todayOrders"
                  defaultMessage="Today Orders"
                />
              </p>
              <p
                className="tracking-[1px]    text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(88, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {data?.todayOrders?.loading ? (
                  <div className=" flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  data?.todayOrders?.data?.todayOrdersCount
                )}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#d9f7e8]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_1}
                srcSet={`${img_1_2}, ${img_1_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>
        </Link>

        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] bg-white border-solid border-b-4 border-b-[#2980ba] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70  text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="todayEarn"
                defaultMessage="Today Earn"
              />
            </p>
            <p
              className="tracking-[1px]    text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {data.todayOrders.loading ? (
                <Spinner />
              ) : (
                data?.todayOrders?.data?.todayOrdersEarn?.toFixed(2) + '$'
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#d9f7e8]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_2}
              srcSet={`${img_2_2}, ${img_2_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Link to="/consultants?type=consultant&status=active">
          <Card
            className="flex  justify-between items-center  py-[24px] px-[16px] bg-white border-solid border-b-4 border-b-[#e6bc59] rounded-[16px]"
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(110, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(62, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70 text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(99, width)}px`,
                  height: `${Responsive.h(20, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="activeFreelancers"
                  defaultMessage=" Active Freelancers"
                />
              </p>
              <p
                className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(88, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {data?.freelancersCount?.loading ? <Spinner /> : data?.freelancersCount?.active}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#fff3d6]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_3}
                srcSet={`${img_3_2}, ${img_3_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>
        </Link>

        <Link to="/users?type=client">
          <Card
            className="flex  justify-between items-center  py-[24px] px-[16px] bg-white border-solid border-b-4  rounded-[16px]"
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(110, height)}px`,
              borderBottomColor: dashColor
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(62, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(99, width)}px`,
                  height: `${Responsive.h(20, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="TotalUser"
                  defaultMessage="Total Users"
                />
              </p>
              <p
                className="tracking-[1px]    text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(88, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {data?.clientsCount?.loading ? <Spinner /> : data?.clientsCount?.data}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[rgb(255,247,253)]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <Icon
                icon={'ph:user'}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                // alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`,
                  color: dashColor
                }}
              />
            </div>
          </Card>
        </Link>

        <Link to="/consultants?type=consultant&status=inactive">
          <Card
            className="flex  justify-between items-center  py-[24px] px-[16px] bg-white border-solid border-b-4 border-b-[#e6bc59] rounded-[16px]"
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(110, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(62, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(95, width)}px`,
                  height: `${Responsive.h(20, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="inactiveFreelancers"
                  defaultMessage="Inactive Freelancers"
                />
              </p>
              <p
                className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(88, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {data?.freelancersCount?.loading ? <Spinner /> : data?.freelancersCount?.inactive}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#fff3d6]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_5}
                srcSet={`${img_5_2}, ${img_5_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>
        </Link>
        <Link to="/support">
          <Card
            className="flex  justify-between items-center  py-[24px] px-[16px] bg-white border-solid border-b-4 border-b-[#f93c65] rounded-[16px]"
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(110, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(62, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(99, width)}px`,
                  height: `${Responsive.h(20, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="TotalComplaint"
                  defaultMessage="Total Complaint"
                />
              </p>
              <p
                className="tracking-[1px]   text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(88, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {data?.supportCount?.loading ? <Spinner /> : data?.supportCount?.data}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#ffebeb]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_6}
                srcSet={`${img_6_2}, ${img_6_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>
        </Link>

  
      </div>
      <div
        className=" flex-grow-0 flex p-0 h-[365px] items-start justify-start gap-[24px] flex-row"
        style={{
          width: `${Responsive.w(1584, width)}px`
          // height: `${Responsive.h(365, height)}px`
        }}
      >
        <div
          className=" flex flex-grow-0 h-[365px]   p-[16px] rounded-[16px] bg-white flex-col"
          style={{
            width: `${Responsive.w(768, width)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className={`flex ${directionClass} items-center justify-between  flex-grow-0 gap-[22px] w-full `}
            style={{
              height: `${Responsive.h(24, height)}px`
            }}
          >
            <p
              className="font-bold p-0 mr-[26px]  flex-grow-0 text-left  text-[20px] text-[#212b36] font-[Montserrat]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="recentUsers"
                defaultMessage="Recent users"
              />
            </p>
            {permission?.some(
              (item) => item?.Pages?.includes('Users') || item?.Pages?.includes('All')
            ) && (
              <Link to={'/users'}>
                <p
                  className="cursor-pointer font-bold  p-0 mr-[26px]  flex-grow-0 text-right text-[20px]   font-[Montserrat] "
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="viewAll"
                    defaultMessage="View All"
                  />
                </p>
              </Link>
            )}
          </div>
          <table className="table-auto">
            <thead>
              <tr>
                <th
                  className="text-center nowrap   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="userPhoto"
                    defaultMessage="User Photo"
                  />
                </th>
                <th
                  className="text-center nowrap  text-[14px]   font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="userName"
                    defaultMessage="User Name"
                  />
                </th>
                <th
                  className="text-center nowrap   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="phoneNumber"
                    defaultMessage="Phone Number"
                  />
                </th>
                <th
                  className="text-center    text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="createdDateValue"
                    defaultMessage="created Date Value"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.recentClients.loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                data?.recentClients?.data.map((user, i) => (
                  <tr key={i}>
                    <td
                      className="flex items-center justify-center nowrap   text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      <img
                        className=" rounded-[50%] mt-4 flex-grow-0 w-[30px] h-[30px]"
                        src={
                          user?.photoUrl ||
                          `${
                            currentDashboard === 'dream'
                              ? dream
                              : currentDashboard === 'jeras'
                              ? photo
                              : currentDashboard === 'nikah'
                              ? nikah
                              : null
                          }`
                        }
                        alt="User"
                        // style={{
                        //   width: `${Responsive.w(30, width)}px`,
                        //   height: `${Responsive.h(30, height)}px`
                        // }}
                      />
                    </td>
                    <td
                      className="text-center nowrap   text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {currentDashboard === 'jeras' || currentDashboard === 'nikah'
                        ? user.name || 'new user'
                        : user.userType === 'USER'
                        ? user?.consultName?.nameAr || 'new user'
                        : (() => {
                            const languages = user.languages;
                            if (languages.includes('ar')) {
                              return user?.consultName?.nameAr || 'new user';
                            } else if (languages.includes('en') && !languages.includes('ar')) {
                              return user?.consultName?.nameEn || 'new user';
                            } else if (
                              languages.includes('fr') &&
                              !languages.includes('ar') &&
                              !languages.includes('en')
                            ) {
                              return user?.consultName?.nameFr || 'new user';
                            } else if (
                              languages.includes('id') &&
                              !languages.includes('ar') &&
                              !languages.includes('en') &&
                              !languages.includes('fr')
                            ) {
                              return user?.consultName?.nameId || 'new user';
                            } else {
                              return user?.consultName?.nameAr || 'new user';
                            }
                          })()}
                    </td>
                    <td
                      className="nowrap   text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user.phoneNumber || 'N/A'}
                    </td>
                    <td
                      className="text-center nowrap   text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >{`${formatStartDay(user?.utcTime)}`}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div
          className=" flex flex-grow-0 sm:gap-[10px] h-[365px] gap-[16px] p-[16px] rounded-[16px] bg-white flex-col"
          style={{
            width: `${Responsive.w(768, width)}px`
            // height: `${Responsive.h(365, height)}px`
          }}
        >
          <div
            className={`flex ${directionClass} items-center justify-between  flex-grow-0 gap-[22px] w-full `}
            style={{
              height: `${Responsive.h(24, height)}px`
            }}
          >
            <p
              className="font-bold p-0 mr-[26px]  flex-grow-0 text-left  text-[20px] text-[#212b36] font-[Montserrat]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="recentTeachers"
                defaultMessage="Recent Teachers"
              />
            </p>
            {permission?.some(
              (item) => item?.Pages?.includes('Users') || item?.Pages?.includes('All')
            ) && (
              <Link to={'/consultants'}>
                <p
                  className="cursor-pointer font-bold  p-0 mr-[26px]  flex-grow-0 text-right   text-[20px]  font-[Montserrat]"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="viewAll"
                    defaultMessage="View All"
                  />
                </p>
              </Link>
            )}
          </div>
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="teacherPhoto"
                    defaultMessage="Teacher Photo"
                  />
                </th>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="teacherName"
                    defaultMessage="Teacher Name"
                  />
                </th>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="phoneNumber"
                    defaultMessage="Phone Number"
                  />
                </th>
                <th
                  className="text-center    text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="createdDateValue"
                    defaultMessage="created Date Value"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.recentConsultants.loading ? (
                <Spinner />
              ) : (
                data?.recentConsultants?.data.map((user, i) => (
                  <tr key={i}>
                    <td
                      className="flex items-center justify-center nowrap   text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      <img
                        className=" rounded-[50%] mt-4 flex-grow-0 w-[30px] h-[30px]"
                        src={
                          user?.photoUrl ||
                          `${
                            currentDashboard === 'dream'
                              ? dream
                              : currentDashboard === 'jeras'
                              ? photo
                              : currentDashboard === 'nikah'
                              ? nikah
                              : null
                          }`
                        }
                        alt="User"
                        style={{
                          // width: `${Responsive.w(30, width)}px`,
                          // height: `${Responsive.h(30, height)}px`,
                          fontSize: `${Responsive.sp(14, width)}px`
                        }}
                      />
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.name || 'معلم جديد'}
                    </td>
                    <td
                      className=" nowrap  text-[10px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user.phoneNumber ? user.phoneNumber : 'N/A'}
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >{`${formatStartDay(user?.utcTime)}`}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className=" flex-grow-0 flex p-0  items-start justify-start  gap-[24px] flex-row"
        style={{
          width: `${Responsive.w(1584, width)}px`,
          // height: `${Responsive.h(365, height)}px`,
          gap: `${Responsive.sp(20, width)}px`
        }}
      >
        <div
          className=" flex flex-grow-0   p-[16px] rounded-[16px] bg-white flex-col items-stretch"
          style={{
            width: `${Responsive.w(900, width)}px`,
            padding: `${Responsive.sp(16, width)}px`,
            // height: `${Responsive.h(365, height)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className={`flex ${directionClass} items-center justify-between  flex-grow-0 gap-[154px] w-full `}
          >
            <p
              className="font-bold p-0 mr-[26px]  flex-grow-0 text-left  text-[20px] text-[#212b36] font-[Montserrat]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="recentOrders"
                defaultMessage="Recent orders"
              />
            </p>

            {permission?.some(
              (item) => item?.Pages?.includes('Orders') || item?.Pages?.includes('All')
            ) && (
              <Link to={'/orders'}>
                <p
                  className="cursor-pointer font-bold  p-0 mr-[26px]  flex-grow-0 text-right  text-[20px]  font-[Montserrat] "
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="viewAll"
                    defaultMessage="View All"
                  />
                </p>
              </Link>
            )}
          </div>
          <table className="table-auto">
            <thead>
              <tr>
                <th
                  className="text-center  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="teacherPhoto"
                    defaultMessage="Teacher Photo"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="teacherName"
                    defaultMessage="Teacher Name"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="userPhoto"
                    defaultMessage="User Photo"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="userName"
                    defaultMessage="User Name"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="price"
                    defaultMessage="Price"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="payWith"
                    defaultMessage="Pay With"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  w-[2px]"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="date"
                    defaultMessage="Date"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.recentOrders?.loading ? (
                <Spinner />
              ) : (
                data?.recentOrders?.data.map((user, i) => (
                  <tr key={i}>
                    <td
                      className="flex items-center justify-center nowrap  text-[14px] font-medium text-[#515151] "
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      <img
                        className=" rounded-[50%] mt-4 flex-grow-0 w-[30px] h-[30px]"
                        src={
                          user?.consult.image ||
                          `${
                            currentDashboard === 'dream'
                              ? dream
                              : currentDashboard === 'jeras'
                              ? photo
                              : currentDashboard === 'nikah'
                              ? nikah
                              : null
                          }`
                        }
                        alt="Teacher"
                        style={{
                          // width: `${Responsive.w(30, width)}px`,
                          // height: `${Responsive.h(30, height)}px`,
                          fontSize: `${Responsive.sp(14, width)}px`
                        }}
                      />
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] "
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.consult?.name.slice(0, 15) || 'معلم جديد'}
                    </td>
                    <td
                      className="flex items-center justify-center nowrap  text-[14px] font-medium text-[#515151] "
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      <img
                        className=" rounded-[50%] mt-4 flex-grow-0 w-[30px] h-[30px]"
                        src={
                          user?.user?.image ||
                          `${
                            currentDashboard === 'dream'
                              ? dream
                              : currentDashboard === 'jeras'
                              ? photo
                              : currentDashboard === 'nikah'
                              ? nikah
                              : null
                          }`
                        }
                        alt="Teacher"
                        style={{
                          // width: `${Responsive.w(30, width)}px`,
                          // height: `${Responsive.h(30, height)}px`,
                          fontSize: `${Responsive.sp(14, width)}px`
                        }}
                      />
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] "
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.user?.name.slice(0, 15) || 'مستخدم جديد'}
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] "
                      style={{
                        width: '100px',
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.price ? Number(user.price).toFixed(2) : 'N/A'}
                    </td>
                    <td
                      className="text-center   text-[14px] font-medium text-[#515151] "
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.payWith ? user.payWith : 'N/A'}
                    </td>
                    <td
                      className="text-left wrap   text-[14px] font-medium text-[#515151] w-[2px]"
                      style={{
                        width: '100px',
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {`${formatStartDay(user?.utcTime || 'N/A')}`}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div
          className=" flex flex-grow-0 flex-shrink-0  gap-[16px] p-[16px] rounded-[16px] bg-white flex-col items-stretch"
          style={{
            width: `${Responsive.w(636, width)}px`,
            gap: `${Responsive.sp(16, width)}px`,
            padding: `${Responsive.sp(24, width)}px`
            // height: `${Responsive.h(365, height)}px`
          }}
        >
          <div
            className={`flex ${directionClass} items-center justify-between  flex-grow-0 gap-[154px] w-full `}
          >
            <p
              className="nowrap font-bold p-0 mr-[26px]  flex-grow-0 text-left  text-[20px]  text-[#212b36] font-[Montserrat]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
            >
              <FormattedMessage
                id="recentTransactions"
                defaultMessage="Recent Transactions"
              />
            </p>

            {permission?.some(
              (item) => item?.Pages?.includes('Accountant') || item?.Pages?.includes('All')
            ) && (
              <Link to={'/payments/tap'}>
                <p
                  className="cursor-pointer font-bold  p-0 mr-[26px] flex-grow-0 text-right  text-[20px] sm:text-[15px]  font-[Montserrat] nowrap "
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="viewAll"
                    defaultMessage="View All"
                  />
                </p>
              </Link>
            )}
          </div>
          <table className="table-auto">
            <thead>
              <tr>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="customerName"
                    defaultMessage="customer name"
                  />
                </th>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="amount"
                    defaultMessage="amount"
                  />
                </th>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="status"
                    defaultMessage="status"
                  />
                </th>
                <th
                  className="text-center   text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="transaction"
                    defaultMessage="transaction date"
                  />
                </th>
                <th
                  className="text-center nowrap  text-[14px] font-medium  self-stretch"
                  style={{
                    color: dashColor,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                >
                  <FormattedMessage
                    id="card"
                    defaultMessage="card"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.recentTransactions?.loading ? (
                <Spinner />
              ) : (
                data?.recentTransactions?.data.map((user, i) => (
                  <tr key={i}>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.customer?.first_name.slice(0, 15) || 'مستخدم جديد'}
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.amount + '$' || 'N/A'}
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.status || 'N/A'}
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {formatDay(Number(user?.transaction?.created)) || 'N/A'}
                    </td>
                    <td
                      className="text-center nowrap  text-[14px] font-medium text-[#515151] self-stretch"
                      style={{
                        height: `${Responsive.h(21, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user?.card?.brand || 'N/A'}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
