import { FormattedMessage } from 'react-intl';
import PickDate from './PickDate';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import PickDateTwo from './PickDateTwo';

const RangePickerTwo = ({ startDate, setStartDate, endDate, setEndDate, applyHandler }) => {
  const locale = useSelector((state: RootState) => state.Locale.value);

  const directionClass = locale === 'ar' ? 'flex-row-reverse' : '';
  return (
    <div className={`flex  ${directionClass} space-x-2 items-center justify-center`}>
      <div className={`flex  ${directionClass} space-x-2 items-center justify-center`}>
        <label>
          <FormattedMessage
            id="from"
            defaultMessage="From"
          />
        </label>
        <PickDateTwo
          selectedDate={startDate}
          setSelectedDate={(date) => setStartDate(date)}
        />
      </div>
      <div className={`flex  ${directionClass} space-x-2 items-center justify-center`}>
        <label>
          <FormattedMessage
            id="to"
            defaultMessage="To"
          />
        </label>
        <PickDateTwo
          selectedDate={endDate}
          setSelectedDate={(date) => setEndDate(date)}
        />
      </div>
      <div className="flex space-x-2 items-center justify-center">
        <button
          onClick={applyHandler}
          className="bg-focal hover:bg-slate-600 p-2 rounded-lg text-white font-semibold text-sm "
        >
          <FormattedMessage
            id="applyFilter"
            defaultMessage="Apply filter"
          />
        </button>
      </div>
    </div>
  );
};

export default RangePickerTwo;
