// useUserTable.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from 'src/helper/AxiosInstance';
import { UpdateFilter, UpdateResult } from 'src/reducers/users';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'src/store';
import { IFilters } from 'src/types/types';
import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';

function useUsersTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const type = searchParams.get('type');
  let initialQueries = type == 'client' ? [['userType', '==', 'USER']] : [];
  const { filters: userFilters, data: currentUsers } = useSelector(
    (state: RootState) => state.Users
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<IFilters>({
    ...userFilters,
    page: 1,
    perPage: 15,
    sorts: [],
    queries: initialQueries,
    search: undefined
  });

  useEffect(() => {
    GetUsers();
  }, [filters, searchParams]);

  async function GetUsers(params?: Partial<IFilters>) {
    try {
      setIsLoading(true);
      const parameters = {
        ...filters,
        ...params,
        sorts: isModalOpen ? [] : filters.sorts // Initialize sorts array when modal is open
      };
      console.log('?', parameters);
      const { data } = await axiosInstance.get('users', {
        params: parameters
      });
      dispatch(UpdateResult(data.result));
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters) => {
      let result: any = { ...filters };
      const isIncluded = Object.prototype.hasOwnProperty.call(filters, key);
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;

        case 'queries':
          result[key] = value;
          break;

        default:
          break;
      }
      const sorts = isModalOpen ? [] : result.sorts;

      setFilters({ ...result, sorts });
      dispatch(UpdateFilter({ ...result, sorts }));
    },
    [filters, userFilters, isModalOpen]
  );

  return { isLoading, ChangeFilter, filters, initialQueries, isModalOpen, setIsModalOpen };
}

export default useUsersTable;
