import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const storageKeys = localStorage.getItem('users-customize-table') as string;

export interface usersState {
  keys: string[];
  customize_keys: string[];
  data: any[];
  count: number;
  pagination: any;
  filters: any;
}

const initialState: usersState = {
  keys: [],
  customize_keys: JSON.parse(storageKeys) || [],
  data: [],
  count: 0,
  pagination: null,
  filters: {
    page: 1,
    perPage: 15,
    sorts: ['createDateValue', 'desc'],
    queries: [],
    search: undefined
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    ChangeCustomizeKeys(state, action) {
      const { checked, value } = action.payload;
      const { keys, customize_keys } = state;  
          
      if (!keys.includes(value) && checked) return;

      const filterKeys = customize_keys.filter((key) => key !== value);
      const collectKeys = new Array().concat(customize_keys, value);
      const data = [...new Set(checked ? collectKeys : filterKeys)];
      const uniqueMap = data;
      localStorage.setItem('users-customize-table', JSON.stringify(data));

      state.customize_keys = uniqueMap;
    },

    UpdateResult(state, action) {
      const { keys, data, pagination, count } = action.payload;
      const { customize_keys } = state;
      const someKeys = [
        'createdDate',
        'name',
        'phoneNumber',
        'accountStatus',
        'balance',
        'ordersNumbers',
        'payedBalance',
        'userLang',
        'userType',
        'chat',
        'voice',
        'otherConnections'
      ];
      state.keys = keys;
      state.data = data;
      state.count = count;
      state.pagination = pagination;
      if (customize_keys.length) return;
      state.customize_keys = someKeys;
    },

    UpdateFilter(state, action) {
      state.filters = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { ChangeCustomizeKeys, UpdateResult, UpdateFilter } = usersSlice.actions;

export default usersSlice.reducer;
