import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { scaleQuantize } from 'd3-scale';
import 'react-tooltip/dist/react-tooltip.css';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';

const geoUrl = '/features.json'; // Ensure this file is available at the public folder of your project

type CountryData = {
  countryISOCode: string;
  userLang?: string; // Assuming this might be part of your data structure
};

interface WorldHeatMapProps {
  data: CountryData[];
}

const TopCountries = ({ countries }) => {
  const { width } = useWindowDimensions();

  // Assuming you have a way to convert country codes to names
  const getCountryName = (code) => {
    // Implement your method to get the country name by its ISO code
    return code; // Replace this with actual name retrieval logic
  };

  // Calculate the maximum count for scaling
  const maxCount = Math.max(...countries.map(([_, count]) => count));

  const first = 'text-yellow-300';

  return (
    <div 
      className="text-black font-bold flex flex-col gap-6 items-start justify-start p-0"
      style={{
        width: `${Responsive.w(500, width)}px`,
      }}
    >
      <h2>Top Countries</h2>
      <ul 
        className='flex flex-col justify-between gap-4 relative'
        style={{
          width: `${Responsive.w(500, width)}px`,
        }}
      >
        {countries.map(([code, count], idx) => (
          <li
            key={code}
            className={`${idx === 0 ? first : ''} flex items-center justify-between`}
          >
            <p>
              {`${idx + 1} - ${getCountryName(code)}`}
            </p>

            <p 
              className='relative flex items-center justify-center bg-[#e5e7eb] h-6 rounded-[6.4px]'
              style={{
                width: `${Responsive.w(400, width)}px`,
              }}
            >
              <span 
                style={{
                  width: `${(count / maxCount) * 100}%`,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }} 
                className='h-6 rounded-[6.4px] before:absolute before:bg-[#7b6c96] before:w-full before:h-full before:rounded-[6.4px]'
              ></span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CountriesMap: React.FC<WorldHeatMapProps> = ({ data }) => {
  const [tooltipContent, setTooltipContent] = useState('');

  // Accumulate the count of each country code
  const countryCounts = data.reduce((acc: Record<string, number>, item) => {
    const countryCode = item.countryISOCode;
    if (countryCode) {
      acc[countryCode] = (acc[countryCode] || 0) + 1;
    }
    return acc;
  }, {});

  // Convert countryCounts to an array, sort it, and get the top 10 non-empty entries
  const sortedCountries = Object.entries(countryCounts)
    .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
    .slice(0, 10); // Get the top 10

  const colorScale = scaleQuantize<string>()
    .domain([0, 10, 100, 1000, 10000]) // Start from 1 to max count
    .range([
      '#ffad9f', // Lighter color for the smallest non-zero values
      '#e2492d',
      '#782618' // Darker color for the highest values
    ]);

  const getColor = (value) => {
    console.log(`Value: ${value}`); // Debug log
    if (value === 0) return '#e0d6f1'; // white for 0
    if (value <= 10) return '#c5b3e6'; // very light sky blue for 1-10
    if (value <= 100) return '#c9b7ea'; // light sky blue for 11-100
    if (value <= 500) return '#9583b6'; // sky blue for 101-500
    if (value <= 2000) return '#5f4d8070'; // bright blue for 501-2000
    if (value <= 5000) return '#564477'; // deeper blue for 2001-5000
    if (value <= 10000) return '#5f4d80'; // even deeper blue for 5001-10000
    return '#5f4d80'; // Darkest navy blue for values above 10000
  };

  const { width } = useWindowDimensions();

  return (
    <div className='flex items-center justify-center gap-6'
      style={{
        width: `${Responsive.w(1560, width)}px`,
      }}
    >
      <div className="text-center relative max-h-[1000px]"
        style={{
          width: `${Responsive.w(876, width)}px`,
        }}
      >
        <h1 className="font-bold text-black text-2xl text-left">Global distribution of users</h1>
        <ReactTooltip id="my-tooltip">{tooltipContent}</ReactTooltip>
        <ComposableMap projectionConfig={{ scale: 200 }}>
  <Geographies geography={geoUrl}>
    {({ geographies }) =>
      geographies.map((geo) => {
        const countryCode = geo.id.slice(0, 2); // Use ISO_A2 for 2-letter country codes
        let correctedCode = countryCode;

        // Handle specific country codes
        if (geo.id === 'TUR') {
          correctedCode = 'TR';
        } else if (geo.id === 'ARE') {
          correctedCode = 'AE';
        }

        const totalValue = countryCounts[correctedCode] || 0;
        console.log(`Country Code: ${correctedCode}, Total Value: ${totalValue}`); // Debug log
        return (
          <Geography
            data-tooltip-id="my-tooltip"
            key={geo.rsmKey}
            geography={geo}
            fill={getColor(totalValue)}
            onMouseEnter={() => {
              setTooltipContent(`${geo.properties.name}: ${totalValue}`);
            }}
            onMouseLeave={() => {
              setTooltipContent('');
            }}
          />
        );
      })
    }
  </Geographies>
</ComposableMap>

      </div>
      <TopCountries countries={sortedCountries} />
    </div>
  );
};

export default CountriesMap;
