import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const storageKeys = localStorage.getItem('promocodes-customize-table') as string;

export interface promoCodeState {
  keys: string[];
  customize_keys: string[];
  data: any[];
  count: number;
  pagination: any;
  filters: any;
}

const initialState: promoCodeState = {
  keys: [],
  customize_keys: JSON.parse(storageKeys) || [],
  data: [],
  count: 0,
  pagination: null,
  filters: {
    page: 1,
    perPage: 15,
    sorts: ['createDateValue', 'desc'],
    queries: [],
    search: undefined
  }
};

export const promoCodeSlice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {
    ChangeCustomizeKeys(state, action) {
      const { checked, value } = action.payload;
      const { keys, customize_keys } = state;

      if (!keys.includes(value) && checked) return;

      const filterKeys = customize_keys.filter((key) => key !== value);
      const collectKeys = new Array().concat(customize_keys, value);
      const data = [...new Set(checked ? collectKeys : filterKeys)];
      const uniqueMap = data;
      localStorage.setItem('promocodes-customize-table', JSON.stringify(data));

      state.customize_keys = uniqueMap;
    },

    UpdateResult(state, action) {
      const { keys, data, pagination, count } = action.payload;
      const { customize_keys } = state;
      const someKeys = [
        'code',
        'discount',
        'ownerName',
        'promoCodeId',
        'promoCodeStatus',
        'promoCodeTimestamp',
        'type',
        'usedNumber'
      ];
      state.keys = keys;
      state.data = data;
      state.count = count;
      state.pagination = pagination;
      if (customize_keys.length) return;
      state.customize_keys = someKeys;
    },

    UpdateFilter(state, action) {
      state.filters = action.payload;
    },

    AddPromoCode(state, action: PayloadAction<any>) {
      const newPromoCode = action.payload;

      // Add the new promo code to the data array
      state.data = [...state.data, newPromoCode];
    },
    UpdatePromoCodeById(state, action: PayloadAction<{ id: string; newData: any }>) {
      const { id, newData } = action.payload;

      const promoCodeIndex = state.data.findIndex((pc) => pc.promoCodeId === id);
      if (promoCodeIndex !== -1) {
        state.data[promoCodeIndex] = { ...state.data[promoCodeIndex], ...newData };
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  ChangeCustomizeKeys,
  UpdateResult,
  UpdateFilter,
  AddPromoCode,
  UpdatePromoCodeById
} = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
