import React, { useState } from 'react';
import Select from 'react-select';

import countriesData from 'src/constants/countries';

function CountryPicker({ onCountryChange }: any) {
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const formatOptionLabel = ({ flag, name, dial_code }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{flag}</span>
      <span>{`${name} (${dial_code})`}</span>
    </div>
  );

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onCountryChange(selectedOption); // Call the prop function with the selected option
  };

  return (
    <div>
      <Select
        value={selectedCountry}
        onChange={handleChange}
        options={countriesData}
        getOptionLabel={(option) => `${option.dial_code} - ${option.name}`}
        formatOptionLabel={formatOptionLabel}
        isClearable
        className="border border-solid border-[#939393] rounded"
      />
    </div>
  );
}

export default CountryPicker;
