import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';

function OrderDetailsPage() {
  const { id } = useParams();
  const [currentOrder, setCurrentOrder] = useState<any | null>(null);
  const [consultPackageData, setConsultPackageData] = useState<any | null>(null);
  const [appointmentData, setAppointmentData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const { width, height } = useWindowDimensions();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderRes = await axiosInstance.get(`/orders/${id}`);
        const orderData = orderRes.data.result.data;
        setCurrentOrder(orderData);
        console.log('order', orderData);

        if (orderData && orderData.packageId) {
          const consultPackageRes = await axiosInstance.get(
            `/consultPackage/${orderData.packageId}`
          );
          const consultPackageData = consultPackageRes.data.result.data;
          setConsultPackageData(consultPackageData);
        }
        // console.log('packk', consultPackageData);
        const appointmentRes = await axiosInstance.get(`/appointments/${orderData.orderId}`);
        const appointmentData = appointmentRes.data.result.data;
        setAppointmentData(appointmentData);
        // console.log('s', appointmentData);
        // Update the state with the fetched data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = d.getUTCMonth() + 1;
    const day = d.getUTCDate();
    return `${day}/${month}/${year}`;
  };
  let statusColor = 'bg-[#ffe2e2] border-solid border-[1px] border-[#e84a4d]  text-[#d91c1f]';
  if (currentOrder?.orderStatus == 'completed')
    statusColor = 'bg-[#ebfeef] border-solid border-[1px] border-[#28c794]  text-[#228176]';
  else if (currentOrder?.orderStatus == 'closed')
    statusColor = `bg-[#e5e1ec] border-solid border-[1px]  ${
      currentDashboard !== 'jeras'
        ? 'text-[#a12f88] border-[#a12f88]'
        : 'text-[#7b6c96] border-[#7b6c96]'
    } `;
  else statusColor = 'bg-[#ffe2e2] border-solid border-[1px] border-[#e84a4d] text-[#d91c1f]';
  return (
    <div
      className="flex flex-row-reverse items-start justify-center   p-0   !overflow-x-hidden"
      style={{
        width: `${Responsive.w(1560, width)}px`,
        gap:`${Responsive.sp(24, width)}px`,
        margin:`${Responsive.sp(24, width)}px`,
        // height: `${Responsive.h(463, height)}px`
      }}
    >
      <Card
        className=" bg-slate-50  h-[463px] hover:bg-[#EFEFEF] flex flex-col justify-start items-start gap-[24px] py-[28px] px-0 border-solid border-[1px] border-[#d3d3d3] !overflow-x-hidden"
        style={{
          width: `${Responsive.w(766, width)}px`
          // height: `${Responsive.h(463, height)}px`
        }}
      >
        <h1
          className={`text-[${dashColor}] text-xl  my-2 text-center font-semibold font-[Montserrat] text-[24px] h-[29px] mx-auto`}
        >
          {useIntl().formatMessage({
            id: 'orderDetails',
            defaultMessage: 'Order Details'
          })}{' '}
        </h1>
        <div className="md:w-3/4 m-auto  flex-col items-center justify-center">
          <div className="flex justify-between my-2">
            <p className="font-semibold">
              {useIntl().formatMessage({
                id: 'date:',
                defaultMessage: 'date:'
              })}{' '}
            </p>
            <p className="font-semibold">{formatDate(currentOrder?.utcTime)}</p>
          </div>
          <div className="flex justify-between  my-2">
            <p className="font-semibold font-[Montserrat]">
              {useIntl().formatMessage({
                id: 'price:',
                defaultMessage: 'price:'
              })}{' '}
            </p>
            <p className="font-semibold font-[Montserrat]">
              ${Number(currentOrder?.price).toFixed(2)}
            </p>
          </div>
          <div className="flex justify-between  my-2">
            <p className="font-semibold font-[Montserrat]">
              {useIntl().formatMessage({
                id: 'callPrice:',
                defaultMessage: 'Call Price:'
              })}{' '}
            </p>
            <p className="font-semibold font-[Montserrat]">
              ${Number(currentOrder?.callPrice).toFixed(2)}
            </p>
          </div>
          <div className="flex justify-between  my-2">
            <p className="font-semibold font-[Montserrat]">
              {useIntl().formatMessage({
                id: 'packCallNum:',
                defaultMessage: 'Package call number:'
              })}{' '}
            </p>
            <p className="font-semibold font-[Montserrat]">{currentOrder?.packageCallNum}</p>
          </div>
          <div className="flex justify-between  my-2">
            <p className="font-semibold font-[Montserrat]">
              {useIntl().formatMessage({
                id: 'answeredCall:',
                defaultMessage: 'Answered Call:'
              })}{' '}
            </p>
            <p className="font-semibold font-[Montserrat]">{currentOrder?.answeredCallNum}</p>
          </div>
          <div className="flex justify-between  my-2 font-[Montserrat]">
            <p className="font-semibold font-[Montserrat]">
              {useIntl().formatMessage({
                id: 'remainingCall:',
                defaultMessage: 'Remaining Call:'
              })}{' '}
            </p>
            <p className="font-semibold font-[Montserrat]">{currentOrder?.remainingCallNum}</p>
          </div>
          <div className="flex justify-between  my-2">
            <p className="font-semibold font-[Montserrat]">
              {useIntl().formatMessage({
                id: 'status:',
                defaultMessage: 'status:'
              })}{' '}
            </p>
            <p className={`font-bold py-[3px] px-[12px] rounded-[6px] ${statusColor}`}>
              {currentOrder?.orderStatus}
            </p>
          </div>
        </div>
      </Card>
      <div
        className="flex flex-col   gap-[24px] overflow-x-hidden"
        style={{
          width: `${Responsive.w(768, width)}px`
          // height: `${Responsive.h(463, height)}px`
        }}
      >
        <div
          className="flex items-center h-[280px] justify-center gap-[24px] "
          style={{
            width: `${Responsive.w(768, width)}px`
            // height: `${Responsive.h(280, height)}px`
          }}
        >
          <Card
            className=" bg-slate-50 h-[280px] hover:bg-[#EFEFEF] flex flex-col gpa-[24px] py-[24px] px-0  items-center justify-start rounded-[16px] border-solid border-[1px] border-[#d3d3d3]"
            style={{
              width: `${Responsive.w(372, width)}px`
              // height: `${Responsive.h(280, height)}px`
            }}
          >
            <h1
              className={`text-[${dashColor}] text-xl font-semibold my-2 sm:text-[15px] text-[24px] font-[Montserrat] h-[29px]`}
            >
              {useIntl().formatMessage({
                id: 'teacherDetails:',
                defaultMessage: 'Teacher Details:'
              })}{' '}
            </h1>
            <div
              className="flex flex-col items-center justify-start gap-[12px] p-0 flex-grow-0"
              style={{
                width: `${Responsive.w(173, width)}px`,
                height: `${Responsive.h(179, height)}px`
              }}
            >
              <img
                src={currentOrder?.consult?.image || '/images/placeholder.png'}
                alt={currentOrder?.consult?.name}
                className="w-16 h-16 rounded-full object-cover"
                style={{
                  width: `${Responsive.w(111, width)}px`,
                  height: `${Responsive.h(111, height)}px`
                }}
              />

              <p
                className="font-bold text-center nowrap"
                style={{
                  width: `${Responsive.w(137, width)}px`,
                  height: `${Responsive.h(24, height)}px`
                }}
              >
                {currentOrder?.consult?.name}
              </p>
              <p
                className="font-semibold text-[#939393] text-center"
                style={{
                  width: `${Responsive.w(130, width)}px`,
                  height: `${Responsive.h(20, height)}px`
                }}
              >
                {currentOrder?.consult?.phone}
              </p>
            </div>
          </Card>
          <Card
            className=" bg-slate-50 h-[280px]  hover:bg-[#EFEFEF] flex flex-col gpa-[24px] py-[24px] px-0  items-center justify-start rounded-[16px] border-solid border-[1px] border-[#d3d3d3]"
            style={{
              width: `${Responsive.w(372, width)}px`
              // height: `${Responsive.h(280, height)}px`
            }}
          >
            <h1
              className={`text-[${dashColor}] text-xl font-semibold my-2 sm:text-[15px] text-[24px] font-[Montserrat] h-[29px]`}
            >
              {useIntl().formatMessage({
                id: 'userDetails:',
                defaultMessage: 'User Details'
              })}{' '}
            </h1>
            <div
              className="flex flex-col items-center justify-start gap-[12px] p-0 flex-grow-0"
              style={{
                width: `${Responsive.w(173, width)}px`,
                height: `${Responsive.h(179, height)}px`
              }}
            >
              <img
                src={currentOrder?.user?.image || '/images/placeholder.png'}
                alt={currentOrder?.user?.name}
                className=" rounded-full object-cover"
                style={{
                  width: `${Responsive.w(111, width)}px`,
                  height: `${Responsive.h(111, height)}px`
                }}
              />

              <p
                className="font-bold text-center nowrap"
                style={{
                  width: `${Responsive.w(137, width)}px`,
                  height: `${Responsive.h(24, height)}px`
                }}
              >
                {currentOrder?.user?.name}
              </p>
              <p
                className="font-semibold text-[#939393] text-center"
                style={{
                  width: `${Responsive.w(130, width)}px`,
                  height: `${Responsive.h(20, height)}px`
                }}
              >
                {currentOrder?.user?.phone}
              </p>
            </div>
          </Card>
        </div>

        {currentOrder.packageId.length > 0 && (
          <Card
            className=" bg-slate-50 py-0 px-[24] sm:text-[15px]  flex-grow-0 hover:bg-[#EFEFEF] gap-[16px]  flex flex-col items-start justify-between rounded-[16px] border-solid border-[1px] border-[#d3d3d3]"
            style={{
              width: `${Responsive.w(768, width)}px`
              // height: `${Responsive.h(463, height)}px`
            }}
          >
            <h1
              className={`text-[${dashColor}] text-xl font-bold my-2 text-center mx-auto font-[Montserrat]`}
            >
              {useIntl().formatMessage({
                id: 'packages',
                defaultMessage: 'Packages'
              })}{' '}
            </h1>
            <div className="md:w-3/4 m-auto  flex-col items-center justify-center">
              <div className="flex justify-between my-2">
                <p className="font-semibold font-[Montserrat]">
                  {useIntl().formatMessage({
                    id: 'calls:',
                    defaultMessage: 'Calls:'
                  })}{' '}
                </p>
                <p className="font-semibold font-[Montserrat] text-[#939393]">
                  {consultPackageData?.callNum}
                </p>
              </div>
              <div className="flex justify-between items-start my-2">
                <p className="font-semibold font-[Montserrat]">
                  {useIntl().formatMessage({
                    id: 'price:',
                    defaultMessage: 'Price:'
                  })}{' '}
                </p>
                <p className="font-semibold font-[Montserrat] text-[#939393]">
                  ${Number(consultPackageData?.price).toFixed(2)}
                </p>
              </div>
            </div>
          </Card>
        )}
        {appointmentData && (
          <Card
            className="  bg-slate-50 py-0 px-[24] sm:text-[15px]  flex-grow-0 hover:bg-[#EFEFEF] gap-[16px]  flex flex-col items-start justify-between rounded-[16px] border-solid border-[1px] border-[#d3d3d3]"
            style={{
              width: `${Responsive.w(768, width)}px`
            }}
          >
            <h1
              className={`text-[${dashColor}] text-xl font-bold my-2 text-center mx-auto font-[Montserrat]`}
            >
              {useIntl().formatMessage({
                id: 'appointment:',
                defaultMessage: 'Appointment:'
              })}{' '}
            </h1>
            <div className="md:w-3/4 m-auto  flex-col items-center justify-center">
              <div className="flex justify-between my-2">
                <p className="font-semibold font-[Montserrat]">
                  {useIntl().formatMessage({
                    id: 'appointmentDate',
                    defaultMessage: 'Appointment Date:'
                  })}
                </p>
                <p className="font-semibold font-[Montserrat] text-[#939393]">
                  {formatDate(appointmentData?.utcTime)}
                </p>
              </div>
              <div className="flex justify-between  my-2">
                <p className="font-semibold">
                  {useIntl().formatMessage({
                    id: 'status:',
                    defaultMessage: 'status:'
                  })}
                </p>
                <p className="font-semibold font-[Montserrat] text-[#939393]">
                  {appointmentData?.appointmentStatus}
                </p>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}

export default OrderDetailsPage;

