import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React from 'react';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';

function Modal({
  visible,
  handleClose,
  title,
  children,
  size
}: {
  visible?: boolean;
  handleClose?: any;
  title?: string;
  children?: React.ReactNode;
  size?: { width: number; height: number };
}) {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const pathName = useLocation().pathname;
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  if(pathName === '/permissions'){
    dashColor = '#2980BA';
  }
  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-[300]" onClose={() => undefined}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={[
                    'transform rounded-2xl bg-[#fff] p-6 text-left align-middle shadow-xl transition-all',
                    // size ? `w-${size.width} h-${size.height}` : 'max-w-xl'
                  ].join(' ')}
                >
                  <div className="flex items-center  h-[83px] justify-between gap-4 p-[8px] px-[32px] m-[-24px] mb-[37px] rounded-t-[1rem]"
                  style={{                   
                    backgroundColor:dashColor
                  }}>
                    {title ? (
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium text-[#fff]  "
                        style={{
                          backgroundColor:dashColor
                        }}
                      >
                        {title}
                      </Dialog.Title>
                    ) : null}
                    <button
                      type="button"
                      className="text-[#fff]"
                      onClick={handleClose}
                    >
                      <Icon icon="ion:close" width={18} />
                    </button>
                  </div>
                  {React.isValidElement(children) ? children : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default React.memo(Modal);
