import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  AreaChart,
  Area,
  ComposedChart,
  TooltipProps
} from 'recharts';

interface UserData {
  utcTime: string; // Define other properties as needed
  // ... (other properties of user data)
}

interface UsersChartProps {
  data: UserData[];
  title: string;
  color?: string;
  type?: 'bar' | 'line';
}

function UsersChart({ data = [], title, color = '#7b6c96', type = 'bar' }: UsersChartProps) {
  // Assuming `data` is your array of user objects
  const userCountsByDay = {};

  if (Array.isArray(data)) {
    // Step 1: Loop through each user and increment the count for the user's registration date
    data.forEach((user) => {
      // Ensure that 'utcTime' exists and is a string before attempting to split it
      if (typeof user.utcTime === 'string') {
        const date = user.utcTime.split(' ')[0]; // This gets the 'YYYY-MM-DD' part
        // console.log("date=========>" ,date);

        // If the date isn't in the object yet, add it with a count of 1; otherwise, increment the count
        if (!userCountsByDay[date]) {
          userCountsByDay[date] = 1;
        } else {
          userCountsByDay[date]++;
        }
      }
    });
  } else {
    console.warn('Expected an array for data but received:', data);
  }

  // Step 2: Convert the counts object intzo an array of objects
  const countsArray = Object.keys(userCountsByDay).map((date) => {
    return { date: date, count: userCountsByDay[date] };
  });

  // 'countsArray' is now an array where each element is an object with the date and the count of registered users for that date
  console.log('count', countsArray);

  // Function to format the XAxis ticks
  // Function to format the XAxis ticks
  // Function to format the XAxis ticks
  const formatDateTick = (tick) => {
    const stringTick = String(tick);
    const dateParts = stringTick.split('-');
    
    // Check if all data is from the same year
    const allSameYear = new Set(data.map((d) => d.utcTime.slice(0, 4))).size === 1;
    
    // Function to remove leading zero if the number is single digit
    const removeLeadingZero = (num) => {
      return num.startsWith('0') ? num.substring(1) : num;
    };
  
    if (allSameYear) {
      // If all data is from the same year, show day and month
      return `${removeLeadingZero(dateParts[2])}-${removeLeadingZero(dateParts[1])}`;
    } else {
      // Otherwise, show the full date
      return stringTick;
    }
  };
  
  

  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="p-5 bg-[#e5e1ec] text-[#7b6c96] rounded-[10px]"
          style={{ boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)' }}
        >
          <p className="flex items-center justify-center gap-1 text-[16px] font-semibold">
            <span
              className="w-[8px] h-[8px] !rounded-full"
              style={{ backgroundColor: '#7b6c96', display: 'inline-block' }}
            ></span>
            {`Count: ${data.count}`}
          </p>
          <p className="label text-[#656565]">{`${data.date}`}</p>
        </div>
      );
    }
    return null;
  };

  // Determine if all data is from the same year for label display
  const allSameYear = new Set(countsArray.map((item) => item.date.slice(0, 4))).size === 1;
  const yearLabel = allSameYear ? countsArray[0].date.slice(0, 4) : '';

  return (
    <div className="text-center">
      <h3>{title}</h3>
      <ResponsiveContainer height={387}>
        <ComposedChart
          data={countsArray}
          margin={{
            top: 5,
            right: 30,
            left: 20
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="date"
            tickFormatter={formatDateTick}
            height={25}
          />
          <YAxis
            color="#fff"
            width={2}
          />
          <Tooltip content={<CustomTooltip />} />

          <Line
            type="monotone"
            dataKey="count"
            stroke={color}
            fill="#7b6c96"
            activeDot={{ r: 8 }}
            strokeWidth={0.2}
          />
          <defs>
            <linearGradient
              id="colorPv"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor="#7b6c96"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="#7b6c96"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="count"
            stroke={color}
            fill="url(#colorPv)"
            fillOpacity={1}
            activeDot={{ r: 8 }}
            strokeWidth={0.3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default UsersChart;

