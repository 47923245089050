import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from 'src/helper/AxiosInstance';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import Card from '../../components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Table from 'src/components/shared/tables';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Icon } from '@iconify/react';

export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState('allCampaigns');
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [data, setData] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState<string>('customize');
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const navigate = useNavigate();

  const keys = [
    'call Date',
    'start Time',
    'end Time',
    'duration',
    'student Video',
    'teacher Video',
    'closed By',
    'call Status',
    'join'
  ];
  const [customizedKeys, setCustomizedKeys] = useState(keys);

  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    }
  ];
  const { appointmentId, key } = useParams();

  const formatDate = (timestamp) => {
    let date :any ;
    if (!timestamp._seconds && !timestamp._nanoseconds){
       date = new Date(timestamp);
    }else{
      date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    }
     
    const options: any = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(',', '').replace(',', ' ,');
  };
  const formatDateTime = (timestamp) => {
    let date :any ;
    if (!timestamp._seconds && !timestamp._nanoseconds){
       date = new Date(timestamp);
    }else{
      date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    }
    const dateOptions: any = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions: any = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions).replace(/, /g, ' , ');
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toLowerCase();
    return `${formattedTime}`;
  };
  const calculateTimeDifferenceInMinutes = (startDate, endDate) => {
    const calculateStart: any = new Date(
      startDate._seconds * 1000 + startDate._nanoseconds / 1000000
    );
    const calculateEnd: any = new Date(endDate._seconds * 1000 + endDate._nanoseconds / 1000000);
    const differenceInMilliseconds = calculateEnd - calculateStart;
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    return `${differenceInMinutes} min`;
  };
  useEffect(() => {
    const fetchLessonsDetails = async () => {
      setIsLoadingMore(true);
      try {
        const response = await axiosInstance.get(`/lesson-details`, {
          params: {
            appointmentId: appointmentId // Start from the first page
          }
        });
        setData(response.data.result.data);
      } catch (error) {
        console.error('Error fetching Lessons:', error);
      }
      setIsLoadingMore(false);
    };
    fetchLessonsDetails();
  }, [appointmentId]);
  const allNotificationsMessage = 'allCampaigns';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = async (option, title) => {
    setIsLoadingMore(true);
    try {
      setTitle(title);
      let startDate, endDate;

      switch (option) {
        case 'All Campaigns':
          startDate = null;
          endDate = null;
          break;

        case 'Today':
          startDate = new Date();
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          break;

        case 'Last 7 days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          endDate = new Date();
          break;

        case 'Last 30 days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 30);
          endDate = new Date();
          break;

        case 'Date range':
          if (start && end) {
            startDate = new Date(start);
            endDate = new Date(end);
          }
          break;

        default:
          startDate = null;
          endDate = null;
          break;
      }

      const response = await axiosInstance.get(`/promotions`, {
        params: {
          startDate: startDate,
          endDate: endDate
        }
      });
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoadingMore(false);
  };

  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        '/lesson-details/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }

  const ChangeCustomizeKey = (isChecked, field) => {
    setCustomizedKeys((prevCustomizedKeys) => {
      if (isChecked) {
        const index = keys.indexOf(field);
        const newIndex = prevCustomizedKeys.findIndex((key) => key === field);
        if (newIndex === -1) {
          return [...prevCustomizedKeys.slice(0, index), field, ...prevCustomizedKeys.slice(index)];
        } else {
          return [
            ...prevCustomizedKeys.slice(0, index),
            ...prevCustomizedKeys.slice(newIndex, newIndex + 1),
            ...prevCustomizedKeys.slice(index, newIndex),
            ...prevCustomizedKeys.slice(newIndex + 1)
          ];
        }
      } else {
        return prevCustomizedKeys.filter((key) => key !== field);
      }
    });
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const lessonData = data?.lessons?.lessons?.[String(key)] || [];

  return (
    <>
      {isLoadingMore ? (
        <LoadingComponent />
      ) : (
        <div
          className=" space-y-6 bg-[#fff] min-h-screen overflow-x-hidden "
          style={{
            width: `${Responsive.w(1584, width)}px`,
            padding: `${Responsive.sp(3, width)}px`
          }}
        >
          <div
            className=" flex  justify-center items-center p-0"
            style={{
              width: `${Responsive.w(768, width)}px`,
              gap: `${Responsive.sp(24, width)}px`,
              marginTop: `${Responsive.sp(35, width)}px`,
              marginRight: `${Responsive.sp(189.5, width)}px`,
              marginBottom: `${Responsive.sp(32, width)}px`,
              marginLeft: `${Responsive.sp(420, width)}px`
            }}
          >
            <Card
              className=" bg-white  hover:bg-[#EFEFEF] flex flex-col  items-center justify-center rounded-[16px] border-solid border-[1px] border-[#d3d3d3]"
              style={{
                width: `${Responsive.w(372, width)}px`,
                gap: `${Responsive.sp(24, width)}px`,
                padding: `${Responsive.sp(24, width)}px`
              }}
            >
              <h1
                className="text-[#7b6c96]  font-semibold font-[Montserrat] h-[29px] flex-grow-0 text-center"
                style={{
                  width: `${Responsive.w(324, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'teacherDetails:',
                  defaultMessage: 'Teacher Details:'
                })}{' '}
              </h1>
              <div
                className="flex  items-center justify-center  p-0 flex-grow-0"
                style={{
                  width: `${Responsive.w(324, width)}px`,
                  gap: `${Responsive.sp(15, width)}px`
                }}
              >
                <img
                  src={data?.appointmentData?.consult?.image || '/images/placeholder.png'}
                  alt={data?.appointmentData?.consult?.name}
                  className=" rounded-full object-cover"
                  style={{
                    width: `${Responsive.w(71, width)}px`,
                    height: `${Responsive.h(71, height)}px`
                  }}
                />

                <div className="flex flex-col items-center justify-center">
                  <p
                    className="font-semibold  text-left  nowrap text-[#000] flex-grow-0"
                    style={{
                      width: `${Responsive.w(137, width)}px`,
                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {data?.appointmentData?.consult?.name}
                  </p>
                  <p
                    className="font-semibold text-[#939393] text-left flex-grow-0"
                    style={{
                      width: `${Responsive.w(130, width)}px`,
                      fontSize: `${Responsive.sp(16, width)}px`
                    }}
                  >
                    {data?.appointmentData?.consult?.phone}
                  </p>
                </div>
              </div>
            </Card>

            <Card
              className=" bg-white  hover:bg-[#EFEFEF] flex flex-col  items-stretch justify-center rounded-[16px] border-solid border-[1px] border-[#d3d3d3]"
              style={{
                width: `${Responsive.w(372, width)}px`,
                gap: `${Responsive.sp(24, width)}px`,
                padding: `${Responsive.sp(24, width)}px`
              }}
            >
              <h1
                className="text-[#7b6c96]  font-semibold font-[Montserrat] h-[29px] flex-grow-0 text-center"
                style={{
                  width: `${Responsive.w(324, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'userDetails:',
                  defaultMessage: 'User Details'
                })}{' '}
              </h1>
              <div
                className="flex  items-center justify-center p-0 flex-grow-0"
                style={{
                  width: `${Responsive.w(324, width)}px`,
                  gap: `${Responsive.sp(15, width)}px`
                }}
              >
                <img
                  src={data?.appointmentData?.user?.image || '/images/placeholder.png'}
                  alt={data?.appointmentData?.user?.name}
                  className=" rounded-full object-cover"
                  style={{
                    width: `${Responsive.w(71, width)}px`,
                    height: `${Responsive.h(71, height)}px`
                  }}
                />

                <div className="flex flex-col items-center justify-center">
                  <p
                    className="font-semibold  text-left  nowrap text-[#000] flex-grow-0"
                    style={{
                      width: `${Responsive.w(137, width)}px`,
                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {data?.appointmentData?.user?.name}
                  </p>
                  <p
                    className="font-semibold text-[#939393] text-left flex-grow-0"
                    style={{
                      width: `${Responsive.w(130, width)}px`,
                      fontSize: `${Responsive.sp(16, width)}px`
                    }}
                  >
                    {data?.appointmentData?.user?.phone}
                  </p>
                </div>
              </div>
            </Card>
          </div>

          <Table
            loading={isLoadingMore}
            searchAble={true}
            isEmpty={!lessonData.length}
            notCountable={true}
            title={title}
            buttonAble={true}
            CustomizeTable={() => (
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] z-40"
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard !== 'jeras'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : '! border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customizedKeys.includes(field)}
                                onChange={(e) => ChangeCustomizeKey(e.target.checked, field)}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                <FormattedMessage
                                  id={`${field.replaceAll(' ', '')}`}
                                  defaultMessage={`${field}`}
                                />
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
            TimeTable={(props) => (
              <div
                // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
                className={`absolute top-[35px] ${
                  title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
                } bg-[#f5f3f8] p-[12px]`}
                style={{
                  width: `${Responsive.w(572, width)}px`,
                  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
                }}
              >
                <ul
                  className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
                  aria-labelledby="dropdownDefaultButton"
                  // style={{
                  //   width: `${Responsive.w(572, width)}px`,
                  // }}
                >
                  <li className="w-full text-left">
                    <a
                      href="#"
                      className="block px-4 py-2 mb-3 font-medium w-full"
                      onClick={() => {
                        handleDateOptionClick('All Campaigns', allNotificationsMessage);
                      }}
                    >
                      {
                        <FormattedMessage
                          id={allNotificationsMessage}
                          defaultMessage={allNotificationsMessage}
                        />
                      }
                    </a>
                  </li>
                  <li className="w-full text-left">
                    <a
                      href="#"
                      className="block px-4 py-2 mb-3 font-medium w-full "
                      onClick={() => handleDateOptionClick('Today', todayMessage)}
                    >
                      {
                        <FormattedMessage
                          id={todayMessage}
                          defaultMessage={todayMessage}
                        />
                      }
                    </a>
                  </li>
                  <li className="w-full text-left">
                    <a
                      href="#"
                      className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                      onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                    >
                      {
                        <FormattedMessage
                          id={last7DaysMessage}
                          defaultMessage={last7DaysMessage}
                        />
                      }
                    </a>
                  </li>
                  <li className="w-full text-left">
                    <a
                      href="#"
                      className="block px-4 py-2 font-medium mb-3 border-solid "
                      onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                    >
                      {
                        <FormattedMessage
                          id={last30DaysMessage}
                          defaultMessage={last30DaysMessage}
                        />
                      }
                    </a>
                  </li>
                  <li className="w-full text-left">
                    <a
                      href="#"
                      className="block px-4 py-2 font-medium mb-3 border-solid "
                      onClick={() => {
                        handleDateOptionClick('Date range', dateRangeMessage);
                      }}
                    >
                      {
                        <FormattedMessage
                          id={dateRangeMessage}
                          defaultMessage={dateRangeMessage}
                        />
                      }
                    </a>
                  </li>
                </ul>
              </div>
            )}
            RenderHead={() => (
              <>
                <th
                  className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff] capitalize"
                  style={{ height: `${Responsive.h(24, height)}px`, backgroundColor: dashColor }}
                >
                  #
                </th>
                {customizedKeys.map((field, index) => (
                  <th
                    key={index}
                    className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff] capitalize"
                    style={{
                      height: `${Responsive.h(24, height)}px`,
                      backgroundColor: dashColor,
                      display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                    }}
                    data-label={field}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                        justifyContent: 'center',
                        flexDirection: 'row-reverse'
                      }}
                    >
                      <FormattedMessage
                        id={`${field.replaceAll(' ', '')}`}
                        defaultMessage={`${field}`}
                      />
                    </div>
                  </th>
                ))}
              </>
            )}
            RenderBody={({ getRowColor }) => (
              <>
                {lessonData.map((lesson, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: getRowColor(index) }}
                    className="hover:bg-[#EAE0EB]"
                  >
                    <td
                      className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch"
                      data-label="#"
                    >
                      {index + 1}
                    </td>
                    {customizedKeys.map((field, fieldIndex) => (
                      <td
                        key={fieldIndex}
                        className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch "
                        data-label={field}
                        style={{
                          display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                        }}
                      >
                        {field === 'call Date' &&
                          (lesson.startDate != null ? formatDate(lesson.startDate) : 'N/A')}
                        {field === 'start Time' &&
                          (lesson.startDate != null ? formatDateTime(lesson.startDate) : 'N/A')}
                        {field === 'end Time' &&
                          (lesson.endDate != null ? formatDateTime(lesson.endDate) : 'N/A')}
                        {field === 'duration' &&
                          (lesson.endDate != null && lesson.startDate  != null 
                            ? calculateTimeDifferenceInMinutes(lesson.startDate, lesson.endDate)
                            : 'N/A')}
                        {field === 'student Video' && (lesson.studentVideo === true ? 'ON' : 'OFF')}
                        {field === 'teacher Video' && (lesson.teacherVideo === true ? 'ON' : 'OFF')}
                        {field === 'closed By' &&
                          (lesson.closedBy != null ? lesson.closedBy : 'N/A')}
                        {field === 'call Status' && (
                          <p
                            className="flex items-center justify-center"
                            style={{
                              height: '30px',
                              border:
                                lesson?.state === 'closed'
                                  ? 'solid 1px #e84a4d'
                                  : lesson?.state === 'oncall'
                                  ? 'solid 1px #28c794'
                                  : lesson?.state === 'refused'
                                  ? `solid 1px ${
                                      currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'
                                    }`
                                  : 'solid 1px #1e1e1e',
                              backgroundColor:
                                lesson?.state === 'closed'
                                  ? '#ffe2e2'
                                  : lesson?.state === 'oncall'
                                  ? '#ebfeef'
                                  : lesson?.state === 'refused'
                                  ? `${currentDashboard !== 'jeras' ? '#fff7fd' : '#e5e1ec'}`
                                  : '',
                              color:
                                lesson?.state === 'closed'
                                  ? ' #d91c1f'
                                  : lesson?.state === 'oncall'
                                  ? '#228176'
                                  : lesson?.state === 'refused'
                                  ? `${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                                  : '#1e1e1e'
                            }}
                          >
                            {lesson?.state || 'N/A'}
                          </p>
                        )}
                        {field === 'join' &&
                          (lesson.state === 'oncall' &&
                          lesson.teacherVideo === true &&
                          lesson.studentVideo === true ? (
                            <button
                              className="flex items-center justify-center gap-4 bg-[#28c794] w-[118px] px-1 py-2 rounded-[4px]"
                              key={index}
                              // onClick={() => handleJoinClick(item.appointmentId)}
                            >
                              <p className="w-[32px] text-base text-left text-white">Join</p>
                              <Icon
                                icon="mdi:video"
                                width={29.5}
                                height={29.5}
                              />
                            </button>
                          ) : (
                            <button
                              className="flex items-center justify-center gap-4 bg-[#afafaf] w-[118px] px-1 py-2 rounded-[4px]"
                              key={index}
                            >
                              <p className="w-[32px] text-base text-left text-white">Join</p>
                              <Icon
                                className="text-white"
                                icon="mdi:video"
                                width={29.5}
                                height={29.5}
                              />
                            </button>
                          ))}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
            onApply={(startDate, endDate) => {
              setStart(startDate);
              setEnd(endDate);
            }}
            buttonExport={() =>
              ExportXlsx(
                lessonData ,
                keys
              )
            }
          />
        </div>
      )}
    </>
  );
}
