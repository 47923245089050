import { useIntl } from 'react-intl';

const usePeriods = () => {
  const intl = useIntl();

  const periods = [
    {
      value: 'today',
      label: intl.formatMessage({ id: 'today', defaultMessage: 'Today' })
    },
    {
      value: 'last-day',
      label: intl.formatMessage({ id: 'lastDay', defaultMessage: 'Last day' })
    },
    {
      value: 'this-week',
      label: intl.formatMessage({ id: 'thisWeek', defaultMessage: 'This week' })
    },
    {
      value: 'last-week',
      label: intl.formatMessage({ id: 'lastWeek', defaultMessage: 'Last week' })
    },

    {
      value: 'last-month',
      label: intl.formatMessage({ id: 'lastMonth', defaultMessage: 'Last month' })
    },
    {
      value: 'last-3-month',
      label: intl.formatMessage({ id: 'lastThreeMonth', defaultMessage: 'Last three months' })
    },
    {
      value: 'last-6-months',
      label: intl.formatMessage({ id: 'lastSixMonth', defaultMessage: 'Last six months' })
    },
    {
      value: 'this-year',
      label: intl.formatMessage({ id: 'thisYear', defaultMessage: 'This year' })
    },
    {
      value: 'last-year',
      label: intl.formatMessage({ id: 'lastYear', defaultMessage: 'Last year' })
    }
  ];

  return periods;
};

export default usePeriods;
