import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { getDates } from 'src/helper/DateUtils';
import parseJwt from 'src/helper/parseJwt';
import { setDashboard } from 'src/reducers/dashboard';
import { UpdateResult } from 'src/reducers/users';
import { RootState } from 'src/store';

function useHooks() {
  const [data, setData] = useState<any>({
    todayOrders: { data: { todayOrdersCount: 0, todayOrdersEarn: 0 }, loading: true },
    todayUsers: { data: 0, loading: true },
    todayAppointmentsCount: { data: 0, loading: true },
    freelancersCount: { active: 0, inactive: 0, loading: true },
    supportCount: { data: 0, loading: true },
    promoCodesCount: { active: 0, inactive: 0, loading: true },
    recentClients: { data: [], loading: true },
    recentConsultants: { data: [], loading: true },
    recentOrders: { data: [], loading: true },
    playStoreInfo: { data: [], loading: true },
    appStoreInfo: { data: [], loading: true }
  });
  useEffect(() => {
    fetchTodayOrders();
    fetchTodayUsers();
    fetchTodayAppointmentsCount();
    fetchPlayStoreInfo();
    fetchAppStoreInfo();
  }, []);

  async function fetchTodayOrders() {
    try {
      const todayOrdersResponse = await axiosInstance.get('dashboard/todayorders');
      setData((prevData) => ({
        ...prevData,
        todayOrders: { data: todayOrdersResponse.data.result, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }

  async function fetchTodayUsers() {
    try {
      const { from, to } = getDates('today');

      const todayUsersResponse = await axiosInstance.get('users', {
        params: {
          queries: [
            ['userType', '==', 'USER'],
            ['createdDateValue', '==', from + '-' + to]
          ]
        }
      });
      setData((prevData) => ({
        ...prevData,
        todayUsers: { data: todayUsersResponse.data.result.data.length, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching clients count:', error);
    }
  }

  async function fetchTodayAppointmentsCount() {
    try {
      const { from, to } = getDates('today');

      const todayAppointmentsCountResponse = await axiosInstance.get('appointments', {
        params: {
          queries: [['timeValue', '==', from + '-' + to]]
        }
      });
      setData((prevData) => ({
        ...prevData,
        todayAppointmentsCount: {
          data: todayAppointmentsCountResponse.data.result.data.length,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching freelancers count:', error);
    }
  }

  async function fetchPlayStoreInfo() {
    try {
      const res = await axiosInstance.get('dashboard/playStoreStats');
      setData((prevData) => ({
        ...prevData,
        playStoreInfo: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching play store:', error);
    }
  }
  async function fetchAppStoreInfo() {
    try {
      const res = await axiosInstance.get('dashboard/appStoreStats');
      console.log('apple res', res.data.result.data);

      setData((prevData) => ({
        ...prevData,
        appStoreInfo: {
          data: res.data.result.data,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error fetching app store info:', error);
    }
  }
  return { data };
}

export default function Index() {
  const navigate = useNavigate();
  const { dashboard, appStoreLink, playStoreLink } = useSelector(
    (state: RootState) => state.Dashboard
  );

  // Now safe to call useHooks and other logic
  const { data } = useHooks();
  return (
    <div className="p-8 pt-12 space-y-6 bg-slate-100 min-h-screen">
      <p className="font-semibold text-3xl">
        <FormattedMessage
          id="todayOverview"
          defaultMessage="Today's Overview"
        />
      </p>
      <div className="grid grid-cols-2 gap-8 w-3/4 mx-auto text-xl">
        <Link to="/orders?date=today">
          <Card className="flex flex-col justify-center items-center py-12">
            <Icon
              className="shrink-0 text-[#0ea5e9]"
              width={24}
              height={24}
              icon="material-symbols:orders-rounded"
            />
            <p className="font-semibold capitalize">
              <FormattedMessage
                id="ordersNumber"
                defaultMessage="Orders Number"
              />
            </p>
            <p className="font-semibold">
              {data?.todayOrders?.loading ? <Spinner /> : data?.todayOrders?.data?.todayOrdersCount}
            </p>
          </Card>
        </Link>

        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-yellow-500"
            width={24}
            height={24}
            icon="nimbus:money"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="totalRevenue"
              defaultMessage="total revenue"
            />
          </p>
          <p className="font-semibold">
            {data.todayOrders.loading ? (
              <Spinner />
            ) : (
              '$' + data?.todayOrders?.data?.todayOrdersEarn?.toFixed(2)
            )}
          </p>
        </Card>

        <Link to="/users?date=today">
          <Card className="flex flex-col justify-center items-center py-12">
            <Icon
              className="shrink-0 text-[#9333ea]"
              width={24}
              height={24}
              icon="ph:user-fill"
            />
            <p className="font-semibold">
              <FormattedMessage
                id="newUsers"
                defaultMessage="New Users"
              />
            </p>
            <p className="font-semibold">
              {data?.todayUsers?.loading ? <Spinner /> : data?.todayUsers?.data}
            </p>
          </Card>
        </Link>

        <Link to="/appointments?date=today">
          <Card className="flex flex-col justify-center items-center py-12">
            <Icon
              className="shrink-0 text-[#ef4444]"
              width={24}
              height={24}
              icon="teenyicons:appointments-outline"
            />
            <p className="font-semibold">
              <FormattedMessage
                id="appointmentsDone"
                defaultMessage="Appointments"
              />
            </p>
            <p className="font-semibold">
              {data?.todayAppointmentsCount?.loading ? (
                <Spinner />
              ) : (
                data?.todayAppointmentsCount?.data
              )}
            </p>
          </Card>
        </Link>
      </div>
    </div>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color:dashColor
          }}
        />
       
      </div>
    </>
  );
}
