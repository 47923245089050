import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import vector from '../images/Vector+.png';
import { useIntl } from 'react-intl';
import axiosInstance from 'src/helper/AxiosInstance';
import { toast } from 'react-toastify';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { FormContext } from '../../../context/context';
import img_1 from '../../images/delete-bin-line.png';
import img_1_2 from '../../images/delete-bin-line@2x.png';
import img_1_3 from '../../images/delete-bin-line@3x.png';
import img_2 from '../../images/Edit.png';
import img_2_2 from '../../images/Edit@2x.png';
import img_2_3 from '../../images/Edit@3x.png';
import { titles } from 'src/helper/PageTitles';
import Select from 'src/components/shared/Select';

export default function CoursesDetailsPage() {
  const { width, height } = useWindowDimensions();
  const intl = useIntl();
  const { formData, setFormData } = useContext(FormContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [users, setUsers] = useState<any>([]);
  const [oneUser, setOneUser] = useState<any>([]);
  const [interest, setInterest] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showTeachers, setShowTeachers] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [isloadingInter, setIsloadingInter] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const navigate = useNavigate();
  let initialQueries: any = [
    ['userType', '==', 'COACH'],
    ['accountStatus', '==', 'Active']
  ];
  console.log('formData', formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleToggle = () => {
    setFormData((prevData) => ({ ...prevData, active: !prevData.active }));
  };

  useEffect(() => {
    if (oneUser && oneUser.length > 0) {
      const usersIds = oneUser.map((user: any) => user.uid);
      setSelectedUsers(usersIds);
    }
  }, [oneUser]);

  const { id } = useParams();

  async function getOneCourse() {
    try {
      const res = await axiosInstance.get(`/courses/${id}`);
      setFormData(res.data.result.data);
    } catch (er) {
      console.log('courses not found');
    }
  }

  async function getUsersCourse() {
    try {
      setIsloading(true);
      const res = await axiosInstance.get(`/users`, {
        params: {
          queries: initialQueries
        }
      });
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('courses not found');
    }
    setIsloading(false);
  }

  async function getOneUsersCourse() {
    try {
      const res = await axiosInstance.get(`/courses-users/${id}`);
      setOneUser(res.data.result.data);
    } catch (er) {
      console.log('courses not found');
    }
  }

  async function getOneInterestCourse() {
    setIsloadingInter(true);
    try {
      const res = await axiosInstance.get(`/courses-interes/`);
      setInterest(res.data.result.data);
    } catch (er) {
      console.log('courses not found');
    }
    setIsloadingInter(false);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setFormData((prevData) => ({ ...prevData, image: e.target.result }));
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const UpdateUsers = async (selectedUsers) => {
    try {
      const reqBody = {
        courseId: formData.courseId,
        selectedUsers: Array.from(selectedUsers)
      };
      await axiosInstance.patch(`/updateUsers/`, reqBody);
      toast.success('User has been modified successfully ✔');
    } catch (er) {
      toast.error('Failed to modify course');
    }
  };

  const toggleOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const toggleTeachers = () => {
    setShowTeachers((prevShowOptions) => !prevShowOptions);
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({ ...prevData, image: '' }));
  };

  useEffect(() => {
    getOneCourse();
    getOneUsersCourse();

    getUsersCourse();
    getOneInterestCourse();
  }, []);
  const handleCheckboxChange = (interestId) => {
    setFormData((prevData) => {
      const updatedInterests = prevData.interestListIds.includes(interestId)
        ? prevData.interestListIds.filter((id) => id !== interestId)
        : [...prevData.interestListIds, interestId];
      return { ...prevData, interestListIds: updatedInterests };
    });
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const handleSearch = async () => {
    try {
      setIsloading(true);
      const queryParams = /^[+]?[0-9]+$/.test(searchQuery)
        ? [['phoneNumber', '>=', searchQuery + '~']]
        : [['name', '>=', searchQuery + '~']];

      const res = await axiosInstance.get('/consultants', {
        params: {
          queries: queryParams
        }
      });
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('Error fetching users:', error);
    } finally {
      setIsloading(false);
    }
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const langOptions = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' },
    { value: 'hi', label: 'Hindi' },
    { value: 'ur', label: 'Urdu' }
  ];
  return (
    <>
      <div
        className="flex justify-between flex-col items-start p-0 overflow-hidden"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          marginTop: `${Responsive.sp(24, width)}px`,
          marginRight: `${Responsive.sp(23, width)}px`,
          marginBottom: `${Responsive.sp(39, width)}px`,
          marginLeft: `${Responsive.sp(25, width)}px`
        }}
      >
        <div
          className="flex items-center justify-between mb-4"
          style={{
            width: `${Responsive.w(1560, width)}px`
          }}
        >
          <div
            className="relative flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
            style={{
              width: `${Responsive.w(430, width)}px`
            }}
          >
            <div
              onClick={toggleTeachers}
              className={` flex items-center !text-[${dashColor}] ${
                currentDashboard === 'jeras' ? 'bg-[#f5f0ff]' : 'bg-[#ffebeb]'
              } outline-none cursor-pointer justify-between gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[${dashColor}]`}
              style={{
                width: `${Responsive.w(240, width)}px`,
                height: `${Responsive.h(56, height)}px`,
                borderRadius: `${Responsive.sp(8, width)}px`,
                color: `${dashColor}`
              }}
            >
              <span
                className=" text-left"
                style={{
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {currentDashboard === 'jeras'
                  ? useIntl().formatMessage({
                      id: 'addTeachers',
                      defaultMessage: 'Add Teachers'
                    })
                  : useIntl().formatMessage({
                      id: 'addcoach',
                      defaultMessage: 'Add Coach'
                    })}
              </span>
              <svg
                className={
                  showTeachers
                    ? '  transform rotate-180 !text-[#7b6c96]'
                    : ' transform rotate-0 !text-[#7b6c96]'
                }
                width="20"
                height="20"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            {showTeachers && (
              <div
                className="absolute top-[100%] left-0 rounded-[8px] overflow-y-auto overflow-x-hidden z-10 bg-[#fff]  p-2 flex flex-col justify-start items-stretch flex-grow"
                style={{
                  width: `${Responsive.w(240, width)}px`,
                  height: `${Responsive.h(400, height)}px`,
                  gap: `${Responsive.sp(12, width)}px`,
                  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
                }}
              >
                <div
                  className=" bg-[#f9fafb] h-[37px] inline-flex items-center  py-2  px-4  border border-neutral-200"
                  style={{
                    width: `${Responsive.w(200, width)}px`,
                    height: `${Responsive.h(37, height)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                >
                  <span className="shrink-0 inline text-neutral-500">
                    <Icon
                      icon="ri:search-line"
                      style={{
                        width: `${Responsive.w(13.3, width)}px`,
                        height: `${Responsive.h(13.3, height)}px`
                      }}
                    />
                  </span>
                  <input
                    type="text"
                    className="form-input !bg-transparent text-[14px] font-[Montserrat] border-none py-0"
                    style={{
                      width: `${Responsive.w(140, width)}px`,
                      height: `${Responsive.h(21, height)}px`,
                      fontSize: `${Responsive.sp(14, width)}px`
                    }}
                    placeholder={useIntl().formatMessage({
                      id: 'Search',
                      defaultMessage: 'Search Teacher'
                    })}
                    autoComplete="off"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && searchQuery.trim() !== '') {
                        handleSearch();
                      }
                    }}
                  />
                </div>
                {isloading ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {users?.map((user: any, index: number) => (
                      <label
                        key={index}
                        className="flex  justify-between items-center p-0   cursor-pointer"
                        style={{
                          height: `${Responsive.h(16, height)}px`,
                          fontSize: `${Responsive.sp(14, width)}px`
                        }}
                      >
                        {user && (
                          <>
                            <span
                              className="text-left text-[#111928] font-medium"
                              style={{
                                fontSize: `${Responsive.sp(14, width)}px`
                              }}
                            >
                              {user?.name}
                            </span>
                            <input
                              className="checked:!bg-[#7b6c96]"
                              type="checkbox"
                              value={user?.uid}
                              checked={selectedUsers?.includes(user?.uid)}
                              onChange={() => handleUserSelection(user?.uid)}
                            />
                          </>
                        )}
                      </label>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>

          <div
            className="flex justify-center items-center mr-4"
            style={{
              gap: `${Responsive.sp(20, width)}px`
            }}
          >
            <label
              className={`text-[${dashColor}] font-[Montserrat] font-semibold text-left`}
              style={{
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'active',
                defaultMessage: 'Active'
              })}
            </label>
            <ToggleSlider
              onClick={handleToggle}
              checked={formData.active}
            />
          </div>
        </div>

        <div
          className="flex items-start justify-start bg-white overflow-x-hidden"
          style={{
            width: `${Responsive.w(1560, width)}px`,
            gap: `${Responsive.sp(21, width)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`
          }}
        >
          <div className="flex items-start justify-start rounded-lg">
            {formData.image ? (
              <div
                className="relative cursor-pointer sm:w-auto h-[56px] inline-flex items-start justify-start rounded-lg"
                style={{
                  width: `${Responsive.w(619, width)}px`,
                  height: `${Responsive.h(391, height)}px`
                }}
              >
                <label
                  htmlFor="file-upload"
                  className=" inline-block cursor-pointer"
                >
                  <input
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <div className="w-8 h-8 flex items-center justify-center bg-[#f7f7f7] rounded-full left-12 top-2 absolute  p-2 hover:bg-slate-600">
                    <img
                      src={img_2}
                      srcSet={`${img_2_2}, ${img_2_3}`}
                      className="object-contain p-0 w-[6.8px] h-[7.8px] rounded-lg"
                      alt="Description of the image"
                    />
                  </div>
                </label>
                <div
                  className="w-8 h-8 flex items-center justify-center bg-[#f7f7f7] rounded-full left-2 top-2 absolute p-2 hover:bg-slate-600"
                  onClick={handleDeleteImage}
                >
                  <img
                    src={img_1}
                    srcSet={`${img_1_2}, ${img_1_3}`}
                    className="object-contain p-0 w-[6.8px] h-[7.8px] rounded-lg"
                    alt="Description of the image"
                  />
                </div>
                <img
                  src={formData.image}
                  alt="Selected"
                  style={{
                    width: `${Responsive.w(619, width)}px`,
                    height: `${Responsive.h(391, height)}px`
                  }}
                />
              </div>
            ) : (
              <>
                {formData.image ? (
                  <div
                    className="relative cursor-pointer sm:w-auto h-[56px] inline-flex items-center rounded-lg"
                    style={{
                      width: `${Responsive.w(619, width)}px`,
                      height: `${Responsive.h(391, height)}px`
                    }}
                  >
                    <label
                      htmlFor="file-upload"
                      className=" inline-block cursor-pointer"
                    >
                      <input
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                      <div className="w-8 h-8 flex items-center justify-center bg-[#fff] rounded-full left-12 top-2 absolute  p-2 hover:bg-slate-600">
                        <img
                          src={img_2}
                          srcSet={`${img_2_2}, ${img_2_3}`}
                          className="object-contain p-0 w-[6.8px] h-[7.8px] rounded-lg"
                          alt="Description of the image"
                        />
                      </div>
                    </label>
                    <div
                      className="w-8 h-8 flex items-center justify-center bg-[#fff] rounded-full left-2 top-2 absolute p-2 hover:bg-slate-600"
                      onClick={handleDeleteImage}
                    >
                      <img
                        src={img_1}
                        srcSet={`${img_1_2}, ${img_1_3}`}
                        className="object-contain p-0 w-[6.8px] h-[7.8px] rounded-lg"
                        alt="Description of the image"
                      />
                    </div>
                    <img
                      src={formData.image}
                      alt="Selected"
                      className="rounded-lg"
                      style={{
                        width: `${Responsive.w(619, width)}px`,
                        height: `${Responsive.h(391, height)}px`
                      }}
                    />
                  </div>
                ) : (
                  <label
                    htmlFor="fileInput"
                    className=" cursor-pointer"
                  >
                    <input
                      id="fileInput"
                      type="file"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                    <div
                      className="cursor-pointer sm:w-auto h-[56px] inline-flex items-center rounded-lg"
                      style={{
                        width: `${Responsive.w(619, width)}px`,
                        height: `${Responsive.h(391, height)}px`
                      }}
                    >
                      <p
                        className={`shrink-0 text-[14px] bg-[#f7f7f7] font-medium font-[Montserrat] text-[${dashColor}] flex items-center justify-center gap-[16px]`}
                        style={{
                          width: `${Responsive.w(619, width)}px`,
                          height: `${Responsive.h(391, height)}px`,
                          fontSize: `${Responsive.sp(24, width)}px`
                        }}
                      >
                        <Icon
                          icon={'tabler:plus'}
                          style={{
                            color: dashColor,
                            width: '24px',
                            height: '24px'
                          }}
                        />
                        <span>
                          {useIntl().formatMessage({
                            id: 'addImage',
                            defaultMessage: 'Add Image'
                          })}
                        </span>
                      </p>
                    </div>
                  </label>
                )}
              </>
            )}
          </div>

          <form
            className="flex items-center justify-center flex-col"
            style={{
              width: `${Responsive.w(885, width)}px`
            }}
          >
            <div
              className="grid grid-cols-2 gap-3"
              style={{
                width: `${Responsive.w(885, width)}px`
              }}
            >
              {/* Course name */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'courseName',
                    defaultMessage: 'Course Name'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="text"
                  required
                  placeholder="Enter Course name"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              {/* Duration */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'duration',
                    defaultMessage: 'Duration'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="number"
                  required
                  placeholder="Enter Duration"
                  value={formData.order}
                  onChange={handleChange}
                  name="order"
                />
              </div>
              {/* Coach Name */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'coachName',
                    defaultMessage: 'Coach Name'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="text"
                  required
                  placeholder="Enter Name of Coach"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              {/* Coach rate */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'coachRate',
                    defaultMessage: 'Coach Rate'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="number"
                  required
                  placeholder="Enter Coach Rate"
                  value={formData.rate}
                  onChange={handleChange}
                  name="rate"
                />
              </div>
              {/* Number Of Videos */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'numberOfVideos',
                    defaultMessage: 'Number Of Videos'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="number"
                  required
                  placeholder="Enter Number Of Videos"
                  value={formData.videoCount}
                  onChange={handleChange}
                  name="videoCount"
                />
              </div>
              {/* Price $ */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'price$',
                    defaultMessage: 'Price $'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="number"
                  required
                  placeholder="Enter Price"
                  value={formData.price}
                  onChange={handleChange}
                  name="price"
                />
              </div>
              {/* Target Audience */}
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`,
                  height: `${Responsive.h(151, height)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'targetAudience',
                    defaultMessage: 'Target Audience'
                  })}
                </label>
                <input
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    height: `${Responsive.h(111, height)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                  type="text"
                  required
                  placeholder="Enter Notes"
                  value={formData.notes}
                  onChange={handleChange}
                  name="notes"
                />
              </div>
              {/* Language */}
              
              <div
                className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white"
                style={{
                  width: `${Responsive.w(430, width)}px`
                }}
              >
                <label
                  className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                  style={{
                    width: `${Responsive.w(122, width)}px`,
                    height: `${Responsive.h(24, height)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'Language',
                    defaultMessage: 'Language'
                  })}
                </label>
                <select
                  name="lang"
                  id="lang"
                  value={formData.lang}
                  onChange={handleChange}
                  className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`
                  }}
                >
                  <optgroup>
                    <option value="">
                      {useIntl().formatMessage({
                        id: 'selectLanguage',
                        defaultMessage: 'Select language'
                      })}
                    </option>
                    <option value="en">
                      {useIntl().formatMessage({
                        id: 'en',
                        defaultMessage: 'English'
                      })}
                    </option>
                    <option value="ar">
                      {useIntl().formatMessage({
                        id: 'ar',
                        defaultMessage: 'العربية'
                      })}
                    </option>
                    <option value="fr">
                      {useIntl().formatMessage({
                        id: 'fr',
                        defaultMessage: 'français'
                      })}
                    </option>
                    <option value="id">
                      {useIntl().formatMessage({
                        id: 'id',
                        defaultMessage: 'Bahasa Indonesia'
                      })}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
            {/* Description of Course */}
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white  mt-2"
              style={{
                width: `${Responsive.w(885, width)}px`,
                height: `${Responsive.h(170, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'descriptionOfCourse',
                  defaultMessage: 'Description of Course'
                })}
              </label>
              <input
                className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                style={{
                  width: `${Responsive.w(840, width)}px`,
                  height: `${Responsive.h(130, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter description"
                value={formData.desc}
                onChange={handleChange}
                name="desc"
              />
            </div>
            {/* About Coach */}
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white  mt-2"
              style={{
                width: `${Responsive.w(885, width)}px`,
                height: `${Responsive.h(170, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'aboutCoach',
                  defaultMessage: 'About Coach'
                })}
              </label>
              <input
                className="flex items-center outline-none justify-start gap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]"
                style={{
                  width: `${Responsive.w(840, width)}px`,
                  height: `${Responsive.h(130, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter description"
                value={formData.desc}
                onChange={handleChange}
                name="desc"
              />
            </div>

            <button
              onClick={() => {
                if (id) {
                  navigate(`/academy/courses/video/${id}`);
                  window.scrollTo(0, 0);
                } else {
                  navigate(`/academy/courses/video`);
                  window.scrollTo(0, 0);
                }
              }}
              className={` bg-[${dashColor}] flex mr-auto mt-5  items-center justify-center text-white font-bold rounded-[8px] py-[5px] px-[16px] text-center`}
              style={{
                width: `${Responsive.w(240, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'next',
                defaultMessage: 'Next'
              })}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
      <Icon
        icon="svg-spinners:3-dots-fade"
        width={50}
        height={25}
        style={{
          color: dashColor
        }}
      />
    </div>
  );
}
