import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import SideModal from 'src/components/shared/SideModal';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import SharedTime from 'src/components/shared/SharedTime';
import SortButton from 'src/components/shared/SortButton';
import appointments, {
  ChangeCustomizeKeys,
  UpdateFilter,
  UpdateResult
} from 'src/reducers/appointments';
import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import RangePicker from 'src/components/shared/RangePicker';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDates } from 'src/helper/DateUtils';
import { periods } from 'src/constants';
import { Fields, IFilters } from 'src/types/types';
import usePeriods from 'src/hooks/usePeriods';
import AppointmentsCalendar from './Calender';
import SmallLoader from 'src/components/shared/SmallLoader';
import Card from 'src/components/shared/Card';
import { toast } from 'react-toastify';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import img_1 from '../images/UnionBl.png';
import img_1_2 from '../images/UnionBl@2x.png';
import img_1_3 from '../images/UnionBl@3x.png';
import img_3 from '../images/clock-eight.png';
import img_3_2 from '../images/clock-eight@2x.png';
import img_3_3 from '../images/clock-eight@3x.png';
import img_4 from '../images/Union.png';
import img_4_2 from '../images/Union@2x.png';
import img_4_3 from '../images/Union@3x.png';
import img_5 from '../images/calendar.png';
import img_5_2 from '../images/calendar (2).png';
import img_5_3 from '../images/calendar (3).png';
import { userState } from '../../reducers/auth/index';
import ZegoCloud from '../../components/zegoCloud';

function useHooks() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let rerender: boolean = true;
  const { filters: userFilters } = useSelector((state: RootState) => state.Users);

  const [endPonit, setEndPonit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTodayAppsLoading, setIsTodayAppsLoading] = useState<boolean>(true);
  const [todayClosedAppsCount, setTodayClosedAppsCount] = useState<any>([]);
  const [todayOpenAppsCount, setTodayOpenAppsCount] = useState<any>([]);
  const [result, setResult] = useState<any>();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const consultUid = searchParams.get('consultid');
  const [monthAppLoading, setMonthAppLoading] = useState(false);
  const [appointmentsOfMonth, setAppointmentsOfMonth] = useState<any>([]);
  const date = searchParams.get('date');
  let initialQueries: any = [];

  const { from, to } = getDates('today');
  const initialFilters = date
    ? [...initialQueries, ['timeValue', '==', `${from}-${to}`]]
    : initialQueries;
  const [filters, setFilters] = useState<IFilters>({
    ...userFilters,
    page: 1,
    perPage: 15,
    sorts: [],
    queries: initialFilters,
    search: undefined
  });

  useEffect(() => {
    if (!rerender) return;
    GetAppointments();
    fetchMonthAppointments();
    getTodayAppointments();

    rerender = false;
  }, [filters]);

  async function GetAppointments(params?: Partial<IFilters>) {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get('appointments-forever', {
        params: {
          ...filters,
          ...params,
          sorts: isModalOpen ? [] : filters.sorts // Initialize sorts array when modal is open
        }
      });
      dispatch(UpdateResult(data.result));
      setIsDataEmpty(data.result.data.length === 0);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const fetchMonthAppointments = async (selectedFrom?: any, selectedTo?: any) => {
    const { from, to }: any = getDates('this-month');
    console.log('from month', from);
    console.log('to month', to);
    try {
      setMonthAppLoading(true);
      let response;
      if (selectedFrom == undefined && selectedTo == undefined) {
        response = await axiosInstance.get('appointments-forever', {
          params: {
            queries: [['timeValue', '==', from + '-' + to]]
          }
        });
      } else {
        response = await axiosInstance.get('appointments-forever', {
          params: {
            queries: [['timeValue', '==', selectedFrom + '-' + selectedTo]]
          }
        });
      }
      const appointments = response.data.result.data;
      console.log('dd', appointments);
      setAppointmentsOfMonth(appointments); // Update the state
      setMonthAppLoading(false);
    } catch (error) {
      console.error('Error fetching today charges:', error);
      // Handle errors appropriately
    }
  };

  const getTodayAppointments = async () => {
    const { from, to }: any = getDates('today');

    try {
      setIsTodayAppsLoading(true);
      const { data } = await axiosInstance.get('appointments-forever', {
        params: {
          queries: [['timeValue', '==', from + '-' + to]]
        }
      });
      const todayApps = data.result.data;
      let closedAppsCount = 0;
      let openAppsCount = 0;
      for (const app of todayApps) {
        if (app.appointmentStatus == 'closed') closedAppsCount++;
        else openAppsCount++;
      }
      setTodayOpenAppsCount(openAppsCount);
      setTodayClosedAppsCount(closedAppsCount);
    } catch (error: AxiosError | any) {
      console.log('error', error);
      toast.error('There is error loading counts of today appointments');
    } finally {
      setIsTodayAppsLoading(false);
    }
  };
  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters | 'clear' | 'calls') => {
      if (key === 'clear') {
        const result = {
          ...filters,
          page: 1,
          perPage: 15,
          sorts: [],
          queries: [...initialQueries],
          search: undefined
        };
        setFilters(result);
        dispatch(UpdateFilter(result));
        return;
      }

      let result: any = { ...filters };
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;
        case 'queries':
          result[key] = value;
          result['page'] = 1;
          break;
        default:
          break;
      }
      const sorts = isModalOpen ? [] : result.sorts;

      setFilters({ ...result, sorts });
      dispatch(UpdateFilter({ ...result, sorts }));
    },
    [filters, initialQueries, isModalOpen, dispatch]
  );

  const [isDataEmpty, setIsDataEmpty] = useState(true);
  const performSearch = async (searchValue) => {
    const cleanedSearchValue = searchValue.replace(/\s+/g, '');
    const isPhoneNumber = /^[+]?[0-9]+$/.test(cleanedSearchValue);

    const searchQueries = async (primaryField, secondaryField, value) => {
      await ChangeFilter([[primaryField, '>=', value + '~']], 'queries');
      if (!isDataEmpty) {
        await ChangeFilter([[secondaryField, '>=', value + '~']], 'queries');
      }
    };

    if (isPhoneNumber) {
      // Search in 'user.phone' first, then in 'consult.phone'
      await searchQueries('user.phone', 'consult.phone', cleanedSearchValue);
    } else {
      // Search in 'user.name' first, then in 'consult.name'
      await searchQueries('user.name', 'consult.name', searchValue);
    }
  };

  return {
    isLoading,
    GetAppointments,
    ChangeFilter,
    filters,
    setIsModalOpen,
    initialQueries,
    isDataEmpty,
    performSearch,
    appointmentsOfMonth,
    setAppointmentsOfMonth,
    monthAppLoading,
    setMonthAppLoading,
    fetchMonthAppointments,
    isTodayAppsLoading,
    todayClosedAppsCount,
    todayOpenAppsCount
  };
}

export default function index() {
  const [newData, setNewData] = useState<any>({});
  const [appoinment, setAppoinment] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [userCall, setUserCall] = useState<any>([]);
  const { width, height } = useWindowDimensions();
  const [isJoinUser, setIsJoinUser] = useState<boolean>(false);
  const [details, setDetails] = useState<any>(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Create a URLSearchParams object to parse query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the consultid parameter
  const consultId = queryParams.get('consultid');
  console.log('consultId', consultId);
 
  const {
    isLoading,
    GetAppointments,
    ChangeFilter,
    filters,
    setIsModalOpen,
    initialQueries,
    isDataEmpty,
    performSearch
  } = useHooks();

  const [title, setTitle] = useState<any>('allAppointments');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const { keys, customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.Appointments
  );
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  // useEffect(() => {
  //   if (currentDashboard === 'jeras') {
  //     userCallState();
  //   }
  // }, []);
  // async function userCallState() {
  //   try {
  //     const response = await axiosInstance.get('/user-call-state');
  //     setUserCall(response.data.result.data);
  //     // console.log('response.data.result.data==>', response.data.result.data);
  //   } catch (error) {
  //     console.error('Error fetching UserCall', error);
  //   }
  // }
  // const handleCallsClick = async (isJoin) => {
  //   if (!isJoin) {
  //     ChangeFilter('', 'clear'); // إعادة تعيين البيانات المبدئية
  //     return;
  //   }

  //   setLoading(true); // تعيين isLoading إلى true عند بدء التحميل

  //   try {
  //     const { data } = await axiosInstance.get('appointments');

  //     let matchedIds = data.result.data
  //       .filter(
  //         (item) =>
  //           (item?.callData?.reciverId === item?.user?.uid &&
  //             item?.callData?.roomId === item?.appointmentId &&
  //             item?.callData?.callState === 'oncall')
  //       )
  //       .map((item) => item?.appointmentId);

  //     if (matchedIds.length > 0) {
  //       ChangeFilter(
  //         [['appointmentId', 'in', matchedIds], ...initialQueries], // استخدم 'in' للبحث عن مجموعة من القيم
  //         'queries'
  //       );
  //     } else {
  //       ChangeFilter([['appointmentId', '==', '']], 'queries'); // إرسال بيانات فارغة إذا لم يتم العثور على تطابق
  //     }
  //   } catch (error) {
  //     console.error('Error fetching appointments:', error);
  //   } finally {
  //     setLoading(false); // تعيين isLoading إلى false بعد انتهاء التحميل
  //   }
  // };

  // const handleCallsClickWrapper = (isJoin) => {
  //   setIsJoinUser(isJoin);
  //   if (isJoin) {
  //     handleCallsClick(true); // استدعاء الدالة عند تعيين isJoinUser إلى true
  //   } else {
  //     ChangeFilter('', 'clear'); // إعادة تعيين البيانات المبدئية مباشرة
  //   }
  // };

  // console.log('isJoinUser', isJoinUser);

  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        '/appointments-forever/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );
      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }

  const allAppointmentsMessage = 'allAppointments';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = (option, title) => {
    setTitle(title);

    let filterQueries = [...initialQueries]; // Start with initial queries

    // Check if the option is 'All Appointments'
    if (option === 'All Appointments') {
      // If 'All Appointments', no date filtering is applied
      ChangeFilter(null, 'clear');
      return; // Exit the function early
    }

    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter(
        [
          ['createdDateValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
          ...initialQueries
        ],
        'queries'
      );
    }
  };

  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  console.log('data===>', data);

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axiosInstance.get('/dashboard/appointments-forever-Count');
      setNewData(res.data.result.data);
      setLoading(false);
console.log('res.data.result.data',res.data.result.data);

      console.log('data', res.data.result.data);
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }
  async function fetchAppConsults() {
    try {
      if (consultId) {
        ChangeFilter(
          [['appConsultId', '==', consultId], ...initialQueries], // استخدم 'in' للبحث عن مجموعة من القيم
          'queries'
        );
      } else if (count > 2000) {
        ChangeFilter(
          [['appConsultId', '==', '']], // استخدم 'in' للبحث عن مجموعة من القيم
          'queries'
        );
      } else {
        ChangeFilter(null, 'clear');
      }
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }
  useEffect(() => {
    fetchAppConsults();
  }, [consultId]);

  useEffect(() => {
    fetchData();
  }, [isLoading]);

  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );
  const {
    appointmentsOfMonth,
    isTodayAppsLoading,
    todayClosedAppsCount,
    todayOpenAppsCount,
    monthAppLoading,
    fetchMonthAppointments
  } = useHooks();

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  function formatDate(closedDate) {
    if (!closedDate || !closedDate.day || !closedDate.month || !closedDate.year) {
      return 'N/A'; // إذا كانت البيانات غير متاحة
    }
  
    const date = new Date(closedDate.year, closedDate.month - 1, closedDate.day);
    return `${getMonthName(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`;
  }
  
  function getMonthName(monthIndex) {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return monthNames[monthIndex];
  }
  // console.log('[...Object.keys(userCall)]', Object.keys(userCall));
  return (
    <div
      className=" space-y-6 bg-[#fafafa] min-h-screen overflow-x-hidden "
      style={{
        width: `${Responsive.w(1584, width)}px`,
        padding: `${Responsive.sp(3, width)}px`
      }}
    >
      <div
        className="flex flex-row justify-start items-start   mt-[24px] mr-0 mb-[32px]  p-0 "
        style={{
          width: `${Responsive.w(1560, width)}px`,
          height: `${Responsive.h(125, height)}px`,
          gap: `${Responsive.sp(24, width)}px`,
          marginLeft: `${Responsive.sp(24, width)}px`
        }}
      >
        <Card
          className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4 border-b-[#2980ba] rounded-[16px]"
          style={{
            width: `${Responsive.w(372, width)}px`,
            height: `${Responsive.h(122, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(163, width)}px`,
              height: `${Responsive.h(79, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalAppointments"
                defaultMessage="Total Appointments"
              />
            </p>
            <p
              className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(40, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {isLoading ? <Spinner /> : count}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#e0f2ff]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_1}
              srcSet={`${img_1_2}, ${img_1_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4 border-b-[#4ad991] rounded-[16px]"
          style={{
            width: `${Responsive.w(372, width)}px`,
            height: `${Responsive.h(122, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className=" flex-grow-0 gap-[10px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(163, width)}px`,
              height: `${Responsive.h(79, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(40, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="DoneAppointments"
                defaultMessage="Done Appointments"
              />
            </p>
            <p
              className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.doneAppAppointments
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#d9f7e8]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_4}
              srcSet={`${img_4_2}, ${img_4_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        {/* <Card
          className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4  rounded-[16px]"
          style={{
            width: `${Responsive.w(372, width)}px`,
            height: `${Responsive.h(122, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`,
            borderBottomColor: dashColor
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(163, width)}px`,
              height: `${Responsive.h(79, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="UpcomingAppointments"
                defaultMessage="Upcoming Appointments"
              />
            </p>
            <p
              className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(40, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.upcomingAppAppointments
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <Icon
              icon="lucide:clock-8"
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`,
                color: dashColor
              }}
            />
          </div>
        </Card>
*/}
        <Card
          className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4 border-b-[#f93c65] rounded-[16px]"
          style={{
            width: `${Responsive.w(372, width)}px`,
            height: `${Responsive.h(122, height)}px`,
            borderRadius: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(163, width)}px`,
              height: `${Responsive.h(79, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(40, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="CancelledAppointments"
                defaultMessage="Cancelled Appointments"
              />
            </p>
            <p
              className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {loading ? (
                <div className=" flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                newData?.cancelledAppAppointments
              )}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#ffebeb]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_5}
              srcSet={`${img_5_2}, ${img_5_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card> 
      </div>

      <Table
        loading={isLoading}
        isEmpty={!data.length}
        title={title}
        count={count}
        calls={false}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] z-50 ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All Appointments', allAppointmentsMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={allAppointmentsMessage}
                      defaultMessage={allAppointmentsMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] "
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="  grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard !== 'jeras'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : '!text-[#7b6c96] border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-center justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customize_keys.some((key) => key === field)}
                                onChange={ChangeCustomizeKey}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                {useIntl().formatMessage({
                                  id: field,
                                  defaultMessage: field
                                })}
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <FilterFields
                        onReset={() => ChangeFilter([...initialQueries], 'queries')}
                        onSubmit={(val: any) => ChangeFilter(val, 'queries')}
                        filters={filters}
                        dashColor={dashColor}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {data?.map((item: any, index: number) => (
              <tr
                key={index}
                onClick={(e) => {
                  const selection = window.getSelection();
                  if (selection && selection.toString()) {
                    // Prevent navigation if text is selected
                    e.stopPropagation();
                  } else {
                    const element = e.target as Element;
                    if (element.closest('.join-button')) {
                      // Prevent navigation if the clicked element is a join button
                      e.stopPropagation();
                    } else {
                      // Navigate if no text is selected and the target is not the Join button
                      if (currentDashboard === 'jeras') {
                        navigate(`/lessons/${item.appointmentId}/${item.orderId}`);
                      }
                    }
                  }
                }}
                style={{ backgroundColor: getRowColor(index) }}
                className="cursor-pointer"
              >
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {index + 1}
                </td>
                {isIncluded('appointmentId') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.appointmentId || 'N/A'}
                  </td>
                )}
                {isIncluded('consultantUid') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.uid || 'N/A'}
                  </td>
                )}

                {isIncluded('consultantPhoto') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <div>
                      <img
                        src={item?.consult?.image || '/images/placeholder.png'}
                        alt={item?.consult?.name}
                        className="w-8 h-8 rounded-full object-cover"
                        width={512}
                        height={512}
                      />
                    </div>
                  </td>
                )}
                {isIncluded('consultantName') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('consultNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.consult?.phone || 'N/A'}
                  </td>
                )}
                {isIncluded('userUid') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.uid || 'N/A'}
                  </td>
                )}
                {isIncluded('userPhoto') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <div>
                      <img
                        src={item?.user?.image || '/images/placeholder.png'}
                        alt={item?.user?.name}
                        className="w-8 h-8 rounded-full object-cover"
                        width={512}
                        height={512}
                      />
                    </div>
                  </td>
                )}

                {isIncluded('userName') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('userNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.phone || 'N/A'}
                  </td>
                )}
                {isIncluded('userCountry') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.user?.countryISOCode || 'N/A'}
                  </td>
                )}
                {(currentDashboard == 'dream' && isIncluded('closedDate')) && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.appointmentStatus === 'closed' ? (
                     formatDate(item?.closedDate)
                    ) : (
                      'N/A'
                    )}
                  </td>
                )}
                {isIncluded('closedDate') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <SharedTime
                      date={new Date(item?.utcTime)}
                      // format="MMMM Do YYYY, h:mm:ss a"
                    />
                  </td>
                )}
                {isIncluded('appointmentStatus') && (
                  <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch rounded-[6px]">
                    <p
                      className="flex items-center justify-center"
                      style={{
                        height: '30px',
                        border:
                          item?.appointmentStatus === 'closed'
                            ? 'solid 1px #e84a4d'
                            : item?.appointmentStatus === 'open'
                            ? 'solid 1px #28c794'
                            : item?.appointmentStatus === 'cancel'
                            ? `solid 1px ${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                            : '',
                        backgroundColor:
                          item?.appointmentStatus === 'closed'
                            ? '#ffe2e2'
                            : item?.appointmentStatus === 'open'
                            ? '#ebfeef'
                            : item?.appointmentStatus === 'cancel'
                            ? `${currentDashboard !== 'jeras' ? '#fff7fd' : '#e5e1ec'}`
                            : '',
                        color:
                          item?.appointmentStatus === 'closed'
                            ? ' #d91c1f'
                            : item?.appointmentStatus === 'open'
                            ? '#228176'
                            : item?.appointmentStatus === 'cancel'
                            ? `${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                            : ''
                      }}
                    >
                      {item?.appointmentStatus || 'N/A'}
                    </p>
                  </td>
                )}
                {isIncluded('appointmentPrice') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.appointmentPrice || item?.callPrice || 0) || 'N/A'}
                  </td>
                )}
                {isIncluded('isUtc') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.isUtc || 'N/A'}
                  </td>
                )}
                {isIncluded('type') && (
                  <td
                    className="text-center nowrap text-[14px] font-medium text-[#515151]
                self-stretch"
                  >
                    {' '}
                    {item?.type || 'N/A'}
                  </td>
                )}
                {/* {isIncluded('join') && currentDashboard === 'jeras' && (
                  <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch flex items-center justify-center">
                    <>
                      {(item?.callData?.reciverId === item?.user?.uid &&
                        item?.callData?.roomId === item?.appointmentId &&
                        item?.callData?.callState === 'oncall')  ? (
                        <button
                          className="join-button flex items-center justify-center gap-4 bg-[#28c794] w-[118px] px-1 py-2 rounded-[4px]"
                          onClick={() => handleJoinClick(item?.appointmentId)}
                        >
                          <p className="w-[32px] text-base text-left text-white">Join</p>
                          <Icon
                            className="text-white"
                            icon="mdi:video"
                            width={29.5}
                            height={29.5}
                          />
                        </button>
                      ) : (
                        <button className="join-button flex items-center justify-center gap-4 bg-[#afafaf] w-[118px] px-1 py-2 rounded-[4px]">
                          <p className="w-[32px] text-base text-left text-white">Join</p>
                          <Icon
                            className="text-white"
                            icon="mdi:video"
                            width={29.5}
                            height={29.5}
                          />
                        </button>
                      )}
                    </>
                  </td>
                )} */}
              </tr>
            ))}
          </>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: dashColor
              }}
            >
              #
            </th>
            {isIncluded('appointmentId') && (
              <th className="capitalize">
                <SortButton
                  filters={filters}
                  sortKey="appointmentId"
                  text={useIntl().formatMessage({
                    id: 'uid',
                    defaultMessage: 'ID'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantUid') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultantUid"
                  text={useIntl().formatMessage({
                    id: 'consultantUid',
                    defaultMessage: 'consultant id'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantPhoto') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consultantPhoto"
                  text={useIntl().formatMessage({
                    id: 'consultantPhoto',
                    defaultMessage: 'consultant photo'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('consultantName') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.name"
                  text={useIntl().formatMessage({
                    id: 'consultantName',
                    defaultMessage: 'consultant name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('consultNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="consult.phone"
                  text={useIntl().formatMessage({
                    id: 'consultantNumber',
                    defaultMessage: 'consultant number'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userUid') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userUid"
                  text={useIntl().formatMessage({
                    id: 'userUid',
                    defaultMessage: 'user id'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userPhoto') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userPhoto"
                  text={useIntl().formatMessage({
                    id: 'userPhoto',
                    defaultMessage: 'user photo'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userName') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.name"
                  text={useIntl().formatMessage({
                    id: 'userName',
                    defaultMessage: 'user name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('userNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userNumber"
                  text={useIntl().formatMessage({
                    id: 'userNumber',
                    defaultMessage: 'user number'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userCountry') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="user.countryISOCode"
                  text={useIntl().formatMessage({
                    id: 'userCountry',
                    defaultMessage: 'user country'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {(currentDashboard == 'dream' && isIncluded('closedDate')) && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="closedDate"
                  text={useIntl().formatMessage({
                    id: 'AppointmentClose',
                    defaultMessage: 'appointment close'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('closedDate') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="closedDate"
                  text={useIntl().formatMessage({
                    id: 'AppointmentClose',
                    defaultMessage: 'appointment close'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('appointmentStatus') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="appointmentStatus"
                  text={useIntl().formatMessage({
                    id: 'appointmentStatus',
                    defaultMessage: 'appointment status'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('appointmentPrice') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="appointmentPrice"
                  text={useIntl().formatMessage({
                    id: 'appointmentPrice',
                    defaultMessage: 'appointment price'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('isUtc') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="isUtc"
                  text={useIntl().formatMessage({
                    id: 'isUtc',
                    defaultMessage: 'is utc time'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('type') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="type"
                  text={useIntl().formatMessage({
                    id: 'type',
                    defaultMessage: 'type'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {/* {isIncluded('join') && currentDashboard === 'jeras' && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                join
              </th> )}*/}
          </>
        )}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        // onCallsClick={(isJoin) => {
        //   handleCallsClickWrapper(!isJoin);
        // }}
        buttonExport={() => ExportXlsx(data, customize_keys)}
        pagination={{ ...pagination, search_key: filters.search }}
        onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
        onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
        }}
        searchProps={{
          onKeyDown(e: any) {
            if (e.key === 'Enter' && e.target.value) {
              performSearch(e.target.value);
            }
          }
        }}
      />
    </div>
  );
}

function TableCustomizeFilterModal({ SendPrams, params, setIsModalOpen, initialQueries }: any) {
  const { keys, customize_keys, filters, data } = useSelector(
    (state: RootState) => state.Appointments
  );

  const handleOpenModal = () => {
    // ChangeFilter('clearSorts', 'sorts');
    // Step 1: Open the modal and set sorts to empty
    SendPrams(initialQueries);
    setIsModalOpen(true);
    setVisible(true);
  };
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const [visible, setVisible] = useState<boolean>(false);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  // async function ExportXlsx(currentData, currentKeys) {
  //   try {
  //     setExportDisabled(true);
  //     const { data } = await axiosInstance.post(
  //       'users/export',
  //       { currentData, currentKeys },
  //       { params, responseType: 'blob' }
  //     );
  //     const filename = new Date().getTime() + '.xlsx';
  //     const blob = new Blob([data]);
  //     const url = URL.createObjectURL(blob);

  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = filename;
  //     a.click();
  //     URL.revokeObjectURL(url);
  //     a.remove();

  //     // console.log(url)
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setExportDisabled(false);
  //   }
  // }
  console.log('params.q', params.queries);
  console.log('initial', initialQueries);
  return (
    <>
      <div>
        <div className="flex items-center flex-wrap gap-3 justify-between ">
          <RangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            // applyHandler={() =>
            //   SendPrams([
            //     ['createdDateValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
            //     ...initialQueries
            //   ])
            // }
          />
          <div className="flex items-center flex-wrap gap-3 ">
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                SendPrams(initialQueries);
                setStartDate(new Date());
                setEndDate(new Date());
              }}
              disabled={initialQueries.length == 0 && params.queries.length == 0}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            {/* <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => ExportXlsx(data, customize_keys)}
              disabled={exportDisabled}
            >
              <Icon
                icon="bi:filetype-xlsx"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'exportExcel',
                  defaultMessage: 'Export Excel'
                })}
              </span>
            </button> */}
            <button
              className="btn-with-icon bg-gray-800 text-white"
              onClick={handleOpenModal}
            >
              <Icon
                icon="majesticons:filter-line"
                width="18"
                height="18"
              />
              <span>
                {' '}
                {useIntl().formatMessage({
                  id: 'filterAndCustomizeTable',
                  defaultMessage: 'Filter & Customize table'
                })}
              </span>
            </button>
          </div>
        </div>
      </div>
      <SideModal
        title={useIntl().formatMessage({
          id: 'filterAndCustomizeTable',
          defaultMessage: 'Filter & Customize table'
        })}
        visible={visible}
        handleClose={() => {
          setVisible(false);
          setIsModalOpen(false);
        }}
        size="max-w-screen-sm"
      >
        <div className="space-y-4 flex flex-col overflow-y-auto">
          <div className="border-b border-b-gray-200 overflow-x-auto grid">
            <ul className="inline-flex">
              {tabs.map((tab: any, index: number) => (
                <li key={index}>
                  <button
                    className={[
                      'btn-with-icon !rounded-none font-medium bg-transparent transition-all',
                      currentTab === tab.key
                        ? '!text-gray-800 border-b-2 border-b-gray-800'
                        : '!text-gray-500'
                    ].join(' ')}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    <span>{tab.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 grid overflow-y-auto">
            {currentTab === 'customize' ? (
              <ul className="space-y-2">
                {keys.map((field: string, index: number) => (
                  <li key={index}>
                    <label
                      htmlFor={field + '-' + index}
                      className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-200  cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        name={field + '-' + index}
                        id={field + '-' + index}
                        className="form-checkbox shrink-0"
                        value={field}
                        checked={customize_keys.some((key) => key === field)}
                        onChange={ChangeCustomizeKey}
                      />

                      <p className="text-sm font-medium capitalize flex-1">
                        {useIntl().formatMessage({
                          id: field,
                          defaultMessage: field
                        })}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <FilterFields
                setIsModalOpen={setIsModalOpen}
                onSubmit={SendPrams}
                filters={filters}
                onReset={() => {
                  SendPrams([]);
                  setIsModalOpen(false);
                }}
                handleClose={setVisible}
              />
            )}
          </div>
        </div>
      </SideModal>
    </>
  );
}

function FilterFields({
  onSubmit,
  filters: values,
  handleClose,
  onReset,
  setIsModalOpen,
  dashColor
}: any) {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<any>(values.queries || []);
  const [selectedField, setSelectedField] = useState<Fields>();
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'price':
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;

          case 'appointmentStatus':
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;

          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'timeValue', '==', from + '-' + to);

            break;
          default:
            break;
        }
      } else {
        // If the field is not selected, remove it from the filters
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  function removeFilter(key: string) {
    // Create a copy of the filters without the filter for the given key
    return filters.filter(([k]: any) => k !== key);
  }

  const findQuery = useCallback(
    (key: string, operator: string, value: any) => {
      const queries: any[] = filters;
      let result: any[];
      const query = queries?.find(
        ([_key, _operator]: string[]) => _key === key && _operator === operator
      );

      if (query) {
        const filter = queries?.filter(([k]) => k !== key);
        result = new Array().concat(filter, [[key, operator, value]]);
      } else {
        result = new Array().concat(queries, [[key, operator, value]]);
      }

      return result;
    },
    [filters]
  );

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      return value;
    },
    [filters]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
    if (selectedFields.includes(field)) {
      // If the field is already selected, remove it from the array
      setSelectedFields(selectedFields.filter((selected) => selected !== field));
    } else {
      // If the field is not selected, add it to the array
      setSelectedFields([...selectedFields, field]);
    }
  }
  useEffect(() => {
    console.log('filters:', filters);
  }, [filters]);
  useEffect(() => {
    setSelectedFields(filters.map(([key]) => key));
  }, [filters]);

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();

        onSubmit(query);
      }}
    >
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="price"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('price')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="price-amount"
          >
            {useIntl().formatMessage({
              id: 'price',
              defaultMessage: 'price'
            })}
          </label>
          <input
            type="number"
            className="form-input"
            name="price-amount"
            id="price-amount"
            placeholder="0"
            min={0}
            onChange={ChangeFilters('price')}
            defaultValue={findValue('price')}
            disabled={!selectedFields.includes('price')}
            readOnly={!selectedFields.includes('price')}
          />
        </div>
      </div>

      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="appointmentStatus"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('appointmentStatus')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="order-status"
          >
            {useIntl().formatMessage({
              id: 'appointmentStatus',
              defaultMessage: 'Appointment status'
            })}
          </label>
          <select
            name="order-status"
            id="order-status"
            className="form-select"
            onChange={ChangeFilters('appointmentStatus')}
            defaultValue={findValue('appointmentStatus') || ''}
            disabled={!selectedFields.includes('appointmentStatus')}
          >
            <optgroup label="Status">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'status',
                  defaultMessage: 'status'
                })}
              </option>

              <option value="open">
                {useIntl().formatMessage({
                  id: 'open',
                  defaultMessage: 'open'
                })}
              </option>
              <option value="cancel">
                {useIntl().formatMessage({
                  id: 'cancel',
                  defaultMessage: 'cancel'
                })}
              </option>
              <option value="closed">
                {useIntl().formatMessage({
                  id: 'closed',
                  defaultMessage: 'closed'
                })}
              </option>
              <option value="completed">
                {useIntl().formatMessage({
                  id: 'completed',
                  defaultMessage: 'completed'
                })}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="date"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('date')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="date"
          >
            {useIntl().formatMessage({
              id: 'date',
              defaultMessage: 'date'
            })}
          </label>
          <select
            name="date"
            id="date"
            className="form-select"
            onChange={ChangeFilters('date')}
            defaultValue={findValue('date') || ''}
            disabled={!selectedFields.includes('date')}
          >
            <optgroup label="Periods">
              <option
                value=""
                disabled
              >
                {useIntl().formatMessage({
                  id: 'selectPeriod',
                  defaultMessage: 'select period'
                })}
              </option>

              {usePeriods().map((period: any, index: number) => (
                <option value={period.value}>{period.label}</option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>

      {/* <div className="flex gap-2 flex-wrap justify-end">
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={onReset}
        >
          <span>
            {useIntl().formatMessage({
              id: 'clear',
              defaultMessage: 'clear'
            })}
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-800"
          type="submit"
        >
          <span>
            {useIntl().formatMessage({
              id: 'apply',
              defaultMessage: 'apply'
            })}
          </span>
        </button>
      </div> */}
      <div className="flex   justify-between items-start">
        <button
          className="btn-with-icon  font-semibold nowrap "
          style={{
            backgroundColor: dashColor
          }}
          type="submit"
        >
          <span>
            <FormattedMessage
              id="applyfilter"
              defaultMessage={'Apply Filter'}
            />
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={onReset}
        >
          <span>
            {useIntl().formatMessage({
              id: 'clear',
              defaultMessage: 'clear'
            })}
          </span>
        </button>
      </div>
    </form>
  );
}
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
