import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from 'src/components/shared/Card';
import SmallLoader from 'src/components/shared/SmallLoader';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import img from '../images/filter-off-fill.png';
import img_1 from '../images/solar_check-read-linear TRUE.png';
import img_1_2 from '../images/solar_check-read-linear TRUE@2x.png';
import img_1_3 from '../images/solar_check-read-linear TRUE@3x.png';
import RangePicker from 'src/components/shared/RangePicker';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Index() {
  const { width, height } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState('');
  const [title, setTitle] = useState('');
  const [titleTime, setTitleTime] = useState('');
  const [timeOption, setTimeOption] = useState('filterByDate');
  const [jobs, setJobs] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTime, setIsOpenTime] = useState(false);
  const [start, setStart] = useState<any>();
  const [end, setEnd] = useState<any>();
  const [startTime, setStartDateTime] = useState(new Date());
  const [endTime, setEndDateTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState('');
  const [pagination, setPagination] = useState<any>([]);
  const [perPage, setPerPage] = useState(15);
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const direction = getDirectionClass();
  const locale = useSelector((state: RootState) => state.Locale.value);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const intl = useIntl();
  const navigate =  useNavigate();
  const toggleTab = (tab) => {
    setSelectedTab(tab);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleDropdownTime = () => setIsOpenTime(!isOpenTime);

  console.log('jobs', jobs);

  const allAdvertisementsMessage = useIntl().formatMessage({
    id: 'allAdvertisements',
    defaultMessage: 'All Advertisements'
  });

  const approvedAdvertisementsMessage = useIntl().formatMessage({
    id: 'approvedAdvertisement',
    defaultMessage: 'Approved Advertisements'
  });
  const notApprovedAdvertisementsMessage = useIntl().formatMessage({
    id: 'notApprovedAdvertisement',
    defaultMessage: 'Not Approved Advertisements'
  });

  const filterByDateMessage = 'filterByDate';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';
  const fetchJobsDate = async (dateOption, approved, page = 1) => {
    setIsLoading(true);
    try {
      let startDate;
      let endDate;
      console.log('dateOption===>>', dateOption);

      // Based on the selected date option, set startDate and endDate appropriately
      if (dateOption === 'Today') {
        startDate = new Date();
        endDate = new Date();
      } else if (dateOption === 'Last 7 days') {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
      } else if (dateOption === 'Last 30 days') {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 30);
      } else if (dateOption === 'Date range') {
        endDate = end;
        startDate = start;
      } else {
        endDate = undefined;
        startDate = undefined;
      }

      // Construct query parameters
      const params = new URLSearchParams();
      if (approved) params.append('approved', approved);
      if (startDate) params.append('startDate', startDate.toISOString());
      if (endDate) params.append('endDate', endDate.toISOString());
      params.append('page', page.toString());
      params.append('perPage', perPage.toString());

      const response = await axiosInstance.get(`/jobs?${params.toString()}`);

      setJobs(response.data.result.data);
      setPagination(response.data.result.pagination);
      setCount(response.data.result.count);
      console.log('response', response.data.result.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchJobsDate(titleTime, approved, currentPage);
  }, [titleTime, approved, currentPage]);

  const handleAllClick = () => {
    setCurrentPage(1);
    setSelectedTab('');
    setApproved('');
    fetchJobsDate(titleTime, '', 1);
  };

  const handleApprovedClick = () => {
    setCurrentPage(1);
    setSelectedTab('true');
    setApproved('true');
    fetchJobsDate(titleTime, 'true', 1);
  };
  const handleNotApprovedClick = () => {
    setCurrentPage(1);
    setSelectedTab('false');
    setApproved('false');
    fetchJobsDate(titleTime, 'false', 1);
  };

  const handleDateOptionClick = (dateOption, timeOption?) => {
    setCurrentPage(1);
    setTimeOption(timeOption);
    setTitleTime(dateOption);
    fetchJobsDate(dateOption, approved, 1);
  };
  useEffect(() => {
    setTitle(allAdvertisementsMessage);
  }, [locale]);

  const handleAccept = async (id) => {
    setIsLoading(true);
    const confirmationMsg = intl.formatMessage({
      id: 'acceptJob',
      defaultMessage: 'هل أنت موافق علي  قبول هذا الاعلان؟'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        const response = await axiosInstance.patch(`/job/${id}`, { approved: true });
        console.log('Response:', response.data);
        setJobs((prevSuggestions: any) =>
          prevSuggestions.map((job) => (job.jobId === id ? { ...job, approved: true } : job))
        );
        const successMsg = intl.formatMessage({
          id: 'accepted',
          defaultMessage: 'Job has been successfully accepted.'
        });
        toast.success(successMsg);
      } catch (error) {
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });

    setIsLoading(false);
  };
  const handleReject = async (id) => {
    setIsLoading(true);
    const confirmationMsg = intl.formatMessage({
      id: 'acceptJob',
      defaultMessage: 'هل أنت موافق علي  حذف هذا الاعلان؟'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        const response = await axiosInstance.delete(`/job/${id}`);
        console.log('Response:', response.data);
        setJobs((prevData) => prevData.filter((job: any) => job.jobId !== id));
        const successMsg = intl.formatMessage({
          id: 'accepted',
          defaultMessage: 'Job has been successfully Rejected.'
        });
        toast.success(successMsg);
      } catch (error) {
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });

    setIsLoading(false);
  };
  const showConfirmation = useConfirmationDialog();
  const handleApplyButtonClick = () => {
    setStart(startTime);
    setEnd(endTime);
  };
  useEffect(() => {
    if (selectedTab === 'true' || selectedTab === '' || selectedTab === 'false') {
      const seenMap = {
        '': allAdvertisementsMessage,
        true: approvedAdvertisementsMessage,
        false: notApprovedAdvertisementsMessage
      };
      setTitle(seenMap[selectedTab]);
    }
  }, [selectedTab, title]);

  useEffect(() => {
    if (timeOption === filterByDateMessage) {
      setStart(null);
      setEnd(null);
    } else if (start && end) {
      setCurrentPage(1);
      fetchJobsDate('Date range', approved, 1);
    }
  }, [start, end, timeOption]);

  const handleNextPage = () => {
    if (pagination.nextPage) {
      setCurrentPage(pagination.nextPage);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };
  if (title === '') {
  }
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const truncateDesc = (desc) => {
    if (!desc) return ''; // تحقق من أن النص موجود
    const maxLength = 200;
    if (desc.length <= maxLength) return desc;
    const lastSpaceIndex = desc.lastIndexOf(' ', maxLength);
    return desc.slice(0, lastSpaceIndex) + '...';
  };

  return (
    <div
      className="min-h-screen flex flex-col justify-start p-[8px] bg-[#fafafa] overflow-x-auto"
      style={{
        width: `${Responsive.w(1584, width)}px`
        // height: `${Responsive.h(24, height)}px`
      }}
    >
      <div
        className="flex justify-between items-center "
        style={{
          width: `${Responsive.w(1560, width)}px`
          // height: `${Responsive.h(24, height)}px`
        }}
      >
        {/* allSuggestionsMessage */}
        <div
          className=" border-solid border-[1px] border-[#d9d9d9]  z-10 bg-white  ml-[20px] flex items-center justify-between rounded-md w-[266px] h-[56px] cursor-pointer wrap py-[10px] px-[24px]"
          style={{
            color: dashColor
          }}
          onClick={toggleDropdown}
        >
          <p>{title}</p>
          {isOpen ? (
            <div className="up transition ease-in-out delay-150">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="transition ease-in-out delay-150"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          ) : (
            <div className="down transition ease-in-out delay-150">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="transition ease-in-out delay-150"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          )}
          {isOpen && (
            <div
              id="dropdown"
              className="z-8 mt-8 bg-white text-left  divide-y divide-gray-100 rounded-lg shadow w-[266px]   "
              style={{
                position: 'absolute',
                marginLeft: '-24px',
                marginTop: '230px',
                boxShadow: ' 1px 1px 20.2px 0 rgba(0, 0, 0, 0.07)'
              }}
            >
              <ul
                className="py-2 text-sm text-[#202224]"
                aria-labelledby="dropdownDefaultButton"
              >
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 mb-3 font-medium "
                    onClick={handleAllClick}
                  >
                    {allAdvertisementsMessage}
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={handleApprovedClick}
                  >
                    {approvedAdvertisementsMessage}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 font-medium mb-3 border-solid "
                    onClick={handleNotApprovedClick}
                  >
                    {notApprovedAdvertisementsMessage}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* applyFilter */}
        {timeOption === dateRangeMessage ? (
          <div
            className="inline-flex relative items-center cursor-pointer gap-3 flex-grow-0 flex-auto py-[5px] px-[16px] rounded-[8px] justify-between h-[56px] bg-[#fff] border-solid border-[1px] border-[#d9d9d9] duration-750 ease-in-out"
            style={{ width: `${Responsive.w(572, width)}px` }}
          >
            <p className="shrink-0 text-[14px] font-medium font-[Montserrat] text-[#262626] flex items-center justify-start gap-[16px]">
              <Icon
                icon={'ri:filter-off-fill'}
                style={{
                  width: `${Responsive.w(24, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  color: dashColor
                }}
                onClick={toggleDropdownTime}
              />
              <span>
                <div className="flex justify-center items-center">
                  <div
                    className="flex items-center justify-start p-0"
                    onClick={toggleDropdownTime}
                    style={{
                      width: `${Responsive.w(80, width)}px`,
                      fontSize: `${Responsive.sp(14, width)}px`,
                      borderRadius: `${Responsive.sp(3, width)}px`,
                      gap: `${Responsive.sp(4, width)}px`
                    }}
                  >
                    <span className="text-[#262626]">
                      <Icon
                        icon="material-symbols-light:date-range-outline"
                        className="w-[20px] h-[20px]"
                        style={{
                          width: `${Responsive.w(20, width)}px`,
                          height: `${Responsive.h(20, height)}px`,
                          color: dashColor
                        }}
                      />
                    </span>
                    <span
                      className="wrap"
                      style={{
                        fontSize: `${Responsive.sp(14, width)}px`,
                        borderRadius: `${Responsive.sp(3, width)}px`,
                        gap: `${Responsive.sp(10, width)}px`,
                        color: '#262626'
                      }}
                    >
                      {
                        <FormattedMessage
                          id={dateRangeMessage}
                          defaultMessage={dateRangeMessage}
                        />
                      }
                    </span>
                  </div>
                  <RangePicker
                    startDate={startTime}
                    setStartDate={setStartDateTime}
                    endDate={endTime}
                    setEndDate={setEndDateTime}
                  />
                  <div className="flex  items-center justify-center mr-[-10]">
                    <button
                      onClick={() => {
                        handleApplyButtonClick();
                      }}
                      style={{
                        width: `${Responsive.w(72, width)}px`,
                        // height: `${Responsive.h(28, height)}px`,
                        backgroundColor: dashColor,
                        borderRadius: `${Responsive.sp(4, width)}px`,
                        fontSize: `${Responsive.sp(12, width)}px`
                      }}
                      className="   text-white font-semibold "
                    >
                      <span
                        className="nowrap"
                        style={{
                          borderRadius: `${Responsive.sp(4, width)}px`,
                          fontSize: `${Responsive.sp(12, width)}px`
                        }}
                      >
                        <FormattedMessage
                          id="applyFilter"
                          defaultMessage="Apply filter"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </span>
            </p>
            <div className="flex items-center justify-center">
              {isOpenTime ? (
                <div className="up transition ease-in-out delay-150">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition ease-in-out delay-150 "
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              ) : (
                <div className="down transition ease-in-out delay-150">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition ease-in-out delay-150 "
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              )}
              {isOpenTime && (
                <div
                  id="dropdown"
                  className="z-10 mt-8 bg-white text-left  divide-y divide-gray-100 rounded-lg shadow w-[266px]   "
                  style={{
                    position: 'absolute',
                    marginTop: '320px',
                    right: '-2px',
                    boxShadow: ' 1px 1px 20.2px 0 rgba(0, 0, 0, 0.07)',
                    width: `${Responsive.w(572, width)}px`
                  }}
                >
                  <ul
                    className="py-2 text-sm text-[#202224]"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 mb-3 font-medium "
                        onClick={() => {
                          handleDateOptionClick('', filterByDateMessage);
                        }}
                      >
                        {useIntl().formatMessage({
                          id: filterByDateMessage,
                          defaultMessage: filterByDateMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 mb-3 font-medium "
                        // onClick={() => handleDateOptionClick('Today', todayMessage)}
                      >
                        {useIntl().formatMessage({
                          id: todayMessage,
                          defaultMessage: todayMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 mb-3 font-medium border-solid "
                        onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                      >
                        {useIntl().formatMessage({
                          id: last7DaysMessage,
                          defaultMessage: last7DaysMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 font-medium mb-3 border-solid "
                        onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                      >
                        {useIntl().formatMessage({
                          id: last30DaysMessage,
                          defaultMessage: last30DaysMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 font-medium mb-3 border-solid "
                        onClick={() => handleDateOptionClick('Date range', dateRangeMessage)}
                      >
                        {useIntl().formatMessage({
                          id: dateRangeMessage,
                          defaultMessage: dateRangeMessage
                        })}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className="inline-flex relative items-center cursor-pointer gap-3 flex-grow-0 flex-auto py-[5px] px-[16px] rounded-[8px] justify-between h-[56px] bg-[#fff] border-solid border-[1px] border-[#d9d9d9] duration-750 ease-in-out"
            style={{ width: `${Responsive.w(572, width)}px` }}
            onClick={toggleDropdownTime}
          >
            <p className="shrink-0 text-[14px] font-medium font-[Montserrat] text-[#262626] flex items-center justify-start gap-[16px]">
              <Icon
                icon={'ri:filter-off-fill'}
                className="w-[24px] h-[24px]"
                style={{
                  color: dashColor
                }}
              />
              <span>
                {useIntl().formatMessage({
                  id: timeOption,
                  defaultMessage: timeOption
                })}
              </span>
            </p>
            <div className="flex items-center justify-center">
              {isOpenTime ? (
                <div className="up transition ease-in-out delay-150">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition ease-in-out delay-150 "
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              ) : (
                <div className="down transition ease-in-out delay-150">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition ease-in-out delay-150 "
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              )}
              {isOpenTime && (
                <div
                  id="dropdown"
                  className="z-10 mt-8 bg-white text-left  divide-y divide-gray-100 rounded-lg shadow w-[266px]   "
                  style={{
                    position: 'absolute',
                    marginTop: '320px',
                    right: '-2px',
                    boxShadow: ' 1px 1px 20.2px 0 rgba(0, 0, 0, 0.07)',
                    width: `${Responsive.w(572, width)}px`
                  }}
                >
                  <ul
                    className="py-2 text-sm text-[#202224]"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 mb-3 font-medium "
                        onClick={() => {
                          handleDateOptionClick('', filterByDateMessage);
                        }}
                      >
                        {useIntl().formatMessage({
                          id: filterByDateMessage,
                          defaultMessage: filterByDateMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 mb-3 font-medium "
                        onClick={() => handleDateOptionClick('Today', todayMessage)}
                      >
                        {useIntl().formatMessage({
                          id: todayMessage,
                          defaultMessage: todayMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 mb-3 font-medium border-solid "
                        onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                      >
                        {useIntl().formatMessage({
                          id: last7DaysMessage,
                          defaultMessage: last7DaysMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 font-medium mb-3 border-solid "
                        onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                      >
                        {useIntl().formatMessage({
                          id: last30DaysMessage,
                          defaultMessage: last30DaysMessage
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 font-medium mb-3 border-solid "
                        onClick={() => handleDateOptionClick('Date range', dateRangeMessage)}
                      >
                        {useIntl().formatMessage({
                          id: dateRangeMessage,
                          defaultMessage: dateRangeMessage
                        })}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className="grid grid-cols-3 gap-[26px] p-[16px] "
        style={{
          width: `${Responsive.w(1560, width)}px`
          // height: `${Responsive.h(24, height)}px`
        }}
      >
        {isLoading ? (
          <div
            className="flex justify-center"
            style={{
              width: `${Responsive.w(1560, width)}px`
              // height: `${Responsive.h(24, height)}px`
            }}
          >
            <Spinner />
          </div>
        ) : (
          jobs.map((job: any, index: number) =>
            isLoading ? (
              <Spinner key={index} />
            ) : (
              <Card
                className="flex flex-col justify-start bg-white cursor-pointer"
                style={{
                  width: `${Responsive.w(504, width)}px`,
                  gap: `${Responsive.sp(16, width)}px`,
                  borderRadius: `${Responsive.sp(20, width)}px`,
                  padding: `${Responsive.sp(16, width)}px`
                  //  maxHeight: `${Responsive.h(383, height)}px`
                }}
             
              >
               <div
               className="flex flex-col justify-start bg-white cursor-pointer"
               style={{
                 width: `${Responsive.w(504, width)}px`,
                 gap: `${Responsive.sp(16, width)}px`,
                 borderRadius: `${Responsive.sp(20, width)}px`,
                 padding: `${Responsive.sp(16, width)}px`
                 //  maxHeight: `${Responsive.h(383, height)}px`
               }}
                  onClick={() => {
                
                    navigate(`/consultants`,{ state: job?.cosultIds  });
                  
                }}>
                 {/* userName */}
                 <p
                  className={`flex  font-[Montserrat]  text-left flex-grow-0 nowrap`}
                  style={{
                    width: `${Responsive.w(238, width)}px`,

                    fontSize: `${Responsive.sp(20, width)}px`,
                    color: dashColor
                  }}
                >
                  <span className="">
                    {useIntl().formatMessage({
                      id: 'userName:',
                      defaultMessage: 'User Name : '
                    })}
                  </span>
                  <span className="text-[#1e1e1e]">{job?.owner?.name}</span>
                </p>
                {/* contact */}
                <p
                  className={`flex  font-[Montserrat]  text-left flex-grow-0 nowrap`}
                  style={{
                    width: `${Responsive.w(238, width)}px`,

                    fontSize: `${Responsive.sp(20, width)}px`,
                    color: dashColor
                  }}
                >
                  <span className="">
                    {useIntl().formatMessage({
                      id: 'contact :',
                      defaultMessage: 'Contact : '
                    })}
                  </span>
                  <span className="text-[#1e1e1e]">{job?.owner?.phone}</span>
                </p>
                {/* title */}
                <p
                  className={`flex  font-[Montserrat]  text-left flex-grow-0`}
                  style={{
                    width: `${Responsive.w(472, width)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`,
                    color: dashColor
                  }}
                >
                  <span className="">
                    {useIntl().formatMessage({
                      id: 'title:',
                      defaultMessage: 'Title:'
                    })}
                  </span>
                  <span className="text-[#1e1e1e]">{truncateDesc(job?.title)}</span>
                </p>
                {/* description */}
                <p
                  className={`flex  font-[Montserrat]  text-left flex-grow-0`}
                  style={{
                    width: `${Responsive.w(472, width)}px`,
                    fontSize: `${Responsive.sp(20, width)}px`,
                    color: dashColor
                  }}
                >
                  <span className="">
                    {useIntl().formatMessage({
                      id: 'description:',
                      defaultMessage: 'Description:'
                    })}
                  </span>
                  <span className="text-[#1e1e1e]">{truncateDesc(job?.desc)}</span>
                </p>
                {/*Specialties :  */}
                <p
                  className={`flex  font-[Montserrat]  text-left flex-grow-0 nowrap`}
                  style={{
                    width: `${Responsive.w(238, width)}px`,

                    fontSize: `${Responsive.sp(20, width)}px`,
                    color: dashColor
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'specialties:',
                    defaultMessage: 'Specialties : '
                  })}
                </p>
                <div
                  className="flex flex-wrap  items-start justify-start p-0 flex-row"
                  style={{
                    width: `${Responsive.w(472, width)}px`,
                    gap: `${Responsive.sp(11, width)}px`,

                    color: dashColor
                  }}
                >
                  {job?.interests?.map((interest: any, index: number) => (
                    <p
                      className={`flex items-center justify-center bg-[#e5dff0] flex-grow-0 `}
                      style={{
                        // width: `${Responsive.w(102, width)}px`,
                        fontSize: `${Responsive.sp(16, width)}px`,
                        borderRadius: `${Responsive.sp(25, width)}px`,
                        paddingTop: `${Responsive.sp(2, width)}px`,
                        paddingBottom: `${Responsive.sp(2, width)}px`,
                        paddingRight: `${Responsive.sp(8, width)}px`,
                        paddingLeft: `${Responsive.sp(8, width)}px`,
                        color: dashColor
                      }}
                    >
                      <span
                        className={`flex items-center justify-center font-[Montserrat]  text-left flex-grow-0 nowrap`}
                        style={{
                          // width: `${Responsive.w(86, width)}px`,
                          fontSize: `${Responsive.sp(16, width)}px`,
                          color: dashColor
                        }}
                      >
                        {interest.enName}
                      </span>
                    </p>
                  ))}
                </div>
                {/* Space to push buttons to the bottom */}
                <div style={{ flexGrow: 1 }}></div>
               </div>
                {/* button */}
                <div
                  className="flex items-center justify-end p-0 flex-row"
                  style={{
                    width: `${Responsive.w(472, width)}px`,
                    height: `${Responsive.h(72, height)}px`,
                    gap: `${Responsive.sp(24, width)}px`,
                    color: dashColor,
                    marginTop: 'auto'
                  }}
                >
                  {job.approved === true ? (
                    <>
                      <button
                        className="flex items-center justify-center text-[#28c794]  w-full "
                        style={{
                          height: `${Responsive.h(48, height)}px`,
                          fontSize: `${Responsive.sp(16, width)}px`,
                          gap: `${Responsive.sp(8, width)}px`,
                          borderRadius: `${Responsive.sp(8, width)}px`,
                          paddingTop: `${Responsive.sp(5, width)}px`,
                          paddingBottom: `${Responsive.sp(5, width)}px`,
                          paddingRight: `${Responsive.sp(16, width)}px`,
                          paddingLeft: `${Responsive.sp(16, width)}px`
                        }}
                      >
                        <Icon
                          icon={'weui:done2-outlined'}
                          style={{
                            width: `${Responsive.w(24, width)}px`,
                            height: `${Responsive.h(24, height)}px`,
                            objectFit: 'contain',
                            color: '#28c794'
                          }}
                        />
                        <p
                          className="text-center"
                          style={{
                            fontSize: `${Responsive.sp(20, width)}px`,
                            lineHeight: `${Responsive.sp(24, width)}px`,
                            color: '#28c794'
                          }}
                        >
                          Accept
                        </p>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="flex items-center justify-center text-[#28c794] bg-[#d9f7e8] w-1/2 hover:bg-[#52d7ad93] transition-all duration-200 ease-in-out"
                        style={{
                          height: `${Responsive.h(48, height)}px`,
                          fontSize: `${Responsive.sp(16, width)}px`,
                          gap: `${Responsive.sp(8, width)}px`,
                          borderRadius: `${Responsive.sp(8, width)}px`,
                          paddingTop: `${Responsive.sp(5, width)}px`,
                          paddingBottom: `${Responsive.sp(5, width)}px`,
                          paddingRight: `${Responsive.sp(16, width)}px`,
                          paddingLeft: `${Responsive.sp(16, width)}px`
                        }}
                        onClick={() => handleAccept(job.jobId)}
                      >
                        <Icon
                          icon={'weui:done2-outlined'}
                          style={{
                            width: `${Responsive.w(16, width)}px`,
                            height: `${Responsive.h(16, height)}px`,
                            objectFit: 'contain',
                            color: '#28c794'
                          }}
                        />
                        <p
                          className="text-center"
                          style={{
                            fontSize: `${Responsive.sp(16, width)}px`,
                            lineHeight: `${Responsive.sp(24, width)}px`,
                            color: '#28c794'
                          }}
                        >
                          Accept
                        </p>
                      </button>
                      <button
                        className="flex items-center justify-center text-[#f93c65] bg-[#ffdbe3] w-1/2 hover:bg-[#dcafba] transition-all duration-200 ease-in-out"
                        style={{
                          height: `${Responsive.h(48, height)}px`,
                          fontSize: `${Responsive.sp(16, width)}px`,
                          gap: `${Responsive.sp(8, width)}px`,
                          borderRadius: `${Responsive.sp(8, width)}px`,
                          paddingTop: `${Responsive.sp(5, width)}px`,
                          paddingBottom: `${Responsive.sp(5, width)}px`,
                          paddingRight: `${Responsive.sp(16, width)}px`,
                          paddingLeft: `${Responsive.sp(16, width)}px`
                        }}
                        onClick={() => handleReject(job.jobId)}
                      >
                        <Icon
                          icon={'carbon:close-outline'}
                          style={{
                            width: `${Responsive.w(16, width)}px`,
                            height: `${Responsive.h(16, height)}px`,
                            objectFit: 'contain',
                            color: '#f93c65'
                          }}
                        />
                        <p
                          className="text-center"
                          style={{
                            fontSize: `${Responsive.sp(16, width)}px`,
                            lineHeight: `${Responsive.sp(24, width)}px`,
                            color: '#f93c65'
                          }}
                        >
                          Reject
                        </p>
                      </button>
                    </>
                  )}
                </div>
              </Card>
            )
          )
        )}
      </div>
      <div className="inline-flex flex-wrap gap-6 items-center justify-center mx-auto">
        <button
          className="btn-with-icon w-[116px] h-[40px] py-[4px] px-[8px] rounded-[4px] shrink-0 "
          disabled={pagination?.currentPage === 1 || !pagination?.currentPage}
          onClick={handlePrevPage}
          style={{
            backgroundColor: dashColor
          }}
        >
          <span>
            <FormattedMessage
              id="previous"
              defaultMessage={'Previous'}
            />
          </span>
        </button>

        <button className="btn-with-icon outline-btn shrink-0">
          <span>{pagination?.currentPage}</span>
        </button>

        <button
          className="btn-with-icon w-[116px] h-[40px] py-[4px] px-[8px] rounded-[4px] shrink-0 "
          style={{
            backgroundColor: dashColor
          }}
          disabled={!pagination?.nextPage}
          onClick={handleNextPage}
        >
          <span>
            <FormattedMessage
              id="next"
              defaultMessage={'Next'}
            />
          </span>
        </button>
      </div>
    </div>
  );
}

const formatDate = (timestampObj) => {
  const date = new Date(timestampObj._seconds * 1000);
  const day = date.getDate();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  return `${day} ${month} ${year}, ${hours}:${minutesStr} ${ampm}`;
};

export default Index;
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
