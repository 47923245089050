import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';

import { store } from 'src/store';
// store.subscribe(listener);

function select(state) {
  return state.Dashboard.axiosBaseUrl;
}
//  let baseUrl = 'http://localhost:5000/';
 let baseUrl = select(store.getState());
 function listener() {
   baseUrl = select(store.getState());
 }

// const { BASE_URL } = {
//   BASE_URL: 'http://localhost:5000/'
//   // BASE_URL: 'https://apis-qwta.onrender.com/api/'
//   // BASE_URL: 'https://api.dream-app.net/'
//   // BASE_URL: 'https://api.jeras.io/'
// };
const axiosInstance = axios.create({
  baseURL: baseUrl
});
axiosInstance.interceptors.request.use(function (
  request: InternalAxiosRequestConfig<AxiosRequestConfig>
) {
  const token = sessionStorage.getItem('@token');

  if (token) request.headers.Authorization = 'Bearer ' + token;
  request.headers['Access-Control-Allow-Origin'] = '*';
  request.headers['Access-Control-Allow-Headers'] = '*';
  request.headers['Access-Control-Allow-Credentials'] = true;
  request.headers['lang'] = 'en';
  return request;
});

axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);

export const updateAxiosBaseUrl = (newBaseUrl) => {
  axiosInstance.defaults.baseURL = newBaseUrl;
  console.log('aa0', axiosInstance.defaults.baseURL);
};

export default axiosInstance;
