// src/context/FormContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface FormData {
  active: boolean;
  age: string;
  desc: string;
  courseId: string;
  lang: string;
  videoCount: number;
  name: string;
  notes: string;
  order: number;
  rate: number;
  price:string;
  title:string;
  sections: string;
  summary: string;
  image: string;
  interestListIds: any[];
}

interface FormContextProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const defaultFormData: FormData = {
  active: false,
  age: '',
  desc: '',
  courseId: '',
  lang: '',
  videoCount: 0,
  name: '',
  notes: '',
  order: 0,
  rate: 0,
  sections: '',
  price:'',
  title:'',
  summary: '',
  image: '',
  interestListIds: [],
};

export const FormContext = createContext<FormContextProps>({
  formData: defaultFormData,
  setFormData: () => {},
});

export const FormProvider = ({ children }: { children: ReactNode }) => {
  const [formData, setFormData] = useState(defaultFormData);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
