import React from 'react';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';

function ToggleSlider({ onClick, checked }) {
  const { width , height} = useWindowDimensions();
  return (
    <div
      className="relative inline-flex w-11 h-6 cursor-pointer"
      onClick={onClick}
      style={{
        width: `${Responsive.w(63, width)}px`,
       
        // fontSize: `${Responsive.sp(20, width)}px`
      }}
    >
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        readOnly
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#5ade5c]"></div>
    </div>
  );
}

export default ToggleSlider;
