import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import Modal from 'src/components/shared/Modal';
import { toast } from 'react-toastify';
import useFetch from 'src/hooks/useFetch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactStars from 'react-rating-stars-component';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import DownloadInvoicePDF from 'src/components/InvoicePDF/PDFGenerator';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import InvoicePdfDoc from 'src/components/shared/InvoicePdfDoc';
import { FormattedMessage, useIntl } from 'react-intl';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import vector from '../images/Vector+.png';
import { set } from 'firebase/database';
import Table from 'src/components/shared/tables';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/import.png';
import img_2_2 from '../images/import@2x.png';
import img_2_3 from '../images/import@3x.png';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

function WomenDetailsPage() {
  const { id } = useParams();
  const [currentConsult, setCurrentConsult] = useState<any | null>(null);
  const [consultPackagesData, setConsultPackagesData] = useState<any | null>([]);
  const [loading, setLoading] = useState(true);
  const [isPaymentInfoModalOpen, setIsPaymentInfoModalOpen] = useState(false);
  const [isReviewsModalOpen, setIsReviewsModalOpen] = useState(false);
  const [isPaymentHistoryModalOpen, setIsPaymentHistoryModalOpen] = useState(false);
  const [consultId, setConsultId] = useState(null);
  const { width, height } = useWindowDimensions();
  const [auth, setAuth] = useState<any>(null);
  const [title, setTitle] = useState<any | null>('Personal');
  const [activeItem, setActiveItem] = useState('Personal');
  console.log('id', id);

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const {
    data: paymentInfoData,
    isDataLoading: isPaymentDataLoading,
    error: loadingPaymentError
  } = useFetch(`/users/${id}`, id ? true : false);

  const openPaymentInfoModal = () => {
    setConsultId(currentConsult?.uid);
    setIsPaymentInfoModalOpen(true);
  };

  const {
    data: reviewsData,
    isDataLoading: isReviewsDataLoading,
    error: loadingReviewsError
  } = useFetch(`/consultReviews/${id}`, id ? true : false);

  const openReviewsModal = () => {
    setConsultId(currentConsult?.uid);
    setIsReviewsModalOpen(true);
  };

  const {
    data: paymentHistoryData,
    isDataLoading: isPaymentHistoryDataLoading,
    error: loadingPaymentHistoryError
  } = useFetch(`/paymentHistory/${id}`, id ? true : false);

  const openPaymentHistoryModal = () => {
    setConsultId(currentConsult?.uid);
    setIsPaymentHistoryModalOpen(true);
  };

  const handleClick = (title: string) => {
    setActiveItem(title);
    setTitle(title);
  };

  const fetchData = async () => {
    try {
      const consultRes = await axiosInstance.get(`/users/${id}`);
      const consultData = consultRes.data?.result?.data;
      setCurrentConsult(consultData);
      console.log('consult', consultData);
      setAuth(consultRes.data.result?.providers);
      const packagesRes = await axiosInstance.get(`/allConsultPackages/${id}`);
      const packagesData = packagesRes.data?.result?.data;
      setConsultPackagesData(packagesData);
      // console.log('packages', packagesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  const formatDay = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  console.log('currentConsult', currentDashboard);
  return (
    <div
      className="p-[8px]  bg-[#fafafa] min-h-screen justify-between flex-col flex gap-7 overflow-x-hidden"
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div
        className="flex mt-[30px] h-auto items-start justify-start  rounded-[16px] p-[16px] bg-white"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          gap: `${Responsive.sp(16, width)}px`
        }}
      >
        {/* img */}
        <div
          className="flex h-auto rounded-[16px] flex-grow items-center justify-center self-stretch"
          style={{
            width: `${Responsive.w(371, width)}px`,
          
          }}
        >
          <img
            className="rounded-[16px] h-auto flex flex-grow items-center justify-center self-stretch"
            style={{
              width: `${Responsive.w(371, width)}px`,
            objectFit: 'cover',
            }}
            src={currentConsult?.photoUrl || '/images/placeholder.png'}
            alt=""
          />
        </div>

        <div
          className="flex flex-col items-start justify-start  p-0 self-stretch"
          style={{
            width: `${Responsive.w(1137, width)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className="flex items-start justify-start  p-0 self-stretch"
            style={{
              width: `${Responsive.w(1137, width)}px`,
              gap: `${Responsive.sp(16, width)}px`
            }}
          >
            {/*Detils */}
            <div
              className="flex flex-col items-start justify-start   self-stretch flex-grow-0  border-solid border-[#afafaf] border-[1px] bg-white"
              style={{
                width: `${Responsive.w(417.2, width)}px`,
                padding: `${Responsive.sp(16, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`
              }}
            >
              <h1
                className="font-[Montserrat] font-semibold text-left text-[#1e1e1e]"
                style={{
                  width: `${Responsive.w(391, width)}px`,

                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {currentConsult?.name}
              </h1>
              <p
                className="font-medium text-left font-[Montserrat] flex-grow-0 text-[#cf0036]  "
                style={{
                  width: `${Responsive.w(124, width)}px`,
                  height: `${Responsive.h(20, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {currentConsult?.uid}
              </p>
              <div
                className="flex align-start justify-between gap-[16px] flex-grow-0 mt-[8px] "
                style={{
                  width: `${Responsive.w(385.5, width)}px`,
                  height: `${Responsive.h(25, height)}px`
                  // fontSize: `${Responsive.sp( 16, width)}px`,
                }}
              >
                <div className="flex items-center space-x-1">
                  <p>{currentConsult?.ordersNumbers}</p>
                  <Icon
                    className="shrink-0 text-[#656565]"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`,
                      color: dashColor
                    }}
                    icon="majesticons:phone-incoming"
                  />
                </div>
                <div className="flex items-center space-x-1">
                  <p>{currentConsult?.rating}</p>
                  <Icon
                    className="shrink-0 text-amber-300"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                    icon="material-symbols:star"
                  />
                </div>
                <div className="flex items-center space-x-1">
                  <p>
                    {currentConsult?.price}{' '}
                    <span
                      style={{
                        color: dashColor
                      }}
                    >
                      $
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* Times of work */}
            <div
              className="flex flex-col items-start justify-start  self-stretch flex-grow-0  border-solid border-[#afafaf] border bg-white"
              style={{
                width: `${Responsive.w(417.2, width)}px`,
                padding: `${Responsive.sp(16, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`
              }}
            >
              <h1
                className="font-[Montserrat] font-semibold nowrap text-left text-[#1e1e1e]"
                style={{
                  width: `${Responsive.w(171, width)}px`,
                  height: `${Responsive.h(25, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'timesOfWork',
                  defaultMessage: 'Times of work'
                })}
              </h1>
              <div className=" m-auto  flex-col items-center justify-center">
                <div
                  className="flex my-2 items-center justify-start  gap-[8px] p-0  flex-grow "
                  style={{
                    width: `${Responsive.w(391, width)}px`,
                    height: `${Responsive.h(54, height)}px`,
                    fontSize: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <Icon
                    className="shrink-0 text-[#656565]"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                    icon="uim:calender"
                  />
                  <p>
                    {currentConsult?.workDays
                      ? getWorkdaysString(currentConsult?.workDays)
                      : useIntl().formatMessage({
                          id: 'noWorkDays',
                          defaultMessage: 'No specific work days.'
                        })}
                  </p>
                </div>

                <div
                  className="flex my-2 items-center justify-start nowrap gap-[8px] p-0 flex-grow "
                  style={{
                    width: `${Responsive.w(118, width)}px`,
                    height: `${Responsive.h(23, height)}px`,
                    fontSize: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <Icon
                    className="shrink-0 text-[#656565]"
                    style={{
                      width: `${Responsive.w(20, width)}px`,
                      height: `${Responsive.h(20, height)}px`
                    }}
                    icon="gala:clock"
                  />
                  <p>
                    {currentConsult?.workTimes?.length > 0 && currentConsult?.workTimes[0]
                      ? formatTimeRange(currentConsult.workTimes[0])
                      : useIntl().formatMessage({
                          id: 'noWorkDays',
                          defaultMessage: 'No specific work days.'
                        })}
                  </p>
                </div>
              </div>
            </div>
            {/* order*/}
            <div
              className="flex flex-col items-stretch  justify-between    flex-grow ] border-solid border-[#afafaf] border bg-white"
              style={{
                width: `${Responsive.w(271, width)}px`,
                padding: `${Responsive.sp(16, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`
              }}
            >
              <Card
                className="flex  items-center justify-between   self-stretch flex-grow py-[8px] px-[16px] border-solid border-[#afafaf] border bg-white"
                style={{
                  width: `${Responsive.w(234, width)}px`,
                  borderRadius: `${Responsive.sp(12, width)}px`
                }}
              >
                <Link
                  to={`/orders?consultid=${currentConsult?.uid}`}
                  className={`text-[${dashColor}]  font-bold flex items-center  justify-between`}
                  style={{
                    width: `${Responsive.w(234, width)}px`
                  }}
                >
                  <h1
                    className={`text-[${dashColor}]  font-medium text-center `}
                    style={{
                      width: `${Responsive.w(59, width)}px`,

                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'orders',
                      defaultMessage: 'orders'
                    })}
                  </h1>
                  <span className="flex items-center justify-center">
                    <Icon
                      icon="bytesize:arrow-right"
                      className="text-[#000] mr-auto w-[14px] h-[14px]"
                    />
                  </span>
                </Link>
              </Card>
              <Card
                className="flex  items-center justify-between   self-stretch flex-grow py-[8px] px-[16px] border-solid border-[#afafaf] border-[1px] bg-white"
                style={{
                  width: `${Responsive.w(234, width)}px`,
                  borderRadius: `${Responsive.sp(12, width)}px`
                }}
              >
                <Link
                  className={`text-[${dashColor}]  font-bold flex items-center  justify-between`}
                  style={{
                    width: `${Responsive.w(234, width)}px`
                    // height: `${Responsive.h(60, height)}px`
                  }}
                  to={`/appointments?consultid=${currentConsult?.uid}`}
                >
                  <h1
                    className={`text-[${dashColor}]  font-medium text-center `}
                    style={{
                      width: `${Responsive.w(59, width)}px`,
                      // height: `${Responsive.h(24, height)}px`,
                      fontSize: `${Responsive.sp(20, width)}px`
                    }}
                  >
                    {useIntl().formatMessage({
                      id: 'appointments',
                      defaultMessage: 'appointments'
                    })}
                  </h1>
                  <span className="flex items-center justify-center">
                    <Icon
                      icon="bytesize:arrow-right"
                      className="text-[#000] mr-auto w-[14px] h-[14px]"
                    />
                  </span>
                </Link>
              </Card>
            </div>
          </div>

          {/* Auth Details */}
          <div
            className="flex items-start justify-start flex-grow-0 p-0 self-stretch"
            style={{
              width: `${Responsive.w(1161, width)}px`,
              gap: `${Responsive.sp(16, width)}px`
            }}
          >
            {/* Apple */}
            <div
              className="py-2 px-4 flex items-center justify-start border-[1px] border-solid border-[#afafaf] bg-white"
              style={{
                width: `${Responsive.w(417.2, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`
              }}
            >
              <Icon
                icon={'devicon:apple'}
                className="w-6 h-6"
              />
              <p
                className="text-left text-[#656565] nowrap gap-[8px]"
                style={{
                  width: `${Responsive.w(214, width)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {auth?.find((provider: any) => provider.type === 'apple')?.value ||
                  'Not Registered with Apple'}
              </p>
            </div>
            {/* Google */}
            <div
              className="py-2 px-4 flex items-center justify-start border-[1px] border-solid border-[#afafaf] bg-white"
              style={{
                width: `${Responsive.w(417.2, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`
              }}
            >
              <Icon
                icon={'devicon:google'}
                className="w-6 h-6"
              />
              <p
                className="text-left text-[#656565] nowrap gap-[8px]"
                style={{
                  width: `${Responsive.w(214, width)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {auth?.find((provider: any) => provider.type === 'google')?.value ||
                  'Not Registered with Google'}
              </p>
            </div>
            {/* Phone */}
            <div
              className="py-2 px-4 flex items-center justify-start border-[1px] border-solid border-[#afafaf] bg-white"
              style={{
                width: `${Responsive.w(271, width)}px`,
                gap: `${Responsive.sp(8, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`
              }}
            >
              <Icon
                icon={'majesticons:phone-incoming'}
                className="w-6 h-6"
                style={{
                  color: `${dashColor}`
                }}
              />
              <p
                className="text-left text-[#656565] nowrap gap-[8px]"
                style={{
                  width: `${Responsive.w(124, width)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                {currentConsult?.phoneNumber || 'Not  Phone'}
              </p>
            </div>
          </div>

          {/* Form && bio */}

          <div
            className="flex  items-start justify-start  p-0 self-stretch flex-grow"
            style={{
              width: `${Responsive.w(1137, width)}px`,
              gap: `${Responsive.sp(16, width)}px`
            }}
          >
              <div
            className="flex flex-col items-start justify-start  p-0 self-stretch flex-grow"
            style={{
              width: `${Responsive.w(850.4, width)}px`,
              gap: `${Responsive.sp(16, width)}px`
            }}
          >
 {/* Form && bio */}
            <div
              className="flex items-start justify-start flex-grow-0 p-0 self-stretch"
              style={{
                width: `${Responsive.w(850.4, width)}px`,
                gap: `${Responsive.sp(16, width)}px`
              }}
            >
              {currentConsult?.bio && <BioCard currentConsult={currentConsult} />}
              {<WomenReviews />}
            </div>
            {/* What I am looking for */}
            <div
              className="flex items-start justify-start flex-grow-0 p-0"
              style={{
                width: `${Responsive.w(850.4, width)}px`,
                gap: `${Responsive.sp(16, width)}px`
              }}
            >
              
              <div
                className="flex flex-col items-start justify-start border-[1px] border-solid border-[#afafaf] bg-white self-stretch"
                style={{
                  width: `${Responsive.w(850.4, width)}px`,
                  gap: `${Responsive.sp(8, width)}px`,
                  borderRadius: `${Responsive.sp(12, width)}px`,
                  padding: `${Responsive.sp(16, width)}px`
                }}
              >
                <h2
                  className="text-left text-[#1e1e1e] font-semibold"
                  style={{
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >What i am looking for</h2>
                <p
                  className="text-left text-[#656565] font-medium"
                  style={{
                    fontSize: `${Responsive.sp(16, width)}px`
                  }}
                >A widowed or divorced man without children, a respectable and reputable man who dose  not mind my children living with us.</p>
              </div>
            </div>
          </div>
            

            <FormCard
              currentConsult={currentConsult}
              color={dashColor}
            />
          </div>
        </div>
      </div>
      {/* ul container */}
      <div
        className="flex flex-col items-start justify-start gap-[24px] rounded-[16px] p-[16px] bg-white"
        style={{
          width: `${Responsive.w(1560, width)}px`
          // height: `${Responsive.h(447, height)}px`
        }}
      >
        <div
          className="flex  items-start justify-start gap-[20px] rounded-[16px] p-0 bg-white flex-row"
          style={{
            width: `${Responsive.w(1528, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <ul
            className="consultants flex  items-start justify-start gap-[20px] rounded-[16px] p-0 bg-white flex-row"
            style={{
              width: `${Responsive.w(1528, width)}px`,
              height: `${Responsive.h(90, height)}px`
            }}
          >
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[4px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'Personal'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${Responsive.w(364, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('Personal')}
            >
              <button
                className=" cursor-pointer active text-center font-semibold font-[Montserrat] flex-grow-1 nowrap active:!text-[#7b6c96]"
                style={{
                  width: `${Responsive.w(364, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="personalInformation"
                    defaultMessage="Personal Information"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[4px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'specifications'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${Responsive.w(364, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('specifications')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${Responsive.w(364, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="specifications"
                    defaultMessage="Specifications"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[4px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'Potential'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${Responsive.w(364, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('Potential')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${Responsive.w(364, width)}px`,
                  height: `${Responsive.h(30, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="potentialSpouseSpecification"
                    defaultMessage="Potential Spouse Specification"
                  />
                }
              </button>
            </li>
            <li
              className={`flex-grow-1 flex justify-center text-[#000] items-center rounded-[8px] bg-white border-[4px] border-solid border-[#ececec] gap-[10px] ${
                activeItem === 'payment'
                  ? `${
                      currentDashboard === 'dream'
                        ? 'text-[#a12f88] border-transparent border-b-[#a12f88]'
                        : currentDashboard === 'jeras'
                        ? 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                        : currentDashboard === 'nikah'
                        ? 'text-[#cf0036] border-transparent border-b-[#cf0036]'
                        : 'text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                    }`
                  : ''
              } `}
              style={{
                width: `${Responsive.w(364, width)}px`,
                height: `${Responsive.h(90, height)}px`,
                boxShadow: ' 0 1px 5px 0 rgba(0, 0, 0, 0.06)'
              }}
              onClick={() => handleClick('payment')}
            >
              <button
                className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap ]"
                style={{
                  width: `${Responsive.w(364, width)}px`,

                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {
                  <FormattedMessage
                    id="paymentHistory"
                    defaultMessage="payment history"
                  />
                }
              </button>
            </li>
          </ul>
        </div>
        {/* select box */}
        <div
          className="flex flex-col  items-stretch justify-start gap-[24px] rounded-[16px] p-0 bg-white "
          style={{
            width: `${Responsive.w(1560, width)}px`
            // height: `${Responsive.h(318, height)}px`
          }}
        >
          {title === 'Personal' && !isPaymentDataLoading && (
            <PaymentInfoModal
              isOpen={isPaymentInfoModalOpen}
              setIsOpen={setIsPaymentInfoModalOpen}
              data={paymentInfoData}
              error={loadingPaymentError}
              color={dashColor}
            />
          )}
          {title === 'specifications' && !isPaymentDataLoading && (
            <SpecificationsfoModal
              isOpen={isPaymentInfoModalOpen}
              setIsOpen={setIsPaymentInfoModalOpen}
              data={paymentInfoData}
              error={loadingPaymentError}
              color={dashColor}
            />
          )}
          {title === 'Potential' && !isPaymentDataLoading && (
            <PotentialCard
              isOpen={isPaymentInfoModalOpen}
              setIsOpen={setIsPaymentInfoModalOpen}
              data={paymentInfoData}
              error={loadingPaymentError}
              color={dashColor}
            />
          )}
          {title === 'payment' && !isPaymentDataLoading && (
            <PaymentHistoryModal
              isOpen={isPaymentHistoryModalOpen}
              setIsOpen={setIsPaymentHistoryModalOpen}
              error={loadingPaymentHistoryError}
              currentConsult={currentConsult}
              color={dashColor}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function formatTimeRange(timeRange) {
  const from = parseInt(timeRange.from);
  const to = parseInt(timeRange.to);

  const fromLabel = from < 12 ? `${from} AM` : `${from - 12} PM`;
  const toLabel = to < 12 ? `${to} AM` : `${to - 12} PM`;

  return `${fromLabel} - ${toLabel}`;
}

function getWorkdaysString(dayNumbers) {
  // Define an array of day names
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Map the day numbers to their corresponding day names
  const workdays = dayNumbers.map((dayNumber) => {
    const dayIndex = parseInt(dayNumber, 10);
    return days[dayIndex % 7]; // Ensure it's within the valid range (0-6)
  });

  // Join the day names into a string with commas and spaces
  return workdays.join(', ');
}

const BioCard = ({ currentConsult }) => {
  // console.log('Bio', currentConsult.bio);
  const { width, height } = useWindowDimensions();
  const truncatedBio = currentConsult.bio.substring(0, 300);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    nameAr: '',
    nameEn: '',
    nameIn: '',
    nameFr: '',
    bioAr: '',
    bioEn: '',
    bioFr: '',
    bioIn: '',
    languages: []
  });
  const [selectedLanguages, setSelectedLanguages] = useState({
    ar: false,
    en: false,
    fr: false,
    id: false
  });

  useEffect(() => {
    if (currentConsult) {
      setFormValues({
        nameAr: currentConsult?.consultName?.nameAr || '',
        nameEn: currentConsult?.consultName?.nameEn || '',
        nameFr: currentConsult?.consultName?.nameFr || '',
        nameIn: currentConsult?.consultName?.nameIn || '',
        bioAr: currentConsult?.consultBio?.bioAr || '',
        bioEn: currentConsult?.consultBio?.bioEn || '',
        bioFr: currentConsult?.consultBio?.bioFr || '',
        bioIn: currentConsult?.consultBio?.bioIn || '',
        languages: currentConsult?.languages || []
      });
    }
  }, [currentConsult]);

  useEffect(() => {
    if (currentConsult) {
      setSelectedLanguages({
        ar: formValues.languages.includes('ar'),
        en: formValues.languages.includes('en'),
        id: formValues.languages.includes('id'),
        fr: formValues.languages.includes('fr')
      });
    }
  }, [formValues]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => {
      return {
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value
      };
    });
  };

  // Function to compare initial and current form values to determine what has changed
  const getChangedValues = (initialValues, currentValues) => {
    return Object.keys(initialValues).reduce((changes, key) => {
      if (initialValues[key] !== currentValues[key]) {
        // for nested objects like "consultBio.bioFr", you may need to transform the key
        // to use the dot notation
        const newKey = key.includes('bio') ? `consultBio.${key}` : `consultName.${key}`;
        changes[newKey] = currentValues[key];
      }
      return changes;
    }, {});
  };

  // Initialize selectedLanguages with the values derived from currentConsult
  const handleLanguageChange = (e) => {
    const { name, checked } = e.target;
    const currentlySelectedCount = Object.values(selectedLanguages).filter(Boolean).length;

    // If trying to uncheck and it's the last selected language, prevent it
    if (!checked && currentlySelectedCount === 1) {
      toast.error('At least one language must be selected.');
      return;
    }

    // Otherwise, proceed to update the state
    setSelectedLanguages((prev) => ({ ...prev, [name]: checked }));
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedLangs = Object.entries(selectedLanguages)
      .filter(([key, value]) => value) // Keep only the entries with a value of true
      .map(([key]) => key);

    const initialFormValues = {
      nameAr: currentConsult.consultName.nameAr,
      nameEn: currentConsult.consultName.nameEn,
      nameFr: currentConsult.consultName.nameFr,
      nameIn: currentConsult.consultName.nameIn,
      bioAr: currentConsult.consultBio.bioAr,
      bioEn: currentConsult.consultBio.bioEn,
      bioFr: currentConsult.consultBio.bioFr,
      bioIn: currentConsult.consultBio.bioIn,
      languages: currentConsult.languages
    };
    const changedValues = getChangedValues(initialFormValues, formValues);

    try {
      const response = await axiosInstance.patch(`/consultants/${currentConsult.uid}`, {
        ...changedValues,
        languages: selectedLangs
      });
      console.log('Update success:', response.data);
      toast.success('Changes have been saved!');
      closeModal();
      // Handle any post-update logic here (e.g., refreshing consultant details)
    } catch (error) {
      console.error('Error updating consultant details:', error);
      // Handle error (e.g., display error message to the user)
    }
  };

  return (
    <Card
      className="flex flex-col items-start justify-start  flex-grow  self-stretch   border-solid border-[#afafaf] border bg-white"
      style={{
        width: `${Responsive.w(417.2, width)}px`,
        padding: `${Responsive.sp(16, width)}px`,
        gap: `${Responsive.sp(8, width)}px`,
        borderRadius: `${Responsive.sp(16, width)}px`
      }}
    >
      <h1
        className="font-[Montserrat] font-semibold nowrap text-left text-[#1e1e1e]"
        style={{
          width: `${Responsive.w(41, width)}px`,
          height: `${Responsive.h(29, height)}px`,
          fontSize: `${Responsive.sp(24, width)}px`
        }}
      >
        {useIntl().formatMessage({
          id: 'Bio',
          defaultMessage: 'Bio'
        })}
      </h1>
      {/* <button
        onClick={openModal}
        className="absolute top-0 right-0 p-2"
      >
        <Icon
          icon="eva:edit-fill"
          className="text-[#C145A6] hover:text-gray-800"
        />
      </button> */}

      <p className="text-right">{isExpanded ? currentConsult.bio : truncatedBio}</p>
      {currentConsult.bio.length > 300 && (
        <button
          onClick={toggleExpand}
          className="text-[#7b6c96] underline"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}

      <Modal
        visible={isModalOpen}
        handleClose={closeModal}
        title="Edit Consultant info"
      >
        <form
          onSubmit={handleSubmit}
          className="mt-2 space-y-6"
        >
          {/* NameAr Input */}
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameAr',
                defaultMessage: 'name in Arabic'
              })}
            </span>
            <input
              type="text"
              name="nameAr"
              value={formValues.nameAr}
              onChange={handleInputChange}
              className="mt-1 block w-full text-right rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter Arabic Name"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameEn',
                defaultMessage: 'name in English'
              })}
            </span>
            <input
              type="text"
              name="nameEn"
              value={formValues.nameEn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter English Name"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameFr',
                defaultMessage: 'name in French'
              })}
            </span>
            <input
              type="text"
              name="nameFr"
              value={formValues.nameFr}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter French Name"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'nameIn',
                defaultMessage: 'name in Indonesian'
              })}
            </span>
            <input
              type="text"
              name="nameIn"
              value={formValues.nameIn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder={useIntl().formatMessage({
                id: 'enterNameInIndo',
                defaultMessage: 'Enter Name in Indonesian'
              })}
            />
          </label>
          <div className="flex justify-between">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'languages',
                defaultMessage: 'languages'
              })}
              :
            </span>
            <label>
              <input
                type="checkbox"
                name="ar"
                checked={selectedLanguages.ar}
                onChange={handleLanguageChange}
              />
              Arabic
            </label>
            <label>
              <input
                type="checkbox"
                name="en"
                checked={selectedLanguages.en}
                onChange={handleLanguageChange}
              />
              English
            </label>
            <label>
              <input
                type="checkbox"
                name="id"
                checked={selectedLanguages.id}
                onChange={handleLanguageChange}
              />
              Indonesian
            </label>
            <label>
              <input
                type="checkbox"
                name="fr"
                checked={selectedLanguages.fr}
                onChange={handleLanguageChange}
              />
              French
            </label>
          </div>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioAr',
                defaultMessage: 'Bio in Arabic'
              })}
            </span>
            <textarea
              name="bioAr"
              value={formValues.bioAr}
              onChange={handleInputChange}
              className="mt-1 block w-full text-right rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter Arabic Bio"
              rows={4}
            ></textarea>
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioEn',
                defaultMessage: 'Bio in English'
              })}
            </span>
            <textarea
              name="bioEn"
              value={formValues.bioEn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter English Bio"
              rows={4}
            ></textarea>
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioFr',
                defaultMessage: 'Bio in French'
              })}
            </span>
            <textarea
              name="bioFr"
              value={formValues.bioFr}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter French Bio"
              rows={4}
            ></textarea>
          </label>
          <label className="block">
            <span className="text-gray-700">
              {useIntl().formatMessage({
                id: 'bioIn',
                defaultMessage: 'Bio in Indonesian'
              })}
            </span>
            <textarea
              name="bioIn"
              value={formValues.bioIn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-pink-300 focus:ring focus:ring-pink-200 focus:ring-opacity-50"
              placeholder="Enter Indian Bio"
              rows={4}
            ></textarea>
          </label>

          {/* Add other inputs similarly... */}
          {/* Submit Button */}
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          >
            {useIntl().formatMessage({
              id: 'saveChanges',
              defaultMessage: 'Save changes'
            })}
          </button>
        </form>
      </Modal>
    </Card>
  );
};

const FormCard = ({ currentConsult, color }) => {
  const { width, height } = useWindowDimensions();
  const [isAccountActive, setIsAccountActive] = useState(currentConsult?.accountStatus == 'Active');
  const [isConvertToUserActive, setisConvertToUserActive] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(currentConsult?.order || 0);

  const handleCheckboxChange = () => {
    setIsAccountActive(!isAccountActive);
  };
  const handleConvertToUserChange = () => {
    setisConvertToUserActive(!isConvertToUserActive);
  };

  const handleDisplayOrderChange = (e) => {
    setDisplayOrder(Number(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axiosInstance.patch(`/consultants/${currentConsult.uid}`, {
      accountStatus: isAccountActive ? 'Active' : 'NotActive',
      userType: isConvertToUserActive ? 'USER' : 'CONSULTANT',
      order: displayOrder
    });
    toast.success('data has been modified successfully ✔');
  };
  return (
    <Card
      className="flex flex-col items-start justify-between  self-stretch flex-grow  border-solid border-[#afafaf] border bg-white"
      style={{
        width: `${Responsive.w(271, width)}px`,
        padding: `${Responsive.sp(16, width)}px`,

        borderRadius: `${Responsive.sp(16, width)}px`
      }}
    >
      <form
        style={{
          width: `${Responsive.w(266, width)}px`
        }}
        className=" w-full flex flex-col  justify-between items-start flex-grow h-full"
        onSubmit={handleSubmit}
      >
        {/* Display Order Input Row */}
        <div
          className="flex items-center justify-between p-0 self-stretch "
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(30, height)}px`
          }}
        >
          <label
            className={` text-center text-[${color}] font-medium font-[Montserrat]`}
            style={{
              width: `${Responsive.w(135, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'displayOrder',
              defaultMessage: 'Display order'
            })}
          </label>
          <div
            className=" flex justify-center items-center py-0 px-[16px] rounded-[8px] border-solid border-[#939393] border-[1px] bg-white"
            style={{
              width: `${Responsive.w(63, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            <input
              className="w-11 h-6 text-center py-0 px-[16px] rounded-[8px] border-solid border-[#939393] border-[1px] bg-white"
              value={displayOrder}
              style={{
                width: `${Responsive.w(63, width)}px`,
                // height: `${Responsive.h(30, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
              onChange={handleDisplayOrderChange}
            ></input>
          </div>
        </div>
        {/* Active Toggle Row */}

        <div
          className="flex items-center justify-between p-0  "
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(30, height)}px`
          }}
        >
          <label
            className={` text-center text-[${color}] font-medium font-[Montserrat]`}
            style={{
              width: `${Responsive.w(135, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'active',
              defaultMessage: 'active'
            })}
          </label>
          <div
            className=" flex justify-center "
            style={{
              width: `${Responsive.w(63, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            <ToggleSlider
              onClick={handleCheckboxChange}
              checked={isAccountActive}
            />
          </div>
        </div>
        {/* Convert to User Toggle Row */}
        <div
          className="flex items-center justify-between p-0 "
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(30, height)}px`
          }}
        >
          <label
            className={` text-center nowrap text-[${color}] font-medium font-[Montserrat]`}
            style={{
              width: `${Responsive.w(135, width)}px`,
              // height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'convertToUser',
              defaultMessage: 'convert to user'
            })}
          </label>
          <div className=" flex justify-center">
            <ToggleSlider
              onClick={handleConvertToUserChange}
              checked={isConvertToUserActive}
            />
          </div>
        </div>

        {/* Save Button */}
        <div
          className="flex items-center justify-center p-0 self-stretch"
          style={{
            width: `${Responsive.w(234, width)}px`
            // height: `${Responsive.h(46, height)}px`
          }}
        >
          <button
            className="   flex items-center justify-center  text-white font-semibold py-[5px] px-[16px] rounded-[8px] "
            style={{
              width: `${Responsive.w(234, width)}px`,
              // height: `${Responsive.h(46, height)}px`,
              backgroundColor: color
            }}
            type="submit"
          >
            {useIntl().formatMessage({
              id: 'save',
              defaultMessage: 'save'
            })}
          </button>
        </div>
      </form>
    </Card>
  );
};

const WomenReviews = () => {
  const { width, height } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Card
        className="p-4 flex items-start justify-start border border-solid border-[#afafaf]  bg-white flex-grow flex-col self-stretch"
        style={{
          width: `${Responsive.w(417.2, width)}px`,
          padding: `${Responsive.sp(16, width)}px`,
          gap: `${Responsive.sp(8, width)}px`,
          borderRadius: `${Responsive.sp(16, width)}px`
        }}
      >
        {/* Header */}
        <h1
          className="font-semibold text-[#1e1e1e] text-left"
          style={{
            width: `${Responsive.w(417.2, width)}px`,
            fontSize: `${Responsive.sp(24, width)}px`
          }}
        >
          Reviews
        </h1>

        <div
          className="p-0 flex items-stretch justify-between flex-col self-stretch"
          style={{
            width: `${Responsive.w(385.2, width)}px`,
            gap: `${Responsive.sp(8, width)}px`
          }}
        >
          {/* Row 1 */}
          <div
            className="flex items-center justify-between p-0 self-stretch "
            style={{
              width: `${Responsive.w(385.2, width)}px`
            }}
          >
            <p
              className="text-[#202020] text-left"
              style={{
                fontSize: `${Responsive.sp(14, width)}px`
              }}
            >
              Serious
            </p>
            <div
              className="p-0 flex items-start justify-end"
              style={{
                gap: `${Responsive.sp(7, width)}px`
              }}
            >
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#afafaf'
                }}
              />
            </div>
          </div>

          {/*  Row 2 */}
          <div
            className="flex items-center justify-between p-0 self-stretch "
            style={{
              width: `${Responsive.w(385.2, width)}px`
            }}
          >
            <p
              className="text-[#202020] text-left"
              style={{
                fontSize: `${Responsive.sp(14, width)}px`
              }}
            >
              Polite
            </p>
            <div
              className="p-0 flex items-start justify-end"
              style={{
                gap: `${Responsive.sp(7, width)}px`
              }}
            >
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#afafaf'
                }}
              />
            </div>
          </div>

          {/* Row 4 */}

          <div
            className="flex items-center justify-between p-0 self-stretch "
            style={{
              width: `${Responsive.w(385.2, width)}px`
            }}
          >
            <p
              className="text-[#202020] text-left"
              style={{
                fontSize: `${Responsive.sp(14, width)}px`
              }}
            >
              Exceptional
            </p>
            <div
              className="p-0 flex items-start justify-end"
              style={{
                gap: `${Responsive.sp(7, width)}px`
              }}
            >
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#afafaf'
                }}
              />
            </div>
          </div>
          {/* Row 4 */}

          <div
            className="flex items-center justify-between p-0 self-stretch "
            style={{
              width: `${Responsive.w(385.2, width)}px`
            }}
          >
            <p
              className="text-[#202020] text-left"
              style={{
                fontSize: `${Responsive.sp(14, width)}px`
              }}
            >
              Appropriate
            </p>
            <div
              className="p-0 flex items-start justify-end"
              style={{
                gap: `${Responsive.sp(7, width)}px`
              }}
            >
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#cf0036'
                }}
              />
              <Icon
                icon={'solar:heart-bold'}
                style={{
                  width: `12px`,
                  height: `12px`,
                  objectFit: 'contain',
                  color: '#afafaf'
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const PaymentInfoModal = ({ isOpen, setIsOpen, data, error, color }) => {
  // console.log('aa', data);

  const [image, setImage] = useState(null);
  const { width, height } = useWindowDimensions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Payment info submitted!', formData);
    const res = await axiosInstance.patch(`paymentInfo/${data.id}`, formData);
    toast.success('Payment info has been modified successfully ✔');
    setIsOpen(false);
    // console.log('res updated', res);
  };

  const [formData, setFormData] = useState({
    origin: data?.origin,
    name: data?.name,
    email: data?.email,
    countryCode: data?.countryCode,
    phoneNumber: data?.phoneNumber,
    country: data?.country,
    living: data?.living,
    district: data?.district,
    zip_code: data?.zip_code,
    siteUrl: data?.siteUrl
  });

  const parseDateString = (originalValue) => {
    const [day, month, year] = originalValue.split('/');
    return new Date(year, month - 1, day);
  };

  // Check if startDate is available and valid
  const hasStartDate = data?.startDate && /\d{2}\/\d{2}\/\d{4}/.test(data.startDate);
  const hasEndDate = data?.endDate && /\d{2}\/\d{2}\/\d{4}/.test(data.endDate);

  // Convert initial startDate from string to Date object if available
  const initialStartDate = hasStartDate ? parseDateString(data.startDate) : new Date();
  const initialEndDate = hasEndDate ? parseDateString(data.endDate) : new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  // ... existing code ...

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: formattedDate
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: formattedDate
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  //  useEffect(() => console.log('changed', formData), [formData]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-4   gap-5 rounded-[16px] p-0 bg-white "
        style={{
          width: `${Responsive.w(1528, width)}px`,
          height: `${Responsive.h(318, height)}px`
        }}
      >
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'fullName',
              defaultMessage: 'Full Name'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.name}
            onChange={handleChange}
            name="fullNameAr"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'title',
              defaultMessage: 'Title'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.origin}
            onChange={handleChange}
            name="title"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'email',
              defaultMessage: 'Email'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.email}
            onChange={handleChange}
            name="email"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}] nowrap`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'countryCode',
              defaultMessage: 'Country code'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.countryCode}
            onChange={handleChange}
            name="countryCode"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'phone',
              defaultMessage: 'Phone'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.phoneNumber}
            onChange={handleChange}
            name="phone"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'address1',
              defaultMessage: 'Address1'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.country}
            onChange={handleChange}
            name="address1"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'address2',
              defaultMessage: 'Address2'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.living}
            onChange={handleChange}
            name="address2"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'district',
              defaultMessage: 'District'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.district}
            onChange={handleChange}
            name="district"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'zip_Code',
              defaultMessage: ' Zip_Code'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.zip_code}
            onChange={handleChange}
            name="zip_code"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'site_Url',
              defaultMessage: ' Site_Url'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.siteUrl}
            onChange={handleChange}
            name="siteUrl"
          />
        </div>
      </form>

      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={` bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};
const SpecificationsfoModal = ({ isOpen, setIsOpen, data, error, color }) => {
  // console.log('aa', data);

  const [image, setImage] = useState(null);
  const { width, height } = useWindowDimensions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Payment info submitted!', formData);
    const res = await axiosInstance.patch(`paymentInfo/${data.id}`, formData);
    toast.success('Payment info has been modified successfully ✔');
    setIsOpen(false);
    // console.log('res updated', res);
  };

  const [formData, setFormData] = useState({
    age: data?.age,
    weight: data?.weight,
    length: data?.length,
    doctrine: data?.doctrine,
    employment: data?.employment,
    living: data?.living,
    education: data?.education,
    specialization: data?.specialization,
    country: data?.country,
    maritalStatus: data?.maritalStatus,
    smooking: data?.smooking
  });

  const parseDateString = (originalValue) => {
    const [day, month, year] = originalValue.split('/');
    return new Date(year, month - 1, day);
  };

  // Check if startDate is available and valid
  const hasStartDate = data?.startDate && /\d{2}\/\d{2}\/\d{4}/.test(data.startDate);
  const hasEndDate = data?.endDate && /\d{2}\/\d{2}\/\d{4}/.test(data.endDate);

  // Convert initial startDate from string to Date object if available
  const initialStartDate = hasStartDate ? parseDateString(data.startDate) : new Date();
  const initialEndDate = hasEndDate ? parseDateString(data.endDate) : new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  // ... existing code ...

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: formattedDate
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: formattedDate
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  // useEffect(() => console.log('changed', formData), [formData]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-4   gap-5 rounded-[16px] p-0 bg-white "
        style={{
          width: `${Responsive.w(1528, width)}px`,
          height: `${Responsive.h(318, height)}px`
        }}
      >
        <div
          className="flex flex-col  items-start justify-start p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'age',
              defaultMessage: 'Age'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.age}
            onChange={handleChange}
            name="age"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'weight(Kg)',
              defaultMessage: 'Weight(Kg)'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.weight}
            onChange={handleChange}
            name="Weight"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'height(cm)',
              defaultMessage: 'Height(cm)'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.length}
            onChange={handleChange}
            name="Height"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}] nowrap`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'doctrine',
              defaultMessage: 'Doctrine'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.doctrine}
            onChange={handleChange}
            name="Doctrine"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'employment',
              defaultMessage: 'Employment status'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.employment}
            onChange={handleChange}
            name="employment"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'standardOfliving',
              defaultMessage: 'Standard of living'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.living}
            onChange={handleChange}
            name="standardOfliving"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'educationalLevel',
              defaultMessage: 'Educational level'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.education}
            onChange={handleChange}
            name="educationalLevel"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'specialization',
              defaultMessage: 'Specialization'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.specialization}
            onChange={handleChange}
            name="specialization"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'ethnicity',
              defaultMessage: ' Ethnicity'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.country}
            onChange={handleChange}
            name="ethnicity"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'maritalStatus',
              defaultMessage: ' Marital status'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.maritalStatus}
            onChange={handleChange}
            name="maritalStatus"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'smoking',
              defaultMessage: 'Smoking'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.smooking}
            onChange={handleChange}
            name="smoking"
          />
        </div>
      </form>

      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={` bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};
const PotentialCard = ({ isOpen, setIsOpen, data, error, color }) => {
  // console.log('aa', data);

  const [image, setImage] = useState(null);
  const { width, height } = useWindowDimensions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Payment info submitted!', formData);
    const res = await axiosInstance.patch(`paymentInfo/${data.id}`, formData);
    toast.success('Payment info has been modified successfully ✔');
    setIsOpen(false);
    // console.log('res updated', res);
  };

  const [formData, setFormData] = useState({
    title: data?.title,
    fullNameAr: data?.name,
    length: data?.length,
    countryCode: data?.countryCode,
    phone: data?.phone,
    swift: data?.swift,
    address1: data?.address1,
    address2: data?.address2,
    district: data?.district,
    zip_code: data?.zip_code,
    siteUrl: data?.siteUrl
  });

  const parseDateString = (originalValue) => {
    const [day, month, year] = originalValue.split('/');
    return new Date(year, month - 1, day);
  };

  // Check if startDate is available and valid
  const hasStartDate = data?.startDate && /\d{2}\/\d{2}\/\d{4}/.test(data.startDate);
  const hasEndDate = data?.endDate && /\d{2}\/\d{2}\/\d{4}/.test(data.endDate);

  // Convert initial startDate from string to Date object if available
  const initialStartDate = hasStartDate ? parseDateString(data.startDate) : new Date();
  const initialEndDate = hasEndDate ? parseDateString(data.endDate) : new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  // ... existing code ...

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: formattedDate
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // Convert Date object to 'dd/mm/yyyy' format
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: formattedDate
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  // useEffect(() => console.log('changed', formData), [formData]);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-4   gap-5 rounded-[16px] p-0 bg-white "
        style={{
          width: `${Responsive.w(1528, width)}px`,
          height: `${Responsive.h(318, height)}px`
        }}
      >
        <div
          className="flex flex-col  items-start justify-start p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'age',
              defaultMessage: 'Age'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.fullNameAr}
            onChange={handleChange}
            name="age"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'weight(Kg)',
              defaultMessage: 'Weight(Kg)'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.title}
            onChange={handleChange}
            name="Weight"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'height(cm)',
              defaultMessage: 'Height(cm)'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.length}
            onChange={handleChange}
            name="Height"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}] nowrap`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'doctrine',
              defaultMessage: 'Doctrine'
            })}
          </label>

          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.countryCode}
            onChange={handleChange}
            name="Doctrine"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'employment',
              defaultMessage: 'Employment status'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.phone}
            onChange={handleChange}
            name="employment"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'standardOfliving',
              defaultMessage: 'Standard of living'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.phone}
            onChange={handleChange}
            name="standardOfliving"
          />
        </div>

        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'educationalLevel',
              defaultMessage: 'Educational level'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.address2}
            onChange={handleChange}
            name="educationalLevel"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'specialization',
              defaultMessage: 'Specialization'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.district}
            onChange={handleChange}
            name="specialization"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'ethnicity',
              defaultMessage: ' Ethnicity'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.zip_code}
            onChange={handleChange}
            name="ethnicity"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'maritalStatus',
              defaultMessage: ' Marital status'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.siteUrl}
            onChange={handleChange}
            name="maritalStatus"
          />
        </div>
        <div
          className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(364, width)}px`,
            height: `${Responsive.h(90, height)}px`
          }}
        >
          <label
            className={`font-[Montserrat] flex-grow-0 font-semibold text-[${color}]`}
            style={{
              width: `${Responsive.w(122, width)}px`,
              height: `${Responsive.h(24, height)}px`,
              fontSize: `${Responsive.sp(20, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'smoking',
              defaultMessage: 'Smoking'
            })}
          </label>
          <input
            className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
            style={{
              width: `${Responsive.w(364, width)}px`,
              height: `${Responsive.h(50, height)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingRight: `${Responsive.sp(16, width)}px`,
              paddingLeft: `${Responsive.sp(16, width)}px`,
              paddingTop: `${Responsive.sp(0, width)}px`,
              paddingBottom: `${Responsive.sp(0, width)}px`
            }}
            type="text"
            value={formData.siteUrl}
            onChange={handleChange}
            name="smoking"
          />
        </div>
      </form>

      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={` bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};

const ReviewsModal = ({ isOpen, setIsOpen, data, error }) => {
  return (
    <Modal
      visible={isOpen}
      handleClose={() => setIsOpen(false)}
      title="Reviews"
    >
      <>
        {data?.reviews.map((review) => (
          <ReviewCard
            review={review}
            key={review.uid}
          />
        ))}
      </>
    </Modal>
  );
};

const PaymentHistoryModal = ({ isOpen, setIsOpen, error, currentConsult, color }) => {
  const [data, setData] = useState<any>();
  const formatDate = (date: any) => {
    const [isloading, setIsloding] = useState('false');
    const dateObj = new Date(date._seconds * 1000);
    const formattedDateTime = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return formattedDateTime;
  };

  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState<any>('paymentHistory');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);
  const formatDay = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };
  const PaymentData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/paymentHistory/${id}`);
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoading(false);
  };
  const PaymentExport = async (invoiceNumber) => {
    const consultId = id;
    try {
      const { data } = await axiosInstance.get(
        `/paymentHistoryInvoiceExport/${consultId}/${invoiceNumber}`,
        {
          responseType: 'blob' // Set responseType to blob to handle binary data
        }
      );

      // Create a blob URL for the response data
      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // Save the Blob as a file using FileSaver.js
      // saveAs(blob, `${invoiceNumber}_invoice.xlsx`);

      toast.success('Payment Download success ✔');
    } catch (error) {
      console.error('Error fetching data by date:', error);
      toast.error('Payment Download error ✔');
    }
  };
  const paymentHistoryMessage = 'paymentHistory';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);
  const handleDateOptionClick = async (dateOption, title) => {
    setIsLoading(true);

    try {
      setTitle(title);
      let startDate;
      let endDate;

      if (dateOption === 'Payment History') {
        PaymentData();
        return;
      }
      if (dateOption === 'Today') {
        startDate = new Date();
        endDate = new Date();
      } else if (dateOption === 'Last 7 days') {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
      } else if (dateOption === 'Last 30 days') {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 30);
      } else if (dateOption === 'Date range') {
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
      }

      const response = await axiosInstance.get(
        `/paymentHistory/${id}?startDate=${startDate}&endDate=${endDate}`
      );
      setData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    PaymentData();
  }, []);
  // if(data){
  //   data.map((e)=>{

  //     console.log("payTime", e.payTime);

  //   })
  // }

  return (
    <>
      <Table
        isEmpty={!data?.length}
        loading={isLoading}
        searchAble={false}
        notCountable={true}
        customizeAble={false}
        title={title}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('Payment History', paymentHistoryMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={paymentHistoryMessage}
                      defaultMessage={paymentHistoryMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        CustomizeTable={() => <></>}
        RenderBody={({ getRowColor }) => (
          <>
            {data?.map((item: any, index: number) => (
              <tr
                key={index}
                style={{ backgroundColor: getRowColor(index) }}
              >
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {index + 1}
                </td>
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {item?.balance.toFixed(2)}$
                </td>
                <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  {formatDay(item?.payDate)}
                </td>
                <td className="text-center flex items-center justify-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                  <button
                    className="flex items-center justify-center"
                    onClick={() => {
                      PaymentExport(item.invoiceNumber);
                    }}
                  >
                    <img
                      src={img_2}
                      srcSet={`${img_2_2}, ${img_2_3}`}
                      className="object-contain p-0 w-[17px] h-[17px] m-auto"
                      alt="Description of the image"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              #
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              {
                <FormattedMessage
                  id="amount"
                  defaultMessage="Amount"
                />
              }
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              {
                <FormattedMessage
                  id="paymentDate"
                  defaultMessage="payment date"
                />
              }
            </th>
            <th
              className="text-center nowrap  text-[14px] font-medium text-[#fff]  self-stretch"
              style={{
                height: `${Responsive.h(24, height)}px`,
                backgroundColor: color
              }}
            >
              {
                <FormattedMessage
                  id="download"
                  defaultMessage="Download"
                />
              }
            </th>
          </>
        )}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        // buttonExport={() => ExportXlsx(data, customize_keys)}
      />
      <div
        className="flex mx-auto mt-5  items-center justify-center gap-[16px] rounded-[8px] py-[5px] px-[16px]  bg-[#7b6c96]  "
        style={{
          width: `${Responsive.w(240, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          backgroundColor: color
        }}
      >
        <button
          type="submit"
          className={`  bg-[${color}] text-white font-bold  text-center `}
        >
          {useIntl().formatMessage({
            id: 'update',
            defaultMessage: 'Update'
          })}
        </button>
      </div>
    </>
  );
};

function ReviewCard({ review }) {
  // Format the date from review time assuming it's a timestamp in milliseconds
  const reviewDate = new Date(review?.reviewTime?.seconds * 1000).toLocaleDateString('en-US');

  return (
    <div className="flex justify-center max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="w-1/5 p-2 m-2">
        <img
          className="rounded-full "
          width={100}
          height={100}
          src={review?.image ? review.image : '/images/placeholder.png'}
        />
      </div>
      <div className="p-8 w-4/5">
        <div className="flex items-center mb-4 justify-between">
          <h1>{review?.name}</h1>
          <div className="flex items-center space-x-1">
            <ReactStars
              count={5}
              value={review.rating}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
              edit={false}
            />
            <span className="text-sm text-gray-500">({review?.rating.toFixed(1)})</span>
          </div>
        </div>
        <p className="text-gray-700 text-base text-right">{review?.review}</p>
      </div>
    </div>
  );
}
export default WomenDetailsPage;
