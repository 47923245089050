import { useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function PickDateTwo({ selectedDate, setSelectedDate }) {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      className="bg-slate-300 rounded-xl w-32"
      dateFormat="dd/MM/yyyy"
      showYearDropdown
      showMonthDropdown
      showIcon
    />
  );
}

export default PickDateTwo;
