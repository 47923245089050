import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import vector from '../images/Vector+.png';
import { useIntl } from 'react-intl';
import axiosInstance from 'src/helper/AxiosInstance';
import { toast } from 'react-toastify';
import { forEach } from 'lodash';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import { IntlProvider, FormattedMessage } from 'react-intl';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/Edit.png';
import img_2_2 from '../images/Edit@2x.png';
import img_2_3 from '../images/Edit@3x.png';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { usePermissions } from 'src/hooks/usePeermissions';
interface CourseData {
  active: boolean;
  age: string;
  desc: string;
  lang: string;
  lessonNum: number;
  name: string;
  notes: string;
  order: number;
  rating: number;
  sections: string;
  summary: string;
  backgroundImage: string;
  interestListIds: any;
  courseId: string;
}
export default function coursesDetailsPage() {
  const { width, height } = useWindowDimensions();
  const intl = useIntl();
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState<CourseData>();
  const [users, setUsers] = useState<any>([]);
  const [oneUser, setOneUser] = useState<any>([]);
  const [interest, setInterest] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showtTeachers, setShowTeachers] = useState(false);
  // const [showTeachers, setShowTeachers] = useState(false);
  const [image, setImage] = useState(data?.backgroundImage || null);
  const [active, setActive] = useState(data?.active || false);
  const [age, setAge] = useState(data?.age || '');
  const [desc, setDesc] = useState(data?.desc || '');
  const [courseId, setCourseId] = useState(data?.courseId || '');
  const [lang, setLang] = useState(data?.lang || '');
  const [lessonNum, setLessonNum] = useState(data?.lessonNum || 0);
  const [name, setName] = useState(data?.name || '');
  const [notes, setNotes] = useState(data?.notes || '');
  const [order, setOrder] = useState(data?.order || 0);
  const [rating, setRating] = useState(data?.rating || 0);
  const [sections, setSections] = useState(data?.sections || '');
  const [summary, setSummary] = useState(data?.summary || '');
  const [selectedInterests, setSelectedInterests] = useState(data?.interestListIds || []);
  const [isloading, setIsloading] = useState(false);
  const [isloadingSubmit, setIsloadingSubmit] = useState(false);
  const [isloadingInter, setIsloadingInter] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const Permission = usePermissions();
  // const [image, setImage] = useState(data?.image || '');
  let initialQueries: any = [
    ['userType', '==', 'CONSULTANT'],
    ['accountStatus', '==', 'Active']
  ];

  console.log('selectedInterests', selectedInterests);
  console.log('oneUser', oneUser);
  console.log('selectedUsers', selectedUsers);

  useEffect(() => {
    if (oneUser && oneUser.length > 0) {
      const usersIds = oneUser.map((user: any) => user.uid);
      setSelectedUsers(usersIds);
    }
  }, [oneUser]);

  let params;

  const { id } = useParams();
  async function getOneCourse() {
    try {
      const res = await axiosInstance.get(`/courses/${id}`);
      console.log('getOneCourse==>', res.data.result.data);
      setData(res.data.result.data);
    } catch (er) {
      console.log('courses not found');
    }
  }
  console.log('image', image);
  async function getUsersCourse() {
    try {
      setIsloading(true);
      const res = await axiosInstance.get(`/consultants`, {
        params: {
          queries: initialQueries
        }
      });
      console.log('Users=====>', res.data.result.data);
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('courses not found');
    }
    setIsloading(false);
  }
  async function getOneUsersCourse() {
    try {
      const res = await axiosInstance.get(`/courses-users/${id}`);
      console.log('OneUser=====>', res.data.result.data);
      setOneUser(res.data.result.data);
    } catch (er) {
      console.log('courses not found');
    }
  }

  async function getOneInterestCourse() {
    setIsloadingInter(true);
    try {
      const res = await axiosInstance.get(`/courses-interes/`);

      console.log('inter=====>', res.data.result.data);
      setInterest(res.data.result.data);
    } catch (er) {
      console.log('courses not found');
    }
    setIsloadingInter(false);
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); // Set the selected file
    console.log('file', event.target);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    // Reset the value of the input
    event.target.value = null;
  };

  const UpdateUsers = async (selectedUsers) => {
    try {
      const reqBody = {
        courseId: courseId,
        selectedUsers: Array.from(selectedUsers) // Convert Set to Array
      };
      const res = await axiosInstance.patch(`/uapdateUsers/`, reqBody);
      toast.success('User has been modified successfully ✔');
    } catch (er) {
      toast.error('Failed to modify course');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsloadingSubmit(true);
      const selected = selectedUsers;

      const errMessage = intl.formatMessage({
        id: 'fileMissingError',
        defaultMessage: 'Please select a file to upload.'
      });
      let uploadedImageUrl = image;
      if (data?.backgroundImage != image) {
        if (!selectedFile) {
          toast.error(errMessage);
          return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await axiosInstance.post('/uploadImgCourses/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        uploadedImageUrl = response.data.result.data.imgUrl || 'noImage';
        console.log('uploadedImageUrl', uploadedImageUrl);
        setImage(uploadedImageUrl);
      }

      const reqBody = {
        image: uploadedImageUrl, // Use the new image URL
        active: active,
        age: age,
        desc: desc,
        lang: lang,
        lessonNum: lessonNum,
        name: name,
        notes: notes,
        order: order,
        rating: rating,
        sections: sections,
        summary: summary,
        interestListIds: selectedInterests,
        selectedUsers: Array.from(selected) // Convert Set to Array
      };

      let coursesRes;
      if (id) {
        coursesRes = await axiosInstance.patch(`/courses/${id}`, reqBody);
      } else {
        coursesRes = await axiosInstance.post(`/courses/`, reqBody);
      }
      await UpdateUsers(selected);

      toast.success('Course has been modified successfully ✔');
      setIsloadingSubmit(false);
    } catch (error) {
      console.error('Error modifying course:', error);
      toast.error('Failed to modify course');
    }
  };

  const toggleOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };
  const toggleTeachers = () => {
    setShowTeachers((prevShowOptions) => !prevShowOptions);
  };
  const handleDeleteImage = () => {
    setImage(null);
    if (data) {
      data.backgroundImage = '';
    }
  };

  useEffect(() => {
    if (data) {
      setActive(data.active || false);
      setAge(data.age || '');
      setDesc(data.desc || '');
      setCourseId(data.courseId || '');
      setLang(data.lang || '');
      setLessonNum(data.lessonNum || 0);
      setName(data.name || '');
      setNotes(data.notes || '');
      setOrder(data.order || 0);
      setRating(data.rating || 0);
      setSections(data.sections || '');
      setSummary(data.summary || '');
      setImage(data.backgroundImage || null);
      setSelectedInterests(data.interestListIds || []);
    }
  }, [data]);
  // useEffect(() => {
  //   if (selectedFile) {
  //     handleFileChange({ target: { files: [selectedFile] } });
  //   }
  // }, [selectedFile]);
  useEffect(() => {
    getUsersCourse();
    getOneCourse();
    getOneUsersCourse();
    getOneInterestCourse();
  }, []);

  const handleCheckboxChange = (interestId) => {
    setSelectedInterests((prevState) => {
      if (prevState.includes(interestId)) {
        return prevState.filter((id) => id !== interestId);
      } else {
        return [...prevState, interestId];
      }
    });
  };
  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers: any) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };
  const handleSearch = async () => {
    try {
      setIsloading(true);

      let queryParams; // Define queryParams variable to store query parameters

      if (/^[+]?[0-9]+$/.test(searchQuery)) {
        // Check if the input is a phone number (only numbers and/or '+')
        queryParams = [['phoneNumber', '>=', searchQuery + '~']];
      } else {
        // Search in name field
        queryParams = [['name', '>=', searchQuery + '~']];
      }

      const res = await axiosInstance.get('/consultants', {
        params: {
          queries: queryParams // Pass queryParams as the value for the 'queries' parameter
        }
      });

      console.log('Users=====>', res.data.result.data);
      setUsers(res.data.result.data);
    } catch (error) {
      console.log('Error fetching users:', error);
      // Handle error
    } finally {
      setIsloading(false);
    }
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  return (
   <>
    {
    isloadingSubmit ? (
      <Spinner/>
    ):
    (
      <div
      className="flex justify-between flex-col items-start p-0 !overflow-x-hidden"
      style={{
        width: `${Responsive.w(1560, width)}px`,
        marginTop: `${Responsive.sp(24, width)}px`,
        marginRight: `${Responsive.sp(23, width)}px`,
        marginBottom: `${Responsive.sp(39, width)}px`,
        marginLeft: `${Responsive.sp(25, width)}px`
      }}
    >
      <div
        className="flex items-center justify-between mb-4"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        <div
          className="relative flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
          style={{
            width: `${Responsive.w(430, width)}px`,
            // height: `${Responsive.h(90, height)}px`
          }}
        >
          <div
            onClick={toggleTeachers}
            className={` flex items-center !text-[${dashColor}]${
              currentDashboard === 'jeras' ? 'bg-[#f5f0ff]' : 'bg-[#ffebeb]'
            } outline-none cursor-pointer justify-between ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[${dashColor}]  `}
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(56, height)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              color: `${dashColor}`
            }}
          >
            <span
              className=" text-left"
              style={{
                // height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              {currentDashboard === 'jeras'
                ? useIntl().formatMessage({
                    id: 'addTeachers',
                    defaultMessage: 'Add Teachers'
                  })
                : useIntl().formatMessage({
                    id: 'addcoach',
                    defaultMessage: 'Add Coach'
                  })}
            </span>
            <svg
              className={
                showtTeachers
                  ? '  transform rotate-180 !text-[#7b6c96]'
                  : ' transform rotate-0 !text-[#7b6c96]'
              }
              width="20"
              height="20"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          {showtTeachers && (
            <div
              className="absolute top-[75%] left-0 rounded-[8px] overflow-y-auto overflow-x-hidden z-10 bg-[#fff]  p-2 flex flex-col justify-start items-stretch flex-grow "
              style={{
                width: `${Responsive.w(240, width)}px`,
                height: `${Responsive.h(400, height)}px`,
                gap: `${Responsive.sp(12, width)}px`,
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
              }}
            >
              <div
                className=" bg-[#f9fafb] h-[37px] inline-flex items-center  py-2  px-4  border border-neutral-200"
                style={{
                  width: `${Responsive.w(200, width)}px`,
                  height: `${Responsive.h(37, height)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              >
                <span className="shrink-0 inline text-neutral-500">
                  <Icon
                    icon="ri:search-line"
                    style={{
                      width: `${Responsive.w(13.3, width)}px`,
                      height: `${Responsive.h(13.3, height)}px`
                    }}
                  />
                </span>

                <input
                  type="text"
                  className="form-input !bg-transparent text-[14px] font-[Montserrat] border-none py-0"
                  style={{
                    width: `${Responsive.w(140, width)}px`,
                    height: `${Responsive.h(21, height)}px`,
                    fontSize: `${Responsive.sp(14, width)}px`
                  }}
                  placeholder={useIntl().formatMessage({
                    id: 'Search',
                    defaultMessage: 'Search Teacher'
                  })}
                  autoComplete="off"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && searchQuery.trim() !== '') {
                      handleSearch(); // Call handleSearch function when Enter key is pressed
                    }
                  }}
                />
              </div>
              {isloading ? (
                <div>
                  <Spinner />
                </div>
              ) : (
                <>
                  {users?.map((user: any, index: number) => (
                    <label
                      key={index}
                      className="flex  justify-between items-center p-0   cursor-pointer"
                      style={{
                        height: `${Responsive.h(16, height)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {user && (
                        <>
                          <span
                            className="text-left text-[#111928] font-medium"
                            style={{
                              // height: `${Responsive.h(14, height)}px`,
                              fontSize: `${Responsive.sp(14, width)}px`
                            }}
                          >
                            {/* if  */}
                            {user?.name}
                          </span>
                          <input
                            className="checked:!bg-[#7b6c96] "
                            type="checkbox"
                            value={user?.uid}
                            checked={selectedUsers?.includes(user?.uid)}
                            onChange={() => handleUserSelection(user?.uid)}
                          />
                        </>
                      )}
                    </label>
                  ))}
                </>
              )}
            </div>
          )}
        </div>

        <div
          className="flex justify-between items-center rounded-xl mr-2"
          style={{
            width: `${Responsive.w(200, width)}px`,
            gap: `${Responsive.sp(20, width)}px`
          }}
        >
          <label
            className={`text-[${dashColor}] font-[Montserrat] font-semibold text-left`}
            style={{
              width: `${Responsive.w(64, width)}px`,
              height: `${Responsive.h(29, height)}px`,
              fontSize: `${Responsive.sp(24, width)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'active',
              defaultMessage: 'Active'
            })}
          </label>
          <ToggleSlider
            onClick={() => setActive(!active)}
            checked={active}
          />
        </div>
      </div>

      <div
        className="flex items-center justify-center"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        {/* image */}
        <div className="flex items-start justify-start">
          {data?.backgroundImage ? (
            <div
              className="relative cursor-pointer sm:w-auto h-[56px] inline-flex items-center rounded-lg"
              style={{
                width: `${Responsive.w(619, width)}px`,
                height: `${Responsive.h(391, height)}px`
              }}
            >
              <label
                htmlFor="file-upload"
                className=" inline-block cursor-pointer"
              >
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  // ref={fileInputRef}
                />
                <div className="w-8 h-8 flex items-center justify-center bg-[#f7f7f7] rounded-full left-12 top-2 absolute  p-2 hover:bg-slate-600">
                  <img
                    src={img_2}
                    srcSet={`${img_2_2}, ${img_2_3}`}
                    className="object-contain p-0 w-[6.8px] h-[7.8px] "
                    alt="Description of the image"
                  />
                </div>
              </label>
              <div
                className="w-8 h-8 flex items-center justify-center bg-[#f7f7f7] rounded-full left-2 top-2 absolute p-2 hover:bg-slate-600"
                onClick={handleDeleteImage}
              >
                <img
                  src={img_1}
                  srcSet={`${img_1_2}, ${img_1_3}`}
                  className="object-contain p-0 w-[6.8px] h-[7.8px]"
                  alt="Description of the image"
                />
              </div>
              <img
                src={image || data.backgroundImage}
                alt="Selected"
                style={{
                  width: `${Responsive.w(619, width)}px`,
                  height: `${Responsive.h(391, height)}px`
                }}
              />
            </div>
          ) : (
            <>
              {image ? (
                <div
                  className="relative cursor-pointer sm:w-auto h-[56px] inline-flex items-center rounded-lg"
                  style={{
                    width: `${Responsive.w(619, width)}px`,
                    height: `${Responsive.h(391, height)}px`
                  }}
                >
                  <label
                    htmlFor="file-upload"
                    className=" inline-block cursor-pointer"
                  >
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleFileChange}
                      className="hidden"
                      // ref={fileInputRef}
                    />
                    <div className="w-8 h-8 flex items-center justify-center bg-[#fff] rounded-full left-12 top-2 absolute  p-2 hover:bg-slate-600">
                      <img
                        src={img_2}
                        srcSet={`${img_2_2}, ${img_2_3}`}
                        className="object-contain p-0 w-[6.8px] h-[7.8px] "
                        alt="Description of the image"
                      />
                    </div>
                  </label>
                  <div
                    className="w-8 h-8 flex items-center justify-center bg-[#fff] rounded-full left-2 top-2 absolute p-2 hover:bg-slate-600"
                    onClick={handleDeleteImage}
                  >
                    <img
                      src={img_1}
                      srcSet={`${img_1_2}, ${img_1_3}`}
                      className="object-contain p-0 w-[6.8px] h-[7.8px]"
                      alt="Description of the image"
                    />
                  </div>
                  <img
                    src={image}
                    alt="Selected"
                    style={{
                      width: `${Responsive.w(619, width)}px`,
                      height: `${Responsive.h(391, height)}px`
                    }}
                  />
                </div>
              ) : (
                <label
                  htmlFor="fileInput"
                  className=" cursor-pointer"
                >
                  <input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <div
                    className="cursor-pointer sm:w-auto h-[56px] inline-flex items-center rounded-lg"
                    style={{
                      width: `${Responsive.w(619, width)}px`,
                      height: `${Responsive.h(391, height)}px`
                    }}
                  >
                    <p
                      className={`shrink-0 text-[14px] bg-[#f7f7f7] font-medium font-[Montserrat] text-[${dashColor}] flex items-center justify-center gap-[16px]`}
                      style={{
                        width: `${Responsive.w(619, width)}px`,
                        height: `${Responsive.h(391, height)}px`,
                        fontSize: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      <Icon
                        icon={'tabler:plus'}
                        style={{
                          color: dashColor,
                          width: '24px',
                          height: '24px'
                        }}
                      />
                      <span>
                        {useIntl().formatMessage({
                          id: 'addImage',
                          defaultMessage: 'Add Image'
                        })}
                      </span>
                    </p>
                  </div>
                </label>
              )}
            </>
          )}
        </div>

        <form
          className="flex items-center justify-center flex-col"
          onSubmit={handleSubmit}
          style={{
            width: `${Responsive.w(885, width)}px`
          }}
        >
          <div
            className="grid grid-cols-2 gap-3 "
            style={{
              width: `${Responsive.w(885, width)}px`
            }}
          >
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                // height: `${Responsive.h(90, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'courseName',
                  defaultMessage: 'Course Name'
                })}
              </label>

              <input
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(50, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter Course name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
              />
            </div>

            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                // height: `${Responsive.h(90, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'age',
                  defaultMessage: 'Age'
                })}
              </label>

              <input
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(50, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                name="age"
              />
            </div>
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                // height: `${Responsive.h(90, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'numberOfLessons',
                  defaultMessage: 'Number Of Lessons'
                })}
              </label>

              <input
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(50, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="number"
                placeholder="Enter Number"
                value={lessonNum}
                onChange={(e) => setLessonNum(Number(e.target.value))}
                name="lessonNum"
              />
            </div>
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                // height: `${Responsive.h(90, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'summary',
                  defaultMessage: 'Summary'
                })}
              </label>

              <input
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(50, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter Summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                name="fullNameEn"
              />
            </div>

            <div
              className="relative flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                // height: `${Responsive.h(90, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'category',
                  defaultMessage: 'Category'
                })}
              </label>

              <div
                onClick={toggleOptions}
                className=" flex items-center outline-none cursor-pointer justify-between   border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(50, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`,
                  gap: `${Responsive.sp(16, width)}px`,
                  padding: `${Responsive.sp(16, width)}px`,


                }}
                // placeholder="Select Category"
              >
                <span
                  className="text-[#afafaf] text-left"
                  style={{
                    // height: `${Responsive.h(20, height)}px`,
                    fontSize: `${Responsive.sp(16, width)}px`
                  }}
                >
                  {interest.length > 0 && selectedInterests.length > 0 ? (
                    interest.map(
                      (interest: any, index) =>
                        selectedInterests.includes(interest.interestId) && (
                          <span
                            key={index}
                            className="text-[#afafaf] text-left"
                            style={{
                              fontSize: `${Responsive.sp(16, width)}px`
                            }}
                          >
                            {interest?.enName}
                            {index !== interest.length - 1 && ', '}
                          </span>
                        )
                    )
                  ) : (
                    <span className="text-[#1e1e1e] text-left">Select Category</span>
                  )}
                </span>
                <svg
                  className={
                    showOptions
                      ? '  transform rotate-180 !text-["#7b6c96"]'
                      : ' transform rotate-0 !text-["#7b6c96"]'
                  }
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              {showOptions && (
                <div
                  className="absolute top-[115%] right-[11%] z-10 bg-[#fff]   flex flex-col justify-start overflow-y-auto items-stretch flex-grow "
                  style={{
                    width: `${Responsive.w(384, width)}px`,
                    height: `${Responsive.h(300, height)}px`,
                    gap: `${Responsive.sp(12, width)}px`,
                    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
                    borderRadius: `${Responsive.sp(8, width)}px`,
                    padding: `${Responsive.sp(16, width)}px`,
                  }}
                >
                  {isloadingInter ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {interest.map((option: any, index: number) => (
                        <label
                          key={index}
                          className="flex  justify-between items-center p-0   cursor-pointer"
                          style={{
                            height: `${Responsive.h(16, height)}px`,
                            fontSize: `${Responsive.sp(14, width)}px`
                          }}
                        >
                          <span
                            className="text-left text-[#111928] font-medium"
                            style={{
                              height: `${Responsive.h(14, height)}px`,
                              fontSize: `${Responsive.sp(14, width)}px`
                            }}
                          >
                            {option?.enName}
                          </span>
                          <input
                            className="checked:!bg-[#7b6c96] mt-1"
                            type="checkbox"
                            value={option.interestId}
                            checked={selectedInterests.includes(option.interestId)}
                            onChange={() => handleCheckboxChange(option.interestId)}
                          />
                        </label>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>

            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                // height: `${Responsive.h(90, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'Language',
                  defaultMessage: 'Language'
                })}
              </label>

              <select
                name="lang"
                id="lang"
                value={lang}
                onChange={(e) => setLang(e.target.value)}
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(50, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              >
                <optgroup>
                  <option value="">
                    {useIntl().formatMessage({
                      id: 'selectLanguage',
                      defaultMessage: 'Select language'
                    })}
                  </option>
                  <option value="en">
                    {useIntl().formatMessage({
                      id: 'en',
                      defaultMessage: 'English'
                    })}
                  </option>
                  <option value="ar">
                    {useIntl().formatMessage({
                      id: 'ar',
                      defaultMessage: 'العربية'
                    })}
                  </option>
                  <option value="fr">
                    {useIntl().formatMessage({
                      id: 'fr',
                      defaultMessage: 'français'
                    })}
                  </option>
                  <option value="id">
                    {useIntl().formatMessage({
                      id: 'id',
                      defaultMessage: 'Bahasa Indonesia'
                    })}
                  </option>
                </optgroup>
              </select>
            </div>
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                height: `${Responsive.h(151, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'reasonsToJoin',
                  defaultMessage: 'Reasons To Join This Course'
                })}
              </label>

              <input
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(111, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter Reasons To Join This Course"
                value={sections}
                onChange={(e) => setSections(e.target.value)}
                name="sections"
              />
            </div>
            <div
              className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white "
              style={{
                width: `${Responsive.w(430, width)}px`,
                height: `${Responsive.h(151, height)}px`
              }}
            >
              <label
                className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
                style={{
                  width: `${Responsive.w(122, width)}px`,
                  height: `${Responsive.h(24, height)}px`,
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'notes',
                  defaultMessage: 'Notes'
                })}
              </label>

              <input
                className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
                style={{
                  width: `${Responsive.w(384, width)}px`,
                  height: `${Responsive.h(111, height)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                type="text"
                placeholder="Enter Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                name="notes"
              />
            </div>
          </div>

          <div
            className="flex flex-col  items-start justify-start gap-[16px]  p-0 bg-white  mt-2"
            style={{
              width: `${Responsive.w(885, width)}px`,
              height: `${Responsive.h(170, height)}px`
            }}
          >
            <label
              className={`font-[Montserrat] flex-grow-0 nowrap font-semibold text-[${dashColor}]`}
              style={{
                width: `${Responsive.w(122, width)}px`,
                height: `${Responsive.h(24, height)}px`,
                fontSize: `${Responsive.sp(20, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'description',
                defaultMessage: 'description'
              })}
            </label>

            <input
              className="flex items-center outline-none justify-start ap-[10px] py-0 px-[16px] rounded-[8px] border-solid border-[1px] border-[#939393]  "
              style={{
                width: `${Responsive.w(840, width)}px`,
                height: `${Responsive.h(130, height)}px`,
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              type="text"
              placeholder="Enter description"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              name="desc"
            />
          </div>
          {
            <button
              disabled={Permission?.some(
                (item) => (item?.permission?.includes('Delete') || item?.permission?.includes('View')) ? false : true
              )}
              type="submit"
              className={` bg-[${dashColor}] flex mr-auto mt-5  items-center justify-center text-white font-bold rounded-[8px] py-[5px] px-[16px] text-center `}
              style={{
                width: `${Responsive.w(240, width)}px`
                // height: `${Responsive.h(56, height)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'save',
                defaultMessage: 'save'
              })}
            </button>
          }
        </form>
      </div>
    </div>
    )
   }   
   </>
  );
}
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
