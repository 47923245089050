import moment from 'moment';
import { DateType } from 'src/types/types';
type ResultType = { from: number; to: number };
export function getDates(type: DateType): ResultType {
  let result = {};
  let from: Date;
  let to: Date;
  const newMoment = moment.utc();

  switch (type) {
    case 'today':
      from = newMoment.clone().startOf('day').toDate();
      to = newMoment.clone().endOf('day').toDate();
      break;
    case 'last-day':
      from = newMoment.clone().add(-1, 'day').startOf('day').toDate();
      to = newMoment.clone().add(-1, 'day').endOf('day').toDate();
      break;
    case 'this-week':
      from = newMoment.clone().add(0, 'week').startOf('week').add(-1, 'day').toDate();
      to = newMoment.clone().add(0, 'week').endOf('week').add(-1, 'day').toDate();
      break;
    case 'last-week':
      from = newMoment.clone().add(-1, 'week').startOf('week').add(-1, 'day').toDate();
      to = newMoment.clone().add(-1, 'week').endOf('week').add(-1, 'day').toDate();
      break;
    case 'last-month':
      from = newMoment.clone().add(-1, 'month').startOf('month').toDate();
      to = newMoment.clone().add(-1, 'month').endOf('month').toDate();
      break;
    case 'this-month':
      from = newMoment.clone().add(0, 'month').startOf('month').toDate();
      to = newMoment.clone().add(0, 'month').endOf('month').toDate();
      break;
    case 'last-3-month':
      from = newMoment.clone().add(-3, 'months').startOf('month').toDate();
      to = newMoment.clone().add(0, 'month').endOf('month').toDate();
      break;
    case 'last-6-months':
      from = newMoment.clone().add(-6, 'months').startOf('month').toDate();
      to = newMoment.clone().add(0, 'month').endOf('month').toDate();
      break;
    case 'this-year':
      from = newMoment.clone().add(0, 'year').startOf('year').toDate();
      to = newMoment.clone().add(0, 'year').endOf('year').toDate();
      break;
    case 'last-year':
      from = newMoment.clone().subtract(1, 'year').startOf('year').toDate();
      to = newMoment.clone().subtract(1, 'year').endOf('year').toDate();
      break;
    default:
      from = newMoment.clone().startOf('day').toDate();
      to = newMoment.clone().endOf('day').toDate();
      break;
  }

  result = { from: from.getTime(), to: to.getTime() };
  return result as ResultType;
}

export function formatDateFromStringRetDate(dateString, options?) {
  // Parse the date string
  const parts = dateString.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
  if (!parts) return 'Invalid Date';

  // Create a new Date object
  const year = +parts[1];
  const month = +parts[2] - 1; // Months are 0-indexed in JavaScript
  const day = +parts[3];
  const hour = +parts[4];
  const minute = +parts[5];
  const second = +parts[6];
  const date = new Date(year, month, day, hour, minute, second);
  return date;
}

export function formatDateFromStringRetString(dateString, options?) {
  const date = formatDateFromStringRetDate(dateString);
  if (date === 'Invalid Date') {
    return 'Invalid Date';
  }
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function formatTimestampRetDate(timestamp, options?) {
  // Validate the input
  if (!timestamp || isNaN(new Date(timestamp).getTime())) {
    return 'Invalid Date';
  }
  // Check if the timestamp is in seconds (typically 10 digits)
  if (timestamp?.toString().length === 10) {
    timestamp *= 1000; // Convert to milliseconds
  }

  const date = new Date(timestamp);
  return date;
}

export function formatTimestampRetString(timestamp, options?) {
  const date = formatTimestampRetDate(timestamp);
  if (date === 'Invalid Date') {
    return 'Invalid Date';
  }
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
