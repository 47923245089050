import UsersTable from './UsersTable';
import FilteringSideModal from './FilteringSideModal';
import CustomizationSection from './TableCustomizeFilterModal';
import { ModalProvider } from './modalContext';

export default function index() {
  return (
    <ModalProvider>
      <div className="p-8 pt-12 space-y-4">
        <CustomizationSection />
        <UsersTable />
        <FilteringSideModal />
      </div>
    </ModalProvider>
  );
}
