import { Icon } from '@iconify/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Table from 'src/components/shared/tables';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import Select from 'react-select';
import Modal from 'src/components/shared/Modal';
import axiosInstance from 'src/helper/AxiosInstance';
import axios from 'axios';
import { toast } from 'react-toastify';
import { routes } from 'src/layouts/SideBar';

export default function index() {
  const { width, height } = useWindowDimensions();

  return (
    <div className="flex justify-center min-h-screen p-0 bg-[#fafafa] w-full ">
      <div
        className="flex flex-col items-stretch justify-start p-0 bg-white rounded-lg"
        style={{
          width: `${Responsive.w(1864, width)}px`,
          gap: `${Responsive.sp(24, width)}px`,
          marginTop: `${Responsive.sp(24, width)}px`,
          marginBottom: `${Responsive.sp(160, width)}px`,
          marginRight: `${Responsive.sp(24, width)}px`,
          marginLeft: `${Responsive.sp(32, width)}px`
        }}
      >
        <Permissions />
        <Admins />
      </div>
    </div>
  );
}

function Permissions() {
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormRoleOpen, setIsFormRoleOpen] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState<any[]>([]);
  const [data, setData] = useState<any>([]);
  const [currentData, setCurrentData] = useState<any>();
  const navigate = useNavigate();

  const permissionData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.focalpoint-apps.com/permissions');
      // test
      // const response = await axios.get('http://localhost:3004/permissions');
      return setData(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  console.log('data ::', data);

  useEffect(() => {
    permissionData();
  }, []);

  const openFormModal = (item) => {
    setIsFormRoleOpen(true);
    setCurrentData(item);
  };
  return (
    <>
      {/* frist table */}
      <div
        style={{
          width: `${Responsive.w(1800, width)}px`,
          gap: `${Responsive.sp(24, width)}px`,
          marginTop: `${Responsive.sp(24, width)}px`,
          // marginBottom: `${Responsive.sp(160, width)}px`,
          marginRight: `${Responsive.sp(24, width)}px`,
          marginLeft: `${Responsive.sp(32, width)}px`
        }}
      >
        <Table
          loading={loading}
          timeAble={false}
          customizeAble={false}
          notCountable={true}
          permissionsAble={true}
          searchAble={false}
          buttonAble={false}
          PromoAble={false}
          text={'Add New Role'}
          CustomizeTable={() => <></>}
          TimeTable={() => <></>}
          RenderBody={({ getRowColor }) => (
            <>
              {/*Super Admin  */}
              {data.map((item: any, index) => (
                <tr
                  className=" hover:bg-[#EAE0EB] "
                  style={{ backgroundColor: getRowColor(index) }}
                >
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {index + 1}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.modulePermission}
                  </td>
                  <td className="text-left nowrap  text-[14px]   font-medium text-[#515151] self-stretch w-auto">
                    <div className="relative  w-auto ">
                      {item?.Pages.map((item: any) => (item == 'All' ? item : ` ${item} / `))}
                    </div>
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.permission?.includes('View') ? (
                      <input
                        type="checkbox"
                        checked
                        readOnly
                        className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
                      />
                    ) : (
                      'NULL'
                    )}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.permission?.includes('Edit') ? (
                      <input
                        type="checkbox"
                        checked
                        readOnly
                        className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
                      />
                    ) : (
                      'NULL'
                    )}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.permission?.includes('Delete') ? (
                      <input
                        type="checkbox"
                        checked
                        readOnly
                        className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
                      />
                    ) : (
                      'NULL'
                    )}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch ">
                    <div className="flex justify-center items-center">
                      <div className="relative  ">
                        <input
                          type="checkbox"
                          checked={item?.active}
                          className="sr-only z-10"
                          readOnly
                        />
                        <div
                          className={`box block h-[21px] w-[40px] rounded-full ${
                            item?.active ? 'bg-[#5ade5c]' : 'bg-gray-200'
                          }`}
                        ></div>
                        <div
                          className={`absolute left-1 top-[2px] flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                            item?.active ? 'translate-x-full' : ''
                          }`}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch cursor-pointer">
                    <div
                      className="flex justify-center"
                      onClick={() => openFormModal(item)}
                    >
                      <Icon
                        icon={'iconamoon:edit-light'}
                        className="text-[#2980BA] w-5 h-5 flex justify-center"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
          RenderHead={() => (
            <>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                #
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'modulePermission',
                  defaultMessage: 'Module Permission'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'pages',
                  defaultMessage: 'Pages'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'view',
                  defaultMessage: 'View'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'edit',
                  defaultMessage: 'Edit'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'delete',
                  defaultMessage: 'Delete'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'active',
                  defaultMessage: 'Active'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'edit',
                  defaultMessage: 'Edit'
                })}
              </th>
            </>
          )}
          onPromoClick={() => {
            openFormModal(null);
          }}
        />
      </div>

      {
        <PermissionRole
          isFormRoleOpen={isFormRoleOpen}
          setIsFormRoleOpen={setIsFormRoleOpen}
          data={currentData}
          setLoading={setLoading}
          permissionData={permissionData}
        />
      }
    </>
  );
}

function PermissionRole({ isFormRoleOpen, setIsFormRoleOpen, data, setLoading, permissionData }) {
  const { width, height } = useWindowDimensions();
  const [selectedPages, setSelectedPages] = useState<any[]>(data?.Pages || []);
  const [permissionPages, setPermissionPages] = useState<any[]>(data?.permission || []);
  const [nameRole, setNameRole] = useState<any>(data?.modulePermission || '');
  const [selectedPagesError, setSelectedPagesError] = useState<any>('');
  const [nameRoleError, setNameRoleError] = useState<any>('');
  const [permissionPagesError, setPermissionPagesError] = useState<any>('');
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isModelOpenPermission, setIsModelOpenPermission] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const PagesOptions = useMemo(() => {
    // Extract titles from routes and subRoutes
    const extractedOptions = routes.flatMap((route) => {
      // if (route.subRoutes) {
      //   return route.subRoutes.map((subRoute) => subRoute.title);
      // }
      return route.title;
    });

    // Add "All" option at the beginning of the array
    return ['All', ...extractedOptions];
  }, []);

  console.log('PagesOptions', PagesOptions);

  // const PagesOptions: any = [
  //   'All',
  //   'Dashboard',
  //   'Statistics',
  //   'Old Statistics',
  //   'Users',
  //   'Orders',
  //   'Appointments',
  //   'Promo Code',
  //   'Academy',
  //   'Courses',
  //   'Summary',
  //   'Notifications',
  //   'Job Advertisement',
  //   'Suggestions',
  //   'Accountant',
  //   'Survey Results',
  //   'AD Campaigns',
  //   'App Settings'
  // ];

  console.log('RoleData', data);

  useEffect(() => {
    if (data) {
      setNameRole(data?.modulePermission || '');
      setSelectedPages(data?.Pages || []);
      setPermissionPages(data?.permission || []);
      setIsChecked(data?.active);
    } else {
      setIsChecked(true);
      setSelectedPages([]);
      setPermissionPages([]);
      setNameRole('');
    }
  }, [isFormRoleOpen, data]);
  const validateForm = () => {
    let isValid = true;
    if (!nameRole) {
      const errMsg = 'Name is required';
      setNameRoleError(errMsg);
      isValid = false;
    } else {
      setNameRoleError('');
    }
    if (selectedPages.length === 0) {
      const errMsg = 'Pages is required';
      setSelectedPagesError(errMsg);
      isValid = false;
    } else {
      setSelectedPagesError('');
    }
    if (permissionPages.length == 0) {
      const errMsg = ' Permission is required';
      setPermissionPagesError(errMsg);
      isValid = false;
    } else {
      setPermissionPagesError('');
    }
    return isValid;
  };
  const permissionOption: any = ['View', 'Edit', 'Delete'];
  const handleSelectPages = (value) => {
    if (value === 'All') {
      // إذا تم اختيار "All"، يتم إلغاء تحديد كل الخيارات الأخرى
      setSelectedPages(['All']);
    } else {
      setSelectedPages((prevSelected) => {
        // إذا كانت "All" محددة بالفعل، يتم إلغاء تحديدها واختيار القيمة الجديدة فقط
        if (prevSelected.includes('All')) {
          return [value];
        }
        // إضافة أو إزالة الخيار حسب ما إذا كان محددًا بالفعل
        if (prevSelected.includes(value)) {
          return prevSelected.filter((item) => item !== value);
        } else {
          return [...prevSelected, value];
        }
      });
    }
  };
  const handlePermissionPages = (value) => {
    setPermissionPages((prevSelected) => {
      // إذا كانت "Edit" أو "Delete" مختارة، يجب اختيار "View" أيضًا
      if (value === 'Edit' || value === 'Delete') {
        if (!prevSelected.includes('View')) {
          return [...prevSelected, 'View', value];
        }
      }

      // إذا تم تحديد "View"، اتركه يعمل بشكل طبيعي
      if (value === 'View') {
        // إذا كانت "View" محددة بالفعل وتم إلغاء تحديدها، أزل الخيارات الأخرى
        if (prevSelected.includes('View')) {
          return prevSelected.filter(
            (item) => item !== 'View' && item !== 'Edit' && item !== 'Delete'
          );
        }
      }

      // إذا كان الخيار محددًا بالفعل، قم بإزالته
      if (prevSelected.includes(value)) {
        return prevSelected.filter((item) => item !== value);
      }

      // إضافة الخيار الجديد
      return [...prevSelected, value];
    });
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const reset = () => {
    setIsFormRoleOpen(false);
    setIsModelOpen(false);
    setIsModelOpenPermission(false);
    setSelectedPages([]);
    setPermissionPages([]);
    setNameRole('');
    setIsChecked(false);
    setSelectedPagesError('');
    setNameRoleError('');
    setPermissionPagesError('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!validateForm()) return;
      setLoading(true);
      const reqBody = {
        modulePermission: nameRole,
        Pages: selectedPages,
        permission: permissionPages,
        active: isChecked
      };

      if (!data) {
        await axios.post('https://api.focalpoint-apps.com/permissions', reqBody);
        // tset
        // await axios.post('http://localhost:3004/permissions', reqBody);
        toast.success('Role has been created successfully ✔');
      } else {
        await axios.patch(`https://api.focalpoint-apps.com/permissions/${data?.permissionId}`, reqBody);
        // test
        // await axios.patch(`http://localhost:3004/permissions/${data?.permissionId}`, reqBody);
        toast.success('Role has been modified successfully ✔');
      }

      reset();
      window.location.reload();
      setLoading(false);
    } catch (err) {
      toast.error('Something went wrong');
      console.log('error', err);
    }
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`https://api.focalpoint-apps.com/permissions/${data?.permissionId}`);
      // test
      // await axios.delete(`http://localhost:3004/permissions/${data?.permissionId}`);
      toast.success('Role has been deleted successfully ✔');

      reset();
      window.location.reload();
      setLoading(false);
    } catch (err) {
      toast.error('Something went wrong');
      console.log('error', err);
    }
  };
  return (
    <>
      <Modal
        visible={isFormRoleOpen}
        handleClose={reset}
        title={data ? 'Edit Role' : 'Add New Role'}
      >
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-[24px] justify-start items-start bg-[#fff] flex-grow-0"
        >
          <div className="space-y-4">
            {/* Name Role */}
            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`
                // height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="nameRole"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  //   color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'nameRole',
                  defaultMessage: 'Name Role'
                })}{' '}
              </label>
              <input
                id="nameRole"
                type="text"
                name="nameRole"
                value={nameRole}
                onChange={(e) => setNameRole(e.target.value)}
                className="flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter Name Role"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              />
            </div>
            {!nameRole && nameRoleError && <p className="text-red-500">{nameRoleError}</p>}
            {/* Pages */}
            <div
              className="relative flex flex-row justify-between items-center p-0 flex-grow-0  h-auto"
              style={{
                width: `${Responsive.w(812, width)}px`
              }}
            >
              <label
                htmlFor="pages"
                className="nowrap font-[Montserrat] font-semibold text-left nowrap"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'pages',
                  defaultMessage: 'Pages'
                })}{' '}
              </label>
              <div
                className="relative  flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] cursor-pointer"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                onClick={() => setIsModelOpen(!isModelOpen)}
              >
                {selectedPages.length === 0 ? (
                  <p className="text-[#939393] flex  justify-start items-center my-auto w-full h-auto">
                    Select Pages
                  </p>
                ) : (
                  <p className=" flex  justify-start items-center my-auto w-full h-auto flex-grow">
                    {selectedPages.join(' / ')}
                  </p>
                )}

                <Icon
                  className="absolute right-3 w-5 h-5 transition-all duration-500 ease-in-out"
                  icon={
                    isModelOpen ? 'fluent:chevron-down-16-regular' : 'fluent:chevron-up-16-regular'
                  }
                  color={'#2980Ab'}
                />
              </div>
              {isModelOpen && (
                <div
                  className="w-full h-auto bg-white absolute top-7 left-0 p-4 border-[1px] border-solid border-[#939393] z-50"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`,
                    left: '34.5%',
                    top: '110%',
                    boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                  }}
                >
                  {PagesOptions.map((option, index) => (
                    <div
                      className="flex justify-start items-center my-auto w-full h-full hover:bg-slate-200 cursor-pointer"
                      key={index}
                      onClick={() => handleSelectPages(option)}
                    >
                      <p className="text-black flex justify-start items-center my-auto w-full h-full">
                        {option}
                      </p>
                      <input
                        type="checkbox"
                        checked={selectedPages.includes(option)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {selectedPages.length === 0 && selectedPagesError && (
              <p className="text-red-500">{selectedPagesError}</p>
            )}
            {/* Permission */}
            <div
              className="relative flex flex-row justify-between items-center p-0 flex-grow-0  h-auto"
              style={{
                width: `${Responsive.w(812, width)}px`
              }}
            >
              <label
                htmlFor="Permission"
                className="nowrap font-[Montserrat] font-semibold text-left nowrap"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'permission',
                  defaultMessage: 'Permission'
                })}{' '}
              </label>
              <div
                className="relative  flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] cursor-pointer"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                onClick={() => setIsModelOpenPermission(!isModelOpenPermission)}
              >
                {permissionPages.length === 0 ? (
                  <p className="text-[#939393] flex  justify-start items-center my-auto w-full h-auto">
                    Select Permission
                  </p>
                ) : (
                  <p className=" flex  justify-start items-center my-auto w-full h-auto flex-grow">
                    {permissionPages.join(' / ')}
                  </p>
                )}

                <Icon
                  className="absolute right-3 w-5 h-5 transition-all duration-500 ease-in-out"
                  icon={
                    isModelOpenPermission
                      ? 'fluent:chevron-down-16-regular'
                      : 'fluent:chevron-up-16-regular'
                  }
                  color={'#2980Ab'}
                />
              </div>
              {isModelOpenPermission && (
                <div
                  className="w-full h-auto bg-white absolute top-7 left-0 p-4 border-[1px] border-solid border-[#939393] z-40"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`,
                    left: '34.5%',
                    top: '110%',
                    boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                  }}
                >
                  {permissionOption.map((option, index) => (
                    <div
                      className="flex justify-start items-center my-auto w-full h-full hover:bg-slate-200 cursor-pointer "
                      key={index}
                      onClick={() => handlePermissionPages(option)}
                    >
                      <p className="text-black flex justify-start items-center my-auto w-full h-full">
                        {option}
                      </p>
                      <input
                        type="checkbox"
                        checked={permissionPages.includes(option)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {permissionPages.length === 0 && permissionPagesError && (
              <p className="text-red-500">{permissionPagesError}</p>
            )}
            {data && (
              <div className="flex justify-between">
                <label
                  htmlFor="type"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    // color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'active',
                    defaultMessage: 'active'
                  })}
                </label>
                <label className="flex cursor-pointer select-none items-center">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="sr-only"
                    />
                    <div
                      className={`box block h-8 w-14 rounded-full ${
                        isChecked ? 'bg-[#5ade5c]' : 'bg-gray-200'
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                        isChecked ? 'translate-x-full' : ''
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center mt-4 w-full">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-[#2980BA] px-4 py-2 text-sm font-medium text-white shadow-sm"
            >
              {data
                ? useIntl().formatMessage({
                    id: 'update',
                    defaultMessage: 'Update'
                  })
                : useIntl().formatMessage({
                    id: 'submit',
                    defaultMessage: 'Submit'
                  })}
            </button>

            {data && (
              <button
                type="button"
                className="flex justify-center items-center rounded-lg gap-[18px] border-solid border-[1px] border-[#f93c65] bg-[#ffdbe3] text-[#f93c65] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
                onClick={handleDelete}
              >
                <Icon
                  className="w-5 h-5"
                  icon="fluent:delete-24-regular"
                  color={'#f93c65'}
                />
                <span>
                  {useIntl().formatMessage({
                    id: 'delete',
                    defaultMessage: 'Delete'
                  })}
                </span>
              </button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
}
function Admins() {
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormAdminOpen, setIsFormAdminOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [currentData, setCurrentData] = useState<any>();
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();

  const permissionData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.focalpoint-apps.com/admins', {
      //   params: { search }
      // });

      // test
      // const response = await axios.get('http://localhost:3004/admins', {
        params: { search }
      });
      return setData(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  console.log('data ::', data);

  useEffect(() => {
    permissionData();
  }, [search]);

  const openFormModal = (item) => {
    setIsFormAdminOpen(true);
    setCurrentData(item);
  };
  return (
    <>
      {/* admin table */}
      <div
        style={{
          width: `${Responsive.w(1800, width)}px`,
          gap: `${Responsive.sp(24, width)}px`,
          // marginTop: `${Responsive.sp(24, width)}px`,
          marginBottom: `${Responsive.sp(160, width)}px`,
          marginRight: `${Responsive.sp(24, width)}px`,
          marginLeft: `${Responsive.sp(32, width)}px`
        }}
      >
        <Table
          loading={loading}
          timeAble={false}
          customizeAble={false}
          notCountable={true}
          permissionsAble={true}
          searchAble={false}
          buttonAble={false}
          PromoAble={false}
          text={'Add New User'}
          CustomizeTable={() => <></>}
          TimeTable={() => <></>}
          RenderBody={({ getRowColor }) => (
            <>
              {/*Super Admin  */}
              {data.map((item: any, index) => (
                <tr
                  className=" hover:bg-[#EAE0EB] "
                  style={{ backgroundColor: getRowColor(index) }}
                >
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {index + 1}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.name}
                  </td>
                  <td className="text-left nowrap  text-[14px]   font-medium text-[#515151] self-stretch w-auto">
                    <div className="relative  w-auto ">{item?.email}</div>
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.password}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.modulePermission}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.Language?.map((e) => `${e} / `)}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.rules.map((e) => `${e} / `)}
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch ">
                    <div className="flex justify-center items-center">
                      <div className="relative  ">
                        <input
                          type="checkbox"
                          checked={item?.active}
                          className="sr-only z-10"
                          readOnly
                        />
                        <div
                          className={`box block h-[21px] w-[40px] rounded-full ${
                            item?.status === 'active' ? 'bg-[#5ade5c]' : 'bg-gray-200'
                          }`}
                        ></div>
                        <div
                          className={`absolute left-1 top-[2px] flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                            item?.status === 'active' ? 'translate-x-full' : ''
                          }`}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch cursor-pointer">
                    <div
                      className="flex justify-center"
                      onClick={() => openFormModal(item)}
                    >
                      <Icon
                        icon={'iconamoon:edit-light'}
                        className="text-[#2980BA] w-5 h-5 flex justify-center"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
          RenderHead={() => (
            <>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                #
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'username',
                  defaultMessage: 'User name'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'userEmail',
                  defaultMessage: 'user Email'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'password',
                  defaultMessage: 'Password'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'modulePermission',
                  defaultMessage: 'Module Permission'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'language',
                  defaultMessage: 'Language'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'apss',
                  defaultMessage: 'Apss'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'active',
                  defaultMessage: 'Active'
                })}
              </th>
              <th
                className="text-center nowrap   text-[14px] font-medium   self-stretch text-white"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: 'rgb(41 128 186)'
                }}
              >
                {useIntl().formatMessage({
                  id: 'edit',
                  defaultMessage: 'Edit'
                })}
              </th>
            </>
          )}
          onPromoClick={() => {
            openFormModal(null);
          }}
          searchProps={{
            onKeyDown(e: any) {
              if (e.key === 'Enter' && e.target.value) {
                setSearch(e.target.value);
              }
            }
          }}
        />
      </div>

      {
        <AdminsPermissions
          isFormAdminOpen={isFormAdminOpen}
          setIsFormAdminOpen={setIsFormAdminOpen}
          data={currentData}
          setLoading={setLoading}
        />
      }
    </>
  );
}
function AdminsPermissions({ isFormAdminOpen, setIsFormAdminOpen, data, setLoading }) {
  const { width, height } = useWindowDimensions();
  const [name, setName] = useState<any>(data?.name || '');
  const [email, setEmail] = useState<any>(data?.email || '');
  const [password, setPassword] = useState<any>(data?.password || '');
  const [permission, setPermission] = useState<any>(data?.modulePermission || '');
  const [permissionId, setPermissionId] = useState<any>(data?.modulePermission || '');
  const [permissionOptions, setPermissionOptions] = useState<any>([]);
  const [language, setLanguage] = useState<any[]>(data?.Language || []);
  const [apps, setApps] = useState<any[]>(data?.rules || []);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isModelOpenLanguage, setIsModelOpenLanguage] = useState(false);
  const [isModelOpenApps, setIsModelOpenApps] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [permissionError, setPermissionError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [appsError, setAppsError] = useState('');

  console.log('Data', data);
  useEffect(() => {
    const id = permissionOptions
      .filter((item) => item.modulePermission === permission)
      .map((item) => item.permissionId)
      .join('');
    setPermissionId(id);
  }, [permission, permissionOptions]);

  console.log('permissionId', permissionId);

  useEffect(() => {
    if (data) {
      setName(data?.name);
      setEmail(data?.email);
      setPassword(data?.password);
      setPermission(data?.modulePermission || '');
      setLanguage(data?.Language || []);
      setApps(data?.rules);
      setIsChecked(data?.status === 'active' ? true : false);
    } else {
      setName('');
      setEmail('');
      setPassword('');
      setPermission('');
      setLanguage([]);
      setApps([]);
      setIsChecked(true);
    }
  }, [isFormAdminOpen, data]);
  const permissionData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.focalpoint-apps.com/permissions');

      // test
      // const response = await axios.get('http://localhost:3004/permissions');
      return setPermissionOptions(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    permissionData();
  }, [isFormAdminOpen]);

  const permissionOption: any = permissionOptions.map((item) => item.modulePermission);
  const languageOption: any = ['English', 'Bahasa Indonesia', 'French', 'Arabic'];
  const appsOption: any = ['focal', 'dream', 'jeras', 'beaut', 'invest '];

  const handlePermission = (value) => {
    setPermission(value);
  };
  const handleLanguageOption = (value) => {
    // no repeat
    if (!language.includes(value)) {
      setLanguage([...language, value]);
    } else {
      setLanguage(language.filter((item) => item !== value));
    }
  };
  const handleAppsOption = (value) => {
    // no repeat
    if (!apps.includes(value)) {
      setApps([...apps, value]);
    } else {
      setApps(apps.filter((item) => item !== value));
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const reset = () => {
    setIsFormAdminOpen(false);
    setIsModelOpen(false);
    setIsModelOpenLanguage(false);
    setIsModelOpenApps(false);
    setApps([]);
    setLanguage([]);
    setPermission('');
    setName('');
    setEmail('');
    setPassword('');
    setIsChecked(false);
    setPermissionId('');
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setPermissionError('');
    setLanguageError('');
    setAppsError('');
  };
  const validateForm = () => {
    let isValid = true;
    if (!name) {
      const errMsg = 'Name is required';
      setNameError(errMsg);
      isValid = false;
    }
    if (!email) {
      const errMsg = 'Email is required';
      setEmailError(errMsg);
      isValid = false;
    }
    if (!password) {
      const errMsg = 'Password is required';
      setPasswordError(errMsg);
      isValid = false;
    }
    if (!permission) {
      const errMsg = 'Permission is required';
      setPermissionError(errMsg);
      isValid = false;
    }
    if (!language.length) {
      const errMsg = 'Language is required';
      setLanguageError(errMsg);
      isValid = false;
    }
    if (!apps.length) {
      const errMsg = 'Apps is required';
      setAppsError(errMsg);
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!validateForm()) return;
      setLoading(true);
      const reqBody = {
        name,
        email,
        password,
        modulePermission: permission,
        Language: language,
        rules: apps,
        permissionId: permissionId,
        status: isChecked ? 'active' : 'inactive'
      };

      if (!data) {
        await axios.post('https://api.focalpoint-apps.com/admins', reqBody);

        // test
        // await axios.post('http://localhost:3004/admins', reqBody);
        toast.success('user has been created successfully ✔');
      } else {
        await axios.patch(`https://api.focalpoint-apps.com/admins/${data?.uid}`, reqBody);

        // test
        // await axios.patch(`http://localhost:3004/admins/${data?.uid}`, reqBody);
        toast.success('user has been updated successfully ✔');
      }

      reset();
      window.location.reload();
      setLoading(false);
    } catch (err) {
      toast.error('Something went wrong');
      console.log('error', err);
    }
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`https://api.focalpoint-apps.com/admins/${data?.uid}`);

      // test
      // await axios.delete(`http://localhost:3004/admins/${data?.uid}`);
      toast.success(' has been deleted successfully ✔');

      reset();
      window.location.reload();
      setLoading(false);
    } catch (err) {
      toast.error('Something went wrong');
      console.log('error', err);
    }
  };
  return (
    <>
      <Modal
        visible={isFormAdminOpen}
        handleClose={reset}
        title={data ? 'Edit User' : 'Add New User'}
      >
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-[24px] justify-start items-start bg-[#fff] flex-grow-0"
        >
          <div className="space-y-4">
            {/* Name  */}
            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`
                // height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="name"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  //   color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'name',
                  defaultMessage: 'Name '
                })}{' '}
              </label>
              <input
                id="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter New Name "
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              />
            </div>
            {!name && nameError && <p className="text-red-500">{nameError}</p>}
            {/* email  */}
            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`
                // height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="email"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  //   color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'email',
                  defaultMessage: 'email '
                })}{' '}
              </label>
              <input
                id="email"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter New Email "
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              />
            </div>
            {!email && emailError && <p className="text-red-500">{emailError}</p>}
            {/* password  */}
            <div
              className="flex flex-row justify-between items-center p-0 flex-grow-0 nowrap"
              style={{
                width: `${Responsive.w(812, width)}px`
                // height: `${Responsive.h(63, height)}px`
              }}
            >
              <label
                htmlFor="password"
                className="font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  //   color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'password',
                  defaultMessage: 'password '
                })}{' '}
              </label>
              <input
                id="password"
                type="text"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholder="Enter New Password "
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
              />
            </div>
            {!password && passwordError && <p className="text-red-500">{passwordError}</p>}
            {/* Permission */}
            <div
              className="relative flex flex-row justify-between items-center p-0 flex-grow-0  h-auto"
              style={{
                width: `${Responsive.w(812, width)}px`
              }}
            >
              <label
                htmlFor="pages"
                className="nowrap font-[Montserrat] font-semibold text-left nowrap"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'permission',
                  defaultMessage: 'Permission'
                })}{' '}
              </label>
              <div
                className="relative  flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] cursor-pointer"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  // height: `${Responsive.sp(56, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                onClick={() => setIsModelOpen(!isModelOpen)}
              >
                {permission === '' ? (
                  <p className="text-[#939393] flex  justify-start items-center my-auto w-full h-auto">
                    Select Permission
                  </p>
                ) : (
                  <p className=" flex  justify-start items-center my-auto w-full h-auto flex-grow">
                    {permission}
                  </p>
                )}

                <Icon
                  className="absolute right-3 w-5 h-5 transition-all duration-500 ease-in-out"
                  icon={
                    isModelOpen ? 'fluent:chevron-down-16-regular' : 'fluent:chevron-up-16-regular'
                  }
                  color={'#2980Ab'}
                />
              </div>
              {isModelOpen && (
                <div
                  className="w-full h-auto bg-white absolute top-7 left-0 p-4 border-[1px] border-solid border-[#939393] z-50"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`,
                    left: '34.5%',
                    top: '110%',
                    boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                  }}
                >
                  {permissionOption.map((option, index) => (
                    <div
                      className="flex justify-start items-center my-auto w-full h-full hover:bg-slate-200 cursor-pointer"
                      key={index}
                      onClick={() => handlePermission(option)}
                    >
                      <p className="text-black flex justify-start items-center my-auto w-full h-full">
                        {option}
                      </p>
                      <input
                        type="checkbox"
                        checked={permission === option}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!permission && permissionError && <p className="text-red-500">{permissionError}</p>}
            {/* Language */}
            <div
              className="relative flex flex-row justify-between items-center p-0 flex-grow-0  h-auto"
              style={{
                width: `${Responsive.w(812, width)}px`
              }}
            >
              <label
                htmlFor="pages"
                className="nowrap font-[Montserrat] font-semibold text-left nowrap"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'language',
                  defaultMessage: 'Language'
                })}{' '}
              </label>
              <div
                className="relative  flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] cursor-pointer"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  // height: `${Responsive.sp(56, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                onClick={() => setIsModelOpenLanguage(!isModelOpenLanguage)}
              >
                {language?.length === 0 ? (
                  <p className="text-[#939393] flex  justify-start items-center my-auto w-full h-auto">
                    Select Language
                  </p>
                ) : (
                  <p className=" flex  justify-start items-center my-auto w-full h-auto flex-grow">
                    {language?.join(' / ')}
                  </p>
                )}

                <Icon
                  className="absolute right-3 w-5 h-5 transition-all duration-500 ease-in-out"
                  icon={
                    isModelOpenLanguage
                      ? 'fluent:chevron-down-16-regular'
                      : 'fluent:chevron-up-16-regular'
                  }
                  color={'#2980Ab'}
                />
              </div>
              {isModelOpenLanguage && (
                <div
                  className="w-full h-auto bg-white absolute top-7 left-0 p-4 border-[1px] border-solid border-[#939393] z-40"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`,
                    left: '34.5%',
                    top: '110%',
                    boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                  }}
                >
                  {languageOption?.map((option, index) => (
                    <div
                      className="flex justify-start items-center my-auto w-full h-full hover:bg-slate-200 cursor-pointer"
                      key={index}
                      onClick={() => handleLanguageOption(option)}
                    >
                      <p className="text-black flex justify-start items-center my-auto w-full h-full">
                        {option}
                      </p>
                      <input
                        type="checkbox"
                        checked={language?.includes(option)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {language.length === 0 && languageError && (
              <p className="text-red-500">{languageError}</p>
            )}
            {/* Apps */}
            <div
              className="relative flex flex-row justify-between items-center p-0 flex-grow-0  h-auto"
              style={{
                width: `${Responsive.w(812, width)}px`
              }}
            >
              <label
                htmlFor="pages"
                className="nowrap font-[Montserrat] font-semibold text-left nowrap"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'apps',
                  defaultMessage: 'Apps'
                })}{' '}
              </label>
              <div
                className="relative  flex items-center outline-none justify-start  flex-grow-0 py-0  border-[1px] border-solid border-[#939393] cursor-pointer"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  paddingInline: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(10, width)}px`,
                  borderRadius: `${Responsive.sp(8, width)}px`
                }}
                onClick={() => setIsModelOpenApps(!isModelOpenApps)}
              >
                {apps.length === 0 ? (
                  <p className="text-[#939393] flex  justify-start items-center my-auto w-full h-auto">
                    Select Apps
                  </p>
                ) : (
                  <p className=" flex  justify-start items-center my-auto w-full h-auto flex-grow">
                    {apps.join(' / ')}
                  </p>
                )}

                <Icon
                  className="absolute right-3 w-5 h-5 transition-all duration-500 ease-in-out"
                  icon={
                    isModelOpenApps
                      ? 'fluent:chevron-down-16-regular'
                      : 'fluent:chevron-up-16-regular'
                  }
                  color={'#2980Ab'}
                />
              </div>
              {isModelOpenApps && (
                <div
                  className="w-full h-auto bg-white absolute top-7 left-0 p-4 border-[1px] border-solid border-[#939393] z-40"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    paddingInline: `${Responsive.sp(16, width)}px`,
                    gap: `${Responsive.sp(10, width)}px`,
                    borderRadius: `${Responsive.sp(8, width)}px`,
                    left: '34.5%',
                    top: '110%',
                    boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                  }}
                >
                  {appsOption.map((option, index) => (
                    <div
                      className="flex justify-start items-center my-auto w-full h-full hover:bg-slate-200 cursor-pointer"
                      key={index}
                      onClick={() => handleAppsOption(option)}
                    >
                      <p className="text-black flex justify-start items-center my-auto w-full h-full">
                        {option}
                      </p>
                      <input
                        type="checkbox"
                        checked={apps.includes(option)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {apps.length === 0 && appsError && <p className="text-red-500">{appsError}</p>}
            {data && (
              <div className="flex justify-between">
                <label
                  htmlFor="type"
                  className=" font-[Montserrat] font-semibold text-left"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    // color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'active',
                    defaultMessage: 'active'
                  })}
                </label>
                <label className="flex cursor-pointer select-none items-center">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="sr-only"
                    />
                    <div
                      className={`box block h-8 w-14 rounded-full ${
                        isChecked ? 'bg-[#5ade5c]' : 'bg-gray-200'
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                        isChecked ? 'translate-x-full' : ''
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center mt-4 w-full">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-[#2980BA] px-4 py-2 text-sm font-medium text-white shadow-sm"
            >
              {data
                ? useIntl().formatMessage({
                    id: 'update',
                    defaultMessage: 'Update'
                  })
                : useIntl().formatMessage({
                    id: 'submit',
                    defaultMessage: 'Submit'
                  })}
            </button>

            {data && (
              <button
                type="button"
                className="flex justify-center items-center rounded-lg gap-[18px] border-solid border-[1px] border-[#f93c65] bg-[#ffdbe3] text-[#f93c65] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
                onClick={handleDelete}
              >
                <Icon
                  className="w-5 h-5"
                  icon="fluent:delete-24-regular"
                  color={'#f93c65'}
                />
                <span>
                  {useIntl().formatMessage({
                    id: 'delete',
                    defaultMessage: 'Delete'
                  })}
                </span>
              </button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
}
