import { Icon } from '@iconify/react';
import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import CountryPicker from 'src/components/shared/CountryPicker';
import Modal from 'src/components/shared/Modal';
import SmallLoader from 'src/components/shared/SmallLoader';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';

export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="flex-col min-h-screen p-12 items-center justify-center ">
      <CampaignsComponent />
    </div>
  );
}

const CampaignsComponent = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [lastDocId, setLastDocId] = useState(null);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState('allCampaigns');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [currentTab, setCurrentTab] = useState<string>('customize');
  const [count, setCount] = useState();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<any>([]);
  const [perPage, setPerPage] = useState(15);
  const [promoId, setPromoId] = useState<any>('');
  const [sortValue, setSortValue] = useState<any>('');
  const [value, setValue] = useState(true);
  const [sortKey, setSortKey] = useState<any>('');

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const keys = [
    'campgain Name',
    'Date',
    'total Visits',
    'total Downloads',
    'download Percentage',
    'total Registrations',
    'total Customers',
    'customers Percentage',
    'total Repeated Customers',
    'reapeted Customers Percentage',
    'total orders',
    'total Earn $'
  ];
  const [customizedKeys, setCustomizedKeys] = useState(keys);

  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    }
    // { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const fetchCampaigns = async (page = 1,promoId,sortValue,sortKey) => {
    setIsLoadingMore(true);
    try {
      const response = await axiosInstance.get(`/promotions`, {
        params: {
          page: page, // Start from the first page
          pageSize: perPage,
          promoId: promoId || '',
          sortValue: sortValue || '',
          sortKey: sortKey || '' // Set the page size
        }
      });
      setCampaigns(response.data.result.data);
      setPagination(response.data.result.pagination);
      setCount(response.data.result.count);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
    setIsLoadingMore(false);
  };

  useEffect(() => {
    fetchCampaigns(pagination.currentPage,promoId,sortValue,sortKey);
  }, [perPage]);

  useEffect(() => {
    if (promoId || sortKey || sortValue) {
      fetchCampaigns(pagination.currentPage,promoId,sortValue,sortKey);
    }
  }, [promoId, sortKey, sortValue]);

  const onNextClick = () => {
    fetchCampaigns((pagination.currentPage || 1) + 1,promoId,sortValue,sortKey);
  };

  const onPreviousClick = () => {
    fetchCampaigns(pagination.currentPage - 1,promoId,sortValue,sortKey);
  };

  const applySorting = (key: string) => {
    setSortValue(value === true ? 'desc' : 'asc');
    setValue(!value);
    setSortKey(key);
  };

  const allNotificationsMessage = 'allCampaigns';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);
  const resetValues = () => {
    setPromoId('');
    setSortKey('');
    setSortValue('');
    setStart(undefined);
    setEnd(undefined);
  };
  const handleDateOptionClick = async (option, title) => {
    setIsLoadingMore(true);
    try {
      setTitle(title);
      let startDate, endDate;

      switch (option) {
        case 'All Campaigns':
          fetchCampaigns(1,'','',''); // استدعاء resetValues هنا لإعادة تعيين القيم
          resetValues()
          return; // Exit the function early

        case 'Today':
          startDate = new Date();
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          break;

        case 'Last 7 days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          endDate = new Date();
          break;

        case 'Last 30 days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 30);
          endDate = new Date();
          break;

        case 'Date range':
          if (start && end) {
            startDate = new Date(start);
            endDate = new Date(end);
          }
          break;

        default:
          startDate = null;
          endDate = null;
          break;
      }

      const response = await axiosInstance.get(`/promotions`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          page: 1, // Start from the first page
          pageSize: perPage,
          sortValue: sortValue || '',
          sortKey: sortKey || '' // Set the page size
        }
      });

      setCampaigns(response.data.result.data);
      setPagination(response.data.result.pagination);
      setCount(response.data.result.count);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoadingMore(false);
  };

  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        'promotions/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }

  const formatDate = (timestamp) => {
    const date = new Date(Number(timestamp));
    const day = date.getDate();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // إضافة 1 لأن الشهور تبدأ من 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const ChangeCustomizeKey = (isChecked, field) => {
    setCustomizedKeys((prevCustomizedKeys) => {
      if (isChecked) {
        // إذا تم التحقق من الخانة، نحتاج إلى إضافة الحقل إلى القائمة المخصصة في المكان المناسب
        const index = keys.indexOf(field); // العثور على موضع الحقل في keys
        const newIndex = prevCustomizedKeys.findIndex((key) => key === field); // التحقق مما إذا كان الحقل موجودًا بالفعل في customizedKeys
        if (newIndex === -1) {
          // إذا لم يتم العثور على الحقل في القائمة المخصصة، فقط قم بإضافته في المكان الصحيح
          return [...prevCustomizedKeys.slice(0, index), field, ...prevCustomizedKeys.slice(index)];
        } else {
          // إذا وجدنا الحقل بالفعل في القائمة المخصصة، فقم بإعادة ترتيبه إلى الموضع الصحيح
          return [
            ...prevCustomizedKeys.slice(0, index),
            ...prevCustomizedKeys.slice(newIndex, newIndex + 1), // النقطة التي تحتوي على الحقل الحالي
            ...prevCustomizedKeys.slice(index, newIndex),
            ...prevCustomizedKeys.slice(newIndex + 1)
          ];
        }
      } else {
        // إذا تم إلغاء التحقق من الخانة، قم بإزالة الحقل من القائمة المخصصة
        return prevCustomizedKeys.filter((key) => key !== field);
      }
    });
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  console.log(sortKey, sortValue, promoId);

  return (
    <div className="flex flex-col space-y-4 items-center justify-center w-full">
      <Table
        loading={isLoadingMore}
        isEmpty={!campaigns.length}
        searchAble={true}
        title={title}
        count={count}
        buttonAble={true}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] z-40"
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard !== 'jeras'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : '! border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customizedKeys.includes(field)}
                                onChange={(e) => ChangeCustomizeKey(e.target.checked, field)}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                <FormattedMessage
                                  id={`${field.replaceAll(' ', '')}`}
                                  defaultMessage={`${field}`}
                                />
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All Campaigns', allNotificationsMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={allNotificationsMessage}
                      defaultMessage={allNotificationsMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff] capitalize"
              style={{ height: `${Responsive.h(24, height)}px`, backgroundColor: dashColor }}
            >
              #
            </th>
            {customizedKeys.map((field, index) => (
              <th
                key={index}
                className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff] capitalize"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor,
                  display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                }}
                data-label={field}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    justifyContent: 'center',
                    flexDirection: 'row-reverse'
                  }}
                >
                  <FormattedMessage
                    id={`${field.replaceAll(' ', '')}`}
                    defaultMessage={`${field}`}
                  />
                  {field === 'campgain Name' && (
                    <button
                      className="w-full max-w-fit inline-flex items-center gap-4 text-sm font-medium text-[#fff] capitalize justify-between"
                      style={{
                        backgroundColor: dashColor
                      }}
                      onClick={() => {
                        applySorting('promoLinkId');
                      }}
                      type="button"
                    >
                      <Icon
                        icon="fa6-solid:sort"
                        width="12"
                        height="12"
                        className="text-gray-600"
                      />
                    </button>
                  )}
                  {field === 'Date' && (
                    <button
                      className="w-full max-w-fit inline-flex items-center gap-4 text-sm font-medium text-[#fff] capitalize justify-between"
                      style={{
                        backgroundColor: dashColor
                      }}
                      onClick={() => {
                        applySorting('createdAt');
                      }}
                      type="button"
                    >
                      <Icon
                        icon="fa6-solid:sort"
                        width="12"
                        height="12"
                        className="text-gray-600"
                      />
                    </button>
                  )}
                  {field === 'total Visits' && (
                    <button
                      className="w-full max-w-fit inline-flex items-center gap-4 text-sm font-medium text-[#fff] capitalize justify-between"
                      style={{
                        backgroundColor: dashColor
                      }}
                      onClick={() => {
                        applySorting('linkVisits');
                      }}
                      type="button"
                    >
                      <Icon
                        icon="fa6-solid:sort"
                        width="12"
                        height="12"
                        className="text-gray-600"
                      />
                    </button>
                  )}
                  {field === 'total Downloads' && (
                    <button
                      className="w-full max-w-fit inline-flex items-center gap-4 text-sm font-medium text-[#fff] capitalize justify-between"
                      style={{
                        backgroundColor: dashColor
                      }}
                      onClick={() => {
                        applySorting('downloadOrInstalls');
                      }}
                      type="button"
                    >
                      <Icon
                        icon="fa6-solid:sort"
                        width="12"
                        height="12"
                        className="text-gray-600"
                      />
                    </button>
                  )}
                </div>
              </th>
            ))}
          </>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {campaigns.map((campaigns: any, index) => (
              <tr
                key={index}
                style={{ backgroundColor: getRowColor(index) }}
                className="hover:bg-[#EAE0EB]"
              >
                <td
                  className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch"
                  data-label="#"
                >
                  {index + 1}
                </td>
                {customizedKeys.map((field, fieldIndex) => (
                  <td
                    key={fieldIndex}
                    className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch "
                    data-label={field}
                    style={{
                      display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                    }}
                  >
                    {field === 'campgain Name' &&
                      campaigns.promotions.map((promotion) => promotion.promoLinkId)}
                    {field === 'Date' &&
                      formatDate(campaigns.promotions.map((promotion) => promotion.createdAt))}
                    {field === 'total Visits' &&
                      campaigns.promotions.map((promotion) => promotion.linkVisits)}
                    {field === 'total Downloads' &&
                      campaigns.promotions.map((promotion) => promotion.downloadOrInstalls)}
                    {field === 'download Percentage' &&
                      `${(
                        (campaigns.promotions.map((promotion) => promotion.downloadOrInstalls) /
                          campaigns.promotions.map((promotion) => promotion.linkVisits)) *
                        100
                      ).toFixed(2)} %`}
                    {/* {field === 'Total Registrations' && campaigns.registrations?.length } */}
                    {field === 'total Registrations' && (
                      <button
                        className="w-full h-full"
                        onClick={() => {
                          navigate(
                            `/registrations/${campaigns.promotions.map(
                              (promotion) => promotion.promoLinkId
                            )}`
                          );
                        }}
                      >
                        {campaigns.registrations?.length}
                      </button>
                    )}
                    {field === 'total Customers' &&
                      [
                        ...new Set(
                          campaigns.purchases.filter((p) => p.isFirstOrder).map((p) => p.userId)
                        )
                      ].length}

                    {field === 'customers Percentage' &&
                      `${
                        campaigns.registrations?.length &&
                        [
                          ...new Set(
                            campaigns.purchases.filter((p) => p.isFirstOrder).map((p) => p.userId)
                          )
                        ].length
                          ? (
                              ([
                                ...new Set(
                                  campaigns.purchases
                                    .filter((p) => p.isFirstOrder)
                                    .map((p) => p.userId)
                                )
                              ].length /
                                campaigns.registrations?.length) *
                              100
                            ).toFixed(2)
                          : '0'
                      } %`}

                    {field === 'total Repeated Customers' &&
                      [
                        ...new Set(
                          campaigns.purchases
                            .filter((p) => p.isFirstOrder == false)
                            .map((p) => p.userId)
                        )
                      ].length}

                    {field === 'reapeted Customers Percentage' &&
                      `${
                        [
                          ...new Set(
                            campaigns.purchases
                              .filter((p) => p.isFirstOrder == false)
                              .map((p) => p.userId)
                          )
                        ].length &&
                        [
                          ...new Set(
                            campaigns.purchases.filter((p) => p.isFirstOrder).map((p) => p.userId)
                          )
                        ].length
                          ? (
                              (Number(
                                [
                                  ...new Set(
                                    campaigns.purchases
                                      .filter((p) => p.isFirstOrder == false)
                                      .map((p) => p.userId)
                                  )
                                ].length
                              ) /
                                [
                                  ...new Set(
                                    campaigns.purchases
                                      .filter((p) => p.isFirstOrder)
                                      .map((p) => p.userId)
                                  )
                                ].length) *
                              100
                            ).toFixed(2)
                          : '0'
                      } %`}
                    {field === 'total orders' && (
                      <button
                        className="w-full h-full"
                        onClick={() => {
                          navigate(
                            `/purchases/${campaigns.promotions.map(
                              (promotion) => promotion.promoLinkId
                            )}`
                          );
                        }}
                      >
                        {campaigns.purchases?.length}
                      </button>
                    )}
                    {field === 'total Earn $' &&
                      `${campaigns.purchases
                        .reduce((total, purchase) => total + purchase.amount, 0)
                        .toFixed(2)}$`}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
        pagination={{ ...pagination }}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return setPerPage(e.target?.value);
        }}
        onNextClick={() => {
          onNextClick();
        }}
        onPreviousClick={() => {
          onPreviousClick();
        }}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        buttonExport={() =>
          ExportXlsx(
            campaigns.map((campaign) => campaign),
            keys
          )
        }
        searchProps={{
          onKeyDown(e: any) {
            if (e.key === 'Enter' && e.target.value) {
              return setPromoId(e.target.value);
            }
          }
        }}
      />
    </div>
  );
};
