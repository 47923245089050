import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export function usePermissions() {
  const [permission, setPermission] = useState<any>([]);
  const { user } = useSelector((state: RootState) => state.auth);

  const permissionData = async () => {
    try {
      const response = await axios.get(`https://api.focalpoint-apps.com/permissions/${user?.permissionId}`);

      // test
      // const response = await axios.get(`http://localhost:3004/permissions/${user?.permissionId}`);
      setPermission(response.data.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.permissionId) {
      permissionData();
    }
  }, [user]);

  return permission;
}
