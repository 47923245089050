import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';

interface UserData {
  utcTime: string; // Define other properties as needed
  // ... (other properties of user data)
}

interface UsersChartProps {
  data: UserData[];
  title: string;
  color?: string;
  type?: 'bar' | 'line';
}

function OrdersChart({ data = [], title, color = '#7b6c96', type = 'bar' }: UsersChartProps) {
  // Assuming `data` is your array of user objects
  const userCountsByDay = {};

  if (Array.isArray(data)) {
    // Step 1: Loop through each user and increment the count for the user's registration date
    data.forEach((user) => {
      // Ensure that 'utcTime' exists and is a string before attempting to split it
      if (typeof user.utcTime === 'string') {
        const date = user.utcTime.split(' ')[0]; // This gets the 'YYYY-MM-DD' part

        // If the date isn't in the object yet, add it with a count of 1; otherwise, increment the count
        if (!userCountsByDay[date]) {
          userCountsByDay[date] = 1;
        } else {
          userCountsByDay[date]++;
        }
      }
    });
  } else {
    console.warn('Expected an array for data but received:', data);
  }

  // Step 2: Convert the counts object into an array of objects
  const countsArray = Object.keys(userCountsByDay).map((date) => {
    return { date: date, count: userCountsByDay[date] };
  });

  // Function to format the XAxis ticks
  const formatDateTick = (tick) => {
    // Ensure tick is treated as a string
    const stringTick = String(tick);

    const dateParts = stringTick.split('-'); // Split the date into [year, month, day]
    const sameYear = data.every((d) => d.utcTime.startsWith(dateParts[0])); // Check if all dates are from the same year
    const sameMonth = data.every(
      (d) => d.utcTime.slice(0, 7) === `${dateParts[0]}-${dateParts[1]}`
    ); // Check if all dates are from the same month & year
    const removeLeadingZero = (num) => {
      return num.startsWith('0') ? num.substring(1) : num;
    };
    if (sameYear && sameMonth) {
      // If all data is from the same month & year, show only the day
      return removeLeadingZero(dateParts[2]);
    } else if (sameYear) {
      // If all data is from the same year, show only month and day
      return `${removeLeadingZero(dateParts[1])}-${removeLeadingZero(dateParts[2])}`;
    }
    // Otherwise, show the full date
    return stringTick;
  };
  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="p-5 bg-[#fff3d6] text-[#a78024] rounded-[10px]"
          style={{ boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)' }}
        >
          <p className="flex items-center justify-center gap-1 text-[16px] font-semibold">
            <span
              className="w-[8px] h-[8px] !rounded-full"
              style={{ backgroundColor: '#a78024', display: 'inline-block' }}
            ></span>
            {`Count: ${data.count}`}
          </p>
          <p className="label text-[#656565]">{`${data.date}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="text-center">
      <h3>{title}</h3>
      <ResponsiveContainer height={386}>
        <BarChart
          data={countsArray}
          margin={{
            top: 5,
            right: 30,
            left: 20
          }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={formatDateTick}
            axisLine={false}
          />
          <YAxis axisLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="count"
            fill="#e6bc59"
            barSize={12.1}
            shape={<CustomBar />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y - 3}
        width={width}
        height={height}
        fill={fill}
        rx={10}
        ry={10}
      />
    </g>
  );
};

export default OrdersChart;

