import React, { FunctionComponent, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { RootState } from 'src/store';
import useUsersTable from './useUsersTable';
import SortButton from 'src/components/shared/SortButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from './modalContext';

function UsersTable(): any {
  const { customize_keys, data, pagination } = useSelector((state: RootState) => state.Users);
  const { isLoading, ChangeFilter, filters, initialQueries } = useUsersTable();
  const { isModalOpen, setIsModalOpen } = useModal();

  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );

  return (
    <Table
      loading={isLoading}
      isEmpty={!data.length}
      TimeTable={()=>(
        <></>
      )}
      CustomizeTable={()=>(
        <>
        </>
      )}
      RenderBody={() => (
        <>
          {data?.map((item: any, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {isIncluded('uid') && <td>{item?.uid || 'N/A'}</td>}
              {isIncluded('name') && <td>{item?.name || 'N/A'}</td>}
              {isIncluded('phoneNumber') && (
                <td className="tabular-nums">{item?.phoneNumber || 'N/A'}</td>
              )}
              {isIncluded('balance') && <td>{CurrencyFormatter(item?.balance || 0)}</td>}
              {isIncluded('countryCode') && <td>{item?.countryCode || 'N/A'}</td>}
              {isIncluded('countryISOCode') && <td>{item?.countryISOCode || 'N/A'}</td>}
              {isIncluded('ordersNumbers') && <td>{item?.ordersNumbers || 0}</td>}
              {isIncluded('payedBalance') && <td>{CurrencyFormatter(item?.payedBalance || 0)}</td>}
              {isIncluded('loggedInVia') && <td>{item?.loggedInVia || 'N/A'}</td>}
              {isIncluded('chat') && <td>{item?.chat ? 'Included' : 'N/A'}</td>}
              {isIncluded('voice') && <td>{item?.voice ? 'Included' : 'N/A'}</td>}
              {isIncluded('userType') && <td>{item?.userType || 'N/A'}</td>}
              {isIncluded('accountStatus') && <td>{item?.accountStatus || 'N/A'}</td>}
              {isIncluded('userLang') && <td>{item?.userLang || 'N/A'}</td>}
              {isIncluded('languages') && <td>{item?.languages?.join(' | ')}</td>}
              {isIncluded('deviceType') && <td>{item?.deviceType}</td>}
              {isIncluded('isBlocked') && <td>{item?.isBlocked ? 'Yes' : 'No'}</td>}
              {isIncluded('isDeveloper') && <td>{item?.isDeveloper ? 'Yes' : 'No'}</td>}
              {isIncluded('isSupervisor') && <td>{item?.isSupervisor ? 'Yes' : 'No'}</td>}
              {isIncluded('createdDate') && (
                <td>
                  <SharedTime date={new Date(item?.utcTime)} />
                </td>
              )}
            </tr>
          ))}
        </>
      )}
      RenderHead={() => (
        <>
          <th>#</th>
          {isIncluded('uid') && (
            <th className="capitalize">
              <SortButton
                filters={filters}
                sortKey="uid"
                text={useIntl().formatMessage({
                  id: 'uid',
                  defaultMessage: 'ID'
                })}
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('name') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="name"
                text={useIntl().formatMessage({
                  id: 'name',
                  defaultMessage: 'name'
                })}
                handler={ChangeFilter}
                sortAble
              />
            </th>
          )}
          {isIncluded('phoneNumber') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="phoneNumber"
                text={useIntl().formatMessage({
                  id: 'phoneNumber',
                  defaultMessage: 'phone number'
                })}
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('balance') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="balance"
                text={useIntl().formatMessage({
                  id: 'balance',
                  defaultMessage: 'balance'
                })}
                sortAble
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('countryCode') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="countryCode"
                text={useIntl().formatMessage({
                  id: 'countryCode',
                  defaultMessage: 'country code'
                })}
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('countryISOCode') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="countryISOCode"
                text={
                  <FormattedMessage
                    id="countryISOCode"
                    defaultMessage={'country ISO code'}
                  />
                }
                handler={ChangeFilter}
                sortAble
              />
            </th>
          )}
          {isIncluded('ordersNumbers') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="ordersNumbers"
                text={
                  <FormattedMessage
                    id="ordersNumber"
                    defaultMessage={'orders number'}
                  />
                }
                handler={ChangeFilter}
                sortAble
              />
            </th>
          )}
          {isIncluded('payedBalance') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="payedBalance"
                text={
                  <FormattedMessage
                    id="payedBalance"
                    defaultMessage={'payed balance'}
                  />
                }
                handler={ChangeFilter}
                sortAble
              />
            </th>
          )}
          {isIncluded('loggedInVia') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="loggedInVia"
                text={
                  <FormattedMessage
                    id="loggedInVia"
                    defaultMessage={'logged in via'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('chat') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="chat"
                text={
                  <FormattedMessage
                    id="chat"
                    defaultMessage={'chat'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('voice') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="voice"
                text={
                  <FormattedMessage
                    id="voice"
                    defaultMessage={'voice'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('userType') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="userType"
                text={
                  <FormattedMessage
                    id="userType"
                    defaultMessage={'user type'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('accountStatus') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="accountStatus"
                text={
                  <FormattedMessage
                    id="accountStatus"
                    defaultMessage={'account status'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('userLang') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="userLang"
                text={
                  <FormattedMessage
                    id="userLang"
                    defaultMessage={'user language'}
                  />
                }
                handler={ChangeFilter}
                sortAble
              />
            </th>
          )}
          {isIncluded('languages') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="languages"
                text={
                  <FormattedMessage
                    id="languages"
                    defaultMessage={'languages'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('deviceType') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="deviceType"
                text={
                  <FormattedMessage
                    id="deviceType"
                    defaultMessage={'device type'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('isBlocked') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="isBlocked"
                text={
                  <FormattedMessage
                    id="isBlocked"
                    defaultMessage={'is blocked'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('isDeveloper') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="isDeveloper"
                text={
                  <FormattedMessage
                    id="isDeveloper"
                    defaultMessage={'is developer'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('isSupervisor') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="isSupervisor"
                text={
                  <FormattedMessage
                    id="isSupervisor"
                    defaultMessage={'is supervisor'}
                  />
                }
                handler={ChangeFilter}
              />
            </th>
          )}
          {isIncluded('createdDate') && (
            <th>
              <SortButton
                filters={filters}
                sortKey="createdDateValue"
                text={
                  <FormattedMessage
                    id="createdDateValue"
                    defaultMessage={'created date value'}
                  />
                }
                handler={ChangeFilter}
                sortAble
              />
            </th>
          )}
        </>
      )}
      title={
        <FormattedMessage
          id="usersTable"
          defaultMessage={'Users Table'}
        />
      }
      pagination={{ ...pagination, search_key: filters.search }}
      onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
      onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
      ChangePerPageLimit={(e: any) => {
        if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
      }}
      searchProps={{
        onKeyDown(e: any) {
          if (e.key === 'Enter' && e.target.value) {
            // Check if the input is a phone number (only numbers and/or '+')
            const isPhoneNumber = /^[+]?[0-9]+$/.test(e.target.value);

            if (isPhoneNumber) {
              // Search in phoneNumber field
              return ChangeFilter([['phoneNumber', '>=', e.target.value + '~']], 'queries');
            } else {
              // Search in name field
              return ChangeFilter([['name', '>=', e.target.value + '~']], 'queries');
            }
          }
        }
      }}
    />
  );
}

export default UsersTable;
