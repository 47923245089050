import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import Modal from 'src/components/shared/Modal';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import axiosInstance from 'src/helper/AxiosInstance';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';
import { FormContext } from 'src/context/context';
export default function coursesVideo() {
  const { width, height } = useWindowDimensions();
  const { formData, setFormData } = useContext(FormContext);
  const { id } = useParams();
  const [active, setActive] = useState(false);
  const [data, setData] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const navigate = useNavigate();
  console.log(id);
  const emptyBannerData = {
    name: '',
    image: '',
    phone: '',
    active: false,
    lang: 'ar',
    id: '',
    uid: ''
  };
  console.log('formData', formData);
  
  const [selectedBanner, setSelectedBanner] = useState<any>(emptyBannerData);
  async function coursesVideo() {
    try {
      const respons = await axiosInstance.get(`/courses/video/${id}`);
      setData(respons.data.result.data);
    } catch (er) {
      console.log(er);
    }
  }

  useEffect(() => {
    coursesVideo();
  }, []);
  const openModal = (video) => {
    setCurrentVideo(video);
    setIsModalOpen(true);
  };
  console.log(data);

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  return (
    <>
      <div
        className="p-[8px]   min-h-screen justify-start items-center flex-col flex gap-7 overflow-x-hidden"
        style={{
          width: `${Responsive.w(1560, width)}px`,
          marginInline: `${Responsive.sp(24, width)}px`,
          marginTop: `${Responsive.sp(51, width)}px`
        }}
      >
        {/* header */}
        <div
          className="flex justify-between items-center p-0 h-[36px]"
          style={{
            width: `${Responsive.w(1560, width)}px`
          }}
        >
          <h2
            className="font-semibold  font-[Montserrat] text-[#000000]"
            style={{
              fontSize: `${Responsive.sp(24, width)}px`
            }}
          >
            Upload Course videos
          </h2>
          <div
            className="flex justify-center items-center mr-4 "
            style={{
              gap: `${Responsive.sp(20, width)}px`
            }}
          >
            <label
              className={`text-[${dashColor}] font-[Montserrat] font-semibold text-left`}
              style={{
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'active',
                defaultMessage: 'Active'
              })}
            </label>
            <ToggleSlider
              onClick={() => setActive(!active)}
              checked={active}
            />
          </div>
        </div>
        {/* body */}
        <div
          className="grid grid-cols-4 p-0"
          style={{
            width: `${Responsive.w(1560, width)}px`,
            gap: `${Responsive.sp(24, width)}px`
          }}
        >
          {/* video card */}
          {data &&
            data?.map((item: any) => (
              <>
                <Card
                  className="flex  justify-start items-start  relative"
                  style={{
                    width: `${Responsive.w(372, width)}px`,
                    gap: `${Responsive.sp(8, width)}px`,
                    height: `${Responsive.h(321, height)}px`,
                    borderRadius: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <div
                    className="w-full h-full absolute flex items-start justify-start left-2 top-2 z-10"
                    style={{
                      width: `${Responsive.w(136, width)}px`,
                      gap: `${Responsive.sp(19.8, width)}px`
                    }}
                  >
                    {/* delete */}
                    <div className="w-8 h-8 flex items-center justify-center bg-[#ffdbe3] rounded-full left-2 top-2 absolute p-2 hover:bg-slate-600 ">
                      <Icon
                        icon={'material-symbols:delete-outline'}
                        className="object-contain p-0 w-[6.8px] h-[7.8px]"
                        color="#cf0036"
                      />
                    </div>
                    {/* edit */}
                    <div
                      className="w-8 h-8 flex items-center justify-center bg-[#f7f7f7] rounded-full left-12 top-2 absolute p-2 hover:bg-slate-600 cursor-pointer "
                      onClick={() => openModal(item)}
                    >
                      <Icon
                        icon={'iconamoon:edit-fill'}
                        className="object-contain p-0 w-[6.8px] h-[7.8px]"
                      />
                    </div>
                  
                  </div>

                  <img
                    style={{
                      borderRadius: `${Responsive.sp(16, width)}px`,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    className="object-cover w-auto h-auto flex-grow absolute top-0 left-0 z-0"
                    src={item?.image}
                    alt={item?.name}
                  />
                </Card>
              </>
            ))}
          {/* Add Video */}
          <div
            className="flex  justify-center items-center bg-[#eaeaea] cursor-pointer"
            style={{
              width: `${Responsive.w(372, width)}px`,
              gap: `${Responsive.sp(8, width)}px`,
              height: `${Responsive.h(321, height)}px`,
              borderRadius: `${Responsive.sp(16, width)}px`
            }}
            onClick={() => openModal(emptyBannerData)}
          >
            <Icon
              icon={'ic:baseline-plus'}
              color={'#cf0036'}
              style={{
                width: `${Responsive.w(37, width)}px`,
                height: `${Responsive.h(37, height)}px`
              }}
            />
            <p
              className="text-[#cf0036] font-[Montserrat] font-semibold nowrap"
              style={{
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              Add Video
            </p>
            
          </div>
        </div>
        {/* footer */}
        <div className="flex items-center justify-center gap-4 ">
          <div
            className="w-14 h-14 flex items-center justify-center p-3 border border-solid border-[#cf0036] rounded-lg cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <Icon
              icon={'material-symbols:arrow-back'}
              className="object-contain p-0 w-[25px] h-[25px]"
              color={'#cf0036'}
            />
          </div>
          {/* Update */}
          <button
            className={` bg-[${dashColor}] h-14 flex mr-auto   items-center justify-center text-white font-bold rounded-[8px] py-[5px] px-[16px] text-center `}
            style={{
              width: `${Responsive.w(240, width)}px`
              // height: `${Responsive.h(56, height)}px`
            }}
          >
            {useIntl().formatMessage({
              id: 'update',
              defaultMessage: 'Update'
            })}
          </button>
        </div>
      </div>
      <FormModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={currentVideo}
        setData={setData}
        dashColor={dashColor}
      />
    </>
  );
}
const FormModal = ({ isOpen, setIsOpen, data, setData, dashColor }) => {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
 
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileWeb, setSelectedFileWeb] = useState(null);
  const intl = useIntl();
  const [name, setName] = useState(data?.name );

  const [type, setType] = useState(data?.type);
  const [itemId, setItemId] = useState(data?.itemId);
  const [image, setImage] = useState(data?.image);
  const [webImage, setWebImage] = useState(data?.webImage);
  const [url, setUrl] = useState(data?.link);
  const [status, setStatus] = useState<boolean>(data?.active);
  const [courses, setCourses] = useState([]);
  const fileInputRef = useRef<any>(null);
  const { width, height } = useWindowDimensions();
  // State for validation messages
  const [nameError, setNameError] = useState('');
  const [langError, setLangError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [courseError, setcourseError] = useState('');
  useEffect(() => console.log(image), [image]);
  useEffect(() => {
    if (data) {
      setName(data.name || '');
      setType(data.type || '');
      setItemId(data.itemId || '');
      setImage(data.image || '');
      setWebImage(data.webImage || '');
      setUrl(data.link || '');
      setStatus(data.active || false);
    } else {
      // Reset to default values for adding a new banner
      setName('');
      setType('');
      setItemId('');
      setImage('');
      setWebImage('');
      setUrl('');
      setStatus(false);
    }
  }, [data]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); // Set the selected file
    console.log('file', event.target);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    // Reset the value of the input
    event.target.value = null;
  };



  const handleClose = () => {
    setIsOpen(false);
    setName(data.name);

    setType(data.type);
    setItemId(data.itemId);
    setImage(data.image);
    setWebImage(data.webImage);
    setUrl(data.Url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //uploading image to storage
      const errMessage = intl.formatMessage({
        id: 'fileMissingError',
        defaultMessage: 'Please select a file to upload.'
      });
      if (data.image != image) {
        if (!selectedFile) {
          toast.error(errMessage);
          return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await axiosInstance.post('/uploadImg', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setImage(response.data.result.data.imgUrl);
      }
      if (data.webImage != webImage) {
        if (!selectedFileWeb) {
          toast.error(errMessage);
          return;
        }

        const formDataWeb = new FormData();
        formDataWeb.append('file', selectedFileWeb);

        const response = await axiosInstance.post('/uploadImg', formDataWeb, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setWebImage(response.data.result.data.imgUrl);
      }

      const reqBody = {
        image,
        webImage,
        name,
        type,
        itemId,
        status,
        url: url || 'noLink'
      };
      //submitting patch data to doc in firestore
      let bannerResponse;
      if (data.id) {
        // Editing an existing banner
        bannerResponse = await axiosInstance.patch(`/banners/${data.id}`, reqBody);
      } else {
        // Adding a new banner
        bannerResponse = await axiosInstance.post('/banners/', reqBody);
      }
      console.log('patch res', bannerResponse);
      setIsOpen(false);
      setData((prevData) =>
        prevData.map((banner) =>
          banner.id === data.id ? { ...banner, ...bannerResponse.data.result.data } : banner
        )
      );

      const successMessage = intl.formatMessage({
        id: 'bannerUpdated',
        defaultMessage: 'Banner updated successfully.'
      });

      toast.success(successMessage);
    } catch (error) {
      console.error('Error during file upload:', error);
      const errMessage = intl.formatMessage({
        id: 'fileUploadError',
        defaultMessage: 'error during file upload.'
      });
      toast.error(errMessage);
    }
  };

  const handleDeleteBanner = async (bannerId) => {
    try {
      await axiosInstance.delete(`banners/${bannerId}`);
      const successMessage = intl.formatMessage({
        id: 'bannerDeletedMsg',
        defaultMessage: 'Banner has been deleted successfully'
      });
      toast.success(successMessage);
      setIsOpen(false);
      setData((prevData) => prevData.filter((banner) => banner.id !== bannerId));
    } catch (error: any) {
      const errMessage = intl.formatMessage({
        id: 'error',
        defaultMessage: 'An Error has happend!'
      });
      toast.error(error.message || errMessage);
    }
  };

  const handleRestoreClick = (event) => {
    event.preventDefault();
    setImage(data.image);
    // Assuming fileInputRef is a ref to your file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!name) {
      const errMsg = intl.formatMessage({
        id: 'nameRequired',
        defaultMessage: 'Name is required'
      });
      setNameError(errMsg);
      isValid = false;
    } else {
      setNameError('');
    }

 

    if (type === 'course' && !itemId) {
      const errMsg = intl.formatMessage({
        id: 'courseRequired',
        defaultMessage: 'course is required'
      });
      setcourseError(errMsg);
      isValid = false;
    } else {
      setcourseError('');
    }

    if (!type) {
      const errMsg = intl.formatMessage({
        id: 'typeIsRequired',
        defaultMessage: 'Type is required'
      });
      setTypeError(errMsg);
      isValid = false;
    } else {
      setTypeError('');
    }

    return isValid;
  };

  return (
    <Modal
      visible={isOpen}
      handleClose={handleClose}
      title={data?.id ? 'Edit Banner' : 'Add Banner'}
    >
      <form
        className="flex items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="flex-col space-y-4  w-full">
          <div
            className="flex flex-row justify-between items-center p-0 flex-grow-0 "
            style={{
              width: `${Responsive.w(748, width)}px`,
              height: `${Responsive.h(63, height)}px`
            }}
          >
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'name',
                defaultMessage: 'name'
              })}
            </label>
            <input
              className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                width: `${Responsive.w(532, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
              placeholder={useIntl().formatMessage({
                id: 'enterNameOfCons',
                defaultMessage: 'enter name of consultant'
              })}
            />
          </div>
    

         
          
          {/* url */}

          <div className="flex justify-between items-center">
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'link',
                defaultMessage: 'Link'
              })}
            </label>
            <input
              className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                width: `${Responsive.w(532, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder={useIntl().formatMessage({
                id: 'enterURL',
                defaultMessage: 'enter URL'
              })}
            />
          </div>

          {/* image */}
          {data?.image ? (
            <div className="flex   justify-between items-center relative">
              <label
                htmlFor="file-upload"
                className=" inline-block cursor-pointer"
              >
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                />
                <div className="w-8 h-8 flex items-center justify-center bg-[#7b6c96] rounded-full right-2 top-2 absolute  p-2 hover:bg-slate-600">
                  <Icon
                    icon="bxs:edit"
                    color="white"
                    width={24}
                    height={24}
                  />
                </div>
              </label>
              <img
                src={image}
                alt="Banner"
                id="imagePreview"
                className="w-120 h-40 object-stretch"
                style={{
                  width: `${Responsive.w(492, width)}px`,
                  height: `${Responsive.h(164, height)}px`
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col justify-between items-center gap-3">
              <div className="flex justify-between items-center  w-full">
                <label
                  htmlFor="file-upload"
                  className=" font-[Montserrat] font-semibold text-left nowrap"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'uploadImage',
                    defaultMessage: 'Upload Image'
                  })}
                </label>

                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  // className="block w-1/2 rounded bg-primary"
                  ref={fileInputRef}
                  className="flex items-center outline-none font-[Montserrat] justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                />
              </div>
              {image && (
                <img
                  src={image}
                  alt="Banner"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(200, height)}px`
                  }}
                  id="imagePreview"
                  className="w-120 h-40 object-stretch"
                />
              )}
            </div>
          )}

          <div className="flex justify-between items-center bg-slate-50 rounded-xl  p-4">
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'active',
                defaultMessage: 'Active'
              })}
            </label>
            <ToggleSlider
              onClick={() => setStatus(!status)}
              checked={status}
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              style={{
                backgroundColor: dashColor
              }}
              className="inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2"
            >
              {data?.id
                ? useIntl().formatMessage({
                    id: 'update',
                    defaultMessage: 'Update'
                  })
                : useIntl().formatMessage({
                    id: 'submit',
                    defaultMessage: 'submit'
                  })}
            </button>

            <button
              onClick={() => handleDeleteBanner(data.id)}
              type="button"
              className="flex justify-center rounded-md itms-center gap-[18px] border-solid border-[1px] border-[#d91c1f]  bg-[#ffe2e2] text-[#d91c1f] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
            >
              <Icon
                icon={'material-symbols:delete-outline'}
                className="object-contain p-0 w-[14] h-[14px] m-auto"
                color="#cf0036"
              />

              <span>
                {useIntl().formatMessage({
                  id: 'delete',
                  defaultMessage: 'Delete'
                })}
              </span>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
