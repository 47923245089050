import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import Card from '../../components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';

export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { width, height } = useWindowDimensions();
  const [data, setData] = useState<any>([]);

  const navigate = useNavigate();
  const { appointmentId, orderId } = useParams();
  const fetchLessons = async () => {
    setIsLoadingMore(true);
    try {
      const response = await axiosInstance.get(`/lessons`, {
        params: {
          appointmentId: appointmentId, // Start from the first page
          orderId: orderId
        }
      });
      setData(response.data.result.data);
    } catch (error) {
      console.error('Error fetching Lessons:', error);
    }
    setIsLoadingMore(false);
  };
  useEffect(() => {
    fetchLessons();
  }, []);
  if (isLoadingMore) return <LoadingComponent />;
  console.log(data);
  const numberOfCards = data.orderData?.packageCallNum || 0;
  const getNumberOfCalls = (index) => {
    const lessonKey = (index + 1).toString();
    if (data.lessons?.lessons && data.lessons.lessons[lessonKey]) {
      return data.lessons.lessons[lessonKey].length;
    }
    return 'NA';
  };
  const getState = (index) => {
    const lessonLength = Object.keys(data?.lessons?.lessons).length;
    if (lessonLength > index + 1) {
      return 'Closed';
    } else if (lessonLength == index + 1) {
      return 'Open';
    } else {
      return 'Not started';
    }
  };
  const getStateColor = (state) => {
    switch (state) {
      case 'Closed':
        return '#ea2121';
      case 'Open':
        return '#28c794';
      case 'Not started':
        return '#202020';
      default:
        return '#202020';
    }
  };
  return (
    <>
      {data.lessons?.lessons ? (
        <div
          className="p-[8px] bg-white  grid grid-cols-3  flex-grow-0 "
          style={{
            width: `${Responsive.w(1584, width)}px`,
            gap: `${Responsive.sp(24, width)}px`
            // height: `${Responsive.h(352, height)}px`,
          }}
        >
          {Array.from({ length: numberOfCards }, (_, index) => (
            <Card
              key={index}
              className="rounded-[16px]  border-[1px] border-[#afafaf] border-solid flex flex-col justify-start items-start flex-grow-0"
              style={{
                width: `${Responsive.w(503, width)}px`,
                padding: `${Responsive.sp(24, width)}px`,
                gap: `${Responsive.sp(32, width)}px`,
                borderRadius: `${Responsive.sp(16, width)}px`
              }}
            >
              <div
                className="flex  justify-center items-center p-0 flex-grow-0 self-stretch"
                style={{
                  width: `${Responsive.w(456, width)}px`,
                  gap: `${Responsive.sp(4, width)}px`
                }}
              >
                <h3
                  className="font-semibold text-[#7b6c96] flex-grow-0 text-center mx-auto"
                  style={{
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  Lesson {index + 1}
                </h3>
              </div>
              <div
                className="flex flex-col justify-center items-center p-0 flex-grow-0 self-stretch"
                style={{
                  width: `${Responsive.w(456, width)}px`,
                  gap: `${Responsive.sp(24, width)}px`
                }}
              >
                <div
                  className="flex justify-start items-center p-0 flex-grow-0"
                  style={{
                    width: `${Responsive.w(456, width)}px`,
                    gap: `${Responsive.sp(55, width)}px`
                  }}
                >
                  <div
                    className="flex flex-col justify-start items-stretch p-0 flex-grow-0 nowrap"
                    style={{
                      width: `${Responsive.w(456, width)}px`,
                      gap: `${Responsive.sp(16, width)}px`
                    }}
                  >
                    <p
                      className="text-left text-[#7b6c96] font-semibold flex-grow-0"
                      style={{
                        fontSize: `${Responsive.sp(20, width)}px`
                      }}
                    >
                      Date:<span className="text-[#202020]"></span>
                    </p>
                    <p
                      className="text-left text-[#7b6c96] font-semibold flex-grow-0 nowrap"
                      style={{
                        fontSize: `${Responsive.sp(20, width)}px`
                      }}
                    >
                      Time:<span className="text-[#202020]"></span>
                    </p>
                  </div>

                  <div
                    className="flex flex-col justify-start items-stretch p-0 flex-grow-0 nowrap"
                    style={{
                      width: `${Responsive.w(456, width)}px`,
                      gap: `${Responsive.sp(16, width)}px`
                    }}
                  >
                    <p
                      className="text-left text-[#7b6c96] font-semibold flex-grow-0 nowrap"
                      style={{
                        fontSize: `${Responsive.sp(20, width)}px`
                      }}
                    >
                      Number of Calls:{' '}
                      <span className="text-[#202020]">{getNumberOfCalls(index)}</span>
                    </p>
                    <p
                      className="text-left text-[#7b6c96] font-semibold flex-grow-0"
                      style={{
                        fontSize: `${Responsive.sp(20, width)}px`
                      }}
                    >
                      State:{' '}
                      <span
                        style={{
                          fontSize: `${Responsive.sp(20, width)}px`,
                          color: getStateColor(getState(index))
                        }}
                      >
                        {getState(index)}
                      </span>
                    </p>
                  </div>
                </div>
                <button
                  className="flex  justify-center py-1 px-3 rounded items-center p-0 flex-grow-0  bg-[#7b6c96]"
                  style={{
                    width: `${Responsive.w(180, width)}px`,
                    gap: `${Responsive.sp(19, width)}px`
                  }}
                  onClick={()=>{
                    navigate(`/lessonDetails/${appointmentId}/${index + 1}`)
                  }}
                  disabled={Object.keys(data?.lessons?.lessons).length < index + 1}
                >
                  <p
                    className="text-left text-white font-semibold flex-grow-0"
                    style={{
                      fontSize: `${Responsive.sp(14, width)}px`
                    }}
                  >
                    More Details
                  </p>
                </button>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <p className="flex text-neutral-500 flex-1 shrink-0 text-xl items-center justify-center !my-auto h-full">
          There are no data to display
        </p>
      )}
    </>
  );
}
