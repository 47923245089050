import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation } from 'react-router-dom';
import { setLocale } from 'src/reducers/locale';
const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('language') || 'en'
  );
  const [pageLanguage, setPageLanguage] = useState('English');
  const dispatch = useDispatch();
  const toggleDropdown = () => setIsOpen(!isOpen);
  const changeLanguage = (lang) => {
    dispatch(setLocale(lang));
    setSelectedLanguage(lang);
    localStorage.setItem('language', lang);
    setIsOpen(false);
  };
  useEffect(() => {
    if (selectedLanguage) {
      const languageMap = {
        en: 'English',
        ar: 'Arabic',
        fr: 'Français',
        id: 'Bahasa Indonesia'
      };
      setPageLanguage(languageMap[selectedLanguage] || selectedLanguage);
    }
  }, [selectedLanguage]);
  return (
    <div className="language-selector flex flex-row-reverse space-x-4 gap-1">
      <div className="language_selector relative z-20 bg-white text-[#2980BA] mr-1 flex items-center justify-between rounded-md w-[124px] h-[44px] cursor-pointer wrap"
      onClick={toggleDropdown}>
        <p className="ml-2 font-extrabold"
        
        >{pageLanguage}</p>
        {
          isOpen ?(  
            <div className="up transition ease-in-out delay-150">
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              className='transition ease-in-out delay-150'
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        ):(
          <div className="down transition ease-in-out delay-150">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            className='transition ease-in-out delay-150'
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
              clip-rule="evenodd"
            />
          </svg>
        </div>
       
        )
        }
       
        
      </div>
      <button
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className="text-white  z-20 ml-4  font-medium rounded-lg text-sm text-center inline-flex items-center"
        type="button"
        
      >
        <Icon
          icon="material-symbols:language"
          width={29.5}
          height={29.5}
        />
      </button>

      {isOpen && (
        <div
          id="dropdown"
          className="z-20 mt-8 bg-white text-center  divide-y divide-gray-100 rounded-lg shadow w-[124px]   "
          style={{ position: 'absolute', marginRight: '5px', "marginTop":"50px" ,"boxShadow":" 1px 1px 20.2px 0 rgba(0, 0, 0, 0.07)"}}
        >
          <ul
            className="py-2 text-sm text-black dark:text-black"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <a
                href="#"
                className="block px-4 py-2 mb-3 font-extrabold border-solid border-b-2 border-b-gray-100 hover:bg-gray-100  dark:hover:text-[#2980BA]"
                onClick={() => changeLanguage('en')} // Replace with actual language change function
              >
                English
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2 mb-3 font-extrabold border-solid border-b-2 border-b-gray-100 hover:bg-gray-100 dark:hover:text-[#2980BA]"
                onClick={() => changeLanguage('ar')} // Replace with actual language change function
              >
                Arabic
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2 font-extrabold mb-3 border-solid border-b-2 border-b-gray-100 hover:bg-gray-100 dark:hover:text-[#2980BA]"
                onClick={() => changeLanguage('fr')} // Replace with actual language change function
              >
                Français
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2  font-extrabold hover:bg-gray-100 dark:hover:text-[#2980BA] wrap"
                onClick={() => changeLanguage('id')} // Replace with actual language change function
              >
                Bahasa Indonesia
              </a>
            </li>

            {/* Add more languages here */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;

