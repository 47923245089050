import { createSlice } from '@reduxjs/toolkit';
import parseJwt from 'src/helper/parseJwt';

// Define the settings for each dashboard
const dashboardSettings = {
  dream: {
    dashboard: 'dream',
    logoSrc: '/images/logo.webp',
    axiosBaseUrl: 'https://api.dream-app.net/',
    // axiosBaseUrl: 'http://localhost:5006/',
    appStoreLink: 'https://apps.apple.com/us/app/dream-dream-interpretation/id1515745954',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.abdulazizahmed.dream'
  },
  jeras: {
    dashboard: 'jeras',
    logoSrc: '/images/jeraslogo.png',
    axiosBaseUrl: 'https://api.jeras.io/',
    // axiosBaseUrl: 'http://localhost:5003/',
    appStoreLink: 'https://apps.apple.com/us/app/jeras-quran-islamic-values/id1612021922',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.app.jeras&hl=en&gl=US'
  },
  beaut: {
    dashboard: 'nikah',
    logoSrc: './images/jerasLogo.png',
     axiosBaseUrl: 'https://api.makemynikah.com/',
    // axiosBaseUrl: 'http://localhost:5005/',
    appStoreLink: 'https://apps.apple.com/us/app/jeras-quran-islamic-values/id1612021922',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.app.jeras&hl=en&gl=US'
  },
  default: {
    dashboard: 'default',
    logoSrc: '/images/focalPointLogo.png',
    axiosBaseUrl: 'https://api.focalpoint-apps.com/',
    // axiosBaseUrl: 'http://localhost:3004/',
    appStoreLink: 'https://apps.apple.com/us/app/dream-dream-interpretation/id1515745954',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.abdulazizahmed.dream'
  }
};
const getInitialState = () => {
  const savedDashboardName = localStorage.getItem('currentDashboard');
  if (savedDashboardName && dashboardSettings[savedDashboardName]) {
    return dashboardSettings[savedDashboardName];
  }
  const token = sessionStorage.getItem('@token');
  if (token) {
    const payload = parseJwt(token);
    if (payload?.rules?.includes('dream')) {
      return dashboardSettings['dream'];
    } 
    if (payload?.rules?.includes('jeras')) {
      return dashboardSettings['jeras'];
    } 
    if (payload?.rules?.includes('beaut')) {
      return dashboardSettings['beaut'];
    }
  }
  
  return dashboardSettings['default'];
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: getInitialState(),
  reducers: {
    setDashboard: (state, action) => {
      const dashboardName = action.payload;
      const settings = dashboardSettings[dashboardName];
      localStorage.setItem('currentDashboard', dashboardName);
      return settings; // استخدم return لتحديث الحالة بشكل صحيح
    }
  }
});


export const { setDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;

