import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

const DuesPaymentPdf = ({ data = [] }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    const fetchImage = async (url, setImage) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl);
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };
    fetchImage('/images/dreamlogo.png', setImage);
  }, []);

  const total = data?.reduce((acc, consult:any) => acc + (parseFloat(consult?.balance) || 0), 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image src={image} style={styles.logo} />
        </View>
        <View style={styles.titleContainer}>
          <View style={styles.line} />
          <Text style={styles.invoiceTitle}>المستحقات المالية</Text>
          <View style={styles.line} />
        </View>
        <View>
          <Text style={styles.invoiceSubTitle}>
            {`${new Date().toISOString()}                              `}
            {`تاريخ اليوم`}
          </Text>
        </View>

        <View style={styles.table}>
          <Text style={styles.cell}>القيمة</Text>
          <Text style={styles.cell}>المستشار</Text>
        </View>
        {data.map((consult :any, index) => (
  <View key={index} style={styles.statement}>
    <Text style={styles.cell}>{CurrencyFormatter(consult?.balance || 20)}</Text>
    <Text style={styles.cell}>
    {consult?.name ? (consult.name.replace(/[^\u0621-\u064Aa-zA-Z]/g, ' ') || 'Default Name') : 'Unknown'}

    </Text>
  </View>
))}


        <Text style={styles.totalSection}>Total: {CurrencyFormatter(total || 100)}</Text>
        <Text style={styles.paymentMethods}>Payment methods: Bank transfer, Paypal, Stc pay</Text>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '40px',
    fontFamily: 'Amiri'
  },
  logo: {
    width: '200px',
    height: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'cover'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20,
    marginVertical: 15
  },
  line: {
    flex: 1,
    borderTop: '1px solid #000',
    marginHorizontal: 5
  },
  invoiceTitle: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 20
  },
  invoiceSubTitle: {
    fontSize: 20,
    textAlign: 'left',
    marginLeft: 15,
    marginTop: 12
  },
  invoiceSection: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  invoiceDetails: {
    fontSize: 14,
    marginBottom: 2,
    fontFamily: 'Amiri'
  },
  totalSection: {
    borderTop: '1 solid #000',
    borderBottom: '1 solid #000',
    paddingVertical: 8,
    marginHorizontal: 20,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    backgroundColor: '#FAE5F1'
  },
  table: {
    borderTop: '1 solid #000',
    borderBottom: '1 solid #000',
    paddingVertical: 8,
    marginHorizontal: 20,
    fontSize: 18,
    fontWeight: 'bold',
    backgroundColor: '#FAE5F1',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center'
  },
  statement: {
    borderTop: '1 solid #000',
    paddingVertical: 8,
    marginHorizontal: 20,
    fontSize: 12,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    marginBottom: 10
  },
  paymentMethods: {
    margin: 20,
    fontSize: 14
  },
  cell: { flex: 1, textAlign: 'center' }
});

export default DuesPaymentPdf;
