import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLogout from 'src/hooks/useLogout';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { setDashboard } from 'src/reducers/dashboard';
import { RootState } from 'src/store';
import img from '../screens/images/Frame 37.png';
import nikah from '../screens/images/Mask Group 35 4.png';
import { useNavigate } from 'react-router-dom';

const DashboardSelector = () => {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDropdown = () => setIsOpen(!isOpen);
  const { handleLogged } = useLogout();
  const changeDashboard = (dashboard) => {
    dispatch(setDashboard(dashboard));
    setIsOpen(false);
    window.location.reload();
  };
  const { logoSrc, dashboard: currentDashboard } = useSelector(
    (state: RootState) => state.Dashboard
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const dashboards = user?.rules?.filter((dashboard: any) =>
    ['dream', 'jeras', 'beaut'].includes(dashboard)
  );
  const logoColor =
    currentDashboard === 'dream' ? '#a12f88' : currentDashboard === 'nikah' ? '#cf0036' : '#7b6c96';

  return (
    <div
      className="relative"
      style={{
        width: `${Responsive.w(312, width)}px`,
        height: `${Responsive.h(112, height)}px`
      }}
    >
      <button
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className="flex flex-row py-0 px-[32px] flex-grow-0 justify-between items-center self-stretch border-solid border-b-[1px] border-[#e3e3e3] bg-white"
        style={{
          width: `${Responsive.w(312, width)}px`,
          height: `${Responsive.h(112, height)}px`
        }}
        type="button"
        onClick={toggleDropdown}
        disabled={dashboards && dashboards.length <= 1}
      >
        <div
          className="flex items-center justify-start p-0 flex-grow-0"
          style={{
            width: `${Responsive.w(159.2, width)}px`,
            height: `${Responsive.h(62.3, height)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          {currentDashboard === 'dream' ? (
            <img
              src={logoSrc}
              alt="website logo"
              style={{
                width: `${Responsive.w(46.2, width)}px`,
                height: `${Responsive.h(62.2, height)}px`
              }}
              className="flex-grow-0"
            />
          ) : currentDashboard === 'nikah' ? (
            <img
              src={nikah}
              alt="website logo"
              style={{
                width: `${Responsive.w(46.2, width)}px`,
                height: `${Responsive.h(62.2, height)}px`
              }}
              className="flex-grow-0"
            />
          ) : currentDashboard === 'jeras' ? (
            <img
              src={img}
              alt="website logo"
              style={{
                width: `${Responsive.w(46.2, width)}px`,
                height: `${Responsive.h(62.2, height)}px`
              }}
              className="flex-grow-0"
            />
          ) : null}
          <h1
            className="capitalize font-semibold font-[Montserrat] text-center flex-grow-0"
            style={{
              width: `${Responsive.w(97, width)}px`,
              fontSize: `${Responsive.sp(36, width)}px`,
              color: logoColor
            }}
          >
            {currentDashboard || 'Focalpoint'}
          </h1>
        </div>
        {dashboards?.length > 1 && (
          <div className="flex items-center justify-end gap-2">
            <Icon
              icon="mingcute:down-fill"
              width={24}
              height={24}
              style={{
                color: logoColor
              }}
            />
          </div>
        )}
      </button>

      {isOpen && (
        <div
          id="dropdown"
          className="absolute z-10 text-center bg-white flex flex-col items-center  justify-center w-full mx-auto left-0"
          
        >
          <ul
            className="py-2 text-lg font-extrabold text-gray-700 dark:text-gray-200 space-y-2 mx-auto pr-1 flex flex-col items-center justify-center"
            style={{
              width: `${Responsive.w(312, width)}px`
            }}
            aria-labelledby="dropdownDefaultButton"
          >
            {dashboards?.includes('dream') && (
              <li
                className={`bg-[#ECC2E3] rounded text-primary text-xl cursor-pointer font-bold hover:bg-[#E8B2DC] flex items-center justify-center mx-auto`}
                onClick={() => {
                  (currentDashboard === 'dream' ? null : changeDashboard('dream')) &&
                    navigate('/dashboard');
                }}
                style={{
                  width: `${Responsive.w(250, width)}px`
                }}
              >
                <img
                  src="/images/logo.webp"
                  alt="dream website logo"
                  width={40}
                  height={25}
                  className="w-8 h-8"
                />
                <p className="block px-4 py-2">Dream</p>
              </li>
            )}
            {dashboards?.includes('jeras') && (
              <li
                className={`bg-[#D0CADB] rounded text-primary text-xl font-bold hover:bg-[#A79CBD] flex items-center mx-auto justify-center cursor-pointer `}
                onClick={() => {
                  (currentDashboard === 'jeras' ? null : changeDashboard('jeras')) &&
                  navigate('/dashboard');
                }}
                style={{
                  width: `${Responsive.w(250, width)}px`
                }}
              >
                <img
                  className="w-8 h-8"
                  src={img}
                  alt="jeras"
                  width={40}
                  height={25}
                />
                <p className="block px-4 py-2 text-[#7B6C96]">Jeras</p>
              </li>
            )}
            {dashboards?.includes('beaut') && (
              <li
                className={`bg-[#FFD8E2] rounded text-[#cf0036] text-xl font-bold hover:bg-[#cf6a85] flex items-center mx-auto justify-center cursor-pointer`}
                onClick={() => {
                  (currentDashboard === 'nikah' ? null : changeDashboard('beaut')) &&
                    navigate('/dashboard');
                }}
                style={{
                  width: `${Responsive.w(250, width)}px`
                }}
              >
                <img
                  className="w-8 h-8"
                  src={nikah}
                  alt="Nikah"
                  width={40}
                  height={25}
                />
                <p className="block px-4 py-2 text-[#cf0036]">Nikah</p>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DashboardSelector;

