import { Icon } from '@iconify/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

function SmallLoader() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
switch (currentDashboard) {
  case 'dream':
    dashColor = '#a12f88';
    break;
  case 'nikah':
    dashColor = '#cf0036';
    break;
  case 'jeras':
    dashColor = '#7b6c96';
    break;
  default:
    dashColor = '#a12f88';
    break;
}
  return (
    <div className="shrink-0 flex flex-col justify-center items-center">
      <Icon
        icon="svg-spinners:3-dots-fade"
        width={50}
       color={dashColor}
      />
    </div>
  );
}

export default SmallLoader;
