import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  // Extract the token from URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
        // const response = await axios.post('https:api.focalpoint-apps.com/reset-password', {
        //   newPassword,
        //   token
        // });
      //  test
        const response = await axios.post('http://localhost:/reset-password', {
          newPassword,
          token
        });
      toast.success('password has changed successfully');
      setMessage('redirecting to login ...');
      setTimeout(() => {
        navigate('/account/login');
      }, 3000);
    } catch (error: any) {
      toast.error(error?.response?.data || 'An error has occurred while changing password');
      console.log('error', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Link
            to="/account/login"
            className="flex justify-center"
          >
            <img
              src="/images/focalPointLogo.png"
              alt="website logo"
              className="w-full max-w-[8rem]"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {useIntl().formatMessage({
              id: 'resetYourPassword',
              defaultMessage: 'Reset Your Password'
            })}
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={handleSubmit}
        >
          <input
            type="hidden"
            name="remember"
            value="true"
          />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="new-password"
                className="sr-only"
              >
                {useIntl().formatMessage({
                  id: 'newPassword',
                  defaultMessage: 'New Password'
                })}
              </label>
              <input
                id="new-password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={useIntl().formatMessage({
                  id: 'newPassword',
                  defaultMessage: 'New Password'
                })}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="sr-only"
              >
                {useIntl().formatMessage({
                  id: 'confirmPassword',
                  defaultMessage: 'Confirm Password'
                })}
              </label>
              <input
                id="confirm-password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={useIntl().formatMessage({
                  id: 'confirmYourPassword',
                  defaultMessage: 'Confirm Your Password'
                })}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#2980BA] hover:bg-slate-600"
            >
              {useIntl().formatMessage({
                id: 'resetYourPassword',
                defaultMessage: 'Reset Your Password'
              })}
            </button>
          </div>
        </form>
        {message && <p className="mt-2 text-center text-sm text-red-600">{message}</p>}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
