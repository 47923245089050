import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import { getDates } from './../../../helper/DateUtils';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import img_1 from '../../images/Union.png';
import img_1_2 from '../../images/Union@2x.png';
import img_1_3 from '../../images/Union@3x.png';
import img_2 from '../../images/ban Aban.png';
import img_2_2 from '../../images/ban Aban@2x.png';
import img_2_3 from '../../images/ban Aban@3x.png';
import img_3 from '../../images/credit-card.png';
import img_3_2 from '../../images/credit-card@2x.png';
import img_3_3 from '../../images/credit-card@3x.png';
import img_4 from '../../images/calendar-close-fill.png';
import img_4_2 from '../../images/calendar-close-fill@2x.png';
import img_4_3 from '../../images/credit-card@3x.png';
import img_5 from '../../images/Vector.png';
import img_5_2 from '../../images/Vector@2x.png';
import img_5_3 from '../../images/Vector@3x.png';
import img_6 from '../../images/Frame 10000038683.png';
import img_6_2 from '../../images/Frame 10000038683@2x.png';
import img_6_3 from '../../images/Frame 10000038683@3x.png';
import { RootState } from 'src/store';

export default function TodayTransactions({
  todayChargesCount,
  countLoading,
  ChangeFilter,
  tableRef,
  color
}) {
  const { width, height } = useWindowDimensions();
  const { from, to } = getDates('today');
  console.log('from', from);
  console.log('to', to);

  // Function to scroll to the table
  const scrollToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div
      className="  space-y-6 "
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div
        className="flex flex-row justify-start items-start sm:gap-[14px] gap-[24px] ml-auto  mt-[24px] mr-0 mb-[32px]  p-0 pr-[20px]"
        style={{
          width: `${Responsive.w(1584, width)}px`,
          height: `${Responsive.h(110, height)}px`
        }}
      >
        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] cursor-pointer bg-white border-solid border-b-4 border-b-[#4ad991] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`
          }}
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'CAPTURED' }, 'queries');
            scrollToTable();
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalCaptured"
                defaultMessage="Total captured"
              />
            </p>
            <p
              className="tracking-[1px]    text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {countLoading ? <Spinner /> : todayChargesCount.CAPTURED}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#d9f7e8]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_1}
              srcSet={`${img_1_2}, ${img_1_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] cursor-pointer bg-white border-solid border-b-4 border-b-[#e6bc59] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`
          }}
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'ABANDONED' }, 'queries');
            scrollToTable();
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70  text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalAbandoned"
                defaultMessage="Total abandoned"
              />
            </p>
            <p
              className="tracking-[1px]    text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {countLoading ? <Spinner /> : todayChargesCount.ABANDONED}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#fff3d6]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_2}
              srcSet={`${img_2_2}, ${img_2_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] cursor-pointer bg-white border-solid border-b-4 border-b-[#ba2b4a] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`
          }}
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'ABANDONED' }, 'queries');
            scrollToTable();
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70 text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalDeclined"
                defaultMessage="Total declined"
              />
            </p>
            <p
              className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {countLoading ? <Spinner /> : todayChargesCount.DECLINED}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px]  bg-[#ffebeb]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_3}
              srcSet={`${img_3_2}, ${img_3_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] cursor-pointer bg-white border-solid border-b-4 border-b-[#7b6c96] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`,
            borderBottomColor:color
          }}
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'DECLINED' }, 'queries');
            scrollToTable();
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalCancelled"
                defaultMessage="Total Cancelled"
              />
            </p>
            <p
              className="tracking-[1px]    text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {countLoading ? <Spinner /> : todayChargesCount.CANCELLED}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] "
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <Icon
            icon={"bx:calendar-x"}
           
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
             
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`,
                color:color
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] cursor-pointer bg-white border-solid border-b-4  border-b-[#2980ba] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`
          }}
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'INITIATED' }, 'queries');
            scrollToTable();
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(95, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalInitiated"
                defaultMessage="Total initiated"
              />
            </p>
            <p
              className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {countLoading ? <Spinner /> : todayChargesCount.INITIATED}{' '}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#d9f7e8]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_5}
              srcSet={`${img_5_2}, ${img_5_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>

        <Card
          className="flex  justify-between items-center  py-[24px] px-[16px] cursor-pointer bg-white border-solid border-b-4 border-b-[#f93c65] rounded-[16px]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(110, height)}px`
          }}
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'FAILED' }, 'queries');
            scrollToTable();
          }}
        >
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
            style={{
              width: `${Responsive.w(99, width)}px`,
              height: `${Responsive.h(62, height)}px`
            }}
          >
            <p
              className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
              style={{
                width: `${Responsive.w(99, width)}px`,
                height: `${Responsive.h(20, height)}px`,
                fontSize: `${Responsive.sp(16, width)}px`
              }}
            >
              <FormattedMessage
                id="TotalFailed"
                defaultMessage="Total Failed"
              />
            </p>
            <p
              className="tracking-[1px]   text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
              style={{
                width: `${Responsive.w(88, width)}px`,
                height: `${Responsive.h(34, height)}px`,
                fontSize: `${Responsive.sp(28, width)}px`
              }}
            >
              {countLoading ? <Spinner /> : todayChargesCount.FAILED}
            </p>
          </div>
          <div
            className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#ffebeb]"
            style={{
              width: `${Responsive.w(42, width)}px`,
              height: `${Responsive.h(42, height)}px`
            }}
          >
            <img
              src={img_6}
              srcSet={`${img_6_2}, ${img_6_3}`}
              className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
              alt="Description of the image"
              style={{
                width: `${Responsive.w(30, width)}px`,
                height: `${Responsive.h(33.3, height)}px`
              }}
            />
          </div>
        </Card>
      </div>

      {/* <div className="grid grid-cols-3 gap-8 w-3/4 mx-auto text-xl">
        <Card
          className="flex flex-col justify-center items-center py-12 hover:scale-105 hover:cursor-pointer"
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'CAPTURED' }, 'queries');
            scrollToTable();
          }}
        >
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={30}
            height={30}
            icon="clarity:inbox-line"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="TotalCaptured"
              defaultMessage="Total captured"
            />
          </p>
          <p className="font-semibold">{countLoading ? <Spinner /> : todayChargesCount.CAPTURED}</p>
        </Card>

        <Card
          className="flex flex-col justify-center items-center py-12 hover:scale-105 hover:cursor-pointer"
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'ABANDONED' }, 'queries');
            scrollToTable();
          }}
        >
          <Icon
            className="shrink-0 text-yellow-500"
            width={30}
            height={30}
            icon="fluent-mdl2:entry-decline"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="TotalAbandoned"
              defaultMessage="Total abandoned"
            />
          </p>
          <p className="font-semibold">
            {countLoading ? <Spinner /> : todayChargesCount.ABANDONED}
          </p>
        </Card>

        <Card
          className="flex flex-col justify-center items-center py-12 hover:scale-105 hover:cursor-pointer"
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'FAILED' }, 'queries');
            scrollToTable();
          }}
        >
          <Icon
            className="shrink-0 text-red-400"
            width={30}
            height={30}
            icon="icon-park-twotone:folder-failed"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="TotalFailed"
              defaultMessage="Total Failed"
            />
          </p>
          <p className="font-semibold">{countLoading ? <Spinner /> : todayChargesCount.FAILED}</p>
        </Card>

        <Card
          className="flex flex-col justify-center items-center py-12 hover:scale-105 hover:cursor-pointer"
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'CANCELLED' }, 'queries');
            scrollToTable();
          }}
        >
          <Icon
            className="shrink-0 text-black"
            width={30}
            height={30}
            icon="lets-icons:cancel"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="TotalCancelled"
              defaultMessage="Total Cancelled"
            />
          </p>
          <p className="font-semibold">
            {countLoading ? <Spinner /> : todayChargesCount.CANCELLED}
          </p>
        </Card>
        <Card
          className="flex flex-col justify-center items-center py-12 hover:scale-105 hover:cursor-pointer"
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'INITIATED' }, 'queries');
            scrollToTable();
          }}
        >
          <Icon
            className="shrink-0 text-[#ef4444]"
            width={30}
            height={30}
            icon="material-symbols-light:rocket-launch"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="TotalInitiated"
              defaultMessage="Total initiated"
            />
          </p>
          <p className="font-semibold">
            {countLoading ? <Spinner /> : todayChargesCount.INITIATED}
          </p>
        </Card>
        <Card
          className="flex flex-col justify-center items-center py-12 hover:scale-105 hover:cursor-pointer"
          onClick={() => {
            ChangeFilter({ date: { from, to }, status: 'DECLINED' }, 'queries');
            scrollToTable();
          }}
        >
          <Icon
            className="shrink-0 text-[#c449f5]"
            width={30}
            height={30}
            icon="game-icons:shield-reflect"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="TotalDeclined"
              defaultMessage="Total declined"
            />
          </p>
          <p className="font-semibold">{countLoading ? <Spinner /> : todayChargesCount.DECLINED}</p>
        </Card>
      </div> */}
    </div>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color:dashColor
          }}
        />
       
      </div>
    </>
  );
}

