import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import SharedTime from 'src/components/shared/SharedTime';
import { formatDateFromStringRetString, formatTimestampRetString } from 'src/helper/DateUtils';

const CallCard = ({ call, jitsiToken }) => {
  const navigate = useNavigate();
  const stateColor = {
    closed: 'text-black',
    oncall: 'text-green-600',
    calling: 'text-blue-600',
    refused: 'text-red-500'
  };

  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  };

  const isTimeStampString = typeof call.timeStamp == 'string';

  const joinMeetingLink = (roomId) =>
    `https://focalpoint-apps.live/${roomId}#config.prejoinConfig.enabled=false&userInfo.displayName=%22support%22&jwt=${jitsiToken}`;

  return (
    <div className="w-full rounded-lg overflow-hidden shadow-md hover:shadow-lg bg-white">
      {/* Card Header */}
      <div className="px-6 py-4 border-b flex justify-between">
        <div className=" text-sm mb-2">
          Call started:{' '}
          {call.timeStamp
            ? isTimeStampString
              ? formatDateFromStringRetString(call.timeStamp, options)
              : formatTimestampRetString(call.timeStamp, options)
            : 'N/A'}
        </div>
        <p className={`text-lg ${stateColor[call.state]}`}>{call.callState.toUpperCase()}</p>
      </div>

      {/* Card Body */}
      <div className="px-6 py-4 flex flex-col">
        <div className="flex">
          {/* Caller */}
          <div
            onClick={() => navigate(`/users/${call.callerID}`)}
            className="flex-1 flex flex-col space-y-2 items-center text-center bg-red-50 p-2 rounded-md rounded-r-none hover:scale-110 hover:cursor-pointer"
          >
            <div className="rounded flex items-center justify-center space-x-2 bg-white px-2 mb-8">
              <span>
                <Icon icon="simple-line-icons:call-out" />
              </span>
              <p>caller</p>
            </div>
            <img
              className="w-16 h-16 rounded-full mx-auto"
              src={'/images/avatarph.png'}
              alt="Avatar"
            />
          </div>

          {/* Receiver */}
          <div
            onClick={() => navigate(`/users/${call.reciverId}`)}
            className="flex-1 flex flex-col space-y-2 items-center text-center bg-focal/30 p-2 rounded-md rounded-l-none hover:scale-110 hover:cursor-pointer"
          >
            <div className="rounded flex items-center justify-center space-x-2 bg-white px-2 mb-8">
              <span>
                <Icon icon="simple-line-icons:call-in" />
              </span>
              <p>receiver</p>
            </div>
            <img
              className="w-16 h-16 rounded-full mx-auto"
              src={'/images/avatarph.png'}
              alt="Avatar"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-between mt-2">
          {!['refused', 'calling'].includes(call.callState) && (
            <div className="flex">
              <div className="bg-white rounded px-2">
                <p className="text-sm ">
                  <span>joined: </span>
                  <span className="bg-sky-50 text-xs font-bold">
                    {isTimeStampString
                      ? formatDateFromStringRetString(call.timeStamp, options)
                      : formatTimestampRetString(call.timeStamp, options)}
                  </span>
                </p>
              </div>
              {call.callState == 'closed' && (
                <div className="bg-white rounded px-2">
                  <p className="text-sm">
                    <span>closed: </span>
                    <span className="bg-sky-50 text-xs font-bold">
                      {isTimeStampString
                        ? formatDateFromStringRetString(call.timeStamp, options)
                        : formatTimestampRetString(call.timeStamp, options)}
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="text-xs">call ID : {call.key}</div>
          {call.callState == 'oncall' && (
            <a
              href={joinMeetingLink(call.roomId)}
              target="_blank"
              className="px-2 bg-green-600 text-white rounded hover:bg-green-700 mt-2"
            >
              Join
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallCard;
