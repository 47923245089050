// modalContext.ts

import React, { createContext, useContext, useState } from 'react';

// Define the type for your context value
type ModalContextValue = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}

export function ModalProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const contextValue: ModalContextValue = {
    isModalOpen,
    setIsModalOpen
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
}
