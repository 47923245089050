import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import SideModal from 'src/components/shared/SideModal';
import FilterFields from './FilterFields';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ChangeCustomizeKeys } from 'src/reducers/users';
import useUsersTable from './useUsersTable';
import { useModal } from './modalContext';

function FilteringSideModal() {
  const { keys, customize_keys, data } = useSelector((state: RootState) => state.Users);
  const { ChangeFilter, filters, initialQueries } = useUsersTable();
  const { isModalOpen, setIsModalOpen } = useModal();

  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];

  const [currentTab, setCurrentTab] = useState('filter');
  return (
    <SideModal
      title={useIntl().formatMessage({
        id: 'filterAndCustomizeTable',
        defaultMessage: 'Filter & Customize table'
      })}
      visible={isModalOpen}
      handleClose={() => {
        setIsModalOpen(false);
      }}
      size="max-w-screen-sm"
    >
      <div className="space-y-4 flex flex-col overflow-y-auto">
        <div className="border-b border-b-gray-200 overflow-x-auto grid">
          <ul className="inline-flex">
            {tabs.map((tab: any, index: number) => (
              <li key={index}>
                <button
                  className={[
                    'btn-with-icon !rounded-none font-medium bg-transparent transition-all',
                    currentTab === tab.key
                      ? '!text-gray-800 border-b-2 border-b-gray-800'
                      : '!text-gray-500'
                  ].join(' ')}
                  onClick={() => setCurrentTab(tab.key)}
                >
                  <span>{tab.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 grid overflow-y-auto">
          {currentTab === 'customize' ? (
            <ul className="space-y-2">
              {keys.map((field: string, index: number) => (
                <li key={index}>
                  <label
                    htmlFor={field + '-' + index}
                    className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-200  cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      name={field + '-' + index}
                      id={field + '-' + index}
                      className="form-checkbox shrink-0"
                      value={field}
                      checked={customize_keys.some((key) => key === field)}
                      onChange={ChangeCustomizeKeys}
                    />

                    <p className="text-sm font-medium capitalize flex-1">
                      {useIntl().formatMessage({
                        id: field,
                        defaultMessage: field
                      })}
                    </p>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <FilterFields
              setIsModalOpen={setIsModalOpen}
              onSubmit={(val) => ChangeFilter(val, 'queries')}
              filters={filters}
              onReset={() => {
                ChangeFilter([], 'queries');
                setIsModalOpen(false);
              }}
              handleClose={() => setIsModalOpen(false)}
            />
          )}
        </div>
      </div>
    </SideModal>
  );
}

export default FilteringSideModal;
