import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { IntlProvider } from 'react-intl';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  RouteObject,
  Navigate,
  useLocation,
  Location
} from 'react-router-dom';
import LoadingComponent from './components/shared/LoadingComponent';
import { routes, authenticationRoutes } from './helper/routes';
import ScrollToTop from './hooks/ScrollToTop';
import useAuth from './hooks/useAuth';
import HeaderLayout from './layouts/HeaderLayout';
import { storeToken } from './reducers/auth';

import { RootState } from './store';
import Dashboard from './screens/Dashboard';

function AppWrapper() {
  const isLoading = useAuth();
  const { token } = useSelector((state: RootState) => state.auth);
  if (isLoading) return <LoadingComponent />;

  return (
    <Fragment>
      {token ? (
        <HeaderLayout>
          <Routes>
            {routes.map((route: any, index: string | number) => (
              <Route
                key={index}
                element={route.element}
                path={route.path}
              ></Route>
            ))}
          </Routes>
        </HeaderLayout>
      ) : (
        <Fragment>
          <Routes>
            {authenticationRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                {...route}
              ></Route>
            ))}
          </Routes>
        </Fragment>
      )}
    </Fragment>
  );
}

import en from './translations/en.json';
import fr from './translations/fr.json';
import ar from './translations/ar.json';
import id from './translations/id.json';


import useFirestoreListener from './hooks/useFirestoreListener';
import parseJwt from './helper/parseJwt';
import { setDashboard } from './reducers/dashboard';
import LanguageSelectorTwo from './components/LanguageSelectorTwo';
import LanguageSelector from './components/LanguageSelector';

const messages = { en, fr, ar, id };
function App() {
  const locale = useSelector((state: RootState) => state.Locale.value);
  const user = useSelector((state: RootState) => state.auth.user);
  //logout user if his account got deactivated
  useFirestoreListener(user);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
    >
      
      <Router>
        <AppWrapper />
        <ScrollToTop />
      </Router>
    </IntlProvider>
  );
}

export default App;
