// useFirestoreListener.js
import { useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from 'src/helper/firebase';
import useLogout from './useLogout';
const useFirestoreListener = (user) => {
  const { handleLogged } = useLogout();
  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, 'Admin', user.uid);

      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            if (userData.status !== 'active') {
              // Perform logout operation
              localStorage.removeItem('currentDashboard');
              handleLogged();
            }
          } else {
            console.log('No such document!');
          }
        },
        (err) => {
          console.log('Encountered error:', err);
        }
      );

      return () => unsubscribe();
    }
  }, [user]);
};

export default useFirestoreListener;
