import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import axiosInstance from 'src/helper/AxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';

import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';
import { useSearchParams } from 'react-router-dom';

import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import RangePicker from 'src/components/shared/RangePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { IFilters } from 'src/types/types';
import { getDates } from 'src/helper/DateUtils';
import UsersChart from './UsersChart';
import CumulativePayedBalanceChart from './CumulativePayedBalanceChart';
import LanguagePie from './LanguagesPie';
import CountriesMap from './CountriesMap';
import AverageRevenue from './AverageRevenue';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import FunnelChart from './FunnelChartComponent';
import FunnelChartComponent from './FunnelChartComponent';
import UserTypes from './userTypes';
import BarChartExample from './userTypes';
import OrdersChart from './OrdersChart';
import DeviceChart from './DeviceChart';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import RangePickerTwo from 'src/components/shared/RangePickerTwo';

function useHooks() {
  let rerender: boolean = true;
  const { filters: userFilters, data: currentUsers } = useSelector(
    (state: RootState) => state.Users
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMonthUsersLoading, setIsMonthUsersLoading] = useState<boolean>(false);
  const [isDayEarnDataLoading, setIsDayEarnDataLoading] = useState<boolean>(false);
  const [isOrdersOfDayDataLoading, setIsOrdersOfDayDataLoading] = useState<boolean>(false);
  const [isActiveUsersDataLoading, setIsActiveUsersDataLoading] = useState<boolean>(false);
  const [isInactiveUsersDataLoading, setIsInactiveUsersDataLoading] = useState<boolean>(false);
  const [isUsersLocaleLoading, setIsUsersLocaleLoading] = useState<boolean>(false);
  const [isUsersCountryLoading, setIsUsersCountryLoading] = useState<boolean>(false);
  const [isUsersTypesLoading, setIsUsersTypesLoading] = useState<boolean>(false);
  const [isAverageRevenueLoading, setIsAverageRevenueLoading] = useState<boolean>(false);
  const [isFunnelDataLoading, setIsFunnelDataLoading] = useState<boolean>(false);
  const [averageDate, setAverageDate] = useState(new Date(new Date().getFullYear(), 0, 1));

  const [monthUsers, setMonthUsers] = useState([]);
  const [dayEarnData, setDayEarnData] = useState([]);
  const [ordersOfDayData, setOrdersOfDayData] = useState([]);
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [inactiveUsersData, setInactiveUsersData] = useState([]);
  const [usersLocaleData, setUsersLocaleData] = useState([]);
  const [usersCountryData, setUsersCountryData] = useState([]);
  const [usersTypesData, setUsersTypesData] = useState({});
  const [averageRevenueData, setAverageRevenueData] = useState([]);
  const [funnelData, setFunnelData] = useState<any>();

  useEffect(() => {
    if (!rerender) return;
    GetChartUsers();
    GetDayEarnData();
    GetOrdersOfDay();
    GetAverageRevenue(averageDate.getFullYear());
    GetUsersLocale();
    GetUsersCountry();
    GetUsersTypes();
    GetFunnelData();
    rerender = false;
  }, []);

  async function GetChartUsers(selectedFrom?: any, selectedTo?: any) {
    try {
      setIsMonthUsersLoading(true);
      const { from, to } = getDates('this-month');
      let response;
      if (selectedFrom == undefined && selectedTo == undefined) {
        response = await axiosInstance.get('users', {
          params: { queries: [['createdDateValue', '==', from + '-' + to]] }
        });
      } else {
        response = await axiosInstance.get('users', {
          params: { queries: [['createdDateValue', '==', selectedFrom + '-' + selectedTo]] }
        });
      }
      setMonthUsers(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsMonthUsersLoading(false);
    }
  }

  async function GetDayEarnData(selectedFrom?: any, selectedTo?: any) {
    try {
      setIsDayEarnDataLoading(true);
      const { from, to } = getDates('this-month');
      let response;
      if (selectedFrom == undefined && selectedTo == undefined) {
        response = await axiosInstance.get('orders', {
          params: { queries: [['createdDateValue', '==', from + '-' + to]] }
        });
      } else {
        response = await axiosInstance.get('orders', {
          params: { queries: [['createdDateValue', '==', selectedFrom + '-' + selectedTo]] }
        });
      }

      setDayEarnData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsDayEarnDataLoading(false);
    }
  }

  async function GetOrdersOfDay(selectedFrom?: any, selectedTo?: any) {
    try {
      setIsOrdersOfDayDataLoading(true);
      const { from, to } = getDates('this-month');
      let response;
      if (selectedFrom == undefined && selectedTo == undefined) {
        response = await axiosInstance.get('orders', {
          params: { queries: [['createdDateValue', '==', from + '-' + to]] }
        });
      } else {
        response = await axiosInstance.get('orders', {
          params: { queries: [['createdDateValue', '==', selectedFrom + '-' + selectedTo]] }
        });
      }
      setOrdersOfDayData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsOrdersOfDayDataLoading(false);
    }
  }

  async function GetUsersLocale() {
    try {
      setIsUsersLocaleLoading(true);
      const response = await axiosInstance.get('dashboard/users-locale');
      setUsersLocaleData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsUsersLocaleLoading(false);
    }
  }
  async function GetUsersCountry(startDate?: any, endDate?: any) {
    try {
      setIsUsersCountryLoading(true);
      let response;
      if (startDate && endDate) {
        response = await axiosInstance.get('dashboard/users-country', {
          params: {
            startDate: startDate,
            endDate: endDate
          }
        });
        console.log('response===>TT', response);
      } else {
        response = await axiosInstance.get('dashboard/users-country');
        console.log('response===>TT', response);
      }
      setUsersCountryData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsUsersCountryLoading(false);
    }
  }
  async function GetUsersTypes(startDate?: any, endDate?: any) {
    try {
      setIsUsersTypesLoading(true);
      let response;
      if (startDate && endDate) {
        response = await axiosInstance.get('/type-signIn', {
          params: {
            startDate: startDate,
            endDate: endDate
          }
        });
        console.log('response===>TT', response);
      } else {
        response = await axiosInstance.get('/type-signIn');
        console.log('response===>TT', response);
      }

      setUsersTypesData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      console.log(error);

      generateAlert(msg, 'error');
    } finally {
      setIsUsersTypesLoading(false);
    }
  }

  async function GetAverageRevenue(year: any) {
    try {
      const currentYear = new Date().getFullYear();
      if (year > currentYear || year < 2021) {
        toast.error('There is no data for this year');
        return; // Exit the function early
      }

      setIsAverageRevenueLoading(true);
      function getYearStartEndTimestamps(year) {
        // Start of the year (January 1st) in local time
        const startOfYear = new Date(year, 0, 1);
        startOfYear.setHours(0, 0, 0, 0); // Set to the very start of January 1st
        const startTimestamp = startOfYear.getTime() + 7200000;

        // End of the year (December 31st) in local time
        const endOfYear = new Date(year, 11, 31);
        endOfYear.setHours(23, 59, 59, 999); // Set to the very end of December 31st
        const endTimestamp = endOfYear.getTime();

        return { startTimestamp, endTimestamp };
      }
      const { startTimestamp: from, endTimestamp: to } = getYearStartEndTimestamps(year);
      const response = await axiosInstance.get(`dashboard/average-revenue?from=${from}&to=${to}`);
      setAverageRevenueData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsAverageRevenueLoading(false);
    }
  }

  async function GetFunnelData() {
    try {
      setIsFunnelDataLoading(true);
      let response = await axiosInstance.get('dashboard/orders-by-users');
      setFunnelData(response.data.result.data);
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
    } finally {
      setIsFunnelDataLoading(false);
    }
  }

  return {
    isLoading,
    isMonthUsersLoading,
    setIsMonthUsersLoading,
    monthUsers,
    GetChartUsers,
    dayEarnData,
    GetDayEarnData,
    isDayEarnDataLoading,
    setIsDayEarnDataLoading,
    ordersOfDayData,
    isOrdersOfDayDataLoading,
    setIsOrdersOfDayDataLoading,
    GetOrdersOfDay,
    isActiveUsersDataLoading,
    isInactiveUsersDataLoading,
    activeUsersData,
    inactiveUsersData,
    GetUsersLocale,
    GetUsersCountry,
    usersLocaleData,
    usersCountryData,
    isUsersLocaleLoading,
    GetUsersTypes,
    usersTypesData,
    isUsersTypesLoading,
    GetAverageRevenue,
    isAverageRevenueLoading,
    averageRevenueData,
    averageDate,
    setAverageDate,
    GetFunnelData,
    funnelData,
    isFunnelDataLoading
  };
}

export default function index() {
  const intl = useIntl();
  const { width, height } = useWindowDimensions();
  const {
    isMonthUsersLoading,
    monthUsers,
    GetChartUsers,
    GetDayEarnData,
    dayEarnData,
    isDayEarnDataLoading,
    ordersOfDayData,
    isOrdersOfDayDataLoading,
    GetOrdersOfDay,
    usersLocaleData,
    usersCountryData,
    isUsersLocaleLoading,
    GetUsersTypes,
    GetUsersCountry,
    usersTypesData,
    isUsersTypesLoading,
    GetAverageRevenue,
    isAverageRevenueLoading,
    averageRevenueData,
    averageDate,
    setAverageDate,
    GetFunnelData,
    funnelData,
    isFunnelDataLoading
  } = useHooks();
  const { customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.Users
  );
  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );
  const [startDateForUsersChart, setStartDateForUsersChart] = useState(new Date());
  const [endDateForUsersChart, setEndDateForUsersChart] = useState(new Date());

  const [startDateForUsersTypes, setStartDateForUsersTypes] = useState(new Date());
  const [endDateForUsersTypes, setEndDateForUsersTypes] = useState(new Date());

  const [startDateForUsersCountry, setStartDateForUsersCountry] = useState(new Date());
  const [endDateForUsersCountry, setEndDateForUsersCountry] = useState(new Date());

  const [startDateForRevenueChart, setStartDateForRevenueChart] = useState(new Date());
  const [endDateForRevenueChart, setEndDateForRevenueChart] = useState(new Date());

  const [startDateForOrdersChart, setStartDateForOrdersChart] = useState(new Date());
  const [endDateForOrdersChart, setEndDateForOrdersChart] = useState(new Date());

  // const changeAvgYear = () => {
  //   GetAverageRevenue(averageDate.getFullYear());
  // };
  console.log('usersCountryData$$$$$$$$$$$$$$$$$>', usersCountryData);

  return (
    <div
      className="p-8 pt-12 space-y-4 min-h-screen overflow-hidden"
      style={{
        width: `${Responsive.w(1584, width)}px`
      }}
    >
      <div
        className="  p-2 flex flex-col items-start  gap-4 rounded-md w-full justify-center bg-white"
        style={{
          width: `${Responsive.w(1560, width)}px`
        }}
      >
        <div
          className="gap-6 grid grid-cols-3"
          style={{
            width: `${Responsive.w(1560, width)}px`
          }}
        >
          <div
            className="rounded-md shadow-md p-2 relative bg-white "
            style={{
              width: `${Responsive.w(504, width)}px`
            }}
          >
            {isFunnelDataLoading ? (
              <div className="absolute top-0 z-30 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            ) : (
              <FunnelChartComponent userCategories={funnelData} />
            )}
          </div>
          {/* usertypes */}
          <div
            className="rounded-md shadow-md p-2 relative bg-white"
            style={{
              width: `${Responsive.w(504, width)}px`
            }}
          >
            {isUsersTypesLoading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            )}
            <div>
              <RangePickerTwo
                startDate={startDateForUsersTypes}
                setStartDate={setStartDateForUsersTypes}
                endDate={endDateForUsersTypes}
                setEndDate={setEndDateForUsersTypes}
                applyHandler={() => {
                  GetUsersTypes(new Date(startDateForUsersTypes), new Date(endDateForUsersTypes));
                }}
              />
            </div>
            <UserTypes
              loading={isUsersTypesLoading}
              data={usersTypesData}
            />
          </div>

          {/* lang */}
          <div
            className="rounded-md shadow-md p-2 relative bg-white w-full"
            style={{
              width: `${Responsive.w(504, width)}px`
            }}
          >
            {isUsersLocaleLoading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            )}
            <LanguagePie
              data={usersLocaleData}
              loading={isUsersLocaleLoading}
            />
          </div>
        </div>

        <div
          className="gap-6 grid grid-cols-2"
          style={{
            width: `${Responsive.w(1560, width)}px`
          }}
        >
          {/*New Users*/}
          <div
            className="rounded-md shadow-md p-2 relative bg-white "
            style={{
              width: `${Responsive.w(1032, width)}px`
            }}
          >
            {isMonthUsersLoading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            )}
            <div>
              <RangePicker
                startDate={startDateForUsersChart}
                setStartDate={setStartDateForUsersChart}
                endDate={endDateForUsersChart}
                setEndDate={setEndDateForUsersChart}
                // applyHandler={() => {
                //   GetChartUsers(
                //     formatStartDay(startDateForUsersChart),
                //     formatEndDay(endDateForUsersChart)
                //   );
                // }}
              />
            </div>
            <UsersChart
              data={monthUsers}
              title={'New users'}
            />
          </div>
          <div></div>
        </div>
        <div
          className="gap-6 flex items-start justify-center"
          style={{
            width: `${Responsive.w(1560, width)}px`
          }}
        >
          {/*day orders*/}
          <div
            className="rounded-md shadow-md p-2 relative bg-white "
            style={{
              width: `${Responsive.w(1032, width)}px`
            }}
          >
            {isOrdersOfDayDataLoading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            )}
            <div>
              <RangePicker
                startDate={startDateForOrdersChart}
                setStartDate={setStartDateForOrdersChart}
                endDate={endDateForOrdersChart}
                setEndDate={setEndDateForOrdersChart}
                // applyHandler={() => {
                //   GetOrdersOfDay(
                //     formatStartDay(startDateForOrdersChart),
                //     formatEndDay(endDateForUsersChart)
                //   );
                // }}
              />
            </div>
            <OrdersChart
              data={ordersOfDayData}
              title={'Orders'}
            />
          </div>
          <div className="gap-6 grid grid-cols-2">
            {/*DeviceChart  */}
            <div
              className="rounded-md shadow-md p-2 relative bg-white w-full"
              style={{
                width: `${Responsive.w(240, width)}px`
              }}
            >
              {isUsersLocaleLoading && (
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                  <span className="text-lg font-semibold">Loading...</span>
                </div>
              )}
              <DeviceChart
                data={usersLocaleData}
                loading={isUsersLocaleLoading}
              />
            </div>
          </div>
        </div>

        <div
          className="gap-6 grid grid-cols-2"
          style={{
            width: `${Responsive.w(1560, width)}px`
          }}
        >
          {/*day revenue*/}
          <div
            className="rounded-md shadow-md p-2 relative bg-white"
            style={{
              width: `${Responsive.w(767.7, width)}px`
            }}
          >
            {isDayEarnDataLoading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            )}

            <CumulativePayedBalanceChart data={dayEarnData} />
          </div>
          {/* Average revenue per user */}
          <div
            className="rounded-md shadow-md p-2 relative bg-white "
            style={{
              width: `${Responsive.w(767.7, width)}px`
            }}
          >
            {isAverageRevenueLoading && (
              <div className="absolute top-0 z-30 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-50">
                <span className="text-lg font-semibold">Loading...</span>
              </div>
            )}

            <div className={`flex  space-x-2 items-center justify-center`}>
              {/* <DatePicker
                selected={averageDate}
                onChange={(date) => setAverageDate(date)}
                showYearPicker
                className="bg-slate-300 rounded-xl w-20 text-center"
                dateFormat="yyyy"
                showYearDropdown
                showIcon
              /> */}
              <div className="flex space-x-2 items-center justify-center">
                {/* <button
                  onClick={changeAvgYear}
                  className="bg-focal hover:bg-slate-600 p-2 rounded-lg text-white font-semibold text-sm "
                >
                  <FormattedMessage
                    id="applyFilter"
                    defaultMessage="Apply filter"
                  />
                </button> */}
              </div>
            </div>
            <AverageRevenue
              data={averageRevenueData}
              title={'Average revenue per user'}
            />
          </div>
        </div>

        <div className="rounded-md shadow-md p-2 relative bg-white col-span-2">
          {isUsersLocaleLoading && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-30 bg-gray-300 bg-opacity-50">
              <span className="text-lg font-semibold">Loading...</span>
            </div>
          )}
          <div>
            <RangePickerTwo
              startDate={startDateForUsersCountry}
              setStartDate={setStartDateForUsersCountry}
              endDate={endDateForUsersCountry}
              setEndDate={setEndDateForUsersCountry}
              applyHandler={() => {
                GetUsersCountry(new Date(startDateForUsersCountry), new Date(endDateForUsersCountry));
              }}
            />
          </div>
          <CountriesMap data={usersCountryData} />
        </div>
      </div>
    </div>
  );
}

