import React, { useEffect, useState } from 'react';
import CallCard from './CallCard';
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  query,
  limitToFirst,
  startAt,
  orderByKey,
  equalTo
} from 'firebase/database';
import app from 'src/helper/firebase';
import { where } from 'firebase/firestore';
import SmallLoader from 'src/components/shared/SmallLoader';
import axiosInstance from 'src/helper/AxiosInstance';
import { formatDateFromStringRetDate, formatTimestampRetDate } from 'src/helper/DateUtils';

function Index() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('oncall');
  const [calls, setCalls] = useState<any>([]);
  const [lastKey, setLastKey] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [lastValue, setLastValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jitsiToken, setJitsiToken] = useState('');
  const itemsPerPage = 150;
  const database = getDatabase(app);

  const fetchData = (selectedTab, page) => {
    setLoading(true);

    let dataQuery = query(
      ref(database, '/userCallState'),
      orderByChild('callState'),
      equalTo(selectedTab),
      limitToFirst(itemsPerPage + 1)
    );

    if (page > 1 && lastValue && lastKey) {
      dataQuery = query(
        ref(database, '/userCallState'),
        orderByChild('callState'),
        startAt(lastValue, lastKey),
        limitToFirst(itemsPerPage + 1)
      );
    }

    onValue(dataQuery, (snapshot) => {
      const fetchedData: any = [];
      let itemCount = 0;

      snapshot.forEach((childSnapshot: any) => {
        itemCount++;
        if (fetchedData.length < itemsPerPage) {
          fetchedData.push({ key: childSnapshot.key, ...childSnapshot.val() });
        } else {
          setLastKey(childSnapshot.key);
          setLastValue(childSnapshot.val().callState);
        }
      });

      // Append new calls to existing calls and update hasMore
      if (itemCount > 0) {
        setCalls((prevCalls) => [...prevCalls, ...fetchedData]);
        setHasMore(itemCount > itemsPerPage);
      } else {
        setHasMore(false);
      }

      setLoading(false);
    });
  };

  const fetchJitsiMeetToken = async () => {
    if (selectedTab == 'oncall') {
      const res = await axiosInstance.get('server-settings');
      const { jitsiToken } = res.data.result.data;
      return jitsiToken;
    }
  };

  useEffect(() => {
    if (selectedTab == 'oncall') {
      fetchJitsiMeetToken().then((token) => {
        console.log('token', token);
        setJitsiToken(token);
      });
    }
  }, []);

  const fetchDataWithDelay = (selectedTab) => {
    setLoading(true);
    setTimeout(() => fetchData(selectedTab, 1), 1000); // Delay the fetch call by 1 second
  };

  const hasRequiredFieldsForOncall = (call) => {
    const requiredFields = [
      'callState',
      'callerID',
      'key',
      'reciverId',
      'roomId',
      'timeStamp',
      'joinedDate' // Assuming 'joinedDate' is required for 'oncall'
    ];
    return requiredFields.every((field) => call.hasOwnProperty(field));
  };

  const hasRequiredFieldsForClosed = (call) => {
    const requiredFields = [
      'callState',
      'callerID',
      'key',
      'reciverId',
      'roomId',
      'timeStamp',
      'joinedDate',
      'closedDate'
    ];
    return requiredFields.every((field) => call.hasOwnProperty(field));
  };

  const filterCallsBasedOnTab = (call) => {
    switch (selectedTab) {
      case 'oncall':
        return hasRequiredFieldsForOncall(call);
      case 'closed':
        return hasRequiredFieldsForClosed(call);
      default:
        return true; // No filtering for 'calling' and 'refused' tabs
    }
  };

  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  };

  const filteredCalls = calls
    .filter(filterCallsBasedOnTab) // First, filter calls based on the selected tab
    .reduce((unique, call) => {
      // Use reduce to accumulate only unique calls
      let date;
      const isTimeStampString = typeof call.timeStamp == 'string';
      if (isTimeStampString) {
        date = formatDateFromStringRetDate(call.timeStamp, options);
      } else {
        date = formatTimestampRetDate(call.timeStamp, options);
      }
      if (!unique.some((item) => item.key === call.key)) {
        unique.push({ ...call, date });
        console.log('callolo', { ...call, date });
      }
      return unique;
    }, [])
    .sort((a, b) => b.date - a.date);

  const handleLoadMore = () => {
    // Only fetch next page if not reached the end
    if (lastKey && calls.length % itemsPerPage === 0) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchData(selectedTab, nextPage);
    }
  };

  // useEffect(() => {
  //   setCalls([]); // Reset calls when the tab changes
  //   fetchData(selectedTab, 1);
  // }, [selectedTab]);

  useEffect(() => {
    setCalls([]);
    fetchDataWithDelay(selectedTab); // Use fetchDataWithDelay when the tab changes
  }, [selectedTab]);

  return (
    <div className="min-h-screen flex flex-col p-12 bg-slate-100">
      <div className="flex space-x-4 mb-4">
        {['oncall', 'calling', 'closed', 'refused'].map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setSelectedTab(tab);
              setLastKey(null); // Reset pagination when tab changes
              setLastValue(null);
              fetchData(tab, 1);
            }}
            className={`px-4 py-2 text-sm font-medium rounded-md ${
              selectedTab === tab ? 'bg-focal text-white' : 'bg-gray-200 text-gray-700'
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      <div>
        <div className="flex flex-col items-center space-y-8 justify-center">
          {filteredCalls.map((call, index) => (
            <div
              key={index}
              className="w-1/2"
            >
              <CallCard
                call={call}
                jitsiToken={jitsiToken}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          {loading ? (
            <SmallLoader />
          ) : (
            <button
              disabled={!hasMore}
              onClick={handleLoadMore}
              className="px-4 py-2 border rounded-l-md disabled:opacity-50"
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Index;
