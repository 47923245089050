import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import UploadImage from 'src/components/shared/UploadImage';
import axiosInstance from 'src/helper/AxiosInstance';
import useFetch from 'src/hooks/useFetch';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import vector from '../images/Vector+.png';
import img_1 from '../images/delete-bin-line.png';
import img_1_2 from '../images/delete-bin-line@2x.png';
import img_1_3 from '../images/delete-bin-line@3x.png';
import img_2 from '../images/Edit.png';
import img_2_2 from '../images/Edit@2x.png';
import img_2_3 from '../images/Edit@3x.png';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import { usePermissions } from 'src/hooks/usePeermissions';
export default function index() {
  const { width, height } = useWindowDimensions();

  return (
    <div
      className="flex justify-center  min-h-screen p-[8px] bg-[#fff]"
      style={{
        width: `${Responsive.w(1584, width)}px`
        // height: `${Responsive.h(24, height)}px`
      }}
    >
      <BannersGallery />
    </div>
  );
}

const BannersGallery = () => {
  const { width, height } = useWindowDimensions();
  // const { data, isDataLoading, error } = useFetch(`/banners/`, true);
  const [bannersData, setBannersData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const { user } = useSelector((state: RootState) => state.auth);
  const [userLoading, setUserLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [userLang, setUserLang] = useState('');
  const Permission = usePermissions();
  const emptyBannerData = {
    name: '',
    image: '',
    phone: '',
    status: false,
    lang: 'ar',
    id: '',
    uid: ''
  };
  const [selectedBanner, setSelectedBanner] = useState<any>(emptyBannerData); // New state for selected banner

  const openModal = (banner) => {
    setSelectedBanner(banner);
    setIsModalOpen(true);
  };

  useEffect(() => {
    setUserLoading(true);
    if (user.Language) {
      setUserLang((prev) => {
        return user.Language.map((lang) => {
          return lang === 'English'
            ? 'en'
            : lang === 'Arabic'
            ? 'ar'
            : lang === 'French'
            ? 'fr'
            : 'ar';
        });
      });
    }
    setUserLoading(false);
  }, [user]);

  console.log('userLang', userLang);

  async function fetchBanners() {
    setIsDataLoading(true);
    try {
      const respons = await axiosInstance.get(`/banners/`, {
        params: {
          userLang
        }
      });
      setBannersData(respons.data.result.data);
    } catch (error: any) {
      toast.error('There is an error: ' + error?.message);
    }
    setIsDataLoading(false);
  }
console.log('bannersData', bannersData);


useEffect(() => {
  if (userLang.length > 0 && !userLoading) {
    fetchBanners();
  }
}, [userLang]);

  if (isDataLoading) {
    return <LoadingComponent />;
  }

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const handleDeleteBanner = async (bannerId) => {
    const confirmationMsg = intl.formatMessage({
      id: 'bannerDeleted',
      defaultMessage: ' Are you sure you want to delete this banner?'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        await axiosInstance.delete(`banners/${bannerId}`);

        setIsModalOpen(false);
        setBannersData((prevData) => prevData.filter((banner) => banner.id !== bannerId));
        const successMessage = intl.formatMessage({
          id: 'bannerDeletedMsg',
          defaultMessage: 'Banner has been deleted successfully'
        });
        toast.success(successMessage);
      } catch (error) {
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });
  };
  const showConfirmation = useConfirmationDialog();

  const hasEditOrDeletePermission = (item) => {
    return item?.permission?.includes('Edit') || item?.permission?.includes('Delete');
  };
  const hasDeletePermission = (item) => {
    return item?.permission?.includes('Delete');
  };
  const hasEditPermission = (item) => {
    return item?.permission?.includes('Edit');
  };
  return (
    <div className="mt-2">
      {Permission?.some(hasEditPermission) && (
        <button
          className=" cursor-pointer sm:w-auto bg-[#f5f0ff] border-[1px] border-solid  h-[56px] inline-flex items-center mr-auto ml-[2px] py-[5px] px-[16px] rounded-[8px]  justify-center"
          onClick={() => openModal(emptyBannerData)}
          style={{
            width: `${Responsive.w(240, width)}px`,
            height: `${Responsive.h(56, height)}px`,
            borderColor: dashColor
          }}
        >
          <p
            className="shrink-0 text-[14px] font-medium font-[Montserrat] text-[#262626] flex items-center justify-center gap-[16px]"
            style={{
              width: `${Responsive.w(240, width)}px`,
              height: `${Responsive.h(56, height)}px`
            }}
          >
            <Icon
              icon={'iconamoon:sign-plus-bold'}
              className="object-contain p-0 w-[20px] h-[20px] "
              style={{
                color: dashColor
              }}
            />
            <span>
              {useIntl().formatMessage({
                id: 'addBanner',
                defaultMessage: 'Add Banner'
              })}
            </span>
          </p>
        </button>
      )}

      {/* <div className="flex justify-end p-4 w-full">
        <button
          
          className="bg-primary hover:bg-slate-500 text-white font-bold py-2 px-4 rounded"
        >
          {useIntl().formatMessage({
            id: 'addBanner',
            defaultMessage: 'Add Banner'
          })}
        </button>
      </div>  */}

      <div
        className="grid grid-cols-3 gap-[24px] mx-auto p-[16px]"
        style={{
          width: `${Responsive.w(1560, width)}px`
          // height: `${Responsive.h(24, height)}px`
        }}
      >
        {bannersData?.map((banner:any) => (
          <div
            key={banner?.id}
            className="flex justify-center items-center rounded-[11.6px] bg-[#f7f7f7]  shadow-md relative"
            style={{
              width: `${Responsive.w(492, width)}px`,
              height: `${Responsive.h(164, height)}px`
            }}
          >
            {Permission?.some(hasEditOrDeletePermission) && (
              <div className="gap-[12.9px] absolute left-3 top-2 p-0 flex hover:cursor-pointer">
                {Permission?.some(hasDeletePermission) && (
                  <button onClick={() => handleDeleteBanner(selectedBanner.id)}>
                    <p className=" flex items-center justify-center rounded-full bg-[#ffdbe3] w-[29.7px]  h-[29.7px] p-0">
                      <img
                        src={img_1}
                        srcSet={`${img_1_2}, ${img_1_3}`}
                        className="object-contain p-0 w-[14px] h-[14px] "
                        alt="Description of the image"
                      />
                    </p>
                  </button>
                )}
                {Permission?.some(hasEditPermission) && (
                  <button onClick={() => openModal(banner)}>
                    <p className=" flex items-center justify-center rounded-full bg-[#ede8f6] w-[29.7px]  h-[29.7px] p-0">
                      <Icon
                        icon={'iconamoon:edit-fill'}
                        className="object-contain p-0 w-[14] h-[14px] "
                        style={{
                          color: dashColor
                        }}
                      />
                    </p>
                  </button>
                )}
              </div>
            )}
            <img
              src={banner.image }
              alt="Banner"
              style={{
                width: `${Responsive.w(492, width)}px`,
                height: `${Responsive.h(164, height)}px`
              }}
              className="rounded-[11.6px]"
            />
          </div>
        ))}
        {Permission?.some(hasEditPermission) && (
          <button
            className=" cursor-pointer sm:w-auto bg-[#f5f0ff] border-[1px] border-solid   h-[56px] inline-flex items-center rounded-lg justify-center"
            style={{
              width: `${Responsive.w(492, width)}px`,
              height: `${Responsive.h(164, height)}px`,
              borderColor: dashColor
            }}
            onClick={() => openModal(emptyBannerData)}
          >
            <p
              className="shrink-0 text-[14px] font-medium font-[Montserrat] text-[#262626] flex items-center justify-center gap-[16px]"
              style={{
                width: `${Responsive.w(492, width)}px`,
                height: `${Responsive.h(164, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              <Icon
                icon={'iconamoon:sign-plus-bold'}
                className="object-contain p-0 w-[24px] h-[24px] "
                style={{
                  color: dashColor
                }}
              />
              <span>
                {useIntl().formatMessage({
                  id: 'addBanner',
                  defaultMessage: 'Add Banner'
                })}
              </span>
            </p>
          </button>
        )}
        <FormModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          data={selectedBanner}
        
          setBannersData={setBannersData}
          bannersData={bannersData}
          dashColor={dashColor}
        />
      </div>
    </div>
  );
};

const FormModal = ({ isOpen, setIsOpen, data,  bannersData, setBannersData, dashColor }) => {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileWeb, setSelectedFileWeb] = useState(null);
  const intl = useIntl();
  const [name, setName] = useState(data.name);
  const [phone, setPhone] = useState(data.phone);
  const [lang, setLang] = useState(data.lang);
  const [type, setType] = useState(data.type);
  const [itemId, setItemId] = useState(data.itemId);
  const [image, setImage] = useState(data.image);
  const [webImage, setWebImage] = useState(data.webImage);
  const [url, setUrl] = useState(data.url);
  const [status, setStatus] = useState<boolean>(data.status);
  const [courses, setCourses] = useState([]);
  const fileInputRef = useRef<any>(null);
  const fileInputWebRef = useRef<any>(null);
  const { width, height } = useWindowDimensions();

  // State for validation messages
  const [nameError, setNameError] = useState('');
  const [langError, setLangError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [courseError, setcourseError] = useState('');

  useEffect(() => {
    if (data) {
      setName(data.name || '');
      setPhone(data.phone || '');
      setLang(data.lang || '');
      setType(data.type || '');
      setItemId(data.itemId || '');
      setImage(data.image || '');
      setWebImage(data.webImage || '');
      setUrl(data.url || '');
      setStatus(data.status || false);
    } else {
      // Reset to default values for adding a new banner
      setName('');
      setPhone('');
      setLang('');
      setType('');
      setItemId('');
      setImage('');
      setWebImage('');
      setUrl('');
      setStatus(false);
    }
  }, [data]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleFileChangeWeb = (event) => {
    const file = event.target.files[0];
    setSelectedFileWeb(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setWebImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleClose = () => {
    setIsOpen(false);
    setName(data.name);
    setPhone(data.phone);
    setLang(data.lang);
    setType(data.type);
    setItemId(data.itemId);
    setImage(data.image);
    setWebImage(data.webImage);
    setUrl(data.url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirmationMsg = intl.formatMessage({
      id: 'bannerConfirmationMsg',
      defaultMessage: 'Are you sure you want to add this banner?'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        const errMessage = intl.formatMessage({
          id: 'fileMissingError',
          defaultMessage: 'Please select a file to upload.'
        });

        let uploadedImage = image;
        if (data?.image !== image && selectedFile) {
          const formData = new FormData();
          formData.append('file', selectedFile);
          const response = await axiosInstance.post('/uploadImg', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          uploadedImage = response.data.result.data.imgUrl;
          setImage(uploadedImage);
        }

        let uploadedWebImage = webImage;
        if (data?.webImage !== webImage && selectedFileWeb) {
          const formDataWeb = new FormData();
          formDataWeb.append('file', selectedFileWeb);
          const response = await axiosInstance.post('/uploadImg', formDataWeb, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          uploadedWebImage = response.data.result.data.imgUrl;
          setWebImage(uploadedWebImage);
        }

        const reqBody = {
          image: uploadedImage,
          webImage: uploadedWebImage,
          name,
          phone,
          lang,
          type,
          itemId,
          status,
          url: url || 'noLink'
        };

        let bannerResponse;
        if (data.id) {
          bannerResponse = await axiosInstance.patch(`/banners/${data.id}`, reqBody);
        } else {
          bannerResponse = await axiosInstance.post('/banners/', reqBody);
        }

        setIsOpen(false);
        setBannersData((prevData) =>
          prevData.map((banner) =>
            banner.id === data.id ? { ...banner, ...bannerResponse.data.result.data } : banner
          )
        );

        const successMessage = intl.formatMessage({
          id: 'bannerUpdated',
          defaultMessage: 'Banner updated successfully.'
        });
        toast.success(successMessage);
      } catch (error: any) {
        console.error('Error during file upload:', error.response?.data || error.message);
        const errMessage = intl.formatMessage({
          id: 'fileUploadError',
          defaultMessage: 'Error during file upload.'
        });
        toast.error(errMessage);
      }
    });
  };

  const handleDeleteBanner = async (bannerId) => {
    const confirmationMsg = intl.formatMessage({
      id: 'bannerDeleted',
      defaultMessage: ' Are you sure you want to delete this banner?'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        await axiosInstance.delete(`banners/${bannerId}`);
        setBannersData((prevData) => prevData.filter((banner) => banner.id !== bannerId));
        setIsOpen(false);
        const successMessage = intl.formatMessage({
          id: 'bannerDeletedMsg',
          defaultMessage: 'Banner has been deleted successfully'
        });
        toast.success(successMessage);
      } catch (error) {
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });
  };
  const showConfirmation = useConfirmationDialog();

  const validateForm = () => {
    let isValid = true;
    if (!name) {
      const errMsg = intl.formatMessage({
        id: 'nameRequired',
        defaultMessage: 'Name is required'
      });
      setNameError(errMsg);
      isValid = false;
    } else {
      setNameError('');
    }

    if (!lang) {
      const errMsg = intl.formatMessage({
        id: 'languageIsRequired',
        defaultMessage: 'Language is required'
      });
      setLangError(errMsg);
      isValid = false;
    } else {
      setLangError('');
    }

    if (type === 'course' && !itemId) {
      const errMsg = intl.formatMessage({
        id: 'courseRequired',
        defaultMessage: 'Course is required'
      });
      setcourseError(errMsg);
      isValid = false;
    } else {
      setcourseError('');
    }

    if (!type) {
      const errMsg = intl.formatMessage({
        id: 'typeIsRequired',
        defaultMessage: 'Type is required'
      });
      setTypeError(errMsg);
      isValid = false;
    } else {
      setTypeError('');
    }

    return isValid;
  };

  async function fetchAllCourses() {
    try {
      const responsCourses = await axiosInstance.get(`/courses/?Active=true`);
      setCourses(responsCourses.data.result.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }

  useEffect(() => {
    fetchAllCourses();
  }, []);

  return (
    <Modal
      visible={isOpen}
      handleClose={handleClose}
      title={data?.id ? 'Edit Banner' : 'Add Banner'}
    >
      <form
        className="flex items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="flex-col space-y-4  w-full">
          <div
            className="flex flex-row justify-between items-center p-0 flex-grow-0 "
            style={{
              width: `${Responsive.w(748, width)}px`,
              height: `${Responsive.h(63, height)}px`
            }}
          >
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'name',
                defaultMessage: 'name'
              })}
            </label>
            <input
              className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                width: `${Responsive.w(532, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
              placeholder={useIntl().formatMessage({
                id: 'enterNameOfCons',
                defaultMessage: 'enter name of consultant'
              })}
            />
          </div>
          <div
            className="flex flex-row justify-between items-center p-0 flex-grow-0 "
            style={{
              width: `${Responsive.w(748, width)}px`,
              height: `${Responsive.h(63, height)}px`
            }}
          >
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'phone',
                defaultMessage: 'phone'
              })}
            </label>
            <input
              className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                width: `${Responsive.w(532, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
              placeholder={useIntl().formatMessage({
                id: 'enterPhoneNum',
                defaultMessage: 'enter phone number'
              })}
            />
          </div>

          <div className="flex justify-between items-center">
            <label
              htmlFor="type"
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'language',
                defaultMessage: 'language'
              })}
            </label>
            <select
              id="lang"
              name="lang"
              value={lang}
              required
              onChange={(e) => setLang(e.target.value)}
              className="flex items-center outline-none font-[Montserrat] justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                width: `${Responsive.w(532, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <option value="">
                {useIntl().formatMessage({
                  id: 'selectLanguage',
                  defaultMessage: 'Select language'
                })}
              </option>
              <option value="en">
                {useIntl().formatMessage({
                  id: 'en',
                  defaultMessage: 'English'
                })}
              </option>
              <option value="ar">
                {useIntl().formatMessage({
                  id: 'ar',
                  defaultMessage: 'العربية'
                })}
              </option>
              <option value="fr">
                {useIntl().formatMessage({
                  id: 'fr',
                  defaultMessage: 'français'
                })}
              </option>
              <option value="id">
                {useIntl().formatMessage({
                  id: 'id',
                  defaultMessage: 'bahasa Indonesiacode'
                })}
              </option>
            </select>
          </div>
          {/* type */}
          <div className="flex justify-between items-center">
            <label
              htmlFor="type"
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'type',
                defaultMessage: 'type'
              })}
            </label>
            <select
              id="type"
              name="type"
              value={type}
              required
              onChange={(e) => setType(e.target.value)}
              className="flex items-center outline-none font-[Montserrat] justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                width: `${Responsive.w(532, width)}px`,
                height: `${Responsive.h(63, height)}px`
              }}
            >
              <option value="">Select type</option>
              {currentDashboard == 'jeras' && (
                <option value="course">
                  {useIntl().formatMessage({
                    id: 'course',
                    defaultMessage: 'course'
                  })}
                </option>
              )}

              <option value="url">
                {useIntl().formatMessage({
                  id: 'url',
                  defaultMessage: 'url'
                })}
              </option>
            </select>
          </div>
          {!type && typeError && <p className="text-red-500">{typeError}</p>}
          {/* course */}
          {type === 'course' && currentDashboard == 'jeras' && (
            <div className="flex justify-between items-center">
              <label
                className="w-1/3  font-[Montserrat] font-semibold text-left"
                style={{
                  color: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'typecourse',
                  defaultMessage: 'select Course'
                })}
              </label>
              <select
                className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                style={{
                  borderRadius: `${Responsive.sp(8, width)}px`
                  // height: `${Responsive.h(44, height)}px`
                }}
                value={itemId}
                onChange={(e) => setItemId(e.target.value)}
              >
                <option value="">Select Course</option>
                {courses.map((course: any, index) => {
                  return (
                    <option
                      key={index}
                      value={course.courseId}
                    >
                      {course.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {type === 'course' && !itemId && courseError && (
            <p className="text-red-500">{courseError}</p>
          )}
          {/* url */}
          {type === 'url' && (
            <div className="flex justify-between items-center">
              <label
                className=" font-[Montserrat] font-semibold text-left"
                style={{
                  width: `${Responsive.w(64, width)}px`,
                  color: dashColor,
                  height: `${Responsive.h(29, height)}px`,
                  fontSize: `${Responsive.sp(24, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'url',
                  defaultMessage: 'url'
                })}
              </label>
              <input
                className="flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                style={{
                  width: `${Responsive.w(532, width)}px`,
                  height: `${Responsive.h(63, height)}px`
                }}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder={useIntl().formatMessage({
                  id: 'enterURL',
                  defaultMessage: 'enter URL'
                })}
              />
            </div>
          )}
          {/* image */}
          {image ? (
            <div className="flex justify-between items-center relative">
              <label
                htmlFor="file-upload"
                className="inline-block cursor-pointer"
              >
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                />
                <div className="w-8 h-8 flex items-center justify-center bg-[#7b6c96] rounded-full right-2 top-2 absolute p-2 hover:bg-slate-600">
                  <Icon
                    icon="bxs:edit"
                    color="white"
                    width={24}
                    height={24}
                  />
                </div>
              </label>
              <img
                src={image}
                alt="Banner"
                id="imagePreview"
                className="w-120 h-40 object-stretch"
                style={{
                  width: `${Responsive.w(492, width)}px`,
                  height: `${Responsive.h(164, height)}px`
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col justify-between items-center gap-3">
              <div className="flex justify-between items-center w-full">
                <label
                  htmlFor="file-upload"
                  className="font-[Montserrat] font-semibold text-left nowrap"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'uploadImage',
                    defaultMessage: 'Upload Image'
                  })}
                </label>

                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  className="flex items-center outline-none font-[Montserrat] justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                />
              </div>
              {image && (
                <img
                  src={image}
                  alt="Banner"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(200, height)}px`
                  }}
                  id="imagePreview"
                  className="w-120 h-40 object-stretch"
                />
              )}
            </div>
          )}
          {/* webImage */}
          {webImage ? (
            <div className="flex justify-between items-center relative">
              <label
                htmlFor="file-upload-web"
                className="inline-block cursor-pointer"
              >
                <input
                  id="file-upload-web"
                  type="file"
                  onChange={handleFileChangeWeb}
                  className="hidden"
                  ref={fileInputWebRef}
                />
                <div className="w-8 h-8 flex items-center justify-center bg-[#7b6c96] rounded-full right-2 top-2 absolute p-2 hover:bg-slate-600">
                  <Icon
                    icon="bxs:edit"
                    color="white"
                    width={24}
                    height={24}
                  />
                </div>
              </label>
              <img
                src={webImage}
                alt="Web Banner"
                id="webImage"
                className="w-120 h-40 object-stretch"
                style={{
                  width: `${Responsive.w(492, width)}px`,
                  height: `${Responsive.h(164, height)}px`
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col justify-between items-center gap-3">
              <div className="flex justify-between items-center w-full">
                <label
                  htmlFor="file-upload-web"
                  className="font-[Montserrat] font-semibold text-left nowrap"
                  style={{
                    width: `${Responsive.w(64, width)}px`,
                    color: dashColor,
                    height: `${Responsive.h(29, height)}px`,
                    fontSize: `${Responsive.sp(24, width)}px`
                  }}
                >
                  {useIntl().formatMessage({
                    id: 'webImage',
                    defaultMessage: 'Upload Web Image'
                  })}
                </label>

                <input
                  id="file-upload-web"
                  type="file"
                  onChange={handleFileChangeWeb}
                  className="flex items-center outline-none font-[Montserrat] justify-start gap-[10px] flex-grow-0 py-0 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(63, height)}px`
                  }}
                />
              </div>
              {webImage && (
                <img
                  src={webImage}
                  alt="Web Banner"
                  style={{
                    width: `${Responsive.w(532, width)}px`,
                    height: `${Responsive.h(200, height)}px`
                  }}
                  id="webImage"
                  className="w-120 h-40 object-stretch"
                />
              )}
            </div>
          )}

          <div className="flex justify-between items-center bg-slate-50 rounded-xl  p-4">
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'active',
                defaultMessage: 'Active'
              })}
            </label>
            <ToggleSlider
              onClick={() => setStatus(!status)}
              checked={status}
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              style={{
                backgroundColor: dashColor
              }}
              className="inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2"
            >
              {data?.id
                ? useIntl().formatMessage({
                    id: 'update',
                    defaultMessage: 'Update'
                  })
                : useIntl().formatMessage({
                    id: 'submit',
                    defaultMessage: 'submit'
                  })}
            </button>

            <button
              onClick={() => handleDeleteBanner(data.id)}
              type="button"
              className="flex justify-center rounded-md itms-center gap-[18px] border-solid border-[1px] border-[#d91c1f]  bg-[#ffe2e2] text-[#d91c1f] px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2"
            >
              <img
                src={img_1}
                srcSet={`${img_1_2}, ${img_1_3}`}
                className="object-contain p-0 w-[14] h-[14px] m-auto"
                alt="Description of the image"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'delete',
                  defaultMessage: 'Delete'
                })}
              </span>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

