import moment from 'moment';
import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  TooltipProps
} from 'recharts';

function AverageRevenue({ data, title, color = '#2980BA', type = 'bar' }) {
  const roundToTwoDecimals = (number) => {
    if (number === null || number === undefined || isNaN(number)) {
      // Handle the case where the number is not valid
      // Return a default value or handle as needed
      return 0; // or any other default value you prefer
    }
    return Number(number.toFixed(2));
  };
  // Modify the data
  const modifiedData = data.map((item) => ({
    ...item,
    averageRevenue: roundToTwoDecimals(item.averageRevenue)
  }));

  // Function to format the XAxis ticks
  const formatDateTick = (tick) => {
    // Ensure tick is treated as a string
    const stringTick = String(tick);

    const dateParts = stringTick.split('-'); // Split the date into [year, month, day]
    const sameYear = data.every((d) => d.date.startsWith(dateParts[0])); // Check if all dates are from the same year
    const sameMonth = data.every((d) => d.date.slice(0, 7) === `${dateParts[0]}-${dateParts[1]}`); // Check if all dates are from the same month & year

    if (sameYear && sameMonth) {
      // If all data is from the same month & year, show only the day
      return dateParts[2];
    } else if (sameYear) {
      // If all data is from the same year, show only month and day
      return `${dateParts[1]}-${dateParts[2]}`;
    }
    // Otherwise, show the full date
    return stringTick;
  };

  // Determine if all data is from the same year for label display
  const allSameYear = new Set(data.map((item) => item.date.slice(0, 4))).size === 1;
  const yearLabel = allSameYear ? data[0].date.slice(0, 4) : '';

  // Parse the dates and sort the data by date
  const sortedData = data
    .map((item) => ({
      ...item,
      date: new Date(item.date), // Convert string date to Date object
      averageRevenue: roundToTwoDecimals(item.averageRevenue) // Round the average revenue
    }))
    .sort((a, b) => a.date - b.date);

  // Function to check if a date is the last day of its month
  const isLastDayOfMonth = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.getMonth() !== date.getMonth();
  };

  // Determine if all data is from the same year
  const isSameYear = sortedData.every(
    (item, _, array) => item.date.getFullYear() === array[0].date.getFullYear()
  );

  // Function to format date based on whether all data is from the same year
  const formatMonth = (date) => {
    // const monthNames = [
    //   'Jan',
    //   'Feb',
    //   'Mar',
    //   'Apr',
    //   'May',
    //   'Jun',
    //   'Jul',
    //   'Aug',
    //   'Sep',
    //   'Oct',
    //   'Nov',
    //   'Dec'
    // ];
    // const month = monthNames[date.getMonth()];
    // const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth() + 1; // يجب إضافة 1 إلى الشهر لأنه يتم تمثيل الشهور من 0 إلى 11
    const year = date.getFullYear();
  
    // عرض اليوم والشهر فقط إذا كانت جميع التواريخ في نفس السنة
    return isSameYear ? `${day}` : `${day}-${month}`;

  };

  // Filter out the data to only include the last day of each month
  const lastDaysOfMonthData = sortedData
    .filter((item) => isLastDayOfMonth(item.date))
    .map((item) => ({
      ...item,
      date: formatMonth(item.date) // Format the date based on the year
    }));
console.log("lastDaysOfMonthData" , lastDaysOfMonthData);
interface CustomTooltipProps extends TooltipProps<any, any> {
  active?: boolean;
  payload?: any[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="p-5 bg-[#fff3d6] text-[#a78024] rounded-[10px]"
        style={{ boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)' }}
      >
        <p className="flex items-center justify-center gap-1 text-[16px] font-semibold">
          <span
            className="w-[8px] h-[8px] !rounded-full"
            style={{ backgroundColor: '#a78024', display: 'inline-block' }}
          ></span>
          {`AverageRevenue: ${data.averageRevenue}`}
        </p>
        <p className="label text-[#656565]">{`${data.date}`}</p>
      </div>
    );
  }
  return null;
};
return (
  <div className="text-center">
    <h3>{title}</h3>
    <ResponsiveContainer height={387}>
      <BarChart
        data={lastDaysOfMonthData}
        margin={{
          top: 5,
          right: 30,
          left: 20
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={formatDateTick}
          axisLine={false}
        />
        <YAxis axisLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="averageRevenue"
          fill="#e6bc59"
          barSize={12.1}
          shape={<CustomBar />}
        />
      </BarChart>
    </ResponsiveContainer>
  </div>
);
}

const CustomBar = (props) => {
const { fill, x, y, width, height } = props;
return (
  <g>
    <rect
      x={x}
      y={y - 3}
      width={width}
      height={height}
      fill={fill}
      rx={10}
      ry={10}
    />
  </g>
);
};



export default AverageRevenue;
