export default function CurrencyFormatter(value: number): string {
  const options = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    currencySign: 'accounting'
    // notation: 'compact'
  } satisfies Intl.NumberFormatOptions;

  return new Intl.NumberFormat('en-SA', options).format(value);
}
