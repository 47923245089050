import { Icon } from '@iconify/react';
import React, { AllHTMLAttributes, FunctionComponent, ReactNode, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDirectionClass } from 'src/helper/directionClass';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import down from '../../../screens/images/Frame 1000004700downLoad.png';
import vector from '../../../screens/images/Vector+.png';
import RangePicker from '../RangePicker';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';

interface RenderBodyProps {
  getRowColor: (index: number) => string;
}
interface RenderTableProps {
  start: Date;
  end: Date;
  apply?: any;
}
interface TableInterface {
  loading?: boolean;
  isEmpty?: boolean;
  pagination?: any;
  onApply?: any;
  onNextClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  onCallsClick?: any;
  onPreviousClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  onPromoClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  ChangePerPageLimit?: AllHTMLAttributes<HTMLInputElement>['onKeyDown'];
  searchProps?: AllHTMLAttributes<HTMLInputElement>;
  RenderBody: FunctionComponent<RenderBodyProps>;
  RenderHead: FunctionComponent;
  title?: any;
  text?: any;
  searchAble?: boolean;
  count?: number;
  notCountable?: boolean;
  notNext?: boolean;
  CustomizeTable: FunctionComponent;
  customizeAble?: boolean;
  TimeTable: FunctionComponent<RenderTableProps>;
  timeAble?: boolean;
  buttonExport?: () => Promise<void>; // خاصية buttonExport مطلوبة هنا
  buttonExportAll?: () => Promise<void>; // خاصية buttonExport مطلوبة هنا
  PromoAble?: boolean;
  buttonAble?: boolean;
  headerAble?: boolean;
  notificationAble?: boolean;
  visible?: boolean;
  calls?: boolean;
  permissionsAble?: boolean;
}

function Table({
  loading,
  pagination,
  searchProps,
  onNextClick,
  onCallsClick,
  onPreviousClick,
  onPromoClick,
  onApply,
  RenderBody,
  RenderHead,
  isEmpty,
  title,
  text,
  ChangePerPageLimit,
  searchAble = true,
  PromoAble = false,
  count,
  notCountable,
  notNext,
  customizeAble = true,
  timeAble = true,
  notificationAble = false,
  buttonExport,
  buttonExportAll,
  CustomizeTable,
  TimeTable,
  visible,
  calls,
  permissionsAble,
  buttonAble = true,
  headerAble = true
}: TableInterface) {
  const direction = getDirectionClass();
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isCustomize, setIsCustomize] = React.useState(false);
  const [isJoin, setIsJoin] = useState<any>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const getRowColor = (index: number) => {
    return index % 2 === 0 ? 'white' : '#f5f5f5';
  };
  const handleExportButtonClick = () => {
    if (buttonExport) {
      buttonExport();
    }
  };
  const handleExportAllButtonClick = () => {
    if (buttonExportAll) {
      buttonExportAll();
    }
  };
  const handleApplyButtonClick = () => {
    if (onApply) {
      onApply(startDate, endDate);
    }
  };
  const handleButtonClick = () => {
    if (onCallsClick) {
      onCallsClick(isJoin);
    }
  };
  console.log('isJoin', isJoin);

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const input = useRef(null);
  let dashColor: string;
  let bgDashColor: string;

  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      bgDashColor = '#FFE4F8';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      bgDashColor = '#FFD8E2';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      bgDashColor = '#E6E6F1';

      break;
    default:
      dashColor = '#a12f88';
      bgDashColor = '#FFE4F8';
      break;
  }
  console.log('bgDashColor', bgDashColor);
  const dateRangeMessage = useIntl().formatMessage({
    id: 'dateRange',
    defaultMessage: 'Date range'
  });
  return (
    <div className="relative rounded-lg  bg-white ring-1 ring-neutral-200 shadow-2xl shadow-neutral-600/5">
      {headerAble && (
        <div className="p-4 flex items-center  justify-between gap-3 ">
          {timeAble && (
            <div className="">
              {loading ? (
                <Icon
                  icon="eos-icons:loading"
                  width={18}
                />
              ) : null}

              {title === 'dateRange' ? (
                <button
                  className="relative inline-flex items-center cursor-pointer justify-between gap-3 flex-grow-0 flex-auto py-[5px] px-[16px] rounded-[8px]  h-[56px] bg-[#fff] border-solid border-[1px] border-[#d9d9d9] duration-750 ease-in-out"
                  style={{ width: `${Responsive.w(600, width)}px` }}
                >
                  <p
                    className="shrink-0  font-medium font-[Montserrat] text-[#262626] flex items-center justify-start "
                    style={{
                      gap: `${Responsive.sp(16, width)}px`
                    }}
                  >
                    <Icon
                      icon="ri:filter-off-fill"
                      className="w-[24px] h-[24px]"
                      style={{
                        width: `${Responsive.w(24, width)}px`,
                        height: `${Responsive.h(24, height)}px`,
                        color: dashColor
                      }}
                      onClick={() => setIsOpen(!isOpen)}
                    />
                    <span
                      className="flex items-center justify-center"
                      style={{ fontSize: `${Responsive.sp(14, width)}px` }}
                    >
                      <div className="flex justify-center items-center ">
                        <div
                          className="flex items-center justify-start p-0"
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            width: `${Responsive.w(80, width)}px`,
                            fontSize: `${Responsive.sp(14, width)}px`,
                            borderRadius: `${Responsive.sp(3, width)}px`,
                            gap: `${Responsive.sp(4, width)}px`
                          }}
                        >
                          <span className="text-[#262626]">
                            <Icon
                              icon="material-symbols-light:date-range-outline"
                              className="w-[20px] h-[20px]"
                              style={{
                                width: `${Responsive.w(20, width)}px`,
                                height: `${Responsive.h(20, height)}px`
                              }}
                            />
                          </span>
                          <span
                            style={{
                              fontSize: `${Responsive.sp(14, width)}px`,
                              borderRadius: `${Responsive.sp(3, width)}px`,
                              gap: `${Responsive.sp(10, width)}px`,
                              color: '#262626'
                            }}
                          >
                            {dateRangeMessage}
                          </span>
                        </div>
                        <RangePicker
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                        />
                      </div>
                      <div className="flex  items-center justify-center mr-4">
                        <button
                          onClick={() => handleApplyButtonClick()}
                          style={{
                            width: `${Responsive.w(72, width)}px`,
                            // height: `${Responsive.h(28, height)}px`,
                            backgroundColor: dashColor,
                            borderRadius: `${Responsive.sp(4, width)}px`,
                            fontSize: `${Responsive.sp(12, width)}px`
                          }}
                          className="text-white font-semibold "
                        >
                          <span
                            className="nowrap"
                            style={{
                              borderRadius: `${Responsive.sp(4, width)}px`,
                              fontSize: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <FormattedMessage
                              id="applyFilter"
                              defaultMessage="Apply filter"
                            />
                          </span>
                        </button>
                      </div>
                    </span>
                  </p>
                  <div className="flex items-center justify-center">
                    {isOpen ? (
                      <div className="up transition ease-in-out delay-150">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          className="transition ease-in-out delay-150 "
                          style={{
                            color: dashColor
                          }}
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className="down transition ease-in-out delay-150">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          className="transition ease-in-out delay-150 "
                          style={{
                            color: dashColor
                          }}
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  {isOpen && (
                    <div className="relative ">
                      <TimeTable
                        start={startDate}
                        end={endDate}
                      />
                    </div>
                  )}
                </button>
              ) : (
                <button
                  className="relative inline-flex items-center cursor-pointer justify-between gap-3 flex-grow-0 flex-auto py-[5px] px-[16px] rounded-[8px]  h-[56px] bg-[#fff] border-solid border-[1px] border-[#d9d9d9] duration-750 ease-in-out"
                  style={{ width: `${Responsive.w(572, width)}px` }}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <p className="shrink-0  font-medium font-[Montserrat] text-[#262626] flex items-center justify-start gap-[16px]">
                    <Icon
                      icon="ri:filter-off-fill"
                      className="w-[24px] h-[24px]"
                      style={{
                        width: `${Responsive.w(24, width)}px`,
                        height: `${Responsive.h(24, height)}px`,
                        color: dashColor
                      }}
                    />
                    <span
                      className="flex items-center justify-center"
                      style={{ fontSize: `${Responsive.sp(14, width)}px` }}
                    >
                      {
                        <FormattedMessage
                          id={title}
                          defaultMessage={title}
                        />
                      }
                    </span>
                  </p>
                  <div className="flex items-center justify-center">
                    {isOpen ? (
                      <div className="up transition ease-in-out delay-150">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          className="transition ease-in-out delay-150 "
                          style={{
                            color: dashColor
                          }}
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className="down transition ease-in-out delay-150">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          className="transition ease-in-out delay-150 "
                          style={{
                            color: dashColor
                          }}
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                    {isOpen && (
                      <div className="relative ">
                        <TimeTable
                          start={startDate}
                          end={endDate}
                        />
                      </div>
                    )}
                  </div>
                </button>
              )}
            </div>
          )}
          
          {permissionsAble && (
            <button
              className={`w-[240px] cursor-pointer   h-[56px] inline-flex items-center justify-center p-4 rounded-lg border border-neutral-200 `}
              onClick={onPromoClick}
              style={{
                backgroundColor: '#f1f5ff'
              }}
            >
              <p
                className="shrink-0 text-[12px] font-semibold font-[Montserrat] text-[#262626] flex items-center justify-start "
                style={{
                  fontSize: `${Responsive.sp(16, width)}px`,
                  gap: `${Responsive.sp(12, width)}px`
                }}
              >
               
                <span className={`text-[#2980ba]`}>
                  {text}
                  {/* {useIntl().formatMessage({ id: text, defaultMessage: 'Add New Role' })} */}
                </span>
              </p>
            </button>
          )}
          {customizeAble && (
            <div>
              <button
                className="inline-flex cursor-pointer items-center mx-auto gap-3 flex-grow-0 flex-auto py-[5px] px-[16px] rounded-[8px] justify-between h-[56px] bg-[#fff] border-solid border-[1px] border-[#d9d9d9]"
                style={{ width: `${Responsive.w(372, width)}px` }}
                onClick={() => setIsCustomize(!isCustomize)}
              >
                <p className="shrink-0  font-medium font-[Montserrat] text-[#262626] flex items-center justify-start gap-[16px]">
                  <Icon
                    icon="ri:filter-off-fill"
                    className="w-[24px] h-[24px]"
                    style={{
                      width: `${Responsive.w(24, width)}px`,
                      height: `${Responsive.h(24, height)}px`,
                      color: dashColor
                    }}
                  />
                  <span style={{ fontSize: `${Responsive.sp(14, width)}px` }}>
                    <FormattedMessage
                      id="filterAndCustomizeTable"
                      defaultMessage={'Customize table & Filter'}
                    />
                  </span>
                </p>
                <div className="flex items-center justify-center ">
                  {isCustomize ? (
                    <div className="up transition ease-in-out delay-150">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        className="transition ease-in-out delay-150 "
                        style={{
                          color: dashColor
                        }}
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06L5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="down transition ease-in-out delay-150">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        className="transition ease-in-out delay-150 "
                        style={{
                          color: dashColor
                        }}
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </button>
              <div>
                {isCustomize && (
                  <div className="relative ">
                    <CustomizeTable />
                  </div>
                )}
              </div>
            </div>
          )}
          {!notCountable && (
            <div className={`flex items-center justify-center ${direction}`}>
              <p className="capitalize mx-2">
                {useIntl().formatMessage({
                  id: 'resultsCount',
                  defaultMessage: 'results count'
                })}
              </p>

              <p
                className="px-2 py-1 rounded-lg"
                style={{
                  backgroundColor: bgDashColor
                }}
              >
                {loading ? 'loading ...' : count}
              </p>
            </div>
          )}
          {searchAble && (
            <div className="w-full sm:w-auto bg-neutral-50 h-[56px] inline-flex items-center ml-auto p-3 rounded-lg border border-neutral-200">
              <span className="shrink-0 inline text-neutral-500">
                <Icon
                  icon="ri:search-line"
                  width={20}
                />
              </span>

              <input
                type="text"
                className="form-input !bg-transparent text-[16px] font-[Montserrat] border-none py-0"
                placeholder={useIntl().formatMessage({
                  id: 'Search',
                  defaultMessage: 'Search'
                })}
                autoComplete="off"
                defaultValue={pagination?.search_key}
                {...searchProps}
              />
            </div>
          )}

          {PromoAble && (
            <button
              className={`w-[240px] cursor-pointer sm:w-auto  h-[56px] inline-flex items-center ml-auto p-3 rounded-lg border border-neutral-200 `}
              onClick={onPromoClick}
              style={{
                backgroundColor: bgDashColor
              }}
            >
              <p
                className="shrink-0 text-[12px] font-medium font-[Montserrat] text-[#262626] flex items-center justify-start "
                style={{
                  fontSize: `${Responsive.sp(14, width)}px`,
                  gap: `${Responsive.sp(12, width)}px`
                }}
              >
                <Icon
                  icon={'iconamoon:sign-plus-bold'}
                  className="w-[16px] h-[16px]"
                  style={{
                    color: dashColor
                  }}
                />
                <span className={`text-[${dashColor}]`}>
                  {useIntl().formatMessage({
                    id: 'addPromoCode',
                    defaultMessage: 'Add Promo Code'
                  })}
                </span>
              </p>
            </button>
          )}
          {notificationAble && (
            <button
              className="w-[260px] cursor-pointer  sm:w-auto  h-[56px] inline-flex items-center ml-auto p-3 rounded-lg border border-neutral-200"
              onClick={onPromoClick}
              style={{
                backgroundColor: dashColor
              }}
            >
              <p
                className="shrink-0 text-[14px] font-medium font-[Montserrat] text-[#fff] flex items-center justify-start gap-[16px]"
                style={{
                  fontSize: `${Responsive.sp(20, width)}px`
                }}
              >
                {useIntl().formatMessage({
                  id: 'sendNotification',
                  defaultMessage: 'Send notifications'
                })}
              </p>
            </button>
          )}

          {buttonAble && (
            <button
              className="w-[59px] h-[56px]  rounded-[8px] flex justify-center items-center text-white py-[5px] px-[16px]"
              onClick={handleExportAllButtonClick}
              // disabled={count ?count > 1000 : true}
              style={{
                backgroundColor: 'black'
              }}
            >
              <img
                className="w-[24px] h-[24px]"
                src={down}
                alt=""
              />
            </button>
          )}
          {buttonAble && (
            <button
              className="w-[59px] h-[56px]  rounded-[8px] flex justify-center items-center text-white py-[5px] px-[16px]"
              onClick={handleExportButtonClick}
              style={{
                backgroundColor: dashColor
              }}
            >
              <img
                className="w-[24px] h-[24px]"
                src={down}
                alt=""
              />
            </button>
          )}
        </div>
      )}

      <div className="grid">
        {calls && currentDashboard === 'jeras' && (
          <div className="z-10">
            <button
              className="space-y-1 mb-2"
              onClick={() => {
                handleButtonClick();
              }}
            >
              <label className="custom-checkbox cursor-pointer bg-white/40 hover:bg-white/20 w-56 p-4 rounded-md flex justify-between items-center shadow">
                <h2 className="text-[16px] mr-auto">Current calls</h2>
                <input
                  type="checkbox"
                  name="payment"
                  className="custom-check hidden"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsJoin(true);
                    } else {
                      setIsJoin(false);
                    }
                  }}
                />

                <span className="checkmark h-5 w-5 rounded-full"></span>
              </label>
            </button>
          </div>
        )}

        <div className="overflow-x-auto">
          <table className="border-collapse w-full text-sm whitespace-nowrap border-t border-t-neutral-200">
            <thead
              className=""
              style={{
                backgroundColor: dashColor
              }}
            >
              <RenderHead />
            </thead>
            <tbody>
              <RenderBody getRowColor={getRowColor} />
              {isEmpty ? (
                <tr className="border-b border-b-neutral-200 hover:bg-[#d6d6d6]">
                  <td
                    colSpan={100}
                    className="p-4 text-neutral-600 text-start"
                  >
                    <p className="text-neutral-500 flex-1 shrink-0 text-sm text-center">
                      There are no data to display
                    </p>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      {pagination ? (
        <div className="p-4 flex items-center gap-4 flex-wrap">
          <div className="flex-1">
            <div className="inline-flex gap-2 flex-wrap items-center">
              <div className="form-group">
                <label
                  htmlFor="per-page"
                  className="text-sm text-gray-600"
                >
                  <FormattedMessage
                    id="showItemsPerPage"
                    defaultMessage={'Show item per page:'}
                  />
                </label>
              </div>
              <input
                type="number"
                name="per-page"
                id="per-page"
                placeholder="0"
                min={1}
                className="form-input max-w-fit"
                defaultValue={pagination?.perPage}
                onKeyDown={ChangePerPageLimit}
              />
            </div>
          </div>
          {!notNext && (
            <div className="inline-flex flex-wrap gap-6 items-center justify-center mx-auto">
              <button
                className="btn-with-icon w-[116px] h-[40px] py-[4px] px-[8px] rounded-[4px] shrink-0 "
                disabled={pagination?.currentPage === 1 || !pagination?.currentPage}
                onClick={onPreviousClick}
                style={{
                  backgroundColor: dashColor
                }}
              >
                <span>
                  <FormattedMessage
                    id="previous"
                    defaultMessage={'Previous'}
                  />
                </span>
              </button>

              <button className="btn-with-icon outline-btn shrink-0">
                <span>{pagination?.currentPage}</span>
              </button>

              <button
                className="btn-with-icon w-[116px] h-[40px] py-[4px] px-[8px] rounded-[4px] shrink-0 "
                style={{
                  backgroundColor: dashColor
                }}
                disabled={!pagination?.nextPage}
                onClick={onNextClick}
              >
                <span>
                  <FormattedMessage
                    id="next"
                    defaultMessage={'Next'}
                  />
                </span>
              </button>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default Table;
