// hooks/useConfirmationDialog.js
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

const useConfirmationDialog = () => {
  const showConfirmation = async (text, onConfirm) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    });

    if (result.isConfirmed) {
      try {
        await onConfirm();
        Swal.fire('Success!', 'Operation completed successfully.', 'success');
      } catch (error: any) {
        Swal.fire('Error!', error.message, 'error');
      }
    }
  };

  return showConfirmation;
};

export default useConfirmationDialog;
