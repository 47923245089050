import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import useUsersTable from './useUsersTable';
import { FormattedMessage, useIntl } from 'react-intl';
import axiosInstance from 'src/helper/AxiosInstance';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import RangePicker from 'src/components/shared/RangePicker';
import { Icon } from '@iconify/react';
import { useModal } from './modalContext';

function CustomizationSection() {
  const { customize_keys, data } = useSelector((state: RootState) => state.Users);
  const { ChangeFilter, initialQueries, filters } = useUsersTable();
  const { setIsModalOpen } = useModal();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleOpenModal = () => {
    ChangeFilter(initialQueries, 'queries');
    setIsModalOpen(true);
  };

  const [exportDisabled, setExportDisabled] = useState<boolean>(false);

  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        'users/export',
        { currentData, currentKeys },
        { params: filters, responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  return (
    <>
      <div>
        <div className="flex items-center flex-wrap gap-3 justify-between ">
          <RangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            // applyHandler={() =>
            //   ChangeFilter(
            //     [
            //       [
            //         'createdDateValue',
            //         '==',
            //         `${formatStartDay(startDate)}-${formatEndDay(endDate)}`
            //       ],
            //       ...initialQueries
            //     ],
            //     'queries'
            //   )
            // }
          />
          <div className="flex items-center flex-wrap gap-3 ">
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                ChangeFilter(initialQueries, 'queries');
                setStartDate(new Date());
                setEndDate(new Date());
              }}
              disabled={initialQueries.length == 0 && filters.queries.length == 0}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => ExportXlsx(data, customize_keys)}
              disabled={exportDisabled}
            >
              <Icon
                icon="bi:filetype-xlsx"
                width="18"
                height="18"
              />
              <span>
                <FormattedMessage
                  id="exportExcel"
                  defaultMessage={'Export Excel'}
                />
              </span>
            </button>
            <button
              className="btn-with-icon bg-gray-800 text-white"
              onClick={handleOpenModal}
            >
              <Icon
                icon="majesticons:filter-line"
                width="18"
                height="18"
              />
              <span>
                <FormattedMessage
                  id="filterAndCustomizeTable"
                  defaultMessage={'Filter & Customize table'}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomizationSection;
