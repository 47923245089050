// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration



const firebaseConfig = {
  apiKey: 'AIzaSyCjk-p0zxs4CAIKQgye-0U4vbwXqm1ID-Y',
  authDomain: 'focalpoint-277d2.firebaseapp.com',
  databaseURL: 'https://app-jeras-default-rtdb.europe-west1.firebasedatabase.app/',
  projectId: 'focalpoint-277d2',
  storageBucket: 'focalpoint-277d2.appspot.com',
  messagingSenderId: '436992255806',
  appId: '1:436992255806:web:73a7fab9e6c97ebf426e6f',
  measurementId: 'G-YZ6G17LR37'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firestore instance
export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;
