import { Icon } from '@iconify/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

type Position = 'absolute' | 'fixed';
function LoadingComponent({ position = 'fixed' }: { position?: Position }) {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const positions: Record<Position, string> = {
    absolute: 'absolute inset-0 w-full h-full',
    fixed: 'fixed inset-0 w-screen min-h-screen'
  };
;
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah ':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className={['bg-white flex items-center justify-center p-8', positions[position]].join(' ')}
    >
      <div className="shrink-0 flex flex-col justify-center items-center">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          style={{
            color:dashColor
          }}
        />
      </div>
    </div>
  );
}

export default LoadingComponent;
