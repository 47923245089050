import React from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer, TooltipProps } from 'recharts';

const UserTypes = ({ data, loading }) => {
  const userTypes = Object.entries(data).map(([name, value]) => ({ name, value }));
  const COLORS = ['#e6bc59', '#7b6c96', '#4ad991','#7fb3d6' , '#ff4f6f'];
  const renderCustomLegend = ({ payload }: any) => {
    const legendData = [
      'Phone Number',
      'Google User',
      'Google & Phone User',
      'Apple User',
      'Apple & Phone User'
    ];
    return (
      <div className="flex justify-center">
        {!loading && (
          <ul className="custom-legend grid grid-cols-2 gap-2 text-left">
            {payload.map((entry: any, index: number) => (
              <li
                className="flex items-center justify-center gap-2"
                key={`item-${index}`}
                style={{ color: '#656565' }}
              >
                <span
                  className="w-[8px] h-[8px] !rounded-full"
                  style={{ backgroundColor: entry.color, display: 'inline-block' }}
                ></span>
                <p>
                  {`${legendData[index]} `}
                  <span className="font-semibold text-[#1e1e1e]">{entry.payload.value}</span>
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
console.log("name====>" , name);

      let backgroundColor = '#fff';
      let textColor = '#000';
      let newName;

      if (name === 'phoneUsers') {
        newName = 'Phone Number';
        backgroundColor = '#e5e1ec';
        textColor = '#e6bc59';
      } else if (name === 'googleUsers') {
        newName = 'Google User';
        backgroundColor = '#e5e1ec';
        textColor = '#7b6c96';
      } else if (name === 'googleAndPhoneUsers') {
        newName = 'Google & Phone User';
        backgroundColor = '#d9f7e8';
        textColor = '#4ad991';
      } else if (name === 'appleUsers') {
        newName = 'Apple User';
        backgroundColor = '#edf0f2';
        textColor = '#2980ba';
      } else if (name === 'appleAndPhoneUsers') {
        newName = 'Apple & Phone User';
        backgroundColor = '#ffdae0';
        textColor = '#ff4f6f';
      }

      return (
        <div
          className="text-center"
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
            padding: '5px',
            borderRadius: '11.6px'
          }}
        >
          <p className="flex justify-center items-center gap-[6.6px]">
            <span
              className="w-[8px] h-[8px] rounded-full"
              style={{ backgroundColor: textColor, display: 'inline-block' }}
            ></span>
            {newName}
          </p>
          <p className="text-[#000]">{value}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="text-center">
      <h3 className="text-[24px] font-semibold text-left font-[Montserrat] text-[#1e1e1e]">
        Users Types
      </h3>
      <ResponsiveContainer height={386}>
        <PieChart>
          <Pie
            data={userTypes}
            cx="50%"
            cy="50%"
            labelLine={false}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            nameKey="name"
          >
            {userTypes.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={renderCustomLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UserTypes;
