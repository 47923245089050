import { Icon } from '@iconify/react';
import React, {
  DOMAttributes,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MoveToBottom, MoveToTop } from '../animations';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import Image from 'src/components/shared/Image';
import useLogout from 'src/hooks/useLogout';
import { FormattedMessage } from 'react-intl';
import DashboardSelector from 'src/components/DashboardSelector';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from './Responsive';
import axiosInstance from 'src/helper/AxiosInstance';
import axios from 'axios';

interface SubRoute {
  title: string;
  path: string;
  icon: string;
}

interface RouterInterface {
  title: string;
  path: string;
  icon: string;
  subRoutes?: SubRoute[];
}

export const routes: RouterInterface[] = [
  {
    title: 'Dashboard',
    icon: 'mage:dashboard',
    path: '/dashboard'
  },
  {
    title: 'Statistics',
    icon: 'fluent:document-landscape-data-20-regular',
    path: '',
    subRoutes: [
      {
        title: 'Statistics',
        icon: 'fluent:document-landscape-data-20-regular',
        path: '/statistics'
      },
      {
        title: 'Customer Service',
        icon: 'mdi:account-outline',
        path: '/customerservice'
      },

      {
        title: 'Website',
        icon: 'mdi:account-child-outline',
        path: '/website'
      }
    ]
  },

  {
    title: 'Old Statistics',
    icon: 'fluent:document-landscape-data-20-regular',
    path: '/oldStatistics'
  },

  {
    title: 'Users',
    icon: 'ci:users',
    path: '',

    subRoutes: [
      {
        title: 'Users',
        icon: 'mdi:account-group-outline',
        path: '/users'
      },
      {
        title: 'Teachers',
        icon: 'mdi:account-outline',
        path: '/consultants'
      },

      {
        title: 'Supervisors',
        icon: 'mdi:account-child-outline',
        path: '/supervisor'
      },
      {
        title: 'Technical Support',
        icon: 'mdi:support',
        path: '/support'
      }
    ]
  },

  {
    title: 'Orders',
    icon: 'lucide:shopping-bag',
    path: '/orders'
  },
  {
    title: 'Appointments',
    icon: 'teenyicons:appointments-outline',
    path: '/appointments'
  },
  {
    title: 'Appointments Closed',
    icon: 'teenyicons:appointments-outline',
    path: '/appointmentsForever'
  },
  {
    title: 'Current Calls',
    icon: 'mdi:phone-outline',
    path: '/currentcalls'
  },

  {
    title: 'Promo Code',
    icon: 'iconamoon:discount',
    path: '/promocodes'
  },

  {
    title: 'Academy',
    icon: 'uil:book-open',
    path: '',
    subRoutes: [
      {
        title: 'Courses',
        icon: 'mdi:account-group-outline',
        path: '/academy/courses'
      },
      {
        title: 'Q&A',
        icon: 'mdi:account-outline',
        path: '/academy/qa'
      },

      {
        title: 'Webinar',
        icon: 'mdi:account-child-outline',
        path: '/webinar'
      }
    ]
  },
  {
    title: 'Courses',
    icon: 'uil:book-open',
    path: '/courses'
  },
  {
    title: 'Banners',
    icon: 'uit:image-v',
    path: '/banners'
  },

  {
    title: 'Summary',
    icon: 'iconoir:stats-report',
    path: '/summary'
  },

  {
    title: 'Notifications',
    icon: 'ion:notifications-outline',
    path: '/notifications'
  },
  {
    title: 'Job Advertisement',
    icon: 'arcticons:jobstreet',
    path: '/jobs'
  },

  {
    title: 'Suggestions',
    icon: 'lets-icons:lamp-duotone-line',
    path: '/suggestions'
  },

  {
    title: 'Accountant',
    icon: 'uil:transaction',
    path: '',
    subRoutes: [
      {
        title: 'Transactions',
        icon: 'fluent:payment-16-regular',
        path: '/payments/tap'
      },
      {
        title: 'Dues Payment',
        icon: 'tdesign:money',
        path: '/dues'
      }
    ]
  },
  {
    title: 'Survey Results',
    icon: 'ri:survey-line',
    path: '',
    subRoutes: [
      {
        title: 'Survey',
        icon: 'fluent:payment-16-regular',
        path: '/survey'
      }
    ]
  },
  {
    title: 'AD Campaigns',

    icon: 'pepicons-print:megaphone',
    path: '/ad-campaigns'
  },

  {
    title: 'App Settings',
    icon: 'solar:settings-linear',
    path: '/app-settings'
  }
];
export const filterRoutes = (currentDashboard: string, pages: any[], loading: boolean) => {

  let newRoutes = routes.map((route) => {
    // تخصيص المسارات بناءً على قيمة currentDashboard
    if (currentDashboard === 'nikah' && route.title === 'Users') {
      return {
        ...route,
        subRoutes: [
          ...(route.subRoutes?.map((subRoute) => {
            if (subRoute.title === 'Users') {
              return { ...subRoute, title: 'Normal User' };
            }
            if (subRoute.title === 'Teachers') {
              return { ...subRoute, title: 'Men' };
            }
            if (subRoute.title === 'Supervisors') {
              return { ...subRoute, title: 'Women' };
            }
            return subRoute;
          }) || []),
          {
            title: 'Coach',
            path: '/coach',
            icon: 'mdi:account-group-outline'
          }
        ]
      };
    } else if (currentDashboard === 'dream' && route.title === 'Users') {
      return {
        ...route,
        subRoutes: [
          ...(route.subRoutes?.map((subRoute) => {
            if (subRoute.title === 'Teachers') {
              return { ...subRoute, title: 'Consultants' };
            }
            return subRoute;
          }) || [])
        ]
      };
    }
    return route;
  });

  // تصفية المسارات بناءً على currentDashboard
  if (currentDashboard === 'dream') {
    newRoutes = newRoutes.filter(
      (route) =>
        ![
          'Courses',
          'Academy',
          'Orders & Calls',
          'Job Advertisement',
          'Current Calls',
          'Appointments Closed'
        ].includes(route.title)
    );
  }
  if (currentDashboard === 'nikah') {
    newRoutes = newRoutes.filter(
      (route) =>
        !['Job Advertisement', 'Courses', 'Current Calls', 'Appointments Closed'].includes(
          route.title
        )
    );
  }

  if (currentDashboard === 'jeras') {
    newRoutes = newRoutes.filter((route) => !['Academy'].includes(route.title));
  }

  // تصفية المسارات بناءً على قيمة pages.pages
  if (pages.some((page) => page?.Pages?.includes('All'))) {
    return newRoutes; // إرجاع جميع المسارات إذا كانت pages.pages تحتوي على 'All'
  } else {
    return newRoutes.filter((route) => pages?.some((page) => page?.Pages?.includes(route.title))); // إرجاع المسارات التي تتطابق مع القيم داخل pages.pages
  }
};
const SideBar: FC = () => {
  const location = useLocation();
  const { width, height } = useWindowDimensions();
  const menuContainer = useRef<HTMLElement | null>(null);
  const [pages, setPages] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { handleLogged } = useLogout();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const routesForJerasOnly = ['Courses'];
  const routesForNikahOnly = ['Academy'];
  const openCloseMenu = useCallback(function (ev: React.MouseEvent) {
    const currentTarget = ev.target as HTMLElement | null;
    if (currentTarget === menuContainer?.current) {
      menuContainer?.current?.classList.add('hidden');
    }
  }, []);

  const [profile, setProfile] = useState<any>();
  const GetProfile = async function () { 
    try {
      setLoading(true);
      const { data } = await axiosInstance.get('profile');
      setProfile(data.result);
      setLoading(false); 
    } catch (error) {
      console.log('error while getting user info\n', error);
    }
  };
   
  const permissionData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.focalpoint-apps.com/permissions/${profile?.permissionId}`);

      // test
      // const response = await axios.get(
      //   `http://localhost:3004/permissions/${profile?.permissionId}`
      // );
      setPages(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  }; 
 
  console.log('pages ::', pages);

  useEffect(() => {
    GetProfile();
  }, [location]);
  useEffect(() => {
    permissionData(); 
  }, [profile]); 

  console.log('profile%%%%', profile);

  const closeMenu = useCallback(function () {
    menuContainer?.current?.classList.add('hidden');
  }, []);

  const filteredRoutes = useMemo(
    () => filterRoutes(currentDashboard, pages, loading),
    [currentDashboard, pages]
  );
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const toggleDropdown = useCallback((index: number) => {
    setDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  }, []);
  const logoSrc = useSelector((state: RootState) => state.Dashboard.logoSrc);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  console.log('dashColor', dashColor);

  return (
    <aside
      className=" shrink-0  shadow-2xl shadow-neutral-600/5 fixed lg:relative z-20 hidden lg:block bg-white scrol"
      style={{
        width: `${Responsive.w(312, width)}px`
      }}
      ref={menuContainer}
      data-type="menu"
      onClick={openCloseMenu}
    >
      <div
        className=" sidebar-container  bg-white-100 sticky top-0 min-h-screen max-h-screen overflow-y-auto overflow-x-hidden flex flex-col border-r rtl:border-r-none rtl:border-l border-neutral-200"
        style={{
          width: `${Responsive.w(312, width)}px`
        }}
      >
        <div
          className=""
          style={{
            width: `${Responsive.w(312, width)}px`
          }}
        >
          <DashboardSelector />
          <button
            className="shrink-0 text-[#1e1e1e] flex lg:hidden items-center justify-center"
            onClick={closeMenu}
          >
            <Icon
              icon="line-md:menu-to-close-transition"
              width={18}
            />
          </button>
        </div>
        <nav
          className=" py-0 px-0 mt-3"
          style={{
            width: `${Responsive.w(312, width)}px`
          }}
        >
          <ul
            className="space-y-1"
            style={{
              width: `${Responsive.w(312, width)}px`,
              gap: `${Responsive.sp(10, width)}px`
            }}
          >
            {loading
              ? routes.map((_, index) => (
                  <li
                    key={index}
                    className="animate-pulse"
                    style={{
                      width: `${Responsive.w(312, width)}px`,
                      height: '100%', // Adjust based on your component's height
                      backgroundColor: '#e0e0e0' // Light gray color for the skeleton
                    }}
                  >
                    <div className="flex items-center gap-3 px-3 py-2">
                      <div
                        className="h-4 bg-[#d4d4d4] rounded"
                        style={{
                          width: `${Responsive.w(150, width)}px`
                        }}
                      ></div>
                    </div>
                  </li>
                ))
              : filteredRoutes.map((route: RouterInterface, index: string | number) => (
                  <motion.li
                    style={{
                      width: `${Responsive.w(312, width)}px`
                    }}
                    key={index}
                    animate="visible"
                    initial="hidden"
                    variants={MoveToTop}
                  >
                    {route.subRoutes ? (
                      <div>
                        <button
                          style={{
                            width: `${Responsive.w(312, width)}px`
                          }}
                          className={[
                            `w-full ${
                              currentDashboard === 'dream'
                                ? 'hover:bg-[#a12f88]'
                                : currentDashboard == 'nikah'
                                ? 'hover:bg-[#cf0036]'
                                : currentDashboard == 'jeras'
                                ? 'hover:bg-[#7b6c96]'
                                : 'red'
                            } hover:bg-[${dashColor}]  hover:text-white flex items-center justify-between gap-3  py-2 px-3 nowrap `,
                            new RegExp('^' + route.path + '$', 'gi').test(location.pathname)
                              ? `${
                                  currentDashboard === 'dream'
                                    ? 'bg-[#a12f88]'
                                    : currentDashboard == 'nikah'
                                    ? 'bg-[#cf0036]'
                                    : currentDashboard == 'jeras'
                                    ? 'bg-[#7b6c96]'
                                    : 'red'
                                } text-white font-semibold`
                              : 'text-[#1e1e1e] font-medium'
                          ].join(' ')}
                          onClick={() => toggleDropdown(Number(index))} // Add toggleDropdown function
                        >
                          <div className="flex items-center justify-center gap-2">
                            <span className="shrink-0">
                              <Icon
                                icon={route.icon}
                                style={{
                                  width: `${Responsive.w(24, width)}px`,
                                  height: `${Responsive.h(24, width)}px`
                                }}
                              />
                            </span>
                            <span
                              className="font-medium text-left"
                              style={{
                                width: `${Responsive.w(118, width)}px`,
                                fontSize: `${Responsive.sp(16, width)}px`
                              }}
                            >
                              <FormattedMessage
                                id={`tab${route.title.replace(' ', '')}`}
                                defaultMessage={`${route.title}`}
                              />
                            </span>
                          </div>
                          <svg
                            className={
                              dropdownOpen === index
                                ? `transform rotate-0  mr-3`
                                : `transform rotate-180  mr-3`
                            }
                            width="24"
                            height="24"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="currentColor"
                              fill-rule="evenodd"
                              d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        {dropdownOpen === index && ( // Render subRoutes if dropdownOpen matches current index
                          <ul
                            className=""
                            style={{
                              width: `${Responsive.w(312, width)}px`
                            }}
                          >
                            {route.subRoutes.map((subRoute: RouterInterface, subIndex: number) => (
                              <motion.li
                                style={{
                                  width: `${Responsive.w(312, width)}px`
                                }}
                                key={subIndex}
                                animate="visible"
                                initial="hidden"
                                variants={MoveToTop}
                              >
                                <Link
                                  to={subRoute.path}
                                  className={[
                                    `w-full ${
                                      currentDashboard === 'dream'
                                        ? 'hover:bg-[#a12f88]'
                                        : currentDashboard == 'nikah'
                                        ? 'hover:bg-[#cf0036]'
                                        : currentDashboard == 'jeras'
                                        ? 'hover:bg-[#7b6c96]'
                                        : 'red'
                                    } text-[#1e1e1e] hover:text-white flex self-stretch items-center justify-start gap-3 text-base py-2 px-3  nowrap`,
                                    new RegExp('^' + subRoute.path + '$', 'gi').test(
                                      location.pathname
                                    )
                                      ? `${
                                          currentDashboard === 'dream'
                                            ? 'bg-[#a12f88]'
                                            : currentDashboard == 'nikah'
                                            ? 'bg-[#cf0036]'
                                            : currentDashboard == 'jeras'
                                            ? 'bg-[#7b6c96]'
                                            : 'red'
                                        } text-white font-semibold`
                                      : 'text-[#1e1e1e] font-medium'
                                  ].join(' ')}
                                >
                                  <span className="shrink-0 opacity-0">
                                    <Icon
                                      icon={subRoute.icon}
                                      style={{
                                        width: `${Responsive.w(24, width)}px`,
                                        height: `${Responsive.h(24, width)}px`
                                      }}
                                    />
                                  </span>
                                  <span
                                    className="font-medium text-center py-2 px-3"
                                    style={{
                                      // width: `${Responsive.w(118, width)}px`,
                                      fontSize: `${Responsive.sp(16, width)}px`
                                    }}
                                  >
                                    <FormattedMessage
                                      id={`tab${subRoute.title.replace(' ', '')}`}
                                      defaultMessage={`${subRoute.title}`}
                                    />
                                  </span>
                                </Link>
                              </motion.li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <Link
                        to={route.path}
                        className={[
                          `w-full ${
                            currentDashboard === 'dream'
                              ? 'hover:bg-[#a12f88]'
                              : currentDashboard == 'nikah'
                              ? 'hover:bg-[#cf0036]'
                              : currentDashboard == 'jeras'
                              ? 'hover:bg-[#7b6c96]'
                              : 'red'
                          } hover:text-white flex items-center justify-start gap-3  py-2 px-3  nowrap`,
                          new RegExp('^' + route.path + '$', 'gi').test(location.pathname)
                            ? `${
                                currentDashboard === 'dream'
                                  ? 'bg-[#a12f88]'
                                  : currentDashboard === 'nikah'
                                  ? 'bg-[#cf0036]'
                                  : currentDashboard === 'jeras'
                                  ? 'bg-[#7b6c96]'
                                  : 'red'
                              } text-white font-semibold`
                            : 'text-[#1e1e1e] font-medium'
                        ].join(' ')}
                      >
                        <span className="shrink-0">
                          <Icon
                            icon={route.icon}
                            style={{
                              width: `${Responsive.w(24, width)}px`,
                              height: `${Responsive.h(24, width)}px`
                            }}
                          />
                        </span>
                        <span
                          className="font-medium text-left"
                          style={{
                            width: `${Responsive.w(118, width)}px`,
                            fontSize: `${Responsive.sp(16, width)}px`
                          }}
                        >
                          <FormattedMessage
                            id={`tab${route.title.replace(' ', '')}`}
                            defaultMessage={`${route.title}`}
                          />
                        </span>
                      </Link>
                    )}
                  </motion.li>
                ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
