import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DownloadInvoicePDF from 'src/components/InvoicePDF/PDFGenerator';
import Card from 'src/components/shared/Card';
import DuesPaymentPdf from 'src/components/shared/DuesPaymentPdf';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import useFetch from 'src/hooks/useFetch';
import { usePermissions } from 'src/hooks/usePeermissions';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';

export default function index() {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // New state to track if more data is available
  const { width, height } = useWindowDimensions();
  const [searchQuery, setSearchQuery] = useState('');
  const [isloading, setIsloading] = useState(false);
  const [consultantsData, setConsultantsData] = useState<any>([]);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const [clear, setClear] = useState(false);
  const handlePageChange = (direction) => {
    if (direction === 'next' && hasMore) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  let initialQueries: any = [
    ['userType', '==', 'CONSULTANT'],
    ['accountStatus', '==', 'Active']
    // ['perPage', '==', 6],
    // ['page', '==', currentPage],
    // ['balance', '>', '0']
  ];
  async function getUsersCourse() {
    try {
      setIsloading(true);
      const res = await axiosInstance.get(
        `/consultants?page=${currentPage}&sorts[0][0]=balance&sorts[0][1]=desc`,
        {
          params: {
            queries: initialQueries
          }
        }
      );
      console.log('Users=====>', res.data.result.data);
      setConsultantsData(res.data.result.data);
    } catch (error) {
      console.log('Users not found');
    }
    setIsloading(false);
  }
 

  const handleSearch = async () => {
    try {
      // setIsloading(true);
      let queryParams; // Define queryParams variable to store query parameters

      if (/^[+]?[0-9]+$/.test(searchQuery)) {
        // Check if the input is a phone number (only numbers and/or '+')
        queryParams = [['phoneNumber', '>=', searchQuery + '~']];
      } else {
        // Search in name field
        queryParams = [['name', '>=', searchQuery + '~']];
      }

      const res = await axiosInstance.get('/consultants', {
        params: {
          queries: queryParams // Pass queryParams as the value for the 'queries' parameter
        }
      });
      console.log('Users=====>', res.data.result.data);
      setConsultantsData(res.data.result.data);
    } catch (error) {
      console.log('Error fetching users:', error);
      // Handle error
    }
    // setIsloading(false);
  };
  useEffect(() => {
    getUsersCourse();
  }, [clear]);

  if (isloading) return <LoadingComponent />;
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className=" flex items-center justify-center min-h-screen mx-auto"
      style={{
        width: `${Responsive.w(1584, width)}px`
        // height: `${Responsive.h(352, height)}px`,
      }}
    >
      <div className="p-8 pt-12 flex flex-col items-center bg-white w-full min-h-screen overflow-x-hidden">
        <div
          className=" flex flex-row justify-end items-center gap-[10px]  mb-[20px] ml-auto"
          style={{
            width: `${Responsive.w(1584, width)}px`
            // height: `${Responsive.h(352, height)}px`,
          }}
        >
          <button
            className="rounded-[8px] h-[54px]  p-3  hover:cursor-pointer text-[#fff]"
            style={{
              backgroundColor:dashColor
            }}
            onClick={()=>setClear(!clear)}
          >
            clear Search
          </button>
          <div className=" bg-neutral-50 h-[56px] inline-flex items-center mr-3  p-3 rounded-lg border border-neutral-200">
            <span className="shrink-0 inline text-neutral-500">
              <Icon
                icon="ri:search-line"
                width={20}
              />
            </span>

            <input
              type="text"
              className="form-input !bg-transparent text-[16px] font-[Montserrat] border-none py-0"
              placeholder={useIntl().formatMessage({
                id: 'Search',
                defaultMessage: 'Search'
              })}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && searchQuery.trim() !== '') {
                  handleSearch(); // Call handleSearch function when Enter key is pressed
                }
              }}
            />
          </div>

          <button className="rounded-[8px] h-[56px] mr-10 p-4  hover:cursor-pointer text-[#fff]"
            style={{
              backgroundColor:dashColor
            }}>
            <DownloadInvoicePDF document={<DuesPaymentPdf data={consultantsData} />} />
          </button>
        </div>

        <div
          className="p-[8px] bg-white h-full grid grid-cols-3 gap-[15px] flex-grow-0"
          style={{
            width: `${Responsive.w(1584, width)}px`
            // height: `${Responsive.h(352, height)}px`,
          }}
        >
          {consultantsData?.map((consult, index) => {
            if (consult.balance > 0) {
              return (
                <div
                  className="flex flex-col"
                  key={index}
                >
                  <ConsultDuesCard
                    consult={consult}
                    setConsultantsData={setConsultantsData}
                    consultantData={consultantsData}
                    color={dashColor}
                  />
                </div>
              );
            }
          })}
          {/* <Pagination
    currentPage={currentPage}
    onPageChange={handlePageChange}
    hasMore={hasMore}
  /> */}
        </div>
      </div>
    </div>
  );
}

const ConsultDuesCard = ({ consult, setConsultantsData, consultantData ,color}) => {
  const { width, height } = useWindowDimensions();
  const Permission = usePermissions();
  const showConfirmation = useConfirmationDialog();

  const payHandlerWithConfirmation = () => {
    showConfirmation('Are you sure you want to proceed with the payment?', handlePay);
  };
  const hasEditPermission = (item) => {
    return item?.permission?.includes('Edit');
  };
  const handlePay = async () => {
    try {
      await axiosInstance.patch(`/consultants/${consult.uid}`, { balance: 0 });
      await axiosInstance.post(`/paymentHistory/`, {
        consultImage: consult.photoUrl,
        consultName: consult.name,
        balance: consult.balance,
        consultUid: consult.uid
      });
      setConsultantsData(consultantData.filter((c) => c?.uid != consult?.uid));
      toast.success('Invoice has been created successfully');
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <Card
      className=" rounded-[16px] h-[160px] border-[1px] border-[#afafaf] border-solid  flex-wrap flex flex-row justify-start items-start gap-[8px] p-0 flex-grow-0 "
      style={{
        width: `${Responsive.w(503, width)}px`
        // height: `${Responsive.h(160, height)}px`,
      }}
    >
      <div
        className=" flex  self-stretch flex-row items-center justify-start p-0 gap[8px]"
        style={{
          width: `${Responsive.w(503, width)}px`
          // height: `${Responsive.w(40, width)}px`
        }}
      >
        <div className="flex items-center justify-center">
          <img
            className="rounded-full object-contain bg-[#efefef]"
            style={{
              width: `${Responsive.w(64, width)}px`,
              height: `${Responsive.w(64, width)}px`
            }}
            src={consult?.photoUrl || '/images/avatarph.png'}
          />
        </div>
        <div
          className="flex  flex-col p-0 pb-[22.1px]"
          style={{
            width: `${Responsive.w(268, width)}px`,
            height: `${Responsive.w(54.8, width)}px`
          }}
        >
          <h1
            className="flex-grow-0 font-[Montserrat] text-left text-[#1e1e1e] font-semibold"
            style={{
              width: `${Responsive.w(268, width)}px`,
              height: `${Responsive.w(32.8, width)}px`,
              fontSize: `${Responsive.sp(24, width)}px`
            }}
          >
            {consult?.name}
          </h1>

          <p
            className="flex-grow-0 font-[Montserrat] text-left text-[#656565] font-semibold"
            style={{
              width: `${Responsive.w(127.2, width)}px`,
              height: `${Responsive.w(18.1, width)}px`,
              fontSize: `${Responsive.sp(16, width)}px`
            }}
          >
            {consult?.phoneNumber}
          </p>
        </div>
      </div>

      <div
        className="flex items-center justify-between p-0 self-stretch flex-row"
        style={{
          width: `${Responsive.w(503, width)}px`,
          height: `${Responsive.w(40, width)}px`
        }}
      >
        <div
          className="font-[Montserrat] nowrap font-semibold text-left text-[#28c794] flex-grow-0 flex items-center justify-center"
          style={{
            width: `${Responsive.w(220, width)}px`,
            height: `${Responsive.w(24, width)}px`,
            fontSize: `${Responsive.sp(20, width)}px`
          }}
        >
          <p className="ml-2 nowrap">
            {useIntl().formatMessage({
              id: 'duesToBePaid',
              defaultMessage: 'Dues to be paid'
            })}
            : <span className="nowrap text-[#1e1e1e]">${Number(consult?.balance).toFixed(3)}</span>
          </p>
        </div>
       {Permission.some((item)=>hasEditPermission(item)) &&<div
          className=" flex items-center justify-center"
          style={{
            width: `${Responsive.w(127, width)}px`,
            height: `${Responsive.w(40, width)}px`,
            fontSize: `${Responsive.sp(20, width)}px`
          }}
        >
          <button
            className=" text-white p-[10px] rounded-[8px] flex justify-center items-center gap-[10px]"
            onClick={payHandlerWithConfirmation}
            style={{
              width: `${Responsive.w(127, width)}px`,
              height: `${Responsive.w(40, width)}px`,
              fontSize: `${Responsive.sp(20, width)}px`,
              backgroundColor:color
            }}
          >
            {useIntl().formatMessage({
              id: 'pay',
              defaultMessage: 'Pay'
            })}
          </button>
        </div>}
      </div>
    </Card>
  );
};

const Pagination = ({ currentPage, onPageChange, hasMore }) => {
  return (
    <div className="flex justify-center gap-[10px] mx-auto mt-3 text-[#fff] ">
      {currentPage > 1 && (
        <button
          className="py-2 px-4 rounded-[8px] bg-[#7b6c96]"
          onClick={() => onPageChange('prev')}
        >
          {useIntl().formatMessage({
            id: 'previous',
            defaultMessage: 'previous'
          })}
        </button>
      )}
      {currentPage && (
        <button className="py-2 px-4 rounded-[8px] bg-[#7b6c96]">{currentPage}</button>
      )}

      {hasMore && (
        <button
          className="py-2 px-4 rounded-[8px] bg-[#7b6c96]"
          onClick={() => onPageChange('next')}
        >
          {useIntl().formatMessage({
            id: 'next',
            defaultMessage: 'next'
          })}
        </button>
      )}
    </div>
  );
};

