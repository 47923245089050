import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import 'sweetalert2/src/sweetalert2.scss';
import { toast } from 'react-toastify';

const ZegoCloud = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const details = location.state;
  const navigate = useNavigate();
  const [isJoined, setIsJoined] = useState(false); // Track if already joined the room
  const zpRef = useRef<any>(null); // Store the ZegoUIKitPrebuilt instance

  useEffect(() => {
    if (!details || isJoined) return;
  
    const { roomID, userID, userName, appId, secretKey } = details;
  
    const initializeZego = () => {
      try {
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
          appId || 1588161922,
          secretKey || '70b700b662f12e892602c841ce7d648a',
          roomID,
          userID || '12a345b6c78d9',
          userName || 'SUPPORT',
        );
        console.log('kitToken==>', kitToken);
  
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        zpRef.current = zp;
  
        if (containerRef.current) {
          zp.joinRoom({
            container: containerRef.current,
            scenario: {
              mode: ZegoUIKitPrebuilt.VideoConference,
            },
            showRoomTimer: true,
            showPreJoinView: false,
            turnOnCameraWhenJoining: true,
            showMyCameraToggleButton: true,
            showAudioVideoSettingsButton: true,
            showLeavingView: false,
            showLeaveRoomConfirmDialog: false,
            onJoinRoom: () => {
              console.log('onJoinRoom');
              setIsJoined(true);
              window.parent.postMessage({ type: 'onJoinRoom' }, '*');
              console.log('message sent');
            },
            onLeaveRoom: () => {
              setIsJoined(false);
              toast.success('closing the room');
              navigate('/currentcalls');
            },
            onUserJoin: () => {
              console.log('onUserJoin');
              window.parent.postMessage({ type: 'onUserJoin' }, '*');
              console.log('message sent');
            },
            onUserLeave: (user) => {
              const userNames = user.map((e: any) => e.userName).join('');
              console.log('onUserLeave', userNames);
              window.parent.postMessage({ type: 'onUserLeave' }, '*');
              console.log('message sent');
  
              if (userNames !== 'SUPPORT') {
                console.log('User left is not SUPPORT, closing the room');
                zpRef.current?.destroy();
                toast.success('closing the room');
                navigate('/currentcalls');
              }
            }
          });
        }
      } catch (error) {
        console.error('Failed to join room:', error);
        toast.error('Failed to join the room, please try again.');
      }
    };
  
    // Add a slight delay before initializing
    const timer = setTimeout(initializeZego, 500);
  
    return () => {
      clearTimeout(timer);
      if (zpRef.current) {
        try {
          console.log("destroying previous ZegoUIKitPrebuilt instance");
          zpRef.current.destroy();
        } catch (err) {
          console.error('Failed to destroy previous ZegoUIKitPrebuilt instance:', err);
        }
        zpRef.current = null;
      }
    };
  }, [details]);

  return (
    <div
      className="myCallContainer"
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
    ></div>
  );
};

export default ZegoCloud;
