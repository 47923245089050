import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line
} from 'recharts';

interface UserData {
  utcTime: string; // Define other properties as needed
  // ... (other properties of user data)
}

interface UsersChartProps {
  data: UserData[];
  title: string;
  color?: string;
  type?: 'bar' | 'line';
}

function UsersChart({ data = [], title, color = '#2980BA', type = 'bar' }: UsersChartProps) {
  // Assuming `data` is your array of user objects
  const userCountsByDay = {};

  if (Array.isArray(data)) {
    // Step 1: Loop through each user and increment the count for the user's registration date
    data.forEach((user) => {
      // Ensure that 'utcTime' exists and is a string before attempting to split it
      if (typeof user.utcTime === 'string') {
        const date = user.utcTime.split(' ')[0]; // This gets the 'YYYY-MM-DD' part

        // If the date isn't in the object yet, add it with a count of 1; otherwise, increment the count
        if (!userCountsByDay[date]) {
          userCountsByDay[date] = 1;
        } else {
          userCountsByDay[date]++;
        }
      }
    });
  } else {
    console.warn('Expected an array for data but received:', data);
  }

  // Step 2: Convert the counts object intzo an array of objects
  const countsArray = Object.keys(userCountsByDay).map((date) => {
    return { date: date, count: userCountsByDay[date] };
  });

  // 'countsArray' is now an array where each element is an object with the date and the count of registered users for that date
  console.log('count', countsArray);

  // Function to format the XAxis ticks
  const formatDateTick = (tick) => {
    const dateParts = tick.split('-'); // Split the date into [year, month, day]
    const sameYear = countsArray.every((d) => d.date.startsWith(dateParts[0])); // Check if all dates are from the same year
    const sameMonth = countsArray.every(
      (d) => d.date.slice(0, 7) === `${dateParts[0]}-${dateParts[1]}`
    ); // Check if all dates are from the same month & year

    if (sameYear && sameMonth) {
      // If all data is from the same month & year, show only the day
      return dateParts[2];
    } else if (sameYear) {
      // If all data is from the same year, show only month and day
      return `${dateParts[1]}-${dateParts[2]}`;
    }
    // Otherwise, show the full date
    return tick;
  };

  // Determine if all data is from the same year for label display
  const allSameYear = new Set(countsArray.map((item) => item.date.slice(0, 4))).size === 1;
  const yearLabel = allSameYear ? countsArray[0].date.slice(0, 4) : '';

  return (
    <div className="text-center">
      <h3>{title}</h3>
      <ResponsiveContainer height={300}>
        {type == 'bar' ? (
          <BarChart
            data={countsArray}
            margin={{
              top: 5,
              right: 30,
              left: 20
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={formatDateTick}
              height={25}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="count"
              fill={color}
              activeBar={
                <Rectangle
                  fill="gold"
                  stroke="purple"
                />
              }
            />
          </BarChart>
        ) : (
          <LineChart
            data={countsArray}
            margin={{
              top: 5,
              right: 30,
              left: 20
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={formatDateTick}
              height={25}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke={color}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  );
}

export default UsersChart;
