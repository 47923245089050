import { useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
function SummaryPickDateTwo({ selectedDate, setSelectedDate }) {
  const { width , height } =useWindowDimensions();
  return (
    <DatePicker
    selected={selectedDate}
    onChange={(date) => setSelectedDate(date)}
    style={{
      width: `${Responsive.w(72, width)}px`,
      height: `${Responsive.h(28, height)}px`,
      borderRadius: `${Responsive.sp(4, width)}px`,
      fontSize: `${Responsive.sp(12, width)}px`,
    }} 
    className="outline-none z-[200] !w-[72px] font-[Montserrat]  text-[#202223]   bg-transparent flex items-center justify-center"
    isOpen={true}
    dateFormat="MMMM dd"
    showYearDropdown
     showMonthDropdown
     showDayDropdown
     shouldCloseOnSelect={false}
     
    />
  );
}

export default SummaryPickDateTwo;
