import React, { useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

function AverageRevenue({ data, title, color = '#2980BA' }) {
  const roundToTwoDecimals = (number) => {
    if (number === null || number === undefined || isNaN(number)) {
      return 0;
    }
    return Number(number.toFixed(2));
  };

  const formatDateTick = (tick) => {
    const dateParts = tick.split('-');
    const sameYear = data.every((d) => d.date.startsWith(dateParts[0]));

    if (sameYear) {
      return dateParts[1];
    }
    return tick;
  };

  const sortedData = data
    .map((item) => ({
      ...item,
      date: new Date(`${item.date}-01`),
      averageRevenue: roundToTwoDecimals(item.averageRevenue)
    }))
    .sort((a, b) => a.date - b.date);

  const isSameYear = sortedData.every(
    (item, _, array) => item.date.getFullYear() === array[0].date.getFullYear()
  );

  const formatMonth = (date) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return isSameYear ? month : `${month}-${year}`;
  };

  const monthlyData = sortedData.map((item) => ({
    ...item,
    date: formatMonth(item.date)
  }));

  return (
    <div className="text-center">
      <h3>{title}</h3>
      <ResponsiveContainer height={300}>
        <LineChart data={monthlyData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={formatDateTick}
          />
          <YAxis />
          <Legend />
          <Tooltip />
          <Line
            dataKey="averageRevenue"
            fill={color}
            stroke={color}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default AverageRevenue;
