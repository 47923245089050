import React from 'react';
import {
  ResponsiveContainer,
  Funnel,
  FunnelChart,
  Tooltip,
  Layer,
  LabelList,
  Label,
  Line,
  TooltipProps
} from 'recharts';

interface FunnelChartComponentProps {
  userCategories: {
    [key: string]: number;
  };
}
const FunnelChartComponent: React.FC<FunnelChartComponentProps> = ({ userCategories = {} }) => {
  const defaultColors = ['#e6bc59', '#4ad991', '#ff4f6f', '#7b6c96'];
  const habitUserColor = '#2980ba'; // Orange color for habitUsers
  const minValueForVisibility = 100; // Set a minimum value for visibility

  const totalUsers = Object.values(userCategories).reduce((acc, cur) => acc + cur, 0);

  const data = Object.entries(userCategories).map(([key, value], index) => {
    let adjustedValue = value;

    // If the value is too small and it's a significant category, adjust it to the minimum visibility threshold.
    if (value < minValueForVisibility && key === 'habitUsers') {
      adjustedValue = minValueForVisibility;
    }

    return {
      value: adjustedValue,
      name: key
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toLowerCase()
        .replace('users', '')
        .trim(),
      fill: key === 'habitUsers' ? habitUserColor : defaultColors[index % defaultColors.length]
    };
  });

  // Define the expected prop types for the CustomTooltip
  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
  }

  // Custom tooltip
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
      const percentage = '%' + ((value / totalUsers) * 100).toFixed(2);

      let backgroundColor = '#fff'; // الخلفية الافتراضية
      let textColor = '#000'; // لون النص الافتراضي

      // التحقق من قيمة الاسم وتعيين الخلفية ولون النص بناءً عليها
      if (name === 'desire') {
        backgroundColor = '#fff3d6'; // خلفية صفراء خفيفة
        textColor = '#e6bc59'; // لون النص الأسود
      } else if (name === 'loyalty') {
        backgroundColor = '#ffdae0'; // خلفية حمراء خفيفة
        textColor = '#ff4f6f'; // لون النص الأسود
      } else if (name === 'conversion') {
        backgroundColor = '#d9f7e8'; // خلفية حمراء خفيفة
        textColor = '#4ad991'; // لون النص الأسود
      } else if (name === 'advocacy') {
        backgroundColor = '#e5e1ec'; // خلفية حمراء خفيفة
        textColor = '#7b6c96'; // لون النص الأسود
      } else if (name === 'habit') {
        backgroundColor = '#edf0f2'; // خلفية حمراء خفيفة
        textColor = '#2980ba'; // لون النص الأسود
      }

      return (
        <div
          className="text-center relative z-20 left-0"
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
            padding: '5px',
            borderRadius:"11.6px"
          }}
        >
          <p className="flex justify-center items-center gap-[6.6px]">
            <span
              className="w-[8px] h-[8px] rounded-full"
              style={{ backgroundColor: textColor, display: 'inline-block' }}
            ></span>
            {name}
          </p>
          <p className="text-[#000]">{`Percentage: (${percentage})`}</p>
        </div>
      );
    }

    return null; // إذا لم يتم العثور على بيانات صالحة، يمكننا إرجاع شيء فارغ
  };

  return (
    <div>
      <div className="flex justify-center mb-2 font-bold">
        <h3>Customer Retention Funnel</h3>
      </div>
      <ResponsiveContainer
        width="100%"
        height={400}
      >
        <FunnelChart>
          <Tooltip content={<CustomTooltip />} />
          <Funnel
            dataKey="value"
            data={data}
            isAnimationActive
            labelLine
          >
            {data.map((entry, index) => (
              <Layer key={`custom-${index}`} />
            ))}
          </Funnel>
        </FunnelChart>
      </ResponsiveContainer>
      <div className="absolute bottom-0">
        {data.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{ margin: '10px 0', color: entry.fill }}
          >
            <strong className="flex justify-center items-center gap-[6.6px]">
              <span
                className="w-[8px] h-[8px] rounded-full"
                style={{ backgroundColor: `${entry.fill}`, display: 'inline-block' }}
              ></span>
              {entry.name}
            </strong>
            : {entry.value} ({((entry.value / totalUsers) * 100).toFixed(2)}
            %)
          </div>
        ))}
      </div>
    </div>
  );
};

export default FunnelChartComponent;

