import React, { useEffect, useState } from 'react';
import Card from 'src/components/shared/Card';
import useFetch from 'src/hooks/useFetch';
import Dashboard from './../Dashboard';
import axiosInstance from 'src/helper/AxiosInstance';
import SmallLoader from 'src/components/shared/SmallLoader';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

function Index() {
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalEarn, setTotalEarn] = useState(0);
  const [isTotalOrdersLoading, setIsTotalOrdersLoading] = useState(false);
  const [isTotalEarnLoading, setIsTotalEarnLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setIsTotalOrdersLoading(true);
      setIsTotalEarnLoading(true);
      const ordersRes = await axiosInstance.get('dashboard/orders');
      const earnRes = await axiosInstance.get('dashboard/totalEarn');
      return { ordersRes, earnRes };
    };
    fetchData()
      .then((data) => {
        console.log('orders', data);
        const { earnRes, ordersRes } = data;
        setTotalOrders(ordersRes.data.result.data.totalOrders);
        setTotalEarn(earnRes.data.result.data.totalEarn);
        setIsTotalEarnLoading(false);
        setIsTotalOrdersLoading(false);

        // console.log('earn', earnRes);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    // console.log('earn', earnRes);
  }, []);
  return (
    <div className="bg-slate-100 min-h-screen p-4 flex justify-center items-center">
      <div className="w-1/3  flex flex-col justify-center items-center space-y-20 h-[500px] p-8">
        <Card
          className="text-2xl h-1/2 w-full flex flex-col items-center justify-center hover:shadow-sm hover:shadow-black hover:cursor-pointer"
          onClick={() => navigate('/orders')}
        >
          <p className="text-gray-500 font-bold">
            {useIntl().formatMessage({
              id: 'totalOrders',
              defaultMessage: 'total orders'
            })}
          </p>
          {isTotalOrdersLoading ? (
            <SmallLoader />
          ) : (
            <p className="font-bold text-primary">{totalOrders}</p>
          )}
        </Card>
        <Card className="text-2xl h-1/2 w-full flex flex-col items-center justify-center">
          <p className="text-gray-500 font-bold">
            {useIntl().formatMessage({
              id: 'totalEarn',
              defaultMessage: 'total earn'
            })}
          </p>
          {isTotalEarnLoading ? (
            <SmallLoader />
          ) : (
            <p className="font-bold text-amber-500">${totalEarn}</p>
          )}
        </Card>
      </div>
    </div>
  );
}

export default Index;
