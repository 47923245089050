import React, { useMemo } from 'react';
import {
  BarChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  TooltipProps,
  Bar
} from 'recharts';


type Order = {
  utcTime: string; // Updated type to include utcTime
  price: string; // or number if it's always a number
};

type ProcessedData = {
  date: string;
  total: number;
};
function CumulativePayedBalanceChart({ data }) {

  
  const processData = (orders: Order[]): { data: ProcessedData[]; sameYear: boolean } => {
    const earningsByDay: Record<string, number> = {};
    let sameYear = true;
    let prevYear: number | null = null;

    orders.forEach((order) => {
      if (order.utcTime && order.price) {
        const date = new Date(order.utcTime);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // Months are zero indexed
        const day = date.getUTCDate();
        const dateKey = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        if (prevYear !== null && prevYear !== year) {
          sameYear = false;
        }
        prevYear = year;

        const price = parseFloat(order.price);
        if (!isNaN(price)) {
          if (!earningsByDay[dateKey]) {
            earningsByDay[dateKey] = price;
          } else {
            earningsByDay[dateKey] += price;
          }
        } else {
          console.warn(`Invalid price for order on ${dateKey}: ${order.price}`);
        }
      }
    });

    const dailyData: ProcessedData[] = [];

    Object.keys(earningsByDay).forEach((date) => {
      const total = Math.round(earningsByDay[date] * 100) / 100;
      dailyData.push({ date, total });
    });

    return {
      data: dailyData,
      sameYear
    };
  };

  const { data: chartData, sameYear } = useMemo(() => processData(data), [data]);

  const formatDateForAxis = (date: string): string => {
    const [year, month, day] = date.split('-');
    // Display only day and month if all dates are in the same year
    return sameYear ? `${day}` : date;
  };

  const fullDateFormat = (date: string): string => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`; // Change this to your preferred date format
  };
  interface CustomTooltipProps extends TooltipProps<any, any> {
    active?: boolean;
    payload?: any[];
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="p-5 bg-[#e5e1ec] text-[#7b6c96] rounded-[10px]"
          style={{ boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)' }}
        >
          <p className="flex items-center justify-center gap-1 text-[16px] font-semibold">
            <span
              className="w-[8px] h-[8px] !rounded-full"
              style={{ backgroundColor: '#7b6c96', display: 'inline-block' }}
            ></span>
            {`Total: ${data.total}`}
          </p>
          <p className="label text-[#656565]">{`${data.date}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="text-center">
      <h3 className="text-[24px] font-semibold text-left font-[Montserrat] text-[#1e1e1e]">revenue</h3>
      <ResponsiveContainer height={387}>
        <BarChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20
          }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={formatDateForAxis}
            axisLine={false}
          />
          <YAxis axisLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="total"
            fill="#7b6c96"
            barSize={12.1}
            shape={<CustomBar />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y - 3}
        width={width}
        height={height}
        fill={fill}
        rx={10}
        ry={10}
      />
    </g>
  );
};
export default CumulativePayedBalanceChart;
