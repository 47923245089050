import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import axiosInstance from 'src/helper/AxiosInstance';
import useConfirmationDialog from 'src/hooks/useConfirmation';

function Index() {
  const [admins, setAdmins] = useState<any>([]);
  const [currentAdmin, setCurrentAdmin] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //form fields states
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axiosInstance.get(`/admins`);
        setAdmins(response.data.result.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAdmins();
  }, []);

  const handleAddAdmin = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // uploading image to storage
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axiosInstance.post('/adminImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setImage(response.data.result.data.imgUrl || 'noImage');
      }

      const reqBody = {
        name,
        mobileNo,
        profileImageUrl: image || 'noImage',
        email
      };
      //submitting patch data to doc in firestore
      let Response;
      // Adding a new noti
      if (!currentAdmin) {
        Response = await axiosInstance.post('/admins/', reqBody);
        setAdmins([...admins, Response.data.result.data]);
      } else {
        Response = await axiosInstance.patch(`/admins/${currentAdmin.uid}`, reqBody);
        const updatedAdmins = admins.map((admin) => {
          if (admin.uid === currentAdmin.uid) {
            return { ...admin, ...Response.data.result.data };
          }
          return admin;
        });

        // Set the updated admins array to state
        setAdmins(updatedAdmins);
      }
      setIsModalOpen(false);
      resetValues();
      const successMsg = useIntl().formatMessage({
        id: 'addedAdmin',
        defaultMessage: 'Admin has been added successfully.'
      });
      toast.success(successMsg);
    } catch (error: any) {
      console.error('There is an error!:', error);
      toast.error(error.response.data.result.message || error.message);
    }
  };

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const fileInputRef = useRef<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const resetValues = () => {
    setImage('');
    setName('');
    setEmail('');
    setMobileNo('');
    setCurrentAdmin(null);
  };

  const handleRestoreClick = (event: any) => {
    event.preventDefault();
    setImage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => console.log('a', selectedFile), [selectedFile]);
  const handleEditButtonClick = (currentAdmin) => {
    setCurrentAdmin(currentAdmin);
    setImage(currentAdmin?.profileImageUrl || '');
    setName(currentAdmin?.name || '');
    setEmail(currentAdmin?.email || '');
    setMobileNo(currentAdmin?.mobileNo || '');
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    resetValues();
    setIsModalOpen(false);
  };
  const showConfirmation = useConfirmationDialog();
  const handleDeactivate = async (id) => {
    let Response: any;
    const confirmationMsg = useIntl().formatMessage({
      id: 'deactivateAdmin',
      defaultMessage: 'Are you sure you want to deactivate this admin?'
    });
    await showConfirmation(confirmationMsg, async () => {
      Response = await axiosInstance.patch(`admins/${id}`, {
        activated: false,
        accountStatus: 'NotActive'
      });
    });
    const updatedAdmins = admins.map((admin) => {
      if (admin?.uid === id) {
        return { ...admin, ...Response.data.result.data };
      }
      return admin;
    });
    setAdmins(updatedAdmins);
  };

  const handleActivate = async (id) => {
    let Response: any;
    const confirmationMsg = useIntl().formatMessage({
      id: 'activateAdmin',
      defaultMessage: 'Are you sure you want to activate this admin?'
    });
    await showConfirmation(confirmationMsg, async () => {
      Response = await axiosInstance.patch(`admins/${id}`, {
        activated: true,
        accountStatus: 'Active'
      });
    });
    const updatedAdmins = admins.map((admin) => {
      if (admin.uid === id) {
        return { ...admin, ...Response.data.result.data };
      }
      return admin;
    });
    setAdmins(updatedAdmins);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (error) {
    console.log('er', error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="py-12 px-8 min-h-screen flex flex-col items-center justify-center bg-slate-50">
      <div className=" flex justify-end w-full ">
        <button
          onClick={handleAddAdmin}
          className=" bg-primary text-white font-bold px-4 py-2 rounded-lg hover:bg-slate-600"
        >
          {useIntl().formatMessage({
            id: 'addNewAdmin',
            defaultMessage: 'Add new admin'
          })}
        </button>
      </div>
      <div className="md:w-1/2 flex flex-col space-y-8">
        {admins.map((admin) => (
          <Card
            key={admin?.id}
            className=""
          >
            <div className="p-4 flex flex-col space-y-4">
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  {useIntl().formatMessage({
                    id: 'name:',
                    defaultMessage: 'name:'
                  })}
                </label>
                <p>{admin?.name}</p>
              </div>
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  {useIntl().formatMessage({
                    id: 'mobileNo:',
                    defaultMessage: 'Mobile number:'
                  })}
                </label>
                <p>{admin?.mobileNo}</p>
              </div>
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  {useIntl().formatMessage({
                    id: 'email:',
                    defaultMessage: 'Email:'
                  })}
                </label>
                <p>{admin?.email}</p>
              </div>
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  {useIntl().formatMessage({
                    id: 'password:',
                    defaultMessage: 'Password:'
                  })}
                </label>
                <p>{admin?.password}</p>
              </div>
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  {useIntl().formatMessage({
                    id: 'primaryAdmin:',
                    defaultMessage: 'Primary admin:'
                  })}
                </label>
                <p className="font-bold">
                  {admin?.primaryAdmin
                    ? useIntl().formatMessage({
                        id: 'yes',
                        defaultMessage: 'yes'
                      })
                    : useIntl().formatMessage({
                        id: 'no',
                        defaultMessage: 'no'
                      })}
                </p>
              </div>
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  Last updated:
                  {useIntl().formatMessage({
                    id: 'lastUpdated',
                    defaultMessage: 'Last updated:'
                  })}
                </label>
                <p>
                  <SharedTime
                    date={
                      new Date(
                        admin?.timestamp?._seconds * 1000 + admin?.timestamp?._nanoseconds / 1000000
                      )
                    }
                    format="MMMM Do YYYY, h:mm:ss a"
                  />
                </p>
              </div>
              <div className="flex space-x-4">
                <label className="text-gray-600 ">
                  {useIntl().formatMessage({
                    id: 'accountStatus',
                    defaultMessage: 'Account status:'
                  })}
                </label>
                <p>
                  {admin?.activated ? (
                    <span className="text-green-500 font-bold">
                      {useIntl().formatMessage({
                        id: 'activated',
                        defaultMessage: 'Activated'
                      })}
                    </span>
                  ) : (
                    <span className="text-red-500">
                      {useIntl().formatMessage({
                        id: 'deactivated',
                        defaultMessage: 'Deactivated'
                      })}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="flex justify-around mt-4 pt-4 ">
              <button
                onClick={() => handleEditButtonClick(admin || null)}
                className="bg-green-200 font-bold rounded-lg p-2 px-8"
              >
                {useIntl().formatMessage({
                  id: 'editAdmin',
                  defaultMessage: 'Edit Admin'
                })}
              </button>
              {admin?.activated ? (
                <button
                  onClick={() => handleDeactivate(admin?.uid)}
                  className="bg-red-200 font-bold rounded-lg p-2 px-8"
                >
                  {useIntl().formatMessage({
                    id: 'deactivate',
                    defaultMessage: 'Deactivate'
                  })}
                </button>
              ) : (
                <button
                  onClick={() => handleActivate(admin?.uid)}
                  className="bg-green-200 font-bold rounded-lg p-2 px-8"
                >
                  {useIntl().formatMessage({
                    id: 'activate',
                    defaultMessage: 'Activate'
                  })}
                </button>
              )}
            </div>
          </Card>
        ))}
      </div>
      <Modal
        visible={isModalOpen}
        handleClose={handleCloseModal}
        title={currentAdmin ? 'Edit admin info' : 'Add New Admin'}
      >
        <form
          className="flex flex-col space-y-4 items-center justify-center"
          onSubmit={handleSubmit}
        >
          <div className="flex-col space-y-4 w-full">
            {/* Image Upload */}
            <div className="mt-2">
              <label
                htmlFor="file-upload"
                className=" inline-block cursor-pointer"
              >
                {useIntl().formatMessage({
                  id: 'uploadImage',
                  defaultMessage: 'Upload Image'
                })}
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                className="block w-1/2 rounded bg-primary"
                ref={fileInputRef}
              />
              {image && (
                <>
                  <img
                    src={image}
                    alt="Avatar"
                    width={200}
                    height={200}
                    id="imagePreview"
                    className="w-100 h-100 object-stretch mt-2"
                  />
                  <button
                    type="button"
                    className=" text-white font-semibold mt-2 hover:bg-slate-600 hover:cursor-pointer  p-2 text-center bg-blue-950 rounded"
                    onClick={handleRestoreClick}
                  >
                    {useIntl().formatMessage({
                      id: 'removeImage',
                      defaultMessage: 'Remove image'
                    })}
                  </button>
                </>
              )}
            </div>

            {/* Name */}
            <div className="flex justify-between items-center">
              <label className="w-1/3">
                {useIntl().formatMessage({
                  id: 'fullName',
                  defaultMessage: 'Full name'
                })}
              </label>

              <input
                className="w-2/3 border border-black rounded bg-slate-50 p-2"
                placeholder="Full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Mobile No */}
            <div className="flex justify-between items-center">
              <label className="w-1/3">
                {useIntl().formatMessage({
                  id: 'mobileNumber',
                  defaultMessage: 'Mobile number'
                })}
              </label>

              <input
                className="w-2/3 border border-black rounded bg-slate-50 p-2"
                placeholder="Mobile number"
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
              />
            </div>

            {/* Email */}
            <div className="flex justify-between items-center">
              <label className="w-1/3">
                {useIntl().formatMessage({
                  id: 'email',
                  defaultMessage: 'email'
                })}
              </label>

              <input
                className="w-2/3 border border-black rounded bg-slate-50 p-2"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="px-4 py-2 mt-4 bg-primary text-white rounded hover:bg-slate-600"
            >
              {currentAdmin
                ? useIntl().formatMessage({
                    id: 'save',
                    defaultMessage: 'save'
                  })
                : useIntl().formatMessage({
                    id: 'addAdmin',
                    defaultMessage: 'addAdmin'
                  })}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Index;
