import { configureStore } from '@reduxjs/toolkit';
import Survey from '../reducers/survey';
import {
  auth,
  globalResponse,
  Users,
  Consultants,
  Orders,
  Tap,
  Appointments,
  PromoCodes,
  Locale,
  Dashboard
} from '../reducers';

export const store = configureStore({
  reducer: {
    auth,
    globalResponse,
    Users,
    Consultants,
    Orders,
    Tap,
    Appointments,
    PromoCodes,
    Locale,
    Dashboard,
    Survey
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
  