import { FormattedMessage } from 'react-intl';
import PickDate from './PickDate';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import PickDateTwo from './PickDateTwo';
import { Icon } from '@iconify/react';
const RangePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const locale = useSelector((state: RootState) => state.Locale.value);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const { width, height } = useWindowDimensions();
  const directionClass = locale === 'ar' ? 'flex-row-reverse' : '';
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div className={`flex z-[200]  ${directionClass}  items-center justify-center `}>
      <div
        className={`flex  ${directionClass} flex-grow-0 items-stretch bg-[#f5f3f8]  justify-center p-1 pr-2`}
        style={{
          width: `${Responsive.w(155, width)}px`,
          height: `${Responsive.h(28, height)}px`,
          borderRadius: `${Responsive.sp(4, width)}px`
        }}
      >
        <label
          className="bg-[#7b6c96]  text-white self-stretch  text-center flex items-center justify-between py-0 px-1 "
          style={{
            width: `${Responsive.w(49, width)}px`,
            height: `${Responsive.h(28, height)}px`,
            fontSize: `${Responsive.sp(14, width)}px`,
            borderRadius: `${Responsive.sp(3, width)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            backgroundColor:dashColor
          }}
        >
          <span className="">
            <FormattedMessage
              id="from"
              defaultMessage="From"
            />
          </span>
        </label>
        <div
          className="ml-2"
          style={{
            width: `${Responsive.w(72, width)}px`,
            height: `${Responsive.h(28, height)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`,
            fontSize: `${Responsive.sp(12, width)}px`
          }}
        >
          <PickDate
            selectedDate={startDate}
            setSelectedDate={(date) => setStartDate(date)}
          />
        </div>
      </div>
      <div
        className={`flex  ${directionClass} flex-grow-0 items-stretch bg-[#f5f3f8]  justify-center p-1 pr-2`}
        style={{
          width: `${Responsive.w(155, width)}px`,
          height: `${Responsive.h(28, height)}px`,
          borderRadius: `${Responsive.sp(4, width)}px`
        }}
      >
        <label
          className="bg-[#7b6c96] text-white self-stretch  text-center flex items-center justify-center py-0 px-1 "
          style={{
            width: `${Responsive.w(49, width)}px`,
            height: `${Responsive.h(28, height)}px`,
            fontSize: `${Responsive.sp(14, width)}px`,
            borderRadius: `${Responsive.sp(3, width)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            backgroundColor:dashColor
          }}
        >
          <span>
            <FormattedMessage
              id="to"
              defaultMessage="To"
            />
          </span>
        </label>
        <div
          className="ml-2"
          style={{
            // width: `${Responsive.w(72, width)}px`,
            height: `${Responsive.h(28, height)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`,
            fontSize: `${Responsive.sp(12, width)}px`
          }}
        >
          <PickDate
            selectedDate={endDate}
            setSelectedDate={(date) => setEndDate(date)}
          />
        </div>
      </div>
      {/* <div className="flex  items-center justify-center mr-4">
        <button
        style={{
          width: `${Responsive.w(72, width)}px`,
          // height: `${Responsive.h(28, height)}px`,
          borderRadius: `${Responsive.sp(4, width)}px`,
          fontSize: `${Responsive.sp(12, width)}px`,
        }}
           onClick={applyHandler}
          className="bg-[#7b6c96]   text-white font-semibold "
        >
          <span className='nowrap'
           style={{
           
            borderRadius: `${Responsive.sp(4, width)}px`,
            fontSize: `${Responsive.sp(12, width)}px`,
          }}>
          <FormattedMessage
            id="applyFilter"
            defaultMessage="Apply filter"
          />
          </span>
        </button>
      </div>  */}
    </div>
  );
};

export default RangePicker;

