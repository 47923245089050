import React, { useState } from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';

type UserData = {
  userLang?: string;
  countryISOCode: string;
  deviceType: string;
};

const DeviceChart = ({ data, loading }: { data: UserData[]; loading: boolean }) => {
  // Initialize language counts for a fixed set of languages
  const initialLanguageCounts = { ar: 0, en: 0, fr: 0, id: 0 };

  // Normalize language codes and count occurrences
  const languageCounts = data.reduce((acc, item) => {
    const lang = item.userLang?.toLowerCase(); // Normalize to lowercase
    if (lang && acc.hasOwnProperty(lang)) {
      // Only count if the language is one of the fixed set
      acc[lang] += 1;
    }
    return acc;
  }, initialLanguageCounts);

  // Calculate the total count
  const totalCount = Object.values(languageCounts).reduce((sum, count) => sum + count, 0);

  // Prepare chart data and include percentage calculation
  const chartData = Object.entries(languageCounts).map(([key, value]) => {
    const percent = ((value / totalCount) * 100).toFixed(2);
    return {
      name: key.toUpperCase(), // Display the language code in uppercase for consistency
      value,
      percent: `${percent}%`
    };
  });

  type ChartData = {
    name: string;
    value: number;
    percent: string;
  };

  // Colors for different languages
  const COLORS = ['#7b6c96','#e6bc59' , '#4ad991'];

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index: number) => {
    setActiveIndex(index);
  };

  // Custom Tooltip content
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload as ChartData;
      let backgroundColor = '#fff';
      let textColor = '#000';

      if (data.name === 'IOS') {
        backgroundColor = '#fff3d6';
        textColor = '#e6bc59';
      }  else if (data.name === 'WEB') {
        backgroundColor = '#d9f7e8';
        textColor = '#4ad991';
      } else if (data.name === 'ANDROID') {
        backgroundColor = '#e5e1ec';
        textColor = '#7b6c96';
      } 
 // Typecast for better type safety
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
            padding: '5px',
            borderRadius: '11.6px'
          }}
        >
          <p className="label  font-semibold">{`${data.percent}`}</p>
        </div>
      );
    }

    return null;
  };

  const generateChartData = (
    data: UserData[],
    key: 'countryISOCode' | 'deviceType'
  ): ChartData[] => {
    const counts = data.reduce((acc, item) => {
      const value = item[key]?.toLowerCase(); // Normalize to lowercase
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      }
      return acc;
    }, {} as Record<string, number>);

    const totalCount = Object.values(counts).reduce((sum, count) => sum + count, 0);

    return Object.entries(counts).map(([key, value]) => ({
      name: key.toUpperCase(), // Display in uppercase
      value,
      percent: `${((value / totalCount) * 100).toFixed(2)}%`
    }));
  };

  // Generate chart data for both countries and device types
  const countryChartData = generateChartData(data, 'countryISOCode');
  const deviceChartData = generateChartData(data, 'deviceType');

  // Custom Legend Renderer
  const renderCustomLegend = ({ payload }: any) => {
const {width , height} = useWindowDimensions();
    return (
      <div className="flex justify-center">
        {!loading && (
          <ul className="custom-legend grid grid-cols-2 gap-2 text-left">
              {payload.map((entry: any, index: number) => (
              <li
                className="flex items-center justify-center gap-1"
                key={`item-${index}`}
                style={{ color: '#656565' }}
              >
                <span
                  className="w-[8px] h-[8px] !rounded-full"
                  style={{ backgroundColor: entry.color, display: 'inline-block' }}
                ></span>
                <p 
                style={{
                  fontSize:`${Responsive.sp(16,width)}px`, 
                }}
                >{`${entry.payload.name} `}
                <span className='font-semibold text-[#1e1e1e]'>{entry.payload.percent}</span>
                </p>
              </li>
            ))}
          </ul>
        )}
      </div> 
    );
  };

  return (

      <div className="text-center">
        <h3 className='text-[24px] font-semibold text-left font-[Montserrat] text-[#1e1e1e]'>Device Types</h3>
        <ResponsiveContainer height={386}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              data={deviceChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              innerRadius={60}
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              onMouseEnter={onPieEnter}
            >
              {deviceChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderCustomLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div>

  );
};

export default DeviceChart;
