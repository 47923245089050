import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Link, redirect, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helper/AxiosInstance';
import { Icon } from '@iconify/react';
import axios, { AxiosError } from 'axios';
import Alert from 'src/components/shared/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { storeToken, saveUserInfo } from 'src/reducers/auth';
import Password from 'src/components/shared/Password';
import generateAlert from 'src/helper/generateAlert';
import { useIntl } from 'react-intl';
import { RootState } from 'src/store';
import { getDirectionClass } from 'src/helper/directionClass';
import { toast } from 'react-toastify';
import LanguageSelector from 'src/components/LanguageSelector';
import { Responsive } from 'src/layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';

interface AuthInterface {
  email?: string;
  password?: string;
}

const FormBody: React.FC = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const globalValues = {
    email: undefined,
    password: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const direction = getDirectionClass();
  const AuthHandler = React.useCallback(async function (
    values: AuthInterface,
    helper: FormikHelpers<AuthInterface>
  ): Promise<any> {
    try {
      setErrors(undefined);
      setDisabled(true);
      let token: string, user: any;
      // const { data } = await axiosInstance.post('auth/login', values);
       const res = await axios.post('https://api.focalpoint-apps.com/login', values);
      // test
  //  const res = await axios.post('http://localhost:3004/login', values);
      token = res.data.token;
      sessionStorage.setItem('@token', token);
      dispatch(storeToken(token));
      navigate('/welcome', { replace: true });
      toast.success('Welcome back!');
    } catch (error: AxiosError | any) {
      console.log('error', error);
      toast.error(error?.response?.data);
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        const message = err?.result?.message;
        if (err?.result) {
          const errMsg = err?.result?.message;
          if (errMsg instanceof Object) {
            setErrors(errMsg);
          }
          generateAlert(errMsg, 'error');
          return;
        }
        generateAlert(message, 'error');
      }
    } finally {
      setDisabled(false);
    }
  },
  []);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: AuthHandler
  });
  const handlePasswordReset = () => {};
  return (
    <form
      className="flex flex-col justify-center items-center p-8 rounded-lg"
      style={{
        width: `${Responsive.w(512, width)}px`,
        gap: `${Responsive.sp(64, width)}px`,
        boxShadow: '0 0 12.6px 0 rgba(0, 0, 0, 0.1)',
        border: ' solid 1px #b4b4b4'
      }}
      onSubmit={(e) => {
        e.preventDefault();
        console.log('submission from form');
        handleSubmit();
      }}
    >
      {errors ? (
        <Alert
          type="error"
          content={errors}
        />
      ) : null}
      <h1
        className="text-center  text-black font-medium font-[Montserrat]"
        style={{
          fontSize: `${Responsive.sp(34, width)}px`
        }}
      >
        {useIntl().formatMessage({
          id: 'signIn',
          defaultMessage: 'Sign in'
        })}
      </h1>
      <div className="form-group flex flex-col "
      style={{
        width: `${Responsive.w(400, width)}px`,
        gap: `${Responsive.sp(48, width)}px`,
        }}>
       <div className=' flex flex-col gap-4 p-0'>
       <label
          htmlFor="email"
          className=" text-[#686868] text-left"
          style={{
            fontSize: `${Responsive.sp(18, width)}px`
          }}
        >
          {useIntl().formatMessage({
            id: 'emailAdress',
            defaultMessage: 'E-mail address'
          })}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input h-[28px] outline-none border-none transition ease-in-out delay-150 !bg-transparent  focus:border-b-2 focus:border-solid focus:border-b-#2980BA focus:border-t-0 focus:border-l-0 focus:border-r-0 "
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          required
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email', target.value)
          }
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
       </div>
       <div className=' flex flex-col gap-4 p-0'>
        <label
          htmlFor="password"
          className=" text-[#686868] text-left"
          style={{
            fontSize: `${Responsive.sp(18, width)}px`
          }}
        >
          {useIntl().formatMessage({
            id: 'password',
            defaultMessage: 'password'
          })}
        </label>
        <Password
          name="password"
          id="password"
          placeholder="Enter Your Password"
          className="form-input h-[28px] outline-none border-none transition ease-in-out delay-150 !bg-transparent focus:border-b-2 focus:border-solid focus:border-b-#2980BA focus:border-t-0 focus:border-l-0 focus:border-r-0 "
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          required
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('password', target.value)
          }
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
       </div>
        
        
      </div>
      <div className="flex flex-col "
      style={{
        width: `${Responsive.w(400, width)}px`,
        gap: `${Responsive.sp(8, width)}px`,
        }}>
        <button
          className="btn-with-icon bg-[#2980BA] w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>
              {useIntl().formatMessage({
                id: 'signIn',
                defaultMessage: 'Sign in'
              })}
            </span>
          )}
        </button>
        <div className={`flex ${direction} text-[14px] justify-start items-start`}>
          <p className="text-[14px] text-left">
            {useIntl().formatMessage({
              id: 'forgotPassword',
              defaultMessage: 'forgot password?'
            })}
          </p>
          <p className=" underline text-[#2980BA] cursor-pointer text-left"
          onClick={() => navigate('/forgot-password')}>
            {useIntl().formatMessage({
              id: 'click',
              defaultMessage: 'click'
            })}{' '}
            <span
              className="underline text-[#2980BA] cursor-pointer"
              
            >
              {useIntl().formatMessage({
                id: 'here',
                defaultMessage: 'here'
              })}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

const Login: React.FC = (): JSX.Element => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      className="relative min-h-screen  flex items-center justify-center  bg-white overflow-hidden"
      style={{
        width: `${Responsive.w(1920, width)}px`
      }}
    >
      <div
        className=" flex items-center justify-center"
        style={{
          width: `${Responsive.w(800, width)}px`
        }}
      >
        <div className=" flex justify-end top-8 left-5 absolute">
          <LanguageSelector />
        </div>
        <div
          className="absolute  left-0 flex justify-center items-center p-8"
          style={{
            height: `${Responsive.h(1800, height)}px`,
            width: `${Responsive.w(512, width)}px`,
            background: 'rgb(41 128 186)',
            zIndex: 1
          }}
        >
          <img
            src="/images/focalPointLogo.png"
            alt="website logo"
            className="w-full  absolute "
            style={{
              width: `${Responsive.w(270, width)}px`,
              height: `${Responsive.h(288, height)}px`,
              right: '-28%',
              zIndex: '8'
            }}
          />
          <div
            className="hexagon"
            style={{
              width: `${Responsive.w(442, width)}px`,
              height: `${Responsive.h(250, height)}px`
            }}
          ></div>
        </div>
      </div>

      <div>
        <div>
          <FormBody />
        </div>
      </div>
    </div>
  );
};

export default Login;
