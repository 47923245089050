import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import CountryPicker from 'src/components/shared/CountryPicker';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import SmallLoader from 'src/components/shared/SmallLoader';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';

export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="flex-col min-h-screen p-12 items-center justify-center ">
      <PurchasesComponent />
    </div>
  );
}

const PurchasesComponent = () => {
  const [purchases, setPurchases] = useState([]);
  const [lastDocId, setLastDocId] = useState(null);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { width, height } = useWindowDimensions();
  const [title, setTitle] = useState('allCampaignOrders');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(true);
  const [isModalShow, setIsModalShow] = useState(true);
  const [isModalIndex, setIsModalIndex] = useState<any>();
  const [currentTab, setCurrentTab] = useState<string>('customize');
  const [pagination, setPagination] = useState<any>([]);
  const [perPage, setPerPage] = useState(15);
  const [count, setCount] = useState(15);
  const [userIds, setUserIds] = useState<any>([]);
  const navigate = useNavigate();

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const keys = [
    'consultant  Photo',
    'consultant  Name',
    'user Photo',
    'user Name',
    'user Country',
    'order Status',
    'price',
    'payment methos',
    'platform',
    'promoCode Id',
    'package Call Num'
  ];
  const [customizedKeys, setCustomizedKeys] = useState(keys);
  const { promoId } = useParams();
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    }
    // { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const fetchCampaigns = async (page = 1) => {
    setIsLoadingMore(true);
    try {
      const response = await axiosInstance.get(
        `/purchases/${promoId}?page=${page}&pageSize=${perPage}`
      );
      setPurchases(() => response.data.result.orders);
      setPagination(response.data.result.pagination);
      setCount(response.data.result.count);
      console.log(response.data.result);
      console.log(response.data.result.users);
    } catch (error) {
      console.error('Error fetching purchases:', error);
    }
    setIsLoadingMore(false);
  };
  useEffect(() => {
    fetchCampaigns();
  }, [perPage]); // Refetch campaigns when perPage changes

  const onNextClick = () => {
    fetchCampaigns((pagination.currentPage || 1) + 1);
  };

  const onPreviousClick = () => {
    fetchCampaigns(pagination.currentPage - 1);
  };
  const allNotificationsMessage = 'allCampaignOrders';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = async (option, title) => {
    setIsLoadingMore(true);
    try {
      setTitle(title);
      let startDate, endDate;

      switch (option) {
        case 'All Campaign Orders':
          fetchCampaigns();
          // setIsLoadingMore(false); // Fetch all campaigns without pagination
          return; // Exit the function early

        case 'Today':
          startDate = new Date();
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          break;

        case 'Last 7 days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          endDate = new Date();
          break;

        case 'Last 30 days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 30);
          endDate = new Date();
          break;

        case 'Date range':
          if (start && end) {
            startDate = new Date(start);
            endDate = new Date(end);
          }
          break;

        default:
          startDate = null;
          endDate = null;
          break;
      }

      const response = await axiosInstance.get(`/purchases/${promoId}`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          page: 1, // Start from the first page
          pageSize: perPage // Set the page size
        }
      });

      setPurchases(response.data.result.orders);
      setPagination(response.data.result.pagination);
      setCount(response.data.result.count);
    } catch (error) {
      console.error('Error fetching data by date:', error);
    }
    setIsLoadingMore(false);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const ChangeCustomizeKey = (isChecked, field) => {
    setCustomizedKeys((prevCustomizedKeys) => {
      if (isChecked) {
        // إذا تم التحقق من الخانة، نحتاج إلى إضافة الحقل إلى القائمة المخصصة في المكان المناسب
        const index = keys.indexOf(field); // العثور على موضع الحقل في keys
        const newIndex = prevCustomizedKeys.findIndex((key) => key === field); // التحقق مما إذا كان الحقل موجودًا بالفعل في customizedKeys
        if (newIndex === -1) {
          // إذا لم يتم العثور على الحقل في القائمة المخصصة، فقط قم بإضافته في المكان الصحيح
          return [...prevCustomizedKeys.slice(0, index), field, ...prevCustomizedKeys.slice(index)];
        } else {
          // إذا وجدنا الحقل بالفعل في القائمة المخصصة، فقم بإعادة ترتيبه إلى الموضع الصحيح
          return [
            ...prevCustomizedKeys.slice(0, index),
            ...prevCustomizedKeys.slice(newIndex, newIndex + 1), // النقطة التي تحتوي على الحقل الحالي
            ...prevCustomizedKeys.slice(index, newIndex),
            ...prevCustomizedKeys.slice(newIndex + 1)
          ];
        }
      } else {
        // إذا تم إلغاء التحقق من الخانة، قم بإزالة الحقل من القائمة المخصصة
        return prevCustomizedKeys.filter((key) => key !== field);
      }
    });
  };
  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        '/purchases/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  return (
    <div className="flex flex-col space-y-4 items-center justify-center w-full">
      <Table
        loading={isLoadingMore}
        isEmpty={!purchases.length}
        count={count}
        searchAble={true}
        title={title}
        buttonAble={true}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] z-40"
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard !== 'jeras'
                                      ? '!text-[#9c3981] border-b-[#9c3981]'
                                      : '! border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customizedKeys.includes(field)}
                                onChange={(e) => ChangeCustomizeKey(e.target.checked, field)}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                <FormattedMessage
                                  id={`${field.replaceAll(' ', '')}`}
                                  defaultMessage={`${field}`}
                                />
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All Campaign Orders', allNotificationsMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={allNotificationsMessage}
                      defaultMessage={allNotificationsMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff]"
              style={{ height: `${Responsive.h(24, height)}px`, backgroundColor: dashColor }}
            >
              #
            </th>
            {customizedKeys.map((field, index) => (
              <th
                key={index}
                className="text-center nowrap text-[14px] font-medium  self-stretch text-[#fff] capitalize"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor,
                  display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                }}
                data-label={field}
              >
                <FormattedMessage
                  id={`${field.replaceAll(' ', '')}`}
                  defaultMessage={`${field}`}
                />
              </th>
            ))}
          </>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {purchases.map((purchases: any, index) => (
              <tr
                key={index}
                style={{ backgroundColor: getRowColor(index) }}
                className="hover:bg-[#EAE0EB]"
              >
                <td
                  className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch"
                  data-label="#"
                >
                  {index + 1}
                </td>
                {customizedKeys.map((field, fieldIndex) => (
                  <td
                    key={fieldIndex}
                    className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch capitalize"
                    data-label={field}
                    style={{
                      display: customizedKeys.includes(field) ? 'table-cell' : 'none'
                    }}
                  >
                    {field === 'consultant  Photo' && (
                      <div>
                        <img
                          src={purchases?.consult?.image || '/images/placeholder.png'}
                          // onError={(e) => (e.target.src = '/images/placeholder.png')}
                          alt={purchases?.consult?.name}
                          className="w-8 h-8 rounded-full object-cover mx-auto"
                          width={512}
                          height={512}
                        />
                      </div>
                    )}
                    {field === 'consultant  Name' &&
                      (purchases.consult.name ? purchases.consult.name : 'N/A')}
                    {field === 'user Photo' && (
                      <div>
                        <img
                          src={purchases?.user?.image || '/images/placeholder.png'}
                          // onError={(e) => (e.target.src = '/images/placeholder.png')}
                          alt={purchases?.user?.name}
                          className="w-8 h-8 rounded-full object-cover mx-auto"
                          width={512}
                          height={512}
                        />
                      </div>
                    )}
                    {field === 'user Name' &&
                      (purchases.user.name != undefined ? purchases.user.name : 'N/A')}
                    {field === 'user Country' &&
                      (purchases.user.countryISOCode != undefined
                        ? purchases.user.countryISOCode
                        : 'N/A')}
                    {field === 'order Status' && (
                      <p
                        className="flex items-center justify-center"
                        style={{
                          height: '30px',
                          border:
                            purchases?.orderStatus === 'cancel'
                              ? 'solid 1px #e84a4d'
                              : purchases?.orderStatus === 'completed'
                              ? 'solid 1px #28c794'
                              : purchases?.orderStatus === 'closed'
                              ? `solid 1px ${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                              : 'solid 1px #1e1e1e',
                          backgroundColor:
                            purchases?.orderStatus === 'cancel'
                              ? '#ffe2e2'
                              : purchases?.orderStatus === 'completed'
                              ? '#ebfeef'
                              : purchases?.orderStatus === 'closed'
                              ? `${currentDashboard !== 'jeras' ? '#fff7fd' : '#e5e1ec'}`
                              : '',
                          color:
                            purchases?.orderStatus === 'cancel'
                              ? ' #d91c1f'
                              : purchases?.orderStatus === 'completed'
                              ? '#228176'
                              : purchases?.orderStatus === 'closed'
                              ? `${currentDashboard !== 'jeras' ? '#9c3981' : '#7b6c96'}`
                              : '#1e1e1e'
                        }}
                      >
                        {purchases?.orderStatus || 'N/A'}
                      </p>
                    )}
                    {field === 'price' && (purchases.price != undefined ? purchases.price : 'N/A')}
                    {field === 'payment methos' &&
                      (purchases.payWith != undefined ? purchases.payWith : 'N/A')}
                    {field === 'platform' &&
                      (purchases.platform != undefined ? purchases.platform : 'N/A')}
                    {field === 'promoCode Id' &&
                      (purchases.promoCodeId != undefined ? purchases.promoCodeId : 'N/A')}
                    {field === 'package Call Num' &&
                      (purchases.packageCallNum != undefined ? purchases.packageCallNum : 'N/A')}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
        pagination={{ ...pagination }}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return setPerPage(e.target?.value);
        }}
        onNextClick={() => {
          onNextClick();
        }}
        onPreviousClick={() => {
          onPreviousClick();
        }}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        buttonExport={() => ExportXlsx(purchases.map((purchase)=>purchase), keys)}
      />
    </div>
  );
};
