import { useState, useEffect } from 'react';
import axiosInstance from 'src/helper/AxiosInstance';

const useFetch = (url: string, shouldFetch: boolean) => {
  const [data, setData] = useState<any | null>(null);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!shouldFetch) return;
      setIsDataLoading(true);
      try {
        const response = await axiosInstance.get(url);
        setData(response.data.result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Type cast error to the Error type if you are sure it's always an error object
        setError(error as Error);
      } finally {
        setIsDataLoading(false);
      }
    };

    if (url) {
      fetchData();
    }
  }, [url, shouldFetch]);

  return { data, isDataLoading, error };
};

export default useFetch;
