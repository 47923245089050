import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/helper/AxiosInstance';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { getDirectionClass } from 'src/helper/directionClass';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

function Index() {
  const [firstTitleAr, setFirstTitleAr] = useState('');
  const [firstTitleEn, setFirstTitleEn] = useState('');
  const [focalDestination, setFocalDestination] = useState('');
  const [taxes, setTaxes] = useState(0);
  const [androidBuildNumber, setAndroidBuildNumber] = useState(0);
  const [androidVersion, setAndroidVersion] = useState('');
  const [iosBuildNumber, setIosBuildNumber] = useState(0);
  const [iosVersion, setIosVersion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/settings/`);
        const data = response.data.result.data;
        setFirstTitleAr(data.firstTitleAr || '');
        setFirstTitleEn(data.firstTitleEn || '');
        setFocalDestination(data.focalDestination || '');
        setTaxes(data.taxes || 0);
        setAndroidBuildNumber(data.androidBuildNumber || 0);
        setAndroidVersion(data.androidVersion || '');
        setIosBuildNumber(data.iosBuildNumber || 0);
        setIosVersion(data.iosVersion || '');
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const updateData = {
        firstTitleAr,
        firstTitleEn,
        focalDestination,
        taxes,
        androidBuildNumber,
        androidVersion,
        iosBuildNumber,
        iosVersion
      };
      await axiosInstance.patch(`/settings/pzBqiphy5o2kkzJgWUT7`, updateData);
      toast.success('Settings has been modified successfully');
      // Handle success
    } catch (error) {
      const errorMessage = useIntl().formatMessage({
        id: 'thereIsError',
        defaultMessage: 'There is an error'
      });
      console.log(error);
      toast.error(errorMessage);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingComponent />;
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className="flex flex-col justify-start items-start h-[412px] py-[64px] px-[20px] bg-white min-h-screen  rounded-[16px] overflow-x-hidden"
      style={{ width: `${Responsive.w(1584, width)}px` ,
      borderRadius: `${Responsive.sp(16, width)}px`}}
    >
      <form
        className="flex flex-col gap-[16px] "
        onSubmit={handleSubmit}
        style={{
          width: `${Responsive.w(1560, width)}px`,
          gap: `${Responsive.sp(16, width)}px`
        }}
      >
        <div
          className="flex flex-row justify-start items-start gap-[16px] p-0 flex-grow-0 h-[94px] "
          style={{
            width: `${Responsive.w(1560, width)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(756, width)}px`,
              gap: `${Responsive.sp(16, width)}px`
            }}
          >
            <label
              className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap"
              style={{
                width: `${Responsive.w(134, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'firstTitleArabic',
                defaultMessage: 'First Title Arabic'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
              onChange={(e) => setFirstTitleAr(e.target.value)}
              value={firstTitleAr}
              style={{
                width: `${Responsive.w(756, width)}px`
              }}
            />
          </div>
          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(756, width)}px`
            }}
          >
            <label className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap">
              {useIntl().formatMessage({
                id: 'firstTitleEnglish',
                defaultMessage: 'First Title English'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
              onChange={(e) => setFirstTitleEn(e.target.value)}
              value={firstTitleEn}
              style={{
                width: `${Responsive.w(756, width)}px`
              }}
            />
          </div>
        </div>
        <div
          className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px]"
          style={{
            width: `${Responsive.w(1560, width)}px`,
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <label className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap">
            {useIntl().formatMessage({
              id: 'focalDestination',
              defaultMessage: 'Focal Destination'
            })}
          </label>
          <input
            className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
            value={focalDestination}
            onChange={(e) => setFocalDestination(e.target.value)}
            style={{
              width: `${Responsive.w(1560, width)}px`
            }}
          />
        </div>

        <div
          className="flex flex-row justify-start items-start gap-[16px] p-0 flex-grow-0 h-[94px] "
          style={{
            width: `${Responsive.w(1560, width)}px`,
            
            gap: `${Responsive.sp(16, width)}px`
          }}
        >
          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(292.8, width)}px`,
            gap: `${Responsive.sp(16, width)}px`
            }}
          >
            <label
              className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap"
              style={{
                width: `${Responsive.w(134, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'taxes',
                defaultMessage: 'taxes'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
              value={taxes}
              onChange={(e) => setTaxes(Number(e.target.value))}
              style={{
                width: `${Responsive.w(292.8, width)}px`
              }}
            />
          </div>

          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(292.8, width)}px`
            }}
          >
            <label className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap">
              {useIntl().formatMessage({
                id: 'androidBuildNumber',
                defaultMessage: 'Android Build Number'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
              value={androidBuildNumber}
              onChange={(e) => setAndroidBuildNumber(Number(e.target.value))}
              style={{
                width: `${Responsive.w(292.8, width)}px`
              }}
            />
          </div>
          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(292.8, width)}px`
            }}
          >
            <label className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap">
              {useIntl().formatMessage({
                id: 'androidVersion',
                defaultMessage: 'Android version'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
              value={androidVersion}
              onChange={(e) => setAndroidVersion(e.target.value)}
              style={{
                width: `${Responsive.w(292.8, width)}px`
              }}
            />
          </div>
          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(292.8, width)}px`
            }}
          >
            <label className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap">
              {useIntl().formatMessage({
                id: 'iosBuildNumber',
                defaultMessage: 'IOS Build Number'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none"
              value={iosBuildNumber}
              onChange={(e) => setIosBuildNumber(Number(e.target.value))}
              style={{
                width: `${Responsive.w(292.8, width)}px`
              }}
            />
          </div>
          <div
            className="flex flex-col justify-start items-start gap-[16px] p-0 flex-grow h-[94px] "
            style={{
              width: `${Responsive.w(292.8, width)}px`
            }}
          >
            <label className="h-[22px] flex-grow-0 font-[Montserrat] font-semibold text-[16px] leading-[22px] text-[#262626] text-left nowrap">
              {useIntl().formatMessage({
                id: 'iosVersion',
                defaultMessage: 'IOS Version'
              })}
            </label>
            <input
              className=" border border-[#d9d9d9] bg-[#fff] rounded-[8px]  h-[56px] py-[5px] px-[16px] outline-none "
              value={iosVersion}
              onChange={(e) => setIosVersion(e.target.value)}
              style={{
                width: `${Responsive.w(292.8, width)}px`
              }}
            />
          </div>
        </div>

        <div className="flex justify-center mt-4 rounded[5px] py-[5x] px-[10x] ">
            <button
              type="submit"
              style={{
                backgroundColor:dashColor
              }}
              className="px-4 py-2 text-white rounded hover:bg-slate-600 w-[134px] font-[Montserrat]"
            >
              {useIntl().formatMessage({
                id: 'save',
                defaultMessage: 'save'
              })}
            </button>
          </div>
      </form>
    </div>

    // <div className="flex flex-col items-center  py-12 bg-slate-100 min-h-screen">
    //   <div className="flex flex-col items-center h-[412px] justify-center gap-[16px] bg-white p-16"
    //   style={
    //     {
    //       width:`${Responsive.w(1560,width)}px`,
    //     }
    //   }>
    //     {/* <h1 className="text-center text-2xl font-bold mb-6">
    //       {useIntl().formatMessage({
    //         id: 'settings',
    //         defaultMessage: 'settings'
    //       })}
    //     </h1> */}
    //     <form
    //       className="flex flex-col  "
    //       onSubmit={handleSubmit}
    //       style={
    //         {
    //           width:`${Responsive.w(1560,width)}px`,
    //         }
    //       }
    //     >
    //       <div className='flex items-start gap-[16px] justify-start p-0 '
    //        style={
    //         {
    //           width:`${Responsive.w(1560,width)}px`,
    //         }
    //       }>
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'firstTitleArabic',
    //           defaultMessage: 'First Title Arabic'
    //         })}
    //         value={firstTitleAr}
    //         onChange={(e) => setFirstTitleAr(e.target.value)}
    //       />
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'firstTitleEnglish',
    //           defaultMessage: 'First Title English'
    //         })}
    //         value={firstTitleEn}
    //         onChange={(e) => setFirstTitleEn(e.target.value)}
    //       />
    //       </div>
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'focalDestination',
    //           defaultMessage: 'Focal Destination'
    //         })}
    //         value={focalDestination}
    //         onChange={(e) => setFocalDestination(e.target.value)}
    //       />
    //       <div className='flex items-start gap-[16px] justify-start p-0'
    //       style={
    //         {
    //           width:`${Responsive.w(1560,width)}px`,
    //         }
    //       }
    //       >
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'taxes',
    //           defaultMessage: 'taxes'
    //         })}
    //         type="number"
    //         value={taxes}
    //         onChange={(e) => setTaxes(Number(e.target.value))}
    //       />
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'androidBuildNumber',
    //           defaultMessage: 'Android Build Number'
    //         })}
    //         type="number"
    //         value={androidBuildNumber}
    //         onChange={(e) => setAndroidBuildNumber(Number(e.target.value))}
    //       />
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'androidVersion',
    //           defaultMessage: 'Android version'
    //         })}
    //         value={androidVersion}
    //         onChange={(e) => setAndroidVersion(e.target.value)}
    //       />
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'iosBuildNumber',
    //           defaultMessage: 'IOS Build Number'
    //         })}
    //         type="number"
    //         value={iosBuildNumber}
    //         onChange={(e) => setIosBuildNumber(Number(e.target.value))}
    //       />
    //       <FormField
    //         label={useIntl().formatMessage({
    //           id: 'iosVersion',
    //           defaultMessage: 'IOS Version'
    //         })}
    //         value={iosVersion}
    //         onChange={(e) => setIosVersion(e.target.value)}
    //       />
    //       </div>

          
    //     </form>
    //   </div>
    // </div>
  );
}

// const FormField = ({ label, value, onChange, type = 'text' }) => {
//   const direction = getDirectionClass();

//   return (
//     <div className={`flex flex-col ${direction}`}>
//       <label className="block">{label}</label>
//       <input
//         type={type}
//         className=" border border-gray-300 rounded p-2"
//         value={value}
//         onChange={onChange}
//       />
//     </div>
//   );
// };

export default Index;

