import { Icon } from '@iconify/react';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export default function SortButton({
  sortKey,
  text,
  handler,
  filters,
  sortAble = false,
  isModalOpen = false
}: any) {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);

  const ChangeSort = useCallback(() => {
    if (!sortAble) return;
    const sorts = isModalOpen ? [] : filters.sorts; // Clear sorts only when the modal is open
    const sort = sorts.find(([key]: any[]) => key === sortKey);
    const [key, value] = sort || [];
    const sortValue = sort ? (value === 'desc' ? 'asc' : 'desc') : 'desc';
    handler([sortKey, sortValue], 'sorts');
  }, [isModalOpen, sortAble, filters]);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const SortHandler = useMemo(() => {
    return (
      <button
        className="w-full max-w-fit inline-flex items-center gap-2 text-sm font-medium text-[#fff] capitalize"
        onClick={ChangeSort}
        style={{
          backgroundColor: dashColor
        }}
        type="button"
      >
        {sortAble && (
          <Icon
            icon="fa6-solid:sort"
            width="12"
            height="12"
            className="text-gray-600"
          />
        )}
        <span className="">{text}</span>
      </button>
    );
  }, [ChangeSort, sortAble, text]);

  return SortHandler;
}
