import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import usePeriods from 'src/hooks/usePeriods';
import { DateType } from 'src/types/types';
import moment from 'moment';
import { getDates } from 'src/helper/DateUtils';

function FilterFields({ onSubmit, filters: values, handleClose, onReset, setIsModalOpen }: any) {
  const periods = usePeriods();
  const [filters, setFilters] = useState<any>(values.queries || []);
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);

  const handleOnClearClick = () => {
    onReset();
    setFilters([]);
    setSelectedFields([]);
  };

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'balance':
            // If "price" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;
          case 'ordersNumbers':
            // If "orderStatus" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;
          case 'accountStatus':
            // If "orderStatus" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;
          case 'countryCode':
            // If "orderStatus" exists, replace it; otherwise, add it
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;

          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'createdDateValue', '==', from + '-' + to);

            break;
          default:
            break;
        }
      } else {
        // If the field is not selected, remove it from the filters
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  function removeFilter(key: string) {
    return filters.filter(([k]: any) => k !== key);
  }

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      return value;
    },
    [filters]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
    if (selectedFields.includes(field)) {
      // If the field is already selected, remove it from the array
      setSelectedFields(selectedFields.filter((selected) => selected !== field));
    } else {
      // If the field is not selected, add it to the array
      setSelectedFields([...selectedFields, field]);
    }
  }

  return (
    //here
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        setIsModalOpen(false);
        onSubmit(query);
        handleClose(false);
      }}
    >
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="balance"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('balance')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="balance-amount"
          >
            <FormattedMessage
              id="balance"
              defaultMessage={'Balance'}
            />
          </label>
          <input
            type="number"
            className="form-input"
            name="balance-amount"
            id="balance-amount"
            placeholder="0"
            min={0}
            onChange={ChangeFilters('balance')}
            defaultValue={findValue('balance')}
            disabled={!selectedFields.includes('balance')}
            readOnly={!selectedFields.includes('balance')}
          />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="ordersNumbers"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('ordersNumbers')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="orders-amount"
          >
            <FormattedMessage
              id="ordersNumbers"
              defaultMessage={'Orders number'}
            />
          </label>
          <input
            type="number"
            className="form-input"
            name="orders-amount"
            id="orders-amount"
            placeholder="0"
            min={0}
            onChange={ChangeFilters('ordersNumbers')}
            // defaultValue={findValue('ordersNumbers')}
            disabled={!selectedFields.includes('ordersNumbers')}
            readOnly={!selectedFields.includes('ordersNumbers')}
          />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="countryCode"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('countryCode')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="phone-number"
          >
            {useIntl().formatMessage({
              id: 'countryCode',
              defaultMessage: 'country code'
            })}
          </label>
          <input
            type="tel"
            className="form-input"
            name="phone-number"
            id="phone-number"
            placeholder="+132"
            onChange={ChangeFilters('countryCode')}
            defaultValue={findValue('countryCode')}
            disabled={!selectedFields.includes('countryCode')}
            readOnly={!selectedFields.includes('countryCode')}
          />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="accountStatus"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('accountStatus')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="account-status"
          >
            <FormattedMessage
              id="accountStatus"
              defaultMessage={'Account status'}
            />
          </label>
          <select
            name="account-status"
            id="account-status"
            className="form-select"
            onChange={ChangeFilters('accountStatus')}
            defaultValue={findValue('accountStatus') || ''}
            disabled={!selectedFields.includes('accountStatus')}
          >
            <optgroup label="Status">
              <option
                value=""
                disabled
              >
                <FormattedMessage
                  id="selectedStatus"
                  defaultMessage={'Select status'}
                />
              </option>

              <option value="Active">
                <FormattedMessage
                  id="active"
                  defaultMessage={'Active'}
                />
                Active
              </option>
              <option value="NotActive">
                <FormattedMessage
                  id="inactive"
                  defaultMessage={'Inactive'}
                />
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="date"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('date')}
        />
        <div className="form-group flex-1">
          <label
            className="form-label"
            htmlFor="date"
          >
            <FormattedMessage
              id="date"
              defaultMessage={'Date'}
            />
          </label>
          <select
            name="date"
            id="date"
            className="form-select"
            onChange={ChangeFilters('date')}
            defaultValue={findValue('date') || ''}
            disabled={!selectedFields.includes('date')}
          >
            <optgroup label="Periods">
              <option
                value=""
                disabled
              >
                <FormattedMessage
                  id="selectPeriod"
                  defaultMessage={'Select period'}
                />
              </option>

              {usePeriods().map((period: any, index: number) => (
                <option value={period.value}>{period.label}</option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>

      <div className="flex gap-2 flex-wrap justify-end">
        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={handleOnClearClick}
        >
          <span>
            <FormattedMessage
              id="clear"
              defaultMessage={'Clear'}
            />
          </span>
        </button>
        <button
          className="btn-with-icon bg-gray-800"
          type="submit"
        >
          <span>
            <FormattedMessage
              id="apply"
              defaultMessage={'Apply'}
            />
          </span>
        </button>
      </div>
    </form>
  );
}

export default FilterFields;
