import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { scaleQuantize } from 'd3-scale';
import 'react-tooltip/dist/react-tooltip.css';

const geoUrl = '/features.json'; // Ensure this file is available at the public folder of your project

type CountryData = {
  countryISOCode: string;
  userLang?: string; // Assuming this might be part of your data structure
};

interface WorldHeatMapProps {
  data: CountryData[];
}

const Legend = ({ colorScale }) => {
  // Array of ranges to display in the legend
  const ranges = [
    { min: 1, max: 10, color: '#e0f7fa' },
    { min: 11, max: 100, color: '#b3e5fc' },
    { min: 101, max: 500, color: '#81d4fa' },
    { min: 501, max: 2000, color: '#4fc3f7' },
    { min: 2001, max: 5000, color: '#29b6f6' },
    { min: 5001, max: 10000, color: '#039be5' },
    { min: 10001, max: '10,000+', color: '#0277bd' }
  ];

  return (
    <div className="legend z-50 absolute bottom-0">
      {ranges.map((range, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: range.color,
              marginRight: '10px'
            }}
          ></div>
          <div className="text-white">{`${range.min} - ${range.max}`}</div>
        </div>
      ))}
    </div>
  );
};

const TopCountries = ({ countries }) => {
  // Assuming you have a way to convert country codes to names
  const getCountryName = (code) => {
    // Implement your method to get the country name by its ISO code
    return code; // Replace this with actual name retrieval logic
  };
  console.log('top', countries);
  const first = 'text-yellow-300';
  return (
    <div className="text-white font-bold absolute bottom-0 left-[820px]">
      <h2>Top 5 Countries</h2>
      <ul>
        {countries.map(([code, count], idx) => (
          <li
            key={code}
            className={idx == 0 ? first : ''}
          >{`${idx + 1}-${getCountryName(code)}: ${count}`}</li>
        ))}
      </ul>
    </div>
  );
};

const CountriesMap: React.FC<WorldHeatMapProps> = ({ data }) => {
  const [tooltipContent, setTooltipContent] = useState('');

  // Accumulate the count of each country code
  const countryCounts = data.reduce((acc: Record<string, number>, item) => {
    const countryCode = item.countryISOCode;
    acc[countryCode] = (acc[countryCode] || 0) + 1;
    return acc;
  }, {});

  // Convert countryCounts to an array, sort it, and get the top 5 non-empty entries
  const sortedCountries = Object.entries(countryCounts)
    .filter(([code]) => code !== '') // Ensure the country code isn't empty
    .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
    .slice(0, 5); // Get the top 5

  const colorScale = scaleQuantize<string>()
    .domain([0, 10, 100, 1000, 10000]) // Start from 1 to max count
    .range([
      '#ffad9f', // Lighter color for the smallest non-zero values

      '#e2492d',
      '#782618' // Darker color for the highest values
    ]);

  const getColor = (value) => {
    if (value === 0) return '#ffffff'; // white for 0
    if (value <= 10) return '#e0f7fa'; // very light sky blue for 1-10
    if (value <= 100) return '#b3e5fc'; // light sky blue for 11-100
    if (value <= 500) return '#81d4fa'; // sky blue for 101-500
    if (value <= 2000) return '#4fc3f7'; // bright blue for 501-2000
    if (value <= 5000) return '#29b6f6'; // deeper blue for 2001-5000
    if (value <= 10000) return '#039be5'; // even deeper blue for 5001-10000
    // Darkest navy blue for values above 10000
    return '#0277bd';
  };

  return (
    <div className="text-center relative max-h-[1000px]">
      <h1 className="font-bold text-white text-2xl">Global distribution of users</h1>
      <ReactTooltip id="my-tooltip">{tooltipContent}</ReactTooltip>
      <ComposableMap projectionConfig={{ scale: 200 }}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryCode = geo.id.slice(0, 2); // Use ISO_A2 for 2-letter country codes
              const totalValue = countryCounts[geo.id == 'TUR' ? 'TR' : countryCode] || 0;
              return (
                <Geography
                  data-tooltip-id="my-tooltip"
                  key={geo.rsmKey}
                  geography={geo}
                  fill={getColor(totalValue)}
                  onMouseEnter={() => {
                    setTooltipContent(`${geo.properties.name}: ${totalValue}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent('');
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <TopCountries countries={sortedCountries} />
      <Legend colorScale={colorScale} />
    </div>
  );
};

export default CountriesMap;
