// localeSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const localeSlice = createSlice({
  name: 'locale',
  initialState: {
    value: localStorage.getItem('language') || 'en'
  },
  reducers: {
    setLocale: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setLocale } = localeSlice.actions;

export default localeSlice.reducer;
