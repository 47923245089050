import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import RangePicker from 'src/components/shared/RangePicker';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { getDates } from 'src/helper/DateUtils';
import parseJwt from 'src/helper/parseJwt';
import { setDashboard } from 'src/reducers/dashboard';
import { UpdateResult } from 'src/reducers/users';
import { RootState } from 'src/store';

export default function Index() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fullDate, setFullDate] = useState(false);
  const [sendDate, setSendDate] = useState({});
  const [data, setData] = useState<any>({
    typeUsers: {
      data: {
        phoneUsers: 0,
        googleUsers: 0,
        appleUsers: 0,
        googleAndPhoneUsers: 0,
        appleAndPhoneUsers: 0,

      },
      loading: true
    }
  });
  const { dashboard, appStoreLink, playStoreLink } = useSelector(
    (state: RootState) => state.Dashboard
  );
  useEffect(() => {
    const fetchData = async () => {
      setData((prevData) => ({
        ...prevData,
        typeUsers: { data: {}, loading: true }
      }));
      try {
        const typeUsersResponse = await axiosInstance.get('/type-signIn', {
          params: sendDate
        });

        setData((prevData) => ({
          ...prevData,
          typeUsers: { data: typeUsersResponse.data.result, loading: false }
        }));
      } catch (error) {
        console.error('Error fetching today orders:', error);
        setData((prevData) => ({
          ...prevData,
          typeUsers: { data: {}, loading: false }
        }));
      }
    };

    fetchData();
  }, [fullDate]);

  // Now safe to call useHooks and other logic
  // const { data ,fetchTypeUsers } = useHooks();
  console.log(data);

  return (
    <div className="p-8 pt-12 space-y-6 bg-slate-100 min-h-screen">
      <p className="font-semibold text-3xl">
        <FormattedMessage
          id="Users-Types"
          defaultMessage="Users Types"
        />
      </p>
      <RangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        // applyHandler={() => {
        //   setStartDate(startDate);
        //   setEndDate(endDate);
        //   setFullDate(!fullDate);
        //   setSendDate({ startDate, endDate });
        // }}
      />
      <div className="flex space-x-2 items-center justify-center">
          <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                setSendDate({});
                setStartDate(new Date());
                setEndDate(new Date());
                setFullDate(!fullDate);
              }}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            </div>
      <div className="grid grid-cols-2 gap-8 w-3/4 mx-auto text-xl">
        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#0ea5e9]"
            width={24}
            height={24}
            icon="material-symbols:contact-phone-rounded"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="PhoneNumber"
              defaultMessage="Phone Number"
            />
          </p>
          <p className="font-semibold">
            {data?.typeUsers?.loading ? <Spinner /> : data?.typeUsers?.data?.data?.phoneUsers}
          </p>
        </Card>

        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-yellow-500"
            width={24}
            height={24}
            icon="devicon:google"
          />
          <p className="font-semibold capitalize">
            <FormattedMessage
              id="googleUsers"
              defaultMessage="Google Users"
            />
          </p>
          <p className="font-semibold">
            {data?.typeUsers?.loading ? <Spinner /> : data?.typeUsers?.data?.data?.googleUsers}
          </p>
        </Card>

        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#9333ea]"
            width={24}
            height={24}
            icon="devicon:apple"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="appleUsers"
              defaultMessage="Apple Users"
            />
          </p>
          <p className="font-semibold">
            {data?.typeUsers?.loading ? <Spinner /> : data?.typeUsers?.data?.data?.appleUsers}
          </p>
        </Card>

        <Card className="flex flex-col justify-center items-center py-12">
          <Icon
            className="shrink-0 text-[#ef4444]"
            width={24}
            height={24}
            icon="vscode-icons:folder-type-android-opened"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="GoogleAndPhoneUsers"
              defaultMessage="Google && Phone Number"
            />
          </p>
          <p className="font-semibold">
            {data?.typeUsers?.loading ? (
              <Spinner />
            ) : (
              data?.typeUsers?.data?.data?.googleAndPhoneUsers
            )}
          </p>
        </Card>
        <Card className="border border-gray-200 rounded-2xl p-5 bg-white shadow-2xl shadow-gray-800/[0.01] relative overflow-hidden flex flex-col justify-center items-center py-12 col-span-2">
          <Icon
            className="shrink-0 text-[#ef4444]"
            width={24}
            height={24}
            icon="vscode-icons:folder-type-ios-opened"
          />
          <p className="font-semibold">
            <FormattedMessage
              id="appleAndPhoneUsers"
              defaultMessage="Apple && Phone Number"
            />
          </p>
          <p className="font-semibold">
            {data?.typeUsers?.loading ? (
              <Spinner />
            ) : (
              data?.typeUsers?.data?.data?.appleAndPhoneUsers
            )}
          </p>
        </Card>
      </div>
    </div>
  );
}

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color:dashColor
          }}
        />
       
      </div>
    </>
  );
}

