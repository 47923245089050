import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'src/components/shared/Card';
import CountryPicker from 'src/components/shared/CountryPicker';
import Modal from 'src/components/shared/Modal';
import SmallLoader from 'src/components/shared/SmallLoader';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { getDirectionClass } from 'src/helper/directionClass';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Responsive } from 'src/layouts/Responsive';
import { RootState } from 'src/store';
import { Icon } from '@iconify/react';
import { log } from 'console';
import Select from 'react-select';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import ToggleSlider from 'src/components/shared/ToggleSlider';
import DatePicker from 'react-datepicker';
import './dataPicker.css';
export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSchedule, setIsModalOpenSchedule] = useState(false);
  const { width, height } = useWindowDimensions();
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleOpenSchedule = () => {
    setIsModalOpenSchedule(true);
  };
  return (
    <div className="flex-col min-h-screen p-12 items-center justify-center bg-[#fafafa] ">
      <div
        className="flex justify-start items-start p-0"
        style={{
          width: `${Responsive.w(504, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        <button
          className="nowrap flex justify-center font-semibold items-center py-[5] px-4 border-[1px] border-solid border-[#cf0036] rounded-[8px]  font-[Montserrat] text-[#cf0036] bg-[#fff3f6]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            gap: `${Responsive.sp(16, width)}px`,
            fontSize: `${Responsive.sp(16, width)}px`,
            borderRadius: `${Responsive.sp(8, width)}px`
          }}
          onClick={handleOpenModal}
        >
          Add live broadcast
        </button>
        <button
          className="nowrap flex justify-center font-semibold items-center py-[5] px-4 border-[1px] border-solid border-[#cf0036] rounded-[8px]  font-[Montserrat] text-[#cf0036] bg-[#fff3f6]"
          style={{
            width: `${Responsive.w(240, width)}px`,
            gap: `${Responsive.sp(16, width)}px`,
            fontSize: `${Responsive.sp(16, width)}px`,
            borderRadius: `${Responsive.sp(8, width)}px`
          }}
          onClick={handleOpenSchedule}
        >
          Schedule a live broadcast
        </button>
      </div>
      <WebinarComponent />
      <WebinrsForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <ScheduleForm
        isModalOpenSchedule={isModalOpenSchedule}
        setIsModalOpenSchedule={setIsModalOpenSchedule}
      />
    </div>
  );
}

const WebinarComponent = () => {
  const [title, setTitle] = useState<any | null>('historyBroadcast');
  const [activeItem, setActiveItem] = useState('historyBroadcast');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [data, setData] = useState<any>([]);

  const { width, height } = useWindowDimensions();

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const fetchWebinars = async () => {
    setIsLoadingMore(true);
    try {
      const response = await axiosInstance.get(`/webinars`);
      setData(response.data.result.data);
    } catch (error) {
      console.error('Error fetching survey:', error);
    }
    setIsLoadingMore(false);
  };

  useEffect(() => {
    fetchWebinars();
  }, []);

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const handleClick = (title) => {
    setActiveItem(title);
    setTitle(title);
  };

  return (
    <div
      className="flex  items-sretch justify-start  bg-white flex-col mx-auto overflow-hidden "
      style={{
        width: `${Responsive.w(1032, width)}px`,
        // height: `${Responsive.h(980, height)}px`,
        borderRadius: `${Responsive.sp(32, width)}px`,
        gap: `${Responsive.sp(32, width)}px`,
        padding: `${Responsive.sp(24, width)}px`,
        marginTop: `${Responsive.sp(91, width)}px`,
        marginBottom: `${Responsive.sp(0, width)}px`
      }}
    >
      <ul
        className="consultants flex  items-start justify-start  p-0 bg-white flex-row self-stretch flex-grow-0 "
        style={{
          width: `${Responsive.w(1032, width)}px`,
          height: `${Responsive.h(56, height)}px`,
          gap: `${Responsive.sp(8, width)}px`
        }}
      >
        <li
          className={`self-stretch  flex-grow-1 flex justify-center text-[#000] items-center py-0  bg-white border-[2px] border-solid border-[#ececec] gap-[10px] ${
            activeItem === 'LiveBroadcast'
              ? `${currentDashboard === 'nikah' ? 'text-[#cf0036] border-b-[#cf0036]' : null}`
              : ''
          } `}
          style={{
            width: `${Responsive.w(317.3, width)}px`,
            height: `${Responsive.h(56, height)}px`,
            boxShadow: '  0 0 2px 0 rgba(0, 0, 0, 0.36);',
            borderRadius: `${Responsive.sp(9, width)}px`,
            paddingLeft: `${Responsive.sp(16, width)}px`,
            paddingRight: `${Responsive.sp(16, width)}px`
          }}
          onClick={() => handleClick('LiveBroadcast')}
        >
          <button
            className=" cursor-pointer active text-center font-semibold font-[Montserrat] flex-grow-1 nowrap select-none"
            style={{
              width: `${Responsive.w(317.3, width)}px`,
              height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(16, width)}px`
            }}
          >
            {
              <FormattedMessage
                id="liveNow"
                defaultMessage="live Now"
              />
            }
          </button>
        </li>
        <li
          className={`self-stretch  flex-grow-1 flex justify-center text-[#000] items-center   bg-white border-[2px] border-solid border-[#ececec] gap-[10px] ${
            activeItem === 'ScheduledBroadcast'
              ? `${currentDashboard === 'nikah' ? 'text-[#cf0036] border-b-[#cf0036]' : null}`
              : ''
          } `}
          style={{
            width: `${Responsive.w(317.3, width)}px`,
            height: `${Responsive.h(56, height)}px`,
            boxShadow: '  0 0 2px 0 rgba(0, 0, 0, 0.36);',
            borderRadius: `${Responsive.sp(9, width)}px`,
            paddingLeft: `${Responsive.sp(16, width)}px`
          }}
          onClick={() => handleClick('ScheduledBroadcast')}
        >
          <button
            className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap select-none"
            style={{
              width: `${Responsive.w(317.3, width)}px`,
              height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(16, width)}px`
            }}
          >
            {
              <FormattedMessage
                id="scheduledbroadcast"
                defaultMessage="Scheduled broadcast"
              />
            }
          </button>
        </li>
        <li
          className={`self-stretch  flex-grow-1 flex justify-center text-[#000] items-center  bg-white border-[2px] border-solid border-[#ececec]  ${
            activeItem === 'historyBroadcast'
              ? `${currentDashboard === 'nikah' ? 'text-[#cf0036] border-b-[#cf0036]' : null}`
              : ''
          } `}
          style={{
            width: `${Responsive.w(317.3, width)}px`,
            height: `${Responsive.h(56, height)}px`,
            boxShadow: '  0 0 2px 0 rgba(0, 0, 0, 0.36);',
            borderRadius: `${Responsive.sp(9, width)}px`,
            paddingRight: `${Responsive.sp(16, width)}px`
          }}
          onClick={() => handleClick('historyBroadcast')}
        >
          <button
            className=" cursor-pointer text-center font-semibold font-[Montserrat] flex-grow-1 nowrap  select-none my-auto"
            style={{
              width: `${Responsive.w(317.3, width)}px`,
              height: `${Responsive.h(30, height)}px`,
              fontSize: `${Responsive.sp(16, width)}px`
            }}
          >
            {
              <FormattedMessage
                id="historyBroadcast"
                defaultMessage="History Broadcast"
              />
            }
          </button>
        </li>
      </ul>
      <div
        className="flex items-start justify-start p-0"
        style={{
          width: `${Responsive.w(984, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        {title === 'historyBroadcast' &&
          (isLoadingMore ? (
            <Spinner />
          ) : (
            <HistoryBroadcast
              data={data.allData}
              currentDashboard={currentDashboard}
            />
          ))}
        {title === 'ScheduledBroadcast' &&
          (isLoadingMore ? (
            <Spinner />
          ) : (
            <ScheduledBroadcast
              data={data.allData}
              currentDashboard={currentDashboard}
            />
          ))}
        {title === 'LiveBroadcast' &&
          (isLoadingMore ? (
            <Spinner />
          ) : (
            <LiveBroadcast
              data={data.allData}
              currentDashboard={currentDashboard}
            />
          ))}
      </div>
    </div>
  );
};

// Live Now
const HistoryBroadcast = ({ data, currentDashboard }) => {
  const { width, height } = useWindowDimensions();
  console.log('data', data);
  function convertTimestampToDate(timestamp: { _seconds: number; _nanoseconds: number }): string {
    const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  // لتحويل Timestamp إلى وقت بالشكل المطلوب
  function convertTimestampToTime(timestamp: { _seconds: number; _nanoseconds: number }): string {
    const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',

      hour12: true,
      timeZone: 'Africa/Cairo'
    };
    return date.toLocaleTimeString('en-GB', options);
  }

  const languageOptions: any = {
    en: 'English',
    ar: 'Arabic',
    fr: 'Français',
    id: 'Bahasa Indonesiacode'
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className="grid grid-cols-3 p-0"
      style={{
        width: `${Responsive.w(984, width)}px`,
        gap: `${Responsive.sp(24, width)}px`
      }}
    >
      {data?.map((item: any, index: number) => (
        <>
          <div
            className="flex flex-col justify-start items-start flex-grow-0 bg-white"
            key={index}
            style={{
              width: `${Responsive.w(312, width)}px`,
              //  height: `${Responsive.h(391, height)}px`,
              borderRadius: `${Responsive.sp(12, width)}px`,
              gap: `${Responsive.sp(16, width)}px`,
              padding: `${Responsive.sp(16, width)}px`,
              boxShadow: ' 2px 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            {/* image */}
            <div
              className="flex justify-start items-start flex-grow "
              style={{
                width: `${Responsive.w(280, width)}px`,
                height: `${Responsive.h(148, height)}px`,
                backgroundImage:
                  'linear-gradient(to bottom, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29)), linear-gradient(116deg,  -5%, #ff2f65 100%)',
                borderRadius: `${Responsive.sp(13.9, width)}px`,
                gap: `${Responsive.sp(182.3, width)}px`,
                paddingTop: `${Responsive.sp(12, width)}px`,
                paddingBottom: `${Responsive.sp(12, width)}px`,
                paddingLeft: `${Responsive.sp(9.5, width)}px`,
                paddingRight: `${Responsive.sp(9.5, width)}px`
              }}
            >
              <img
                src={item.image != 'noImage' ? item.image : '/images/placeholder.png'}
                alt="webinar"
                style={{
                  width: `${Responsive.w(280, width)}px`,
                  height: `${Responsive.h(148, height)}px`,
                  borderRadius: `${Responsive.sp(13.9, width)}px`
                }}
              />
            </div>

            <div
              className="flex flex-col  justify-start items-start flex-grow-0 p-0"
              style={{
                gap: `${Responsive.sp(4, width)}px`
              }}
            >
              {/* clock */}
              <div
                className="flex justify-between items-center p-0 flex-grow-0"
                style={{
                  width: `${Responsive.w(280, width)}px`,
                  height: `${Responsive.h(20, height)}px`
                }}
              >
                <div
                  className="p-0 flex items-center justify-center"
                  style={{
                    width: `${Responsive.w(250, width)}px`,
                    // height: `${Responsive.h(20, height)}px`,
                    gap: `${Responsive.sp(4, width)}px`
                  }}
                >
                  <Icon
                    icon="pixelarticons:calendar-week"
                    width={16.7}
                    height={16.7}
                    className=""
                    style={{
                      color: '#cf0036'
                    }}
                  />
                  <span
                    className="font-medium font-[Montserrat] text-[#1e1e1e]"
                    style={{
                      width: `${Responsive.w(155, width)}px`,

                      gap: `${Responsive.sp(4, width)}px`,
                      fontSize: `${Responsive.sp(14, width)}px`
                    }}
                  >
                    {convertTimestampToDate(item.Date)}
                  </span>

                  <span
                    className="font-medium font-[Montserrat] text-[#1e1e1e] nowrap"
                    style={{
                      width: `${Responsive.w(155, width)}px`,

                      gap: `${Responsive.sp(4, width)}px`,
                      fontSize: `${Responsive.sp(14, width)}px`
                    }}
                  >
                    {`${convertTimestampToTime(item.Date)}`}
                  </span>
                </div>
              </div>
              {/* header */}
              <h3
                className="font-semibold font-[Montserrat] text-[#cf0036] text-left "
                style={{
                  fontSize: `${Responsive.sp(16, width)}px`,
                  lineHeight: 1.9
                }}
              >
                {item.title.titleEn}
              </h3>
              {/* description */}
              <p
                className="font-medium font-[Montserrat] text-[#535353] text-left flex-grow"
                style={{
                  fontSize: `${Responsive.sp(14, width)}px`,
                  lineHeight: 1.29
                }}
              >
                {item.details.detailsEn}
              </p>
              <p
                className="font-bold font-[Montserrat] text-[#1e1e1e] text-left flex-grow"
                style={{
                  fontSize: `${Responsive.sp(14, width)}px`,
                  lineHeight: 1.29
                }}
              >
                Couch : <span className="font-medium text-[#535353]">{item.Admin}</span>
              </p>
              <p
                className="font-bold font-[Montserrat] text-[#1e1e1e] text-left flex-grow"
                style={{
                  fontSize: `${Responsive.sp(14, width)}px`,
                  lineHeight: 1.29
                }}
              >
                Members :{' '}
                <span className="font-medium text-[#535353]">
                  {item.members.map((e) => `${e.name}/`)}{' '}
                </span>
              </p>

              <div
                className="flex justify-between items-start p-0 self-stretch"
                style={{
                  width: `${Responsive.w(280, width)}px`
                }}
              >
                <p
                  className="font-bold font-[Montserrat] text-[#1e1e1e] text-left flex-grow"
                  style={{
                    fontSize: `${Responsive.sp(14, width)}px`,
                    lineHeight: 1.29
                  }}
                >
                  Users :{' '}
                  <span className="font-medium text-[#535353]">
                    {!Array.isArray(item.Users) ? 'All' : item.Users.join(' / ')}
                  </span>
                </p>
                <p
                  className="font-bold font-[Montserrat] text-[#1e1e1e] text-right flex-grow "
                  style={{
                    fontSize: `${Responsive.sp(14, width)}px`,
                    lineHeight: 1.29
                  }}
                >
                  Language :{' '}
                  <span className="font-medium text-[#535353]">
                    {item.Language.map((lang) => languageOptions[lang]).join(' / ')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

// Scheduled broadcast
const ScheduledBroadcast = ({ data, currentDashboard }) => {
  const [isModalOpenSchedule, setIsModalOpenSchedule] = useState(false);
  const { width, height } = useWindowDimensions();
  console.log('data', data);
  function convertTimestampToDate(timestamp: { _seconds: number; _nanoseconds: number }): string {
    const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  // لتحويل Timestamp إلى وقت بالشكل المطلوب
  function convertTimestampToTime(timestamp: { _seconds: number; _nanoseconds: number }): string {
    const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Africa/Cairo'
    };
    return date.toLocaleTimeString('en-GB', options);
  }
  const handleOpenSchedule = () => {
    setIsModalOpenSchedule(true);
  };
  const languageOptions: any = {
    en: 'English',
    ar: 'Arabic',
    fr: 'Français',
    id: 'Bahasa Indonesiacode'
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const initialData = data.map((e) => e);
  return (
    <>
      <div
        className="grid grid-cols-3 p-0"
        style={{
          width: `${Responsive.w(984, width)}px`,
          gap: `${Responsive.sp(24, width)}px`
        }}
      >
        {data?.map((item: any, index: number) => (
          <>
            <div
              className="flex flex-col justify-start items-start flex-grow-0 bg-white"
              key={index}
              style={{
                width: `${Responsive.w(312, width)}px`,
                borderRadius: `${Responsive.sp(12, width)}px`,
                gap: `${Responsive.sp(16, width)}px`,
                padding: `${Responsive.sp(16, width)}px`,
                boxShadow: ' 2px 2px 8px 0 rgba(0, 0, 0, 0.15)'
              }}
            >
              <div
                className="flex flex-col  justify-start items-start flex-grow-0 p-0"
                style={{
                  gap: `${Responsive.sp(4, width)}px`
                }}
              >
                {/* clock */}
                <div
                  className="flex justify-between items-center p-0 flex-grow-0"
                  style={{
                    width: `${Responsive.w(280, width)}px`,
                    height: `${Responsive.h(20, height)}px`
                  }}
                >
                  <div
                    className="p-0 flex items-center justify-center"
                    style={{
                      gap: `${Responsive.sp(8, width)}px`
                    }}
                  >
                    <Icon
                      icon="pixelarticons:calendar-week"
                      width={16.7}
                      height={16.7}
                      className=""
                      style={{
                        color: '#cf0036'
                      }}
                    />
                    <span
                      className="font-medium font-[Montserrat] text-[#1e1e1e]"
                      style={{
                        gap: `${Responsive.sp(4, width)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {convertTimestampToDate(item.Date)}
                    </span>
                  </div>
                  <div
                    className="p-0 flex items-center justify-end"
                    style={{
                      gap: `${Responsive.sp(8, width)}px`
                    }}
                  >
                    <Icon
                      icon="mdi:clock-outline"
                      width={16.7}
                      height={16.7}
                      className=""
                      style={{
                        color: '#cf0036'
                      }}
                    />
                    <span
                      className="font-medium font-[Montserrat] text-[#1e1e1e]"
                      style={{
                        gap: `${Responsive.sp(4, width)}px`,
                        fontSize: `${Responsive.sp(14, width)}px`
                      }}
                    >
                      {convertTimestampToTime(item.Date)}
                    </span>
                  </div>
                </div>
                {/* Coach */}
                <p
                  className="font-bold font-[Montserrat] text-[#1e1e1e] text-left flex-grow mt-2"
                  style={{
                    fontSize: `${Responsive.sp(14, width)}px`,
                    lineHeight: 1.29
                  }}
                >
                  Couch : <span className="font-medium text-[#535353]">{item.Admin}</span>
                </p>
                {/* header */}
                <h3
                  className="font-semibold font-[Montserrat] text-[#cf0036] text-left "
                  style={{
                    fontSize: `${Responsive.sp(16, width)}px`,
                    lineHeight: 1.9
                  }}
                >
                  {item.title.titleEn}
                </h3>
                {/* description */}
                <p
                  className="font-medium font-[Montserrat] text-[#535353] text-left flex-grow"
                  style={{
                    fontSize: `${Responsive.sp(14, width)}px`,
                    lineHeight: 1.29
                  }}
                >
                  {item.details.detailsEn}
                </p>

                {/* button */}
                <div
                  className="flex justify-end items-center p-0 flex-grow-0 mt-2"
                  style={{
                    width: `${Responsive.w(280, width)}px`,
                    gap: `${Responsive.sp(16, width)}px`
                  }}
                >
                  <button
                    className="flex items-center justify-center text-white   border border-solid border-[#cf0036] bg-[#cf0036] flex-grow "
                    style={{
                      paddingTop: `${Responsive.sp(8, width)}px`,
                      paddingBottom: `${Responsive.sp(8, width)}px`,
                      paddingRight: `${Responsive.sp(16, width)}px`,
                      paddingLeft: `${Responsive.sp(16, width)}px`,
                      gap: `${Responsive.sp(8, width)}px`,
                      borderRadius: `${Responsive.sp(8, width)}px`
                    }}
                    onClick={handleOpenSchedule}
                  >
                    <Icon
                      icon={'iconamoon:edit-fill'}
                      color="white"
                      style={{
                        width: '11px',
                        height: '11px',
                        objectFit: 'contain'
                      }}
                    />
                    <p
                      className="font-medium text-center "
                      style={{
                        fontSize: `${Responsive.sp(16, width)}px`,
                        lineHeight: 1.5
                      }}
                    >
                      Edit
                    </p>
                    {isModalOpenSchedule && (
                      <ScheduleForm
                        isModalOpenSchedule={isModalOpenSchedule}
                        setIsModalOpenSchedule={setIsModalOpenSchedule}
                        initialData={item}
                      />
                    )}
                  </button>
                  <button
                    className="flex items-center justify-center text-[#cf0036] rounded-lg border border-solid border-[#cf0036] bg-white  flex-grow "
                    style={{
                      paddingTop: `${Responsive.sp(8, width)}px`,
                      paddingBottom: `${Responsive.sp(8, width)}px`,
                      paddingRight: `${Responsive.sp(16, width)}px`,
                      paddingLeft: `${Responsive.sp(16, width)}px`,
                      gap: `${Responsive.sp(8, width)}px`,
                      borderRadius: `${Responsive.sp(8, width)}px`
                    }}
                  >
                    <Icon
                      icon={'material-symbols:delete-outline'}
                      color="#cf0036"
                      style={{
                        width: '11px',
                        height: '11px',
                        objectFit: 'contain'
                      }}
                    />
                    <p
                      className="font-medium text-center "
                      style={{
                        fontSize: `${Responsive.sp(16, width)}px`,
                        lineHeight: 1.5
                      }}
                    >
                      Delete
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};
// LIVE broadcast
const LiveBroadcast = ({ data, currentDashboard }) => {
  const { width, height } = useWindowDimensions();
  console.log('data', data);

  const languageOptions: any = {
    en: 'English',
    ar: 'Arabic',
    fr: 'Français',
    id: 'Bahasa Indonesiacode'
  };
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <div
      className="grid grid-cols-1 p-0"
      style={{
        width: `${Responsive.w(984, width)}px`,
        gap: `${Responsive.sp(24, width)}px`
      }}
    >
      {data?.map((item: any, index: number) => (
        <>
          <div
            className="flex flex-col justify-start items-start flex-grow-0 bg-white"
            key={index}
            style={{
              width: `${Responsive.w(984, width)}px`,
              //  height: `${Responsive.h(391, height)}px`,
              borderRadius: `${Responsive.sp(12, width)}px`,
              gap: `${Responsive.sp(16, width)}px`,

              boxShadow: ' 2px 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            {/* image */}
            <div
              className="flex justify-start items-start flex-grow-0 "
              style={{
                width: `${Responsive.w(984, width)}px`,
                height: `${Responsive.h(400, height)}px`,
                backgroundImage:
                  'linear-gradient(to bottom, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29)), linear-gradient(116deg,  -5%, #ff2f65 100%)',
                borderRadius: `${Responsive.sp(28, width)}px`,
                gap: `${Responsive.sp(366, width)}px`,
                paddingTop: `${Responsive.sp(28, width)}px`,
                paddingBottom: `${Responsive.sp(28, width)}px`,
                paddingLeft: `${Responsive.sp(19, width)}px`,
                paddingRight: `${Responsive.sp(19, width)}px`
              }}
            >
              <img
                src={item.image != 'noImage' ? item.image : '/images/placeholder.png'}
                alt="webinar"
                style={{
                  width: `${Responsive.w(984, width)}px`,
                  height: `${Responsive.h(400, height)}px`,
                  borderRadius: `${Responsive.sp(28, width)}px`
                }}
              />
            </div>

            <div
              className="flex flex-col justify-start items-start "
              style={{
                width: `${Responsive.w(984, width)}px`,
                gap: `${Responsive.sp(16, width)}px`,
                padding: `${Responsive.sp(16, width)}px`
              }}
            >
              {/* Coach */}
              <p
                className="font-bold font-[Montserrat] text-[#1e1e1e] text-left flex-grow mt-2"
                style={{
                  fontSize: `${Responsive.sp(14, width)}px`,
                  lineHeight: 1.29
                }}
              >
                Couch : <span className="font-medium text-[#535353]">{item.Admin}</span>
              </p>
              {/* header */}
              <h3
                className="font-semibold font-[Montserrat] text-[#1e1e1e] text-left "
                style={{
                  fontSize: `${Responsive.sp(16, width)}px`,
                  lineHeight: 1.9
                }}
              >
                {item.title.titleEn}
              </h3>
              {/* description */}
              <p
                className="font-medium font-[Montserrat] text-[#535353] text-left flex-grow"
                style={{
                  fontSize: `${Responsive.sp(14, width)}px`,
                  lineHeight: 1.29
                }}
              >
                {item.details.detailsEn}
              </p>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

// Live Now Form
const WebinrsForm = ({ isModalOpen, setIsModalOpen }) => {
  // State for the form fields
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [image, setImage] = useState('');
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setIsloading] = useState(false);
  const [title, setTitle] = useState({
    titleEn: '',
    titleAr: ''
  });
  const [admin, setAdmin] = useState('');
  const [status, setStatus] = useState<boolean>(false);
  const [description, setDescription] = useState({
    detailsEn: '',
    detailsAr: ''
  });
  const [speakers, setSpeakers] = useState<any>([]);
  const [type, setType] = useState<any>('');
  const [language, setLanguage] = useState([]);
  const [allowedUsers, setAllowedUsers] = useState<any>([]);
  const { width, height } = useWindowDimensions();
  const [isMulti, setIsMulti] = useState(true);
  // State for validation messages
  const [titleError, setTitleError] = useState('');
  const [titleErrorEn, setTitleErrorEn] = useState('');
  const [titleErrorAr, setTitleErrorAr] = useState('');
  const [detailsErrorEn, setDetailsErrorEn] = useState('');
  const [detailsErrorAr, setDetailsErrorAr] = useState('');
  const [adminError, setAdminError] = useState('');
  const [usersError, setUsersError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [speakersError, setSpeakersError] = useState('');
  const [imageError, setImageError] = useState('');
  const [languageError, setLanguageError] = useState('');

  const fileInputRef = useRef<any>(null);
  const intl = useIntl();
  let initialQueries: any = [
    ['userType', '==', 'COACH'],
    ['accountStatus', '==', 'Active']
  ];

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  console.log('admin', admin);
  console.log('speakers', speakers);
  console.log('type', type);
  const resetValues = () => {
    setTitle({
      titleEn: '',
      titleAr: ''
    });

    setDescription({
      detailsEn: '',
      detailsAr: ''
    });
    setSpeakers([]);
    setLanguage([]);
    setImage('');
    setTitleError('');
    setAdminError('');
    setDescriptionError('');
    setSpeakersError('');
    setImageError('');
    setLanguageError('');
    setUsersError('');
    setAllowedUsers([]);
    setTypeError('');
    setType('');
    setStatus(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    resetValues();
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const validateForm = () => {
    let isValid = true;
    if (!image) {
      const errMsg = intl.formatMessage({
        id: 'imageRequired',
        defaultMessage: 'image is required'
      });
      setImageError(errMsg);
      isValid = false;
    } else {
      setImageError('');
    }
    if (!title.titleEn) {
      const errMsg = intl.formatMessage({
        id: 'liveNameEnRequired',
        defaultMessage: 'Live Name English is required'
      });
      setTitleErrorEn(errMsg);
      isValid = false;
    } else {
      setTitleErrorEn('');
    }
    if (!title.titleAr) {
      const errMsg = intl.formatMessage({
        id: 'liveNameArRequired',
        defaultMessage: 'Live Name Arabic is required'
      });
      setTitleErrorAr(errMsg);
      isValid = false;
    } else {
      setTitleErrorAr('');
    }

    if (!description.detailsEn) {
      const errMsg = intl.formatMessage({
        id: 'liveDetailsEnIsRequired',
        defaultMessage: 'Live Details English is required'
      });
      setDetailsErrorEn(errMsg);
      isValid = false;
    } else {
      setDetailsErrorEn('');
    }

    if (!description.detailsAr) {
      const errMsg = intl.formatMessage({
        id: 'liveDetailsArIsRequired',
        defaultMessage: 'Live Details Arabic is required'
      });
      setDetailsErrorAr(errMsg);
      isValid = false;
    } else {
      setDetailsErrorAr('');
    }

    if (!speakers.length) {
      const errMsg = intl.formatMessage({
        id: 'SpeakersIsRequired',
        defaultMessage: 'Speakers is required'
      });
      setSpeakersError(errMsg);
      isValid = false;
    } else {
      setSpeakersError('');
    }

    if (!language.length) {
      const errMsg = intl.formatMessage({
        id: 'languageIsRequired',
        defaultMessage: 'Language is required'
      });
      setLanguageError(errMsg);
      isValid = false;
    } else {
      setLanguageError('');
    }
    if (!allowedUsers.length) {
      const errMsg = intl.formatMessage({
        id: 'usersIsRequired',
        defaultMessage: 'Users is required'
      });
      setUsersError(errMsg);
      isValid = false;
    } else {
      setUsersError('');
    }
    if (!type) {
      const errMsg = intl.formatMessage({
        id: 'typrRequired',
        defaultMessage: 'Type is required'
      });
      setTypeError(errMsg);
      isValid = false;
    } else {
      setTypeError('');
    }

    return isValid;
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const confirmationMsg = intl.formatMessage({
      id: 'Addlivebroadcast',
      defaultMessage: 'Are you sure you want to Add live broadcast?'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        let uploadedImageUrl = image; // Default to the current image in state

        // Uploading image to storage
        if (selectedFile) {
          const formData = new FormData();
          formData.append('file', selectedFile);

          const response = await axiosInstance.post('/uploadImgWebinars', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          uploadedImageUrl = response.data.result.data.imgUrl || 'noImage';
          console.log('uploadedImageUrl', uploadedImageUrl);
          setImage(uploadedImageUrl); // Update the state with the uploaded image URL
        }

        const reqBody = {
          title: title,
          Admin: admin,
          image: uploadedImageUrl,
          details: description,
          Language: language.map((lang: any) => lang?.value),
          Speakers: speakers.map((speak: any) => speak?.value),
          AllowedUsers: allowedUsers.map((user: any) => user?.value),
          status: status ? 'Active' : 'NotActive'
        };

        console.log('body', reqBody);
        const WebinrsResponse = await axiosInstance.post('/webinars/', reqBody);

        setIsModalOpen(false);
        resetValues();

        const successMsg = intl.formatMessage({
          id: 'webinarSent',
          defaultMessage: 'webinar sent successfully.'
        });
        toast.success(successMsg);
      } catch (error) {
        console.error('There is an error!:', error);
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });
  };

  async function fetchAllusers() {
    setIsloading(true);
    try {
      const responsUsers = await axiosInstance.get(`/consultants`, {
        params: {
          queries: initialQueries
        }
      });
      setUsersData(responsUsers.data.result.data);
    } catch (error) {
      console.error('Error fetching couses:', error);
    }
    setIsloading(false);
  }
  async function fetchgetProfile() {
    try {
      const profile = await axiosInstance.get('profile');
      setAdmin(profile.data.result.name);
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }
  useEffect(() => {
    fetchAllusers();
    fetchgetProfile();
  }, []);
  useEffect(() => {
    if (!speakers.length) {
      setType('');
    }
  }, [speakers]);
  const handleRestoreClick = (event: any) => {
    event.preventDefault();
    setImage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleUsersChange = (selected) => {
    // Ensure 'selected' is an array
    if (!Array.isArray(selected)) {
      selected = [selected];
    }

    if (selected.some((user) => user.value === 'All')) {
      // If "All" is selected, set "All" as the only selected option and set isMulti to false
      setAllowedUsers([{ value: 'All', label: 'All' }]);
      setIsMulti(false);
    } else {
      // If any other option is selected, remove "All" from the selection and set isMulti to true
      const filteredSelected = selected.filter((user) => user.value !== 'All');
      setAllowedUsers(filteredSelected);
      setIsMulti(true);
    }
  };

  const handleTypeChange = (selectedType) => {
    setType(selectedType);

    const updatedSpeakers = speakers.map((speaker: any) =>
      speaker.value.uid === selectedType.value.uid
        ? { ...speaker, value: { ...speaker.value, type: 'primary' } }
        : { ...speaker, value: { ...speaker.value, type: 'secondary' } }
    );
    setSpeakers(updatedSpeakers);
  };

  const handleSpeakersChange = (selected) => {
    setSpeakers(selected);
  };

  const showConfirmation = useConfirmationDialog();

  const languageOptions: any = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' }
  ];
  const usersOptions: any = [
    { value: 'All', label: 'All' },
    { value: 'Women', label: 'Women' },
    { value: 'Men', label: 'Men' },
    { value: 'Normal', label: 'Normal user' },
    { value: 'Couch', label: 'Couch' },
    { value: 'NoneRigistred', label: 'None Rigistred' }
  ];

  const speakersOptions = usersData
    ?.filter((user: any) => !speakers.some((selected: any) => selected.value.uid === user.uid))
    .map((user: any) => ({
      value: {
        name: user.name,
        image: user.photoUrl || null,
        uid: user?.uid,
        status: 'send',
        type: 'secondary'
      },
      label: user?.name
    }));

  return (
    <Modal
      visible={isModalOpen}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'Addlivebroadcast',
        defaultMessage: 'Add live broadcast'
      })}
    >
      <form
        className="flex flex-col space-y-4 items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="flex-col space-y-4 w-full">
          {/* Image Upload */}
          <div
            className="flex flex-row justify-between items-center p-0 flex-grow-0 "
            style={{
              width: `${Responsive.w(748, width)}px`,
              maxHeight: `${Responsive.h(70, height)}px`
            }}
          >
            <label
              htmlFor="file-upload"
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(175, width)}px`,
                height: `${Responsive.h(31, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`,
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'uploadImage',
                defaultMessage: 'Upload Image'
              })}
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="flex items-center  text-[#fff] text-left outline-none justify-center gap-[10px] flex-grow-0 p-[10px]  rounded-[10px]"
              style={{
                width: `${Responsive.w(220, width)}px`
              }}
              ref={fileInputRef}
            />
            {image && image != 'noImage' && (
              <div className="relative w-full flex justify-between items-center">
                <img
                  src={image}
                  alt="Banner"
                  className="w-full object-contain mr-28"
                  style={{
                    maxHeight: `${Responsive.h(100, height)}px`
                  }}
                />
                <button
                  type="button"
                  className="absolute top-2 right-2 text-white font-semibold hover:bg-slate-600 hover:cursor-pointer p-2 text-center bg-blue-950 rounded"
                  onClick={handleRestoreClick}
                >
                  {intl.formatMessage({
                    id: 'removeImage',
                    defaultMessage: 'Remove image'
                  })}
                </button>
              </div>
            )}
          </div>
          {!image && imageError && <p className="text-red-500">{imageError}</p>}
          {/* Title English */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'liveNameEn',
                defaultMessage: 'Live Name English'
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={title.titleEn}
              onChange={(e) => setTitle({ ...title, titleEn: e.target.value })}
              placeholder={useIntl().formatMessage({
                id: 'enterLiveNameEn',
                defaultMessage: 'Enter Live Name English'
              })}
            />
          </div>
          {!title.titleEn && titleErrorEn && <p className="text-red-500">{titleErrorEn}</p>}
          {/* Title Arabic */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'liveNameAr',
                defaultMessage: 'Live Name Arabic'
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={title.titleAr}
              onChange={(e) => setTitle({ ...title, titleAr: e.target.value })}
              placeholder={useIntl().formatMessage({
                id: 'enterLiveNameAr',
                defaultMessage: 'Enter Live Name Arabic'
              })}
            />
          </div>
          {!title.titleAr && titleErrorAr && <p className="text-red-500">{titleErrorAr}</p>}

          {/* Description  english*/}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'liveDetailsEn ',
                defaultMessage: 'Live Details English '
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={description.detailsEn}
              onChange={(e) => setDescription({ ...description, detailsEn: e.target.value })}
              placeholder="Enter Live Details English "
            />
          </div>
          {!description.detailsEn && detailsErrorEn && (
            <p className="text-red-500">{detailsErrorEn}</p>
          )}

          {/* Description  arabic*/}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'liveDetailsAr ',
                defaultMessage: 'Live Details Arabic '
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={description.detailsAr}
              onChange={(e) => setDescription({ ...description, detailsAr: e.target.value })}
              placeholder="Enter Live Details Arabic "
            />
          </div>
          {!description.detailsAr && detailsErrorAr && (
            <p className="text-red-500">{detailsErrorAr}</p>
          )}

          {/* Speakers */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'speakers',
                defaultMessage: 'Speakers'
              })}
            </label>
            <Select
              className="w-2/3"
              isMulti
              options={speakersOptions}
              value={speakers}
              onChange={(selected: any) => handleSpeakersChange(selected)}
            />
          </div>
          {!speakers.length && speakersError && <p className="text-red-500">{speakersError}</p>}
          {/* type */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'main Speaker',
                defaultMessage: 'Main Speaker'
              })}
            </label>
            <Select
              className="w-2/3"
              options={speakers}
              value={type}
              onChange={(select) => handleTypeChange(select)}
            />
          </div>
          {!type && typeError && <p className="text-red-500">{typeError}</p>}

          {/* Users */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'allowedUsers',
                defaultMessage: 'Allowed Users'
              })}
            </label>
            <Select
              className="w-2/3"
              isMulti={isMulti} // Modify this line
              options={usersOptions}
              value={allowedUsers}
              onChange={handleUsersChange}
            />
          </div>
          {!allowedUsers.length && usersError && <p className="text-red-500">{usersError}</p>}
          {/* Language */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'language',
                defaultMessage: 'Language'
              })}
            </label>
            <Select
              className="w-2/3"
              isMulti
              options={languageOptions}
              value={language}
              onChange={(selected: any) => setLanguage(selected)}
            />
          </div>
          {!language.length && languageError && <p className="text-red-500">{languageError}</p>}
          <div className="flex justify-between items-center bg-slate-50 rounded-xl  p-4">
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'status',
                defaultMessage: 'Status'
              })}
            </label>
            <ToggleSlider
              onClick={() => setStatus(!status)}
              checked={status}
            />
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            className="px-4 py-2 mt-4  text-white rounded "
            style={{
              backgroundColor: dashColor
            }}
          >
            {useIntl().formatMessage({
              id: 'liveNow',
              defaultMessage: 'Live Now'
            })}
          </button>
        </div>
      </form>
    </Modal>
  );
};

interface InitialData {
  id?: string;
  time?: string;
  date?: string;
  title?: {
    titleAr: string;
    titleEn: string;
  };
  status?: string;
  details?: {
    detailsAr: string;
    detailsEn: string;
  };
  Speakers?: [];
  type?: string;
  language?: any[];
  AllowedUsers?: any[];
}

// Schedule Form
const ScheduleForm = ({
  isModalOpenSchedule,
  setIsModalOpenSchedule,
  initialData
}: {
  isModalOpenSchedule: boolean;
  setIsModalOpenSchedule: (isOpen: boolean) => void;
  initialData?: InitialData;
}) => {
  // State for the form fields
  console.log('initialData', initialData?.AllowedUsers);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [time, setTime] = useState<any>(initialData?.time || '');
  const [date, setDate] = useState<any>(initialData?.date || '');
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setIsloading] = useState(false);
  const [title, setTitle] = useState(
    initialData?.title || {
      titleEn: '',
      titleAr: ''
    }
  );
  const [admin, setAdmin] = useState('');
  const [status, setStatus] = useState<boolean>(
    initialData?.status === 'Active' ? true : false || false
  );
  const [description, setDescription] = useState(
    initialData?.details || {
      detailsEn: '',
      detailsAr: ''
    }
  );
  const [speakers, setSpeakers] = useState<any>(
    initialData?.Speakers?.map((speak: any) => ({
      value: {
        uid: speak?.uid,
        name: speak?.name,
        image: speak.image,
        type: speak?.type,
        status: speak?.status
      },
      label: speak?.name
    })) || []
  );
  const [type, setType] = useState<any>(
    initialData?.Speakers?.map((speak: any) => ({
      value: {
        uid: speak?.uid,
        name: speak?.name,
        image: speak.image,
        type: 'primary',
        status: speak?.status
      },
      label: speak?.name
    })) || ''
  );
  const [language, setLanguage] = useState(
    initialData?.language?.map((lang: any) => ({
      value: lang,
      label: lang === 'en' ? 'English' : lang === 'ar' ? 'Arabic' : lang
    })) || []
  );
  const [allowedUsers, setAllowedUsers] = useState<any>(
    initialData?.AllowedUsers?.map((user: any) => ({
      value: user,
      label: user === 'NoneRigistred' ? 'None Rigistred' : user === 'Normal' ? 'Normal user' : user
    })) || []
  );
  const { width, height } = useWindowDimensions();
  const [isMulti, setIsMulti] = useState(true);
  // State for validation messages
  const [titleError, setTitleError] = useState('');
  const [adminError, setAdminError] = useState('');
  const [usersError, setUsersError] = useState('');
  const [titleErrorEn, setTitleErrorEn] = useState('');
  const [titleErrorAr, setTitleErrorAr] = useState('');
  const [detailsErrorEn, setDetailsErrorEn] = useState('');
  const [detailsErrorAr, setDetailsErrorAr] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [speakersError, setSpeakersError] = useState('');
  const [imageError, setImageError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const fileInputRef = useRef<any>(null);
  const intl = useIntl();
  let initialQueries: any = [
    ['userType', '==', 'COACH'],
    ['accountStatus', '==', 'Active']
  ];

  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  console.log('admin', admin);
  console.log('speakers', speakers);
  console.log('type', type);
  const resetValues = () => {
    setTitle({
      titleEn: '',
      titleAr: ''
    });
    setTime('');
    setDate('');
    setDescription({
      detailsEn: '',
      detailsAr: ''
    });
    setSpeakers([]);
    setLanguage([]);
    setTitleError('');
    setAdminError('');
    setDescriptionError('');
    setSpeakersError('');
    setImageError('');
    setLanguageError('');
    setUsersError('');
    setAllowedUsers([]);
    setTypeError('');
    setType('');
    setStatus(false);
  };

  const handleClose = () => {
    setIsModalOpenSchedule(false);
    resetValues();
  };

  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const validateForm = () => {
    let isValid = true;
    if (!title.titleEn) {
      const errMsg = intl.formatMessage({
        id: 'liveNameEnRequired',
        defaultMessage: 'Live Name English is required'
      });
      setTitleErrorEn(errMsg);
      isValid = false;
    } else {
      setTitleErrorEn('');
    }
    if (!title.titleAr) {
      const errMsg = intl.formatMessage({
        id: 'liveNameArRequired',
        defaultMessage: 'Live Name Arabic is required'
      });
      setTitleErrorAr(errMsg);
      isValid = false;
    } else {
      setTitleErrorAr('');
    }

    if (!description.detailsEn) {
      const errMsg = intl.formatMessage({
        id: 'liveDetailsEnIsRequired',
        defaultMessage: 'Live Details English is required'
      });
      setDetailsErrorEn(errMsg);
      isValid = false;
    } else {
      setDetailsErrorEn('');
    }

    if (!description.detailsAr) {
      const errMsg = intl.formatMessage({
        id: 'liveDetailsArIsRequired',
        defaultMessage: 'Live Details Arabic is required'
      });
      setDetailsErrorAr(errMsg);
      isValid = false;
    } else {
      setDetailsErrorAr('');
    }

    if (!speakers.length) {
      const errMsg = intl.formatMessage({
        id: 'SpeakersIsRequired',
        defaultMessage: 'Speakers is required'
      });
      setSpeakersError(errMsg);
      isValid = false;
    } else {
      setSpeakersError('');
    }

    if (!language.length) {
      const errMsg = intl.formatMessage({
        id: 'languageIsRequired',
        defaultMessage: 'Language is required'
      });
      setLanguageError(errMsg);
      isValid = false;
    } else {
      setLanguageError('');
    }
    if (!allowedUsers.length) {
      const errMsg = intl.formatMessage({
        id: 'usersIsRequired',
        defaultMessage: 'Users is required'
      });
      setUsersError(errMsg);
      isValid = false;
    } else {
      setUsersError('');
    }
    if (!type) {
      const errMsg = intl.formatMessage({
        id: 'typrRequired',
        defaultMessage: 'Type is required'
      });
      setTypeError(errMsg);
      isValid = false;
    } else {
      setTypeError('');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const confirmationMsg = intl.formatMessage({
      id: 'Addlivebroadcast',
      defaultMessage: 'Are you sure you want to Add live broadcast?'
    });
    showConfirmation(confirmationMsg, async () => {
      try {
        const reqBody = {
          title: title,
          Admin: admin,
          details: description,
          Language: language.map((lang: any) => lang?.value),
          Speakers: speakers.map((speak: any) => speak?.value),
          AllowedUsers: allowedUsers.map((user: any) => user?.value),
          status: status ? 'Active' : 'NotActive',
          time: time,
          date: date
        };

        if (initialData) {
          // Perform PATCH request to update existing data
          await axiosInstance.patch(`/webinars/${initialData.id}`, reqBody);
        } else {
          // Perform POST request to add new data
          await axiosInstance.post('/webinars/', reqBody);
        }

        setIsModalOpenSchedule(false);
        resetValues();

        const successMsg = intl.formatMessage({
          id: 'webinarSent',
          defaultMessage: 'webinar sent successfully.'
        });
        toast.success(successMsg);
      } catch (error) {
        console.error('There is an error!:', error);
        const errMsg = intl.formatMessage({
          id: 'thereIsError',
          defaultMessage: 'There is an error!'
        });
        toast.error(errMsg);
      }
    });
  };

  async function fetchAllusers() {
    setIsloading(true);
    try {
      const responsUsers = await axiosInstance.get(`/consultants`, {
        params: {
          queries: initialQueries
        }
      });
      setUsersData(responsUsers.data.result.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    setIsloading(false);
  }

  async function fetchgetProfile() {
    try {
      const profile = await axiosInstance.get('profile');
      setAdmin(profile.data.result.name);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }

  useEffect(() => {
    fetchAllusers();
    fetchgetProfile();
  }, []);

  useEffect(() => {
    if (!speakers.length) {
      setType('');
    }
  }, [speakers]);

  const handleUsersChange = (selected) => {
    // Ensure 'selected' is an array
    if (!Array.isArray(selected)) {
      selected = [selected];
    }

    if (selected.some((user) => user.value === 'All')) {
      // If "All" is selected, set "All" as the only selected option and set isMulti to false
      setAllowedUsers([{ value: 'All', label: 'All' }]);
      setIsMulti(false);
    } else {
      // If any other option is selected, remove "All" from the selection and set isMulti to true
      const filteredSelected = selected.filter((user) => user.value !== 'All');
      setAllowedUsers(filteredSelected);
      setIsMulti(true);
    }
  };
  const handleTypeChange = (selectedType) => {
    setType(selectedType);

    const updatedSpeakers = speakers.map((speaker: any) =>
      speaker.value.uid === selectedType.value.uid
        ? { ...speaker, value: { ...speaker.value, type: 'primary' } }
        : { ...speaker, value: { ...speaker.value, type: 'secondary' } }
    );
    setSpeakers(updatedSpeakers);
  };

  const handleSpeakersChange = (selected) => {
    setSpeakers(selected);
  };

  const handleDateIconClick = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  const handleTimeIconClick = () => {
    setTimePickerOpen(!timePickerOpen);
  };

  const showConfirmation = useConfirmationDialog();
  let languageOptions: any = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' }
  ];

  let usersOptions: any = [
    { value: 'All', label: 'All' },
    { value: 'Women', label: 'Women' },
    { value: 'Men', label: 'Men' },
    { value: 'Normal', label: 'Normal user' },
    { value: 'Couch', label: 'Couch' },
    { value: 'NoneRigistred', label: 'None Rigistred' }
  ];

  const speakersOptions = usersData
    ?.filter((user: any) => !speakers.some((selected: any) => selected.value.uid === user.uid))
    .map((user: any) => ({
      value: {
        name: user.name,
        image: user.photoUrl || null,
        uid: user?.uid,
        status: 'send',
        type: 'secondary'
      },
      label: user?.name
    }));

  return (
    <Modal
      visible={isModalOpenSchedule}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'ScheduleAlivebroadcast',
        defaultMessage: 'Schedule a live broadcast'
      })}
    >
      <form
        className="flex flex-col space-y-4 items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="flex-col space-y-4 w-full">
          {/* Title */}

          {/* Title English */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'liveNameEn',
                defaultMessage: 'Live Name English'
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={title.titleEn}
              onChange={(e) => setTitle({ ...title, titleEn: e.target.value })}
              placeholder={useIntl().formatMessage({
                id: 'enterLiveNameEn',
                defaultMessage: 'Enter Live Name English'
              })}
            />
          </div>
          {!title.titleEn && titleErrorEn && <p className="text-red-500">{titleErrorEn}</p>}
          {/* Title Arabic */}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'liveNameAr',
                defaultMessage: 'Live Name Arabic'
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={title.titleAr}
              onChange={(e) => setTitle({ ...title, titleAr: e.target.value })}
              placeholder={useIntl().formatMessage({
                id: 'enterLiveNameAr',
                defaultMessage: 'Enter Live Name Arabic'
              })}
            />
          </div>
          {!title.titleAr && titleErrorAr && <p className="text-red-500">{titleErrorAr}</p>}

          {/* Description  english*/}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'liveDetailsEn ',
                defaultMessage: 'Live Details English '
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={description.detailsEn}
              onChange={(e) => setDescription({ ...description, detailsEn: e.target.value })}
              placeholder="Enter Live Details English "
            />
          </div>
          {!description.detailsEn && detailsErrorEn && (
            <p className="text-red-500">{detailsErrorEn}</p>
          )}

          {/* Description  arabic*/}
          <div className="flex justify-between items-center">
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'liveDetailsAr ',
                defaultMessage: 'Live Details Arabic '
              })}
            </label>
            <input
              className="w-2/3 flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
              style={{
                borderRadius: `${Responsive.sp(8, width)}px`
              }}
              value={description.detailsAr}
              onChange={(e) => setDescription({ ...description, detailsAr: e.target.value })}
              placeholder="Enter Live Details Arabic "
            />
          </div>
          {!description.detailsAr && detailsErrorAr && (
            <p className="text-red-500">{detailsErrorAr}</p>
          )}
          {/* Speakers */}
          <div
            className="flex justify-between items-center"
            style={{
              width: `${Responsive.w(748, width)}px`
            }}
          >
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'speakers',
                defaultMessage: 'Speakers'
              })}
            </label>
            <Select
              className="w-2/3"
              isMulti
              options={speakersOptions}
              value={speakers}
              onChange={(speakers) => handleSpeakersChange(speakers)}
            />
          </div>
          {!speakers.length && speakersError && <p className="text-red-500">{speakersError}</p>}

          {/* Type */}
          <div
            className="flex justify-between items-center"
            style={{
              width: `${Responsive.w(748, width)}px`
            }}
          >
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {intl.formatMessage({
                id: 'mainSpeaker',
                defaultMessage: 'Main Speaker'
              })}
            </label>
            <Select
              className="w-2/3"
              options={speakers}
              value={type}
              onChange={(select) => handleTypeChange(select)}
            />
          </div>
          {!type && typeError && <p className="text-red-500">{typeError}</p>}

          {/* Users */}
          <div
            className="flex justify-between items-center"
            style={{
              width: `${Responsive.w(748, width)}px`
            }}
          >
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'allowedUsers',
                defaultMessage: 'Allowed Users'
              })}
            </label>
            <Select
              className="w-2/3"
              isMulti={isMulti}
              options={usersOptions}
              value={allowedUsers}
              onChange={handleUsersChange}
            />
          </div>
          {!allowedUsers.length && usersError && <p className="text-red-500">{usersError}</p>}

          {/* Language */}
          <div
            className="flex justify-between items-center"
            style={{
              width: `${Responsive.w(748, width)}px`
            }}
          >
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'language',
                defaultMessage: 'Language'
              })}
            </label>
            <Select
              className="w-2/3"
              isMulti
              options={languageOptions}
              value={language}
              onChange={(selected: any) => setLanguage(selected)}
            />
          </div>
          {!language.length && languageError && <p className="text-red-500">{languageError}</p>}

          {/* Date */}
          <div
            className="custom-datepicker-wrapper flex justify-between items-center"
            style={{
              width: `${Responsive.w(748, width)}px`
            }}
          >
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'date',
                defaultMessage: 'Date'
              })}
            </label>
            <div className="w-2/3 relative custom-datepicker-wrapper">
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                open={datePickerOpen}
                onClickOutside={() => setDatePickerOpen(false)}
                className="w-full flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholderText={useIntl().formatMessage({
                  id: 'chooseDate',
                  defaultMessage: 'Choose Date'
                })}
                dateFormat="M / d / yyyy"
              />
              <Icon
                icon={'uil:calender'}
                className="w-6 h-6 absolute top-[10px] right-[10px] cursor-pointer"
                onClick={handleDateIconClick}
                color="#cf0036"
              />
            </div>
          </div>

          {/* Time */}
          <div
            className="custom-datepicker-wrapper flex justify-between items-center"
            style={{
              width: `${Responsive.w(748, width)}px`
            }}
          >
            <label
              className="w-1/3  font-[Montserrat] font-semibold text-left select-none"
              style={{
                color: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'time',
                defaultMessage: 'Time'
              })}
            </label>
            <div className="w-2/3 relative custom-datepicker-wrapper">
              <DatePicker
                selected={time}
                onChange={(time) => setTime(time)}
                open={timePickerOpen}
                onClickOutside={() => setTimePickerOpen(false)}
                className="w-full flex items-center outline-none justify-start gap-[10px] flex-grow-0 py-2 px-[16px] border-[1px] border-solid border-[#939393] rounded-[8px]"
                placeholderText={useIntl().formatMessage({
                  id: 'chooseTime',
                  defaultMessage: 'Choose Time'
                })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
              <Icon
                icon={'ph:clock-bold'}
                className="w-6 h-6 absolute top-[10px] right-[10px] cursor-pointer"
                onClick={handleTimeIconClick}
                color="#cf0036"
              />
            </div>
          </div>

          <div className="flex justify-between items-center bg-slate-50 rounded-xl  p-4">
            <label
              className=" font-[Montserrat] font-semibold text-left"
              style={{
                width: `${Responsive.w(64, width)}px`,
                color: dashColor,
                height: `${Responsive.h(29, height)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {useIntl().formatMessage({
                id: 'status',
                defaultMessage: 'Status'
              })}
            </label>
            <ToggleSlider
              onClick={() => setStatus(!status)}
              checked={status}
            />
          </div>

          {/* Submit Button */}
          {initialData === undefined ? (
            <button
              type="submit"
              className="px-4 py-2 mt-4  text-white rounded"
              style={{
                backgroundColor: dashColor
              }}
            >
              {useIntl().formatMessage({
                id: 'save',
                defaultMessage: 'Save'
              })}
            </button>
          ) : (
            <div className="flex justify-between w-full items-center">
              <button
                type="submit"
                className="px-4 py-2 mt-4  text-white rounded"
                style={{
                  backgroundColor: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'update',
                  defaultMessage: 'Update'
                })}
              </button>
              <button
                className="px-4 py-2 mt-4  text-[#f93c65] rounded flex gap-2 items-center justify-center"
                style={{
                  backgroundColor: '#ffdbe3'
                }}
              >
                <Icon
                  icon={'material-symbols:delete-outline'}
                  color="#f93c65"
                />
                {useIntl().formatMessage({
                  id: 'delete',
                  defaultMessage: 'Delete'
                })}
              </button>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
