import React, { cloneElement, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { titles } from '../helper/PageTitles';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import Alert from 'src/components/shared/Alert';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageSelector from 'src/components/LanguageSelector';
import LanguageSelectorTwo from 'src/components/LanguageSelectorTwo';
import axiosInstance from 'src/helper/AxiosInstance';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import useLogout from 'src/hooks/useLogout';
import img_1 from '../screens/images/logout.png';
import img_1_2 from '../screens/images/logoutcurve@2x.png';
import img_1_3 from '../screens/images/logoutcurve@3x.png';
import img_2 from '../screens/images/log.png';
import img_2_2 from '../screens/images/logx2.png';
import img_2_3 from '../screens/images/logx3.png';
import img_3 from '../screens/images/acc.png';
import img_3_2 from '../screens/images/accX2.png';
import img_3_3 from '../screens/images/accX3.png';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { Responsive } from './Responsive';
const SideBar = React.lazy(() => import('./SideBar'));
const HeaderLayout: React.FC<{ children: any }> = ({ children }): JSX.Element => {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [data, setData] = useState<any>({
    AdminProfile: { data: [], loading: true }
  });
  console.log(data.AdminProfile.data);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleLogOut = () => setLogOut(true);
  const { handleLogged } = useLogout();
  const { pathname } = useLocation();
  const { response } = useSelector((state: RootState) => state.globalResponse);
  console.log('object', pathname);
  const handleMenu = useCallback(function () {
    document.querySelector('[data-type="menu"]')?.classList.remove('hidden');
  }, []);
  useEffect(() => {
    fetchgetProfile();
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  async function fetchgetProfile() {
    try {
      const profile = await axiosInstance.get('profile');
      setData((prevData) => ({
        ...prevData,
        AdminProfile: { data: profile.data.result, loading: false }
      }));
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }

  const handleLogout = () => {
    // Example color
    const styleSheet = document.styleSheets[0]; // Select the first stylesheet

    // Insert each rule separately
    styleSheet.insertRule(
      `.swal2-html-container { color: ${dashColor} !important; }`,
      styleSheet.cssRules.length
    );
    styleSheet.insertRule(
      `.swal2-close { color: ${dashColor} !important; }`,
      styleSheet.cssRules.length
    );
    styleSheet.insertRule(
      `.my-confirm-button { background-color: ${dashColor} !important; color: white !important; }`,
      styleSheet.cssRules.length
    );
    styleSheet.insertRule(
      `.my-cancel-button { border-color: ${dashColor} !important; color: ${dashColor} !important; }`,
      styleSheet.cssRules.length
    );

    Swal.fire({
      text: 'Are you sure you want to logout?',
      iconHtml: `
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="${dashColor}">
        <g fill="${dashColor}">
          <path d="M14.945 1.25c-1.367 0-2.47 0-3.337.117c-.9.12-1.658.38-2.26.981c-.524.525-.79 1.17-.929 1.928c-.135.737-.161 1.638-.167 2.72a.75.75 0 0 0 1.5.008c.006-1.093.034-1.868.142-2.457c.105-.566.272-.895.515-1.138c.277-.277.666-.457 1.4-.556c.755-.101 1.756-.103 3.191-.103h1c1.436 0 2.437.002 3.192.103c.734.099 1.122.28 1.4.556c.276.277.456.665.555 1.4c.102.754.103 1.756.103 3.191v8c0 1.435-.001 2.436-.103 3.192c-.099.734-.279 1.122-.556 1.399c-.277.277-.665.457-1.399.556c-.755.101-1.756.103-3.192.103h-1c-1.435 0-2.436-.002-3.192-.103c-.733-.099-1.122-.28-1.399-.556c-.243-.244-.41-.572-.515-1.138c-.108-.589-.136-1.364-.142-2.457a.75.75 0 1 0-1.5.008c.006 1.082.032 1.983.167 2.72c.14.758.405 1.403.93 1.928c.601.602 1.36.86 2.26.982c.866.116 1.969.116 3.336.116h1.11c1.368 0 2.47 0 3.337-.116c.9-.122 1.658-.38 2.26-.982c.602-.602.86-1.36.982-2.26c.116-.867.116-1.97.116-3.337v-8.11c0-1.367 0-2.47-.116-3.337c-.121-.9-.38-1.658-.982-2.26c-.602-.602-1.36-.86-2.26-.981c-.867-.117-1.97-.117-3.337-.117h-1.11Z"/>
          <path d="M15 11.25a.75.75 0 0 1 0 1.5H4.027l1.961 1.68a.75.75 0 1 1-.976 1.14l-3.5-3a.75.75 0 0 1 0-1.14l3.5-3a.75.75 0 1 1 .976 1.14l-1.96 1.68H15Z"/>
        </g>
      </svg>
    `,
      showCancelButton: true,
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
      showCloseButton: true,
      customClass: {
        confirmButton: 'my-confirm-button',
        cancelButton: 'my-cancel-button'
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogged();
      }
    });
  };

  return (
    <React.Fragment>
      <div
        className="flex min-h-screen"
        data-type="body"
      >
        {pathname != '/welcome' && pathname != '/permissions' &&
         <Suspense fallback={Spinner()}>
          <SideBar />
          </Suspense>}

        <main
          className="flex-1 shrink-0  flex flex-col"
          data-type="content"
          style={{
            width: `${Responsive.w(1584, width)}px`
          }}
        >
          {pathname != '/welcome' && (
            <header
              className="bg-white w-full sticky top-0 z-[300]  flex flex-grow-0 items-center justify-between gap-[32px] py-4 px-24px border-solid border-b-[1px] border-[#e3e3e3]"
              style={{
                // width: `${Responsive.w(1584, width)}px`,
                height: `${Responsive.h(112, height)}px`
              }}
            >
              <div
                className="flex justify-between items-start w-full"
                style={{
                  // width: `${Responsive.w(1560, width)}px`,
                  height: `${Responsive.h(112, height)}px`
                }}
              >
                <div>
                  <button
                    className="btn-with-icon !text-neutral-500 md:hidden "
                    onClick={handleMenu}
                  >
                    <Icon
                      icon="heroicons-solid:menu"
                      width={22}
                    />
                  </button>
                </div>
                <div className="flex flex-grow-0 justify-start w-full my-auto">
                  <LanguageSelectorTwo />
                </div>
                <div className="relative flex  items-center justify-end flex-grow-2  p-0 gap-[10px] my-auto mr-3">
                  <div className="flex flex-col ">
                    <h4 className="h-[20px] self-stretch text-[16px] text-[#1e1e1e] font-[Montserrat] flex-grow-0 text-right font-bold nowrap">
                      {data?.AdminProfile?.loading ? <Spinner /> : data?.AdminProfile?.data?.name}
                    </h4>
                    <p className="h-[17px]  self-stretch text-[14px] text-[#656565] font-[Montserrat] flex-grow-0 text-left my-auto nowrap">
                      {data?.AdminProfile?.loading ? (
                        <Spinner />
                      ) : (
                        data?.AdminProfile?.data?.modulePermission
                      )}
                    </p>
                  </div>
                  <button
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    className="text-white mb-2 z-20 font-medium rounded-lg text-sm text-center inline-flex items-center"
                    type="button"
                  >
                    <img
                      className=" rounded-[50%] flex-grow-0 cursor-pointer w-14 "
                      src={data?.AdminProfile?.data?.image || '/images/placeholder.png'}
                      alt="profileImage"
                      onClick={toggleDropdown}
                     
                    />
                  </button>
                  {isOpen && (
                    <div
                      id="dropdown"
                      className="logout_down z-10 top-[115%]  p-[16px] bg-white  rounded-[16px]  w-[207px] justify-center m-auto"
                      style={{
                        position: 'absolute',
                        boxShadow: ' 1px 1px 20.2px 0 rgba(0, 0, 0, 0.07)'
                      }}
                    >
                      <ul
                        className="text-center flex  flex-col items-start justify-start gap-[16px] "
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li className="flex w-[137px] items-center justify-start h-[24px]">
                          <img
                            src={img_3}
                            srcSet={`${img_3_2}, ${img_3_3}`}
                            className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 w-[24px] h-[24px]"
                            alt="Description of the image"
                          />
                          <NavLink
                            to="/account/profile"
                            className="nowrap block px-4 py-2 mb-4 font-medium  h-[20px] text-[16px] font-[Montserrat] text-[#1e1e1e] dark:hover:text-[#7b6c96]"
                            // Replace with actual language change function
                          >
                            {
                              <FormattedMessage
                                id="myAccount"
                                defaultMessage=" My Account"
                              />
                            }
                          </NavLink>
                        </li>
                        <li
                          onClick={handleLogout}
                          className="flex w-[137px] items-center justify-start h-[24px]"
                        >
                          <img
                            src={img_2}
                            srcSet={`${img_2_2}, ${img_2_3}`}
                            className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 w-[24px] h-[24px]"
                            alt="Description of the image"
                          />
                          <a
                            href="#"
                            className="block px-4 py-2  font-medium   text-[16px] font-[Montserrat] text-[#1e1e1e] dark:hover:text-[#7b6c96]"
                            // Replace with actual language change function
                          >
                            {
                              <FormattedMessage
                                id="logout"
                                defaultMessage="Logout"
                              />
                            }
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </header>
          )}
          <div className="flex-1 w-full">{children}</div>
        </main>
      </div>
    </React.Fragment>
  );
};
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}
export default HeaderLayout;
