import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import SharedTime from 'src/components/shared/SharedTime';
import axiosInstance from 'src/helper/AxiosInstance';
import { RootState } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { getDirectionClass } from 'src/helper/directionClass';

function Index() {
  const [admin, setAdmin] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const adminId = user.uid;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAdmin = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/admins/${adminId}`);
        setAdmin(response.data.result.data);
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAdmin();
  }, [adminId]);
  if (isLoading) return <LoadingComponent />;
  return (
    <div className="flex flex-col items-center  py-12 bg-slate-100 min-h-screen">
      <div className="flex justify-end mt-4 w-full px-12">
        <button
          onClick={() => navigate('/admins')}
          className="px-4 py-2 bg-primary text-white rounded hover:bg-slate-600"
        >
          {useIntl().formatMessage({
            id: 'allAdmins',
            defaultMessage: 'All admins'
          })}
        </button>
      </div>
      <div className="w-full max-w-md border border-black rounded-lg p-8 shadow-lg bg-white">
        <h1 className="text-center text-2xl font-bold mb-6">
          {' '}
          {useIntl().formatMessage({
            id: 'myAccount',
            defaultMessage: 'my account'
          })}
        </h1>
        <AdminForm admin={admin} />
      </div>
    </div>
  );
}

const AdminForm = ({ admin }) => {
  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const direction = getDirectionClass();

  useEffect(() => {
    if (admin) {
      setName(admin.name || '');
      setMobileNo(admin.mobileNo || '');
      setEmail(admin.email || '');
    }
  }, [admin]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitted');
  };

  return (
    <form
      className="flex flex-col space-y-4 "
      onSubmit={handleSubmit}
    >
      <FormField
        label={useIntl().formatMessage({
          id: 'fullName',
          defaultMessage: 'full name'
        })}
        value={name}
        readOnly
      />
      <FormField
        label={useIntl().formatMessage({
          id: 'mobileNumber',
          defaultMessage: 'mobile number'
        })}
        value={mobileNo}
        readOnly
      />
      <FormField
        label={useIntl().formatMessage({
          id: 'email',
          defaultMessage: 'email'
        })}
        value={email}
        readOnly
      />
      <FormField
        label={useIntl().formatMessage({
          id: 'primaryAdmin',
          defaultMessage: 'primary admin'
        })}
        value={
          admin.primaryAdmin
            ? useIntl().formatMessage({
                id: 'yes',
                defaultMessage: 'yes'
              })
            : {
                id: 'no',
                defaultMessage: 'no'
              }
        }
        readOnly
      />

      <div className={`flex justify-between items-center ${direction}`}>
        <label className="w-1/3 mx-2">
          {useIntl().formatMessage({
            id: 'lastUpdated',
            defaultMessage: 'last updated'
          })}
        </label>
        <p className="w-2/3 border border-gray-300 rounded p-2">
          {' '}
          <SharedTime
            date={
              new Date(admin?.timestamp?._seconds * 1000 + admin?.timestamp?._nanoseconds / 1000000)
            }
            format="MMMM Do YYYY, h:mm:ss a"
          />
        </p>
      </div>

      <div className="flex justify-center mt-4">
        <button
          type="submit"
          className="px-4 py-2 bg-primary text-white rounded hover:bg-slate-600"
        >
          {useIntl().formatMessage({
            id: 'save',
            defaultMessage: 'save'
          })}
        </button>
      </div>
    </form>
  );
};

// Making 'onChange' optional
const FormField = ({ label, value, onChange = (e?) => {}, readOnly }) => {
  const direction = getDirectionClass();
  return (
    <div className={`flex justify-between items-center ${direction}`}>
      <label className="w-1/3 mx-2">{label}</label>
      <input
        type="text"
        className="w-2/3 border border-gray-300 rounded p-2"
        value={value}
        onChange={readOnly ? undefined : (e) => onChange(e.target.value)}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Index;
