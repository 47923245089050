import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import SmallLoader from 'src/components/shared/SmallLoader';

const Index = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  // Extract the token from URL
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
       const response = await axios.post('https://api.focalpoint-apps.com/forgot-password', {
          email
      });
      // test
      // const response = await axios.post('http://localhost:3004/forgot-password', {
      //  email
      //  });
      setLoading(false);
      toast.success('A link has been sent to your email to reset your password');
      setMessage('Redirecting to login ...');
      setTimeout(() => {
        navigate('/account/login');
      }, 3000);
    } catch (error: any) {
      setMessage(error.response?.data || 'An error occurred');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Link
            to="/account/login"
            className="flex justify-center"
          >
            <img
              src="/images/focalPointLogo.png"
              alt="website logo"
              className="w-full max-w-[8rem]"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {useIntl().formatMessage({
              id: 'resetYourPassword',
              defaultMessage: 'Reset Your Password'
            })}
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={handleSubmit}
        >
          <input
            type="hidden"
            name="remember"
            value="true"
          />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="new-password"
                className="sr-only"
              >
                {useIntl().formatMessage({
                  id: 'enterYourEmail',
                  defaultMessage: 'enter your email'
                })}
              </label>
              <input
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={useIntl().formatMessage({
                  id: 'enterYourEmail',
                  defaultMessage: 'enter your email'
                })}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-8">
            {!loading ? (
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#2980BA] hover:bg-slate-600 "
              >
                {useIntl().formatMessage({
                  id: 'sendResetLink',
                  defaultMessage: 'Send reset link to my email'
                })}
              </button>
            ) : (
              <SmallLoader />
            )}
            <button
              onClick={() => navigate('/account/login')}
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#2980BA] hover:bg-slate-600 "
            >
              {useIntl().formatMessage({
                id: 'back',
                defaultMessage: 'back'
              })}
            </button>
          </div>
        </form>
        {message && <p className="mt-2 text-center text-sm text-red-600">{message}</p>}
      </div>
    </div>
  );
};

export default Index;

