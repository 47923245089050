import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LanguageSelector from 'src/components/LanguageSelector';
import parseJwt from 'src/helper/parseJwt';
import useLogout from 'src/hooks/useLogout';
import { setDashboard } from 'src/reducers/dashboard';
import { RootState } from 'src/store';
import img from '../images/Frame 37.png';
import nikah from '../images/Mask Group 35 4.png';
import { Responsive } from './../../layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Icon } from '@iconify/react';
import { filterRoutes } from 'src/layouts/SideBar';
import axiosInstance from 'src/helper/AxiosInstance';
import axios from 'axios';

function Index() {
  const { token } = useSelector((state: RootState) => state.auth);
  const [pages, setPages] = useState<any>([]);
  const [loading , setLoading] = useState<boolean>(false);
  const { width, height } = useWindowDimensions();
  const payload = parseJwt(token);
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { handleLogged } = useLogout();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);

  const permissionData = async () => {
    try {
      setLoading(true);
      const response = await axios.get( `https://api.focalpoint-apps.com/permissions/${user?.permissionId}`);

      // test
      // const response = await axios.get(`http://localhost:3004/permissions/${user?.permissionId}`);
       setPages(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    permissionData();
  }, [user]);

  const PagesOptions = useMemo(
    () => filterRoutes(currentDashboard, pages , loading),
    [currentDashboard, pages]
  );

  const firstPagePath: any = PagesOptions?.[0]?.subRoutes
    ? PagesOptions?.[0]?.subRoutes?.[0]?.path
    : PagesOptions?.[0]?.path;

  console.log('PagesOptions', PagesOptions);

  const dashboards = payload?.rules.filter((dashboard) =>
    ['dream', 'jeras', 'beaut'].includes(dashboard)
  );
  console.log(
    'dashboards&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&',
    dashboards
  );
  const handleDashboardSelect = (dashboard) => {
    dispatch(setDashboard(dashboard));
  };

  const renderDashboardLinks = () => {
    if (!dashboards || dashboards.length === 0) {
      return (
        <p className="text-center text-lg text-gray-700">
          {intl.formatMessage({
            id: 'noAccess',
            defaultMessage: "Sorry, you don't have access to any of our dashboards currently."
          })}
        </p>
      );
    }

    return dashboards.map((dashboard, index) => {
      const isDream = dashboard === 'dream';
      const isNikah = dashboard === 'beaut';
      const linkClass = isDream
        ? 'bg-[#ffe8f9] hover:bg-[#E8B2DC] text-primary'
        : isNikah
        ? 'bg-[#FFD8E2] hover:bg-[#cf6a85] text-[#cf0036]'
        : 'bg-[#f5f0ff] hover:bg-[#A79CBD] text-[#7B6C96]';
      const logoSrc = isDream ? '/images/logo.webp' : isNikah ? nikah : img;
      const altText = isDream
        ? 'dream website logo'
        : isNikah
        ? 'nikah website logo'
        : 'jeras website logo';
      return (
        <a
          key={index}
          onClick={() => handleDashboardSelect(dashboard)}
          className={`group relative w-full  flex flex-col justify-center capitalize items-center border border-transparent font-semibold rounded-md ${linkClass} focus:outline-none focus:ring-2 focus:ring-offset-2`}
          href={firstPagePath}
          style={{
            padding: `${Responsive.sp(35, width)}px`,
            fontSize: `${Responsive.sp(19.8, width)}px`
          }}
        >
          <img
            src={logoSrc}
            alt={altText}
            width={40}
            height={25}
            className="w-8 h-8 mr-2"
          />
          <span>{isNikah ? 'Nikah' : dashboard}</span>
        </a>
      );
    });
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-white overflow-hidden"
      style={{ width: `${Responsive.w(1920, width)}px` }}
    >
      <div
        className=" flex items-center justify-center"
        style={{
          width: `${Responsive.w(800, width)}px`
        }}
      >
        <div className=" flex justify-end top-8 left-5 absolute">
          <LanguageSelector />
        </div>

        {user?.modulePermission === 'Super Admin' && (
          <a
            className=" flex justify-center items-center  top-8 right-5 absolute text-white"
            href="/permissions"
            // onClick={() => navigate('')}
            style={{
              height: `${Responsive.h(56, height)}px`,
              width: `${Responsive.w(240, width)}px`,
              gap: `${Responsive.sp(8, width)}px`,
              borderRadius: `${Responsive.sp(8, width)}px`,
              paddingInline: `${Responsive.sp(16, width)}px`,
              paddingBlock: `${Responsive.sp(5, width)}px`,
              background: 'rgb(41 128 186)'
            }}
          >
            <Icon
              icon={'ph:users'}
              color={'white'}
              className="w-[22px] h-[17px] font-semibold"
            />
            <p className="text-[16px] font-semibold">Permissions</p>
          </a>
        )}
        <div
          className="absolute  left-0 flex justify-center items-center p-8"
          style={{
            height: `${Responsive.h(1800, height)}px`,
            width: `${Responsive.w(512, width)}px`,
            background: 'rgb(41 128 186)',
            zIndex: 1
          }}
        >
          <img
            src="/images/focalPointLogo.png"
            alt="website logo"
            className="w-full  absolute "
            style={{
              width: `${Responsive.w(270, width)}px`,
              height: `${Responsive.h(288, height)}px`,
              right: '-28%',
              zIndex: '8'
            }}
          />
          <div
            className="hexagon"
            style={{
              width: `${Responsive.w(442, width)}px`,
              height: `${Responsive.h(250, height)}px`
            }}
          ></div>
        </div>
      </div>

      <div
        className="flex flex-col justify-center items-center p-8 rounded-lg"
        style={{
          width: `${Responsive.w(512, width)}px`,
          gap: `${Responsive.sp(64, width)}px`,
          boxShadow: '0 0 12.6px 0 rgba(0, 0, 0, 0.1)',
          border: 'solid 1px #b4b4b4'
        }}
      >
        <div
          className="flex flex-col gap-4 justify-center flex-grow-0 p-0"
          style={{ width: `${Responsive.w(440, width)}px` }}
        >
          <h2
            className="text-[#000] wrap text-center font-semibold font-[Montserrat] leading-7"
            style={{
              width: `${Responsive.w(440, width)}px`,
              fontSize: `${Responsive.sp(32, width)}px`
            }}
          >
            {intl.formatMessage({
              id: 'welcomeBack',
              defaultMessage: 'Welcome back to Focalpoint!'
            })}
          </h2>
          {dashboards.length > 0 && (
            <h3
              className="text-[#515151] wrap text-center font-[Montserrat]"
              style={{
                width: `${Responsive.w(440, width)}px`,
                fontSize: `${Responsive.sp(24, width)}px`
              }}
            >
              {intl.formatMessage({
                id: 'chooseDashboard',
                defaultMessage: 'Choose a dashboard to start'
              })}
            </h3>
          )}
        </div>

        <div className="space-y-6">
          <div
            className="flex justify-center items-center gap-4 "
            style={{ width: `${Responsive.w(343, width)}px` }}
          >
            {renderDashboardLinks()}
          </div>
        </div>
        <div
          style={{
            width: `${Responsive.w(440, width)}px`,
            fontSize: `${Responsive.sp(16, width)}px`
          }}
        >
          <button
            onClick={handleLogged}
            className="flex justify-center capitalize items-center py-2 px-4 border border-transparent rounded-md bg-[#2980ba] text-white"
            style={{
              width: `${Responsive.w(440, width)}px`,
              fontSize: `${Responsive.sp(16, width)}px`
            }}
          >
            {intl.formatMessage({
              id: 'signOut',
              defaultMessage: 'Sign out'
            })}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Index;

