export const formatStartDay = (date, type?): string => {
  // Start of the day
  const dateTime = date;
  console.log('dada', dateTime);
  var startOfDay = new Date(
    Date.UTC(dateTime.getUTCFullYear(), dateTime.getUTCMonth(), dateTime.getUTCDate())
  );
  var startOfDayTimestamp = startOfDay.getTime();
  console.log('ffro', `${startOfDayTimestamp}`);
  return `${startOfDayTimestamp}`;
};

export const formatEndDay = (date, type?): string => {
  // Start of the day
  const dateTime = date;

  var endOfDay = new Date(
    Date.UTC(
      dateTime.getUTCFullYear(),
      dateTime.getUTCMonth(),
      dateTime.getUTCDate() + 1,
      0,
      0,
      0,
      -1
    )
  );
  var endOfDayTimestamp = endOfDay.getTime();
  console.log('ffro', `${endOfDayTimestamp}`);

  return `${endOfDayTimestamp}`;
};
